/*Spacing Prebuilds*/
@spacing: 4px;
@spacing-0: @spacing * 0;
@spacing-1: @spacing * 1;
@spacing-2: @spacing * 2;
@spacing-3: @spacing * 3;
@spacing-4: @spacing * 4;
@spacing-5: @spacing * 5;
@spacing-large: @spacing * 8;

/*Flex Prebuilds*/
.njc-flex-direction-column() {
  display: flex;
  flex-direction: column;
}
.njc-flex-direction-column-reverse() {
  display: flex;
  flex-direction: column-reverse;
}
.njc-flex-direction-row() {
  display: flex;
  flex-direction: row;
}
.njc-flex-start() {
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-flex-center() {
  align-items: center;
  justify-content: center;
}
.njc-flex-startcenter() {
  align-items: center;
  justify-content: flex-start;
}
.njc-flex-centerstart() {
  align-items: flex-start;
  justify-content: center;
}
.njc-flex-endcenter() {
  align-items: center;
  justify-content: flex-end;
}
.njc-flex-justify() {
  align-items: center;
  justify-content: space-between;
}
.njc-flex-stretch() {
  align-items: stretch;
  justify-content: stretch;
}

.njc-flex-direction-column-start() {
  .njc-flex-direction-column;
  .njc-flex-start;
}
.njc-flex-direction-column-center() {
  .njc-flex-direction-column;
  .njc-flex-center;
}
.njc-flex-direction-row-start() {
  .njc-flex-direction-row;
  .njc-flex-start;
}
.njc-flex-direction-row-center() {
  .njc-flex-direction-row;
  .njc-flex-center;
}

.njc-position-relative {
  position: relative;
}

/* Text Prebuilds */

.njc-font-1() {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}

.njc-letter-spacing() {

}

.njc-text-heading-1() {
  font-weight: 700;
  font-size: 34px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  .njc-letter-spacing;
  .njc-font-1;
}
.njc-text-heading-2() {
  font-weight: 700;
  font-size: 24px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  margin: @spacing-0;
  .njc-letter-spacing;
  .njc-font-1;
}
.njc-text-heading-2-description() {
  font-weight: 400;
  font-size: 14px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  .njc-letter-spacing;
  .njc-font-1;
}

.njc-text-heading-3() {
  font-weight: 700;
  font-size: 18px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  .njc-letter-spacing;
  .njc-font-1;
}

.njc-h3-big-description {
  .njc-font-1;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--grayscale-100);
  margin: 16px 0 0 0;
}

.njc-text-heading-4() {
  font-weight: 700;
  font-size: 14px;
  color: var(--grayscale-100);
  .njc-letter-spacing;
  .njc-font-1;
}
.njc-text-tag() {
  color: var(--grayscale-0);
  font-weight: 700;
  font-size: 12px;
  border-radius: 10px;
  padding: @spacing-0 @spacing-3;
  .njc-letter-spacing;
}

.njc-text-tag-success {
  .njc-text-tag;
  background-color: var(--success-color);
}
.njc-text-tag-warning {
  .njc-text-tag;
  background-color: var(--warning-color-dark);
}
.njc-text-tag-critical {
  .njc-text-tag;
  background-color: var(--critical-color);
}
.njc-text-tag-groovy-info {
  .njc-text-tag;
  background-color: var(--purple-100);
}
.njc-text-tag-disabled {
  .njc-text-tag;
  background-color: var(--grayscale-70);
}
.njc-text-tag-info {
  .njc-text-tag;
  background-color: var(--grayscale-100);
}

.njc-text-beta-tag() {
  .njc-text-tag;
  text-transform: uppercase;
  background-color: var(--primary-color);
  margin: @spacing-0 @spacing-0 @spacing-5 @spacing-2;
}
.njc-text-threshold-tag() {
  font-weight: 700;
  font-size: 12px;
  color: var(--grayscale-0);
  border-radius: 12px;
  display: inline-block;
  height: 24px;
  margin: @spacing-0 @spacing-2;
  padding: 3px 10px;
  .njc-letter-spacing;
}
.njc-text-toast() {
  .njc-font-1;
  font-weight: 500;
  font-size: 14px;
  color: var(--grayscale-0);
}
.njc-text-after-label() {
  font-weight: 400;
  font-size: 14px;
  color: var(--grayscale-70);
}
.njc-text-toggle() {
  font-size: 13px;
  font-weight: 400;
  margin-left: @spacing-3;
  cursor: default;
}
.njc-text-heading-subtext {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-50);
  text-transform: uppercase;
  .njc-letter-spacing;

  a {
    font-size: 13px;
    font-wight: 500;
    color: var(--primary-color);
    text-decoration: none;
    text-transform: capitalize;
    cursor: pointer;
    .njc-letter-spacing;
  }
}

.njc-text-warning {
  font-weight: 400;
  font-size: 12px;
  color: var(--critical-color);
  line-height: 18px;
  .njc-letter-spacing;
  .njc-flex-direction-row;
  .njc-flex-startcenter;
  display: inline-block;
  svg {
    .njc-sizing-sq(18px);
    margin-right: @spacing-1;
  }
}

.njc-text-token-warning {
  font-weight: 600;
  font-size: 12px;
  color: var(--critical-color);
  line-height: 18px;
  .njc-letter-spacing;
  .njc-flex-direction-row;
  .njc-flex-startcenter;
  white-space: nowrap;
  svg {
    .njc-sizing-sq(18px);
    margin-right: @spacing-1;
  }
}

.njc-text-align-left {
  text-align: left !important;
}

.njc-text-paragraph() {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  .njc-letter-spacing;
}
.njc-text-paragraph-bold() {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  .njc-letter-spacing;
}
.njc-text-paragraph-light() {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-50);
  line-height: 18px;
  .njc-letter-spacing;
}
.njc-text-dropdown-empty-message() {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-60);
  line-height: 18px;
  .njc-letter-spacing;
}
.njc-text-table-header(){
  font-weight: 700;
  font-size: 13px;
  color: var(--grayscale-60);
  text-transform: capitalize;
  text-decoration: none;
  line-height: 16px;
  .njc-font-1;
  .njc-letter-spacing;

  &.disabled {
    color: var(--grayscale-30);
    cursor: not-allowed;
    &:hover {
      color: var(--grayscale-30);
    }
  }
  &.w--current {
    color: var(--primary-color);
    border-radius: 5px;
    background-color: var(--secondary-color);
    .njc-letter-spacing;
    &:hover {
      color: var(--primary-color);
    }
  }
}
.njc-text-table-list-subhead() {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--grayscale-60);
  .njc-font-1;
  .njc-letter-spacing;
}
.njc-text-table-footer(){
  font-weight: 400;
  font-size: 14px;
  border-radius: 5px;
  margin: 0 1px;
  color: var(--grayscale-70);
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;
  .njc-font-1;
  .njc-letter-spacing;


  &.disabled {
    color: var(--grayscale-30);
    cursor: not-allowed;
    &:hover {
      color: var(--grayscale-30);
      background-color: transparent;
    }
  }
  &.w--current {
    color: var(--primary-color);
    font-weight: 700;
    border-radius: 5px;
    background-color: var(--primary-color-5);
    .njc-letter-spacing;
    cursor: initial;
    &:hover {
      color: var(--primary-color);
      background-color: var(--primary-color-5);
    }
  }
}

.njc-clickable-row {
  .njc-clickable {
    .njc-cursor-pointer;
    .njc-letter-spacing;
  }
  &.njc-list-table-header {
    .njc-clickable:hover .text-7b7b7b , .njc-clickable:hover {
      color: var(--grayscale-70) !important;
    }
  }
  &.njc-list-table-row {
    .njc-non-clickable-table-row {
      cursor: default;
    }
  }
}

.njc-cursor-pointer {
  cursor: pointer !important;
}

.njc-text-table-large() {
  font-weight: 400;
  font-size: 14px;
  color: var(--grayscale-100);
  .njc-font-1;
  .njc-letter-spacing;
}
.njc-text-table-large-bold() {
  font-weight: 600;
  font-size: 14px;
  color: var(--grayscale-100);
  .njc-font-1;
  .njc-letter-spacing;
}


.njc-text-table-standard {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 16px;
  .njc-font-1;
  .njc-letter-spacing;
}
.njc-text-table-standard-bold {
  font-weight: 500;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 16px;
  .njc-font-1;
  .njc-letter-spacing;
}

.njc-text-table-sub-text , .njc-text-table-sub-text strong {
  font-size: 11px !important;
  font-weight: 400 !important;
  color: var(--grayscale-60) !important;
  line-height: 11px !important;
  margin: 0px;

}

.njc-text-table-small {
  font-weight: 400;
  font-size: 12px;
  color: var(--grayscale-60);
  line-height: 16px;
  .njc-font-1;
  .njc-letter-spacing;
}
.njc-text-table-small-bold {
  font-weight: 500;
  font-size: 12px;
  color: var(--grayscale-60);
  line-height: 16px;
  .njc-font-1;
  .njc-letter-spacing;
}
.njc-text-table-small-link {
  .njc-text-link !important;
  line-height: 16px;
  .njc-font-1;
  .njc-letter-spacing;
  .njc-flex-direction-row;
  .njc-flex-startcenter;
  a {
    &:extend(.njc-text-table-small-link);
    .njc-letter-spacing;
  }

  &-click-padded {
    padding: @spacing-3;
    margin: -@spacing-3;
  }
  svg {
    .njc-sizing-sq(18px);
    margin-right:@spacing-1;
  }
  &-right-align {
    .njc-text-link !important;
    line-height: 16px;
    .njc-font-1;
    .njc-letter-spacing;
    text-align: right;
  }
}

.njc-text-table-leading {
  padding-top: @spacing-1 !important;
  .njc-font-1;
  .njc-letter-spacing;
}
.njc-text-table-leading-alternative {
  padding-top: 2px !important;
  .njc-font-1;
  .njc-letter-spacing;
}
.njc-text-table-button() {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  .njc-letter-spacing;
}

.njc-text-table-status() {
  font-weight: 600;
  font-size: 12px;
  .njc-font-1;
  .njc-letter-spacing;
}

.njc-text-status-message() {
  font-weight: 500;
  font-size: 12px;
  .njc-letter-spacing;

  svg {
    margin-right: @spacing-1;
    .njc-sizing-sq(18px);
    min-width: 18px;
  }
}

.njc-text-status-message-primary {
  .njc-text-status-message;
  color: var(--primary-color);
}

.njc-text-status-message-success {
  .njc-text-status-message;
  color: var(--success-color);
}
.njc-text-status-message-warning {
  .njc-text-status-message;
  color: var(--warning-color-dark);
}
.njc-text-status-message-critical {
  .njc-text-status-message;
  color: var(--critical-color);
}
.njc-text-status-message-groovy-info {
  .njc-text-status-message;
  color: var(--purple-100);
}
.njc-text-status-message-disabled {
  .njc-text-status-message;
  color: var(--grayscale-70);
}

.njc-text-form-plain-text() {
  .njc-font-1;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-70);
}

.njc-text-form() {
  .njc-font-1;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  .njc-letter-spacing;
  &::placeholder {
    color: var(--grayscale-70);
  }
}

.njc-text-form-input-hover() {
  border: 1px solid var(--grayscale-50);
}
.njc-text-form-input-focus() {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.njc-text-form-input-disabled() {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}

.njc-text-form-border() {
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  .njc-flex-direction-row;
  align-items: center;
  padding: @spacing-0 @spacing-4;
  .njc-transition(0.2s);
  outline: none;
  .njc-letter-spacing;


  &:hover {
    .njc-text-form-input-hover;
  }

  &:active , &:focus {
    .njc-text-form-input-focus;
  }

  &:disabled {
    .njc-text-form-input-disabled;
  }

  &[type="number"] {
    -moz-appearance: spinner-upbutton;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: inner-spin-button;
      margin: 0 -12px 0 2px;
      height: 28px;
    }
  }
}

.njc-text-form-select() {
  .njc-font-1;
  background-image: url("/images/njc/njc-icon-dropdown.svg");
  background-repeat: no-repeat;
  background-position: calc(~"100% -" 20px) 50%;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 35px;

  &::-ms-expand {
    display: none;
  }

  &:disabled {
    background-image: url("/images/njc/njc-icon-dropdown-disabled.svg");
  }
}

.njc-text-form-select-tag() {
  margin: 8px 5px 8px 0;
  background-color: var(--primary-color-bg);
  display: flex;
  flex-direction: row;
  padding: @spacing-1 @spacing-3;
  border-radius: 20px;
  color: var(--primary-color);
  border: 0px solid transparent;
}
.njc-text-form-select-tag-close() {
  margin: 0px;
  padding: 0 0 0 @spacing-2;
  background-image: url("/images/njc/njc-small-close-primary.svg");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  color: transparent;

  &:hover {
    background-image: url("/images/njc/njc-small-close-hover.svg");
  }
}

.njc-text-form-text-tag() {
  margin: 8px 5px 8px 0;
  background-color: var(--primary-color-bg);
  .njc-flex-direction-row;
  padding: @spacing-1 @spacing-3;
  border-radius: 20px;
  color: var(--primary-color);
  border: 0px solid transparent;
  min-height: 28px;
  .ti-actions {
    margin-left: 6px;
    font-size: 1em;
    &:hover {
      color: var(--primary-color-50);
    }
  }
  &.invalid {
    background-color: var(--critical-color-5);
    color: var(--critical-color);

    .ti-actions {
      &:hover{
        color: var(--critical-color-50);
      }
    }
  }

}

.njc-text-form-radio() {
  border: 1px solid var(--grayscale-70);
  background: transparent;
  border-radius: 50%;
  padding: 0px;
  overflow: visible;
  height: 20px;
  width: 20px;
  white-space: nowrap;
  text-indent: 35px;
  margin-bottom: @spacing-3;
  outline: none;
  color: var(--grayscale-100);
  .njc-transition(0.2s);

  &.nc-button-group-blue , &:checked {
    border: 1px solid var(--primary-color);
    background: var(--primary-color);
    box-shadow: 0 0 0 3px var(--grayscale-0) inset;
  }
}
.njc-text-form-radio-input() {
  input {
    border: 1px solid var(--grayscale-70);
    background: transparent;
    border-radius: 50%;
    padding: 0px;
    overflow: visible;
    height: 20px;
    width: 20px;
    white-space: nowrap;
    text-indent: 35px;
    margin-bottom: @spacing-3;
    outline: none;
    color: var(--grayscale-100);
    .njc-transition(0.2s);

    &:checked {
      border: 1px solid var(--primary-color);
      background: var(--primary-color);
      box-shadow: 0 0 0 3px var(--grayscale-0) inset;
    }
  }
  label {
    .njc-text-paragraph;
    margin: 0 0 12px 15px;
  }
}

.njc-text-form-calendar() {
  background-image: url("/images/njc/njc-calendar.svg");
  background-repeat: no-repeat;
  background-position: calc(~"100% -" 10px) 50%;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 35px;
}

.njc-text-calendar-ranges() {
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  line-height: 16px;
  .njc-font-1;

  &.active {
    color: var(--primary-color);
  }
}

.njc-text-calendar-month-week() {
  font-size: 13px;
  font-weight: 600;
  color: var(--grayscale-100);
  line-height: 18px;
  .njc-font-1;
}

.njc-text-calendar-days() {
  font-size: 13px;
  font-weight: 400;
  color: var(--grayscale-100);
  line-height: 18px;
  .njc-font-1;

  &.today {
    font-weight: 600;
    background-color: var(--grayscale-10);
  }
  &.off , &.off:hover {
    font-weight: 400 !important;
    color: var(--grayscale-70) !important;
    background-color: transparent !important;
  }
  &.disabled , &.disabled:hover {
    text-decoration: none;
    color: var(--grayscale-30) !important;
  }
  &.in-range {
    color: var(--primary-color);
    font-weight: 600;
    background-color: var(--primary-color-5);
  }
  &.start-date {
    border-radius: 5px 0 0 5px;
  }
  &.end-date {
    border-radius: 0 5px 5px 0;
  }
  &.start-date.end-date {
    border-radius: 5px;
  }
  &.active , &.active:hover {
    color: var(--grayscale-0);
    font-weight: 600;
    background-color: var(--primary-color);
  }

}


.njc-text-form-dashed() {
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  .njc-letter-spacing;
}

.njc-text-form-dashed-border() {
  height: 44px;
  border: 1px dashed #8E95B6;
  border-radius: 5px;
  .njc-flex-direction-row;
  align-items: center;
  justify-content: space-between;
  padding: @spacing-0 @spacing-5;
  .njc-letter-spacing;
}

.njc-text-form-label() {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-80);
  text-transform: capitalize;
  .njc-letter-spacing;
}

.njc-text-label-bold() {
  font-weight: 700;
  font-size: 13px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  .njc-letter-spacing;
}

.njc-text-form-sublabel() {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-70);
  text-transform: capitalize;
  .njc-letter-spacing;
}

.njc-text-form-link() {
  font-weight: 400;
  font-size: 12px;
  color: var(--primary-color);
  text-decoration: none;
  .njc-letter-spacing;

  &:hover {
    color: var(--listhovercolor);
  }
}

.njc-text-form-checkbox-text() {
  font-size: 12px;
  font-weight: 400;
  color: var(--grayscale-100);
  .njc-letter-spacing;
}

.njc-text-form-description() {
  font-size: 13px;
  margin:0px;
  background-color: var(--grayscale-10);
  border-radius: 5px;
  padding: @spacing-3 @spacing-large;
  min-height: 44px;
  .njc-letter-spacing;
}


.njc-text-button() {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
  padding: @spacing-0 @spacing-5;
  height: 44px;
  min-width: 44px;
  border-radius: 5px;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
  .njc-flex-direction-row-center;
  .njc-letter-spacing;
  .njc-font-1;
  svg {
    margin: @spacing-0 @spacing-2 @spacing-0 @spacing-0;
    .njc-sizing-sq(18px);
    min-width: 18px;

    &.njc-icon-right-aligned {
      margin: @spacing-0 @spacing-0 @spacing-0 @spacing-2;
    }

    &.njc-icon-progress-arrow {
      .njc-sizing-sq(12px);
    }
  }
  &:disabled {
    background-color: var(--grayscale-60);
    color: var(--grayscale-30);
    border: 1px solid var(--grayscale-30);
    &:hover {
      background-color: var(--grayscale-60);
      cursor: not-allowed;
      color: var(--grayscale-30);
    }
  }
}
.njc-text-button-tiny() {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  cursor: pointer;
  padding: @spacing-0 @spacing-3;
  height: 28px;
  border-radius: 5px;
  text-decoration: none;
  text-align: left;
  .njc-flex-direction-row-center;
  .njc-letter-spacing;
  .njc-font-1;
  svg {
    margin: @spacing-0 @spacing-2 @spacing-0 @spacing-0;
    .njc-sizing-sq(18px);
  }
  &:disabled {
    background-color: var(--grayscale-60);
    &:hover {
      background-color: var(--grayscale-60);
      cursor: not-allowed;
    }
  }
}
.njc-text-link {
  font-weight: 400;
  font-size: 12px;
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
  .njc-letter-spacing;
  .njc-font-1;

  &-med {
    font-size: 13px;
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 400;
    cursor: pointer;
    .njc-letter-spacing;
    .njc-font-1;

    &:hover {
      color: var(--primary-color);
      text-decoration: underline;
    }

    svg {
      width: 18px;
      height: 18px;
      min-width: 18px;
      margin-right: 10px;
      color: var(--primary-color);
    }
  }

  &-larger {
    font-size: 14px;
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 400;
    cursor: pointer;
    .njc-letter-spacing;
    .njc-font-1;

    &:hover {
      color: var(--primary-color);
      text-decoration: underline;
    }

    svg {
      width: 18px;
      height: 18px;
      min-width: 18px;
      color: var(--primary-color);
    }
  }

  &:hover {
    color: var(--primary-color);
    text-decoration: underline;
  }
}
.njc-text-default() {
  font-weight: 400;
  font-size: 12px;
  color: var(--grayscale-100);
  .njc-letter-spacing;
}
.njc-text-error() {
  font-weight: 400;
  font-size: 12px;
  color: var(--critical-color);
  .njc-letter-spacing;
}

.njc-br:before {
  content: "\a";
  white-space: pre;
}

.njc-text-moreaction-menu() {
  font-weight: 400;
  font-size: 14px;
  color: var(--grayscale-80);
  white-space: nowrap;
  min-height: 36px;
  padding: @spacing-0 @spacing-5 @spacing-0 @spacing-3;
  text-decoration: none;
  .njc-letter-spacing;
  &:hover {
    color: var(--primary-color);
    cursor: pointer;
  }
}

.njc-text-moreaction-menu-border() {
  min-height: 36px;
  display: flex;
  align-items: center;
  background-color: var(--grayscale-0);
  position: relative;

  &:after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid var(--secondary-color-variant);
  }
  &:first-child {
    border-radius: 5px 5px 0 0;

  }
  &:last-child {
    border-radius: 0 0 5px 5px;
  }

  &:hover {
    background-color: var(--listhovercolor);
    color: var(--primary-color);
  }
  svg {
    margin: @spacing-0 @spacing-2 @spacing-0 @spacing-0;
    .njc-sizing-sq(18px);
  }
}

.njc-text-table-icon-more-action-item() {
  font-size: 14px;
  color: var(--grayscale-80);
  text-decoration: none;

  &:hover {
    color: var(--primary-color);
    background-color: var(--primary-color-5);
  }
}

.njc-text-navigation-tertiary() {
  font-weight: 500;
  font-size: 13px;
  color: var(--grayscale-100);
  margin-bottom: -1px;
  text-align: center;
  .njc-letter-spacing;

  &.njc-tertiary-tabs-link-current {
    font-weight: 600;
  }
  &:hover {
    cursor: pointer;
  }
  &.njc-tertiary-tabs-link-disabled {
    color: var(--grayscale-70);
    font-weight: 500;
    cursor: not-allowed;
  }
  &.njc-tertiary-tab-link-complete {
    padding: @spacing-2 28px @spacing-2 @spacing-3;
    background-image: url("/images/njc/njc-green-check.svg");
    background-repeat: no-repeat;
    background-position: calc(~'100% -' 12px) 50%;
    &:hover {
      cursor: default;
      color: var(--grayscale-100);
    }
  }
}

.njc-text-color-modal() {
  max-width: 355px !important;
  max-height: 500px !important;

  .vue-color__sketch {
    width: 250px;
    box-shadow: none;
    padding-top: @spacing-5;
  }
}

.njc-text-generic-logo() {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-0);
  .njc-letter-spacing;
}

.njc-text-icon-hover() {
  font-weight: 500;
  font-size: 12px;
  text-transform: capitalize;
  color: var(--grayscale-0);
  .njc-letter-spacing;
}

/*Grid Prebuilds*/
.njc-table-grid-right {
  justify-content: flex-end !important;
  text-align: right;
  .njc-list-table-cell-content {
    &:extend(.njc-table-grid-right);
    flex-direction: row !important;
  }
}

.njc-table-grid(@grid) {
  flex: 0 0 6.25% * @grid;
  max-width: 6.25% * @grid;
  padding: 0 0 0 @spacing-4;
}

.njc-table-grid {
  &-0 {
    .njc-table-grid(0);
    padding: 0px;
  }
  &-1 {
    .njc-table-grid(1);
  }
  &-2 {
    .njc-table-grid(2);
  }
  &-3 {
    .njc-table-grid(3);
  }
  &-4 {
    .njc-table-grid(4);
  }
  &-5 {
    .njc-table-grid(5);
  }
  &-6 {
    .njc-table-grid(6);
  }
  &-7 {
    .njc-table-grid(7);
  }
  &-8 {
    .njc-table-grid(8);
  }
  &-9 {
    .njc-table-grid(9);
  }
  &-10 {
    .njc-table-grid(10);
  }
  &-11 {
    .njc-table-grid(11);
  }
  &-12 {
    .njc-table-grid(12);
  }
  &-13 {
    .njc-table-grid(13);
  }
  &-14 {
    .njc-table-grid(14);
  }
  &-15 {
    .njc-table-grid(15);
  }
  &-16 {
    .njc-table-grid(16);
  }
  &-fill {
    flex: 1 1 0;
    max-width: 100%;
    padding: 0 0 0 @spacing-4;
  }
  &-110px {
    width: 110px;
    padding: 0 0 0 @spacing-4;
  }
}

.njc-table-icon-grid(@grids,@icons) {
  flex: 0 0 6.25% * @grids / @icons;
}

.njc-table-icon-grid {
  &-1-1 {
    .njc-table-icon-grid(1,1)
  }
  &-2-1 {
    .njc-table-icon-grid(2,1)
  }
  &-2-2 {
    .njc-table-icon-grid(2,2)
  }
  &-2-3 {
    .njc-table-icon-grid(2,3)
  }
  &-3-4 {
    .njc-table-icon-grid(3,4)
  }
}

.njc-table-break {
  padding: @spacing-4 0
}

/*Other Prebuilds*/

.njc-transition(@time) {
  -webkit-transition: all @time;
  -moz-transition: all @time;
  -ms-transition: all @time;
  -o-transition: all @time;
  transition: all @time;
}

.njc-sizing-sq(@dimension) {
  width: @dimension;
  height: @dimension;
}


.njc-hidden {
  display: none !important;
}

.njc-show {
  display: block !important;

  &-flex {
    display: flex !important;
  }
}

.njc-overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.njc-block {
  display: block;
}

.njc-inline-block {
  display: inline-block;
}

.njc-width-content {
  width: fit-content;
}

.njc-full-width {
  width: 100%;
  flex-basis: 100%;
  padding: 0px;
  margin: 0px;
}
.njc-90p-width {
  width: 90%;
}
.njc-80p-width {
  width: 80%;
}
.njc-70p-width {
  width: 70%;
}
.njc-60p-width {
  width: 60%;
}
.njc-50p-width {
  width: 50%;
}
.njc-40p-width {
  width: 40%;
}
.njc-33p-width {
  width: 33.33%;
}
.njc-30p-width {
  width: 30%;
}
.njc-20p-width {
  width: 20%;
}
.njc-10p-width {
  width: 10%;
}

@keyframes njc-modal-bg-animate {
  0%   {background-color: transparent; overflow-y: hidden}
  100% {background-color: rgba(213,218,225,0.9); overflow-y: hidden}
}
@keyframes njc-modal-animate {
  0%   {transform: translateY(300%);}
  90% {transform: translateY(-10px);}
  100% {transform: translateY(0px);}
}
@keyframes njc-menu-animate-agency-logo {
  0%   {padding: 0 0 0 20px;}
  100% {padding: 0 0 0 0px;}
}
@keyframes njc-spin {
  0%   {transform: rotate(0deg)}
  100% {transform: rotate(360deg)}
}

.njc-modal() {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  min-height: 100%;
  display: flex;
  align-items: start;
  background-color: rgba(213,218,225,0.9);
  z-index: 50;
  animation-name: njc-modal-bg-animate;
  animation-duration: 0.2s;

  .Fullpage.animated , .Modal__container.animated , .modal-dialog {
    animation-name: njc-modal-animate;
    animation-duration: 0.2s;
  }

  &.njc-modal-animation-stop {
    animation-name: none;
    animation-duration: 0s;

    .Fullpage.animated , .Modal__container.animated {
      animation-name: none;
      animation-duration: 0s;
    }
  }
}

.njc-modal-full() {
  width: 85%;
  max-width:1040px;
  margin: 50px auto;
  background-color: var(--grayscale-0);
  border-radius: 8px;
  border:1px solid var(--grayscale-30);
  .njc-shadow-modal;
}
.njc-modal-full-body() {
  position: relative;
  margin: 55px 50px 24px 50px;
}

.njc-modal-small() {
  width: 600px;
  margin: 100px auto auto auto;
  background-color: var(--grayscale-0);
  border-radius: 8px;
  border:1px solid var(--grayscale-30);
  .njc-shadow-modal;
  //max-height: 640px !important;
}
.njc-modal-small-body() {
  margin: 32px 40px 20px;
}

.njc-margin-top-5 {
  margin-top: @spacing-5;
}
.njc-margin-top-large {
  margin-top: @spacing-large;
}

.njc-shadow() {
  box-shadow: 2px 2px 4px rgba(45,53,64,0.1);
}
.njc-shadow-modal() {
  box-shadow: 2px 2px 8px rgba(45,53,64,0.1);
}

.njc-checkbox-input {
  appearance: none;
  .njc-sizing-sq(18px) !important;
  border: 1px solid var(--grayscale-70);
  border-radius: 5px;

  &:checked {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    background-image: url("/images/njc/njc-checkbox.svg");
    background-repeat: no-repeat;
    background-position: 3px 50%;
  }
  &:focus {
    outline: none;
  }
}

.njc-table-icon-more-action {
  .njc-sizing-sq(26px);
  position: relative;

  &-menu {
    position: absolute;
    top: 8px;
    right: 50px;
    flex-direction: column-reverse;
    padding: 0px;
    color: var(--grayscale-80);
    z-index: 11;
    border-radius: 5px;
    background-color: var(--grayscale-0);
    border: 1px solid var(--grayscale-30);
    min-width: 160px;
    .njc-shadow;

    &.show {
      .njc-flex-direction-column-reverse !important;

    }

    .njc-table-icon-more-action-menu-item {
      height: 40px;
      z-index: 12;
      position: relative;

      a {
        .njc-flex-direction-row !important;
        .njc-flex-startcenter;
        .njc-text-table-icon-more-action-item !important;
        cursor:pointer;
        padding: 0 @spacing-3;
        height: 100%;
        white-space: nowrap;

        svg {
          .njc-sizing-sq(18px);
          margin-right: @spacing-2;
        }
      }

      &:first-child {
        border-radius: 0 0 5px 5px;
        a {
          border-radius: 0 0 5px 5px;
        }
        &:before {
          border-bottom: 0px solid transparent;
        }
      }
      &:nth-last-child(2) {
        border-radius: 5px 5px 0 0;
        a {
          border-radius: 5px 5px 0 0;
        }
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid var(--grayscale-30);
      }
    }
    &-exit {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;

      a {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  &-menu-alternate {
    border: 0px solid transparent;
    box-shadow: none;

    .njc-table-icon-more-action-menu-extra {
      border: 1px solid var(--grayscale-30);
      background-color: var(--grayscale-0);
      position: absolute;
      right: 0;
      top: 11px;
      min-width: 160px;
      border-radius: 5px;
      .njc-flex-direction-column-reverse;
      .njc-shadow;

      .njc-table-icon-more-action-menu-item {
        &:first-child {
          border-radius: 5px 5px 0 0;
          a {
            border-radius: 5px 5px 0 0;
          }
        }
        &:nth-last-child(2) {
          border-radius: 0 0 5px 5px;
          a {
            border-radius: 0 0 5px 5px;
          }
        }
      }
    }
  }
}

.njc-table-column-grow {
  flex-grow: 1;
}

.njc-table-column-w70px {
  flex-basis: 70px;
}
.njc-table-column {
  &-w10 {
    flex-basis: 10%;
    min-width: 10%;
  }
  &-w15 {
    flex-basis: 15%;
    min-width: 15%;
  }
  &-w20 {
    flex-basis: 20%;
    min-width: 20%;
  }
  &-w25 {
    flex-basis: 25%;
    min-width: 25%;
  }
  &-w30 {
    flex-basis: 30%;
    min-width: 30%;
  }
  &-w35 {
    flex-basis: 35%;
    min-width: 35%;
  }
  &-w40 {
    flex-basis: 40%;
    min-width: 40%;
  }
  &-w45 {
    flex-basis: 45%;
    min-width: 45%;
  }
  &-w50 {
    flex-basis: 50%;
    min-width: 50%;
  }
  &-w55 {
    flex-basis: 55%;
    min-width: 55%;
  }
}

.njc-table-first-text-column {
  .nc-text-ellipsis , .njc-table-first-text , strong , .template-title-text {
    .njc-text-table-standard-bold;
  }
}

.njc-campaign-bullet-graph() {
  .nc-bullet-graph {
    .nc-bullet-graph-container {
      position: relative;

      .nc-bullet-graph-wrap {
        width: 100%;
        z-index: 1;
        height: 8px;
        border-radius: 5px;
        background-color: var(--grayscale-10);
        margin: @spacing-2 @spacing-0;
      }
      .nc-bullet-graph-projected {
        position: absolute;
        left: 0;
        top: 0;
        height: 8px;
        z-index: 2;
        border-radius: 5px;
        padding: 0px;
        .njc-transition(0.5s);

        &:hover {
          border: 1px solid var(--grayscale-0);
          .njc-icon-button-hover {
            left: 99%;
            right: auto;
            .njc-icon-button-hover-arrow {
              left: -50%;
              right: auto;
            }
          }
        }
      }
      .nc-bullet-graph-current {
        position: absolute;
        left: 0;
        top: 0px;
        height: 8px;
        z-index: 3;
        border-radius: 5px 0 0 5px;
        padding: 0px;
        .njc-transition(0.5s);

        &:hover {
          border: 1px solid var(--grayscale-0);
          .njc-icon-button-hover {
            left: 100%;
            right: auto;
            .njc-icon-button-hover-arrow {
              left: -50%;
              right: auto;
            }
          }
        }
      }
      .nc-bullet-graph-target {
        position: absolute;
        top: 0px;
        height: 0;
        width: 1px !important;
        z-index: 4;
        .njc-transition(0.5s);

        .njc-kpi-triangle {
          position: relative;
          color: var(--grayscale-50);
          top: -9px;
          left: -5px;
          .njc-sizing-sq(9px);
        }
      }
    }
  }
}

.njc-status-bar {
  .njc-text-paragraph;
  min-height: 44px;
  width: 100%;
  margin-top: @spacing-5;
  .njc-flex-direction-row;
  .njc-flex-startcenter;
  border-radius: 5px;
  border: 1px solid var(--grayscale-50) !important;
  background-color: var(--grayscale-0);
  padding: @spacing-3 @spacing-4;
  text-align: left;

  svg {
    .njc-sizing-sq(18px);
    min-width: 18px;
    margin: 0px 9px 0px 0;
  }

  &-success {
    border: 1px solid var(--success-color) !important;
    background-color: var(--success-bg-color);
    svg {
      color: var(--success-color);
    }
  }
  &-info {
    border: 1px solid var(--primary-color-50) !important;
    background-color: var(--primary-color-5);
    svg {
      color: var(--primary-color);
    }
  }
  &-warning {
    border: 1px solid var(--warning-color-dark) !important;
    background-color: var(--warning-bg-color);
    svg {
      color: var(--warning-color-dark);
    }
  }
  &-error {
    border: 1px solid var(--critical-color) !important;
    background-color: var(--critical-color-5);
    svg {
      color: var(--critical-color);
    }
  }
  h3 {
    padding: 0px !important;
    .njc-text-paragraph !important;
  }

  .link {
    .njc-text-link;
    font-size: 13px;
  }

  &-inner {

    p {
      display: inline;
      text-align: left !important;
      .njc-text-paragraph !important;

      a {
        color: var(--grayscale-100);
      }
    }
  }
}

.njc-btn-primary {
  .njc-text-button;
  color: var(--grayscale-0);
  background-color: var(--primary-color);
  border-width: 0px;
  &-tiny {
    .njc-text-button-tiny;
    color: var(--grayscale-0);
    background-color: var(--primary-color);
    border-width: 0px;
  }
  &-round {
    border-radius: 30px;
  }
  &:hover {
    color: var(--grayscale-0);
    background-color: var(--primary-color);
  }
}
.njc-btn-secondary {
  .njc-text-button;
  color: var(--primary-color);
  background-color: var(--grayscale-0);
  border: 1px solid var(--primary-color);
  &-tiny {
    .njc-text-button-tiny;
    color: var(--primary-color);
    background-color: var(--grayscale-0);
    border: 1px solid var(--primary-color);
  }
  &-round {
    border-radius: 30px;
  }
  &:hover {
    color: var(--primary-color);
    background-color: var(--grayscale-0);
  }
}

.njc-btn-delete {
  .njc-text-button;
  color: var(--critical-color);
  background-color: var(--grayscale-0);
  border: 1px solid var(--critical-color);
  &-tiny {
    .njc-text-button-tiny;
    color: var(--critical-color);
    background-color: var(--grayscale-0);
    border: 1px solid var(--critical-color);
  }
}

.njc-button-disabled {
  .njc-btn-primary , .njc-btn-secondary , .njc-btn-delete {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}
.njc-btn-dashed-add {
  .njc-text-button;
  color: var(--grayscale-60);
  background-color: var(--grayscale-0);
  border: 1px dashed var(--grayscale-60);
  width: 100%;
}

.njc-btn-secondary-dropdown {
  .njc-text-button;
  color: var(--primary-color);
  background-color: var(--grayscale-0);
  border: 1px solid var(--primary-color);
  position: relative;

  svg {
    .njc-sizing-sq(16px);
    margin: @spacing-0 @spacing-0 @spacing-0 @spacing-3;
  }

  &:hover {
    background-color: var(--grayscale-0);
  }
}
.dropdown , .njc-dropdown {
  position: relative;
}
.njc-btn-secondary-dropdown {

  &.njc-active {
    .njc-secondary-dropdown-menu {
      display: block;
    }
  }
}
.njc-secondary-dropdown-menu {
  display: none;
  position: absolute;
  top: 53px !important;
  right: auto !important;
  left: 50% !important;
  transform: translate(-50%, 0%) !important;
  .njc-shadow;
  z-index: 99;
  margin: 0px;
  padding: 0px;
  min-width: 158px;
  border: 1px solid var(--grayscale-60);
  border-radius: 5px;
  background-color: var(--grayscale-0);
  color: inherit;

  &:before {
    left: 50%;
    bottom: 100%;
    border: 5px solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: var(--grayscale-0);
    margin-left: -5px;
    z-index: 2;
  }
  &:after {
    left: 50%;
    bottom: 100%;
    border: 6px solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: var(--grayscale-60);
    margin-left: -6px;
  }

  &.show {
    display: block !important;
  }

  &-item {
    .njc-text-moreaction-menu-border;
    text-transform: capitalize;
    position: relative;

    a , span {
      .njc-flex-direction-row;
      .njc-flex-startcenter;
      .njc-text-moreaction-menu;
      width: 100%;
    }

    &.njc-delete-option {
      a , span {
        color: var(--critical-color);
      }
    }
  }
}

.njc-logo-pic (@size){
  width: @size;
  height: @size;
  max-height: @size !important;
  max-width: @size !important;
  object-fit: cover;
  border-radius: 5px;
}


.njc-icon {

  &-28 {
    svg {
      .njc-sizing-sq(28px);
      min-width: 28px;
      color: var(--grayscale-80);

    }
  }

  &-24 {
    svg {
      .njc-sizing-sq(24px);
      min-width: 24px;
      color: var(--grayscale-80);

    }
  }

  &-20 {
    svg {
      .njc-sizing-sq(20px);
      min-width: 20px;
      color: var(--grayscale-80);
    }
  }

  &-18 {
    svg {
      .njc-sizing-sq(18px);
      min-width: 18px;
      color: var(--grayscale-80);

    }
  }
  &-16 {
    svg {
      .njc-sizing-sq(16px);
      min-width: 16px;
      color: var(--grayscale-80);

    }
  }

  &-text {
    .njc-flex-direction-row;
    .njc-flex-startcenter;
    width: auto;

    svg {
      margin-right: @spacing-1;
    }
  }

  &-word-wrap {
    .njc-flex-direction-row;
    .njc-flex-startcenter;
    cursor: pointer;

    &:hover , &:hover svg {
      color: var(--grayscale-30);
    }
  }

  &-button {
    padding: @spacing-1;
    position: relative;
    display: inline-block;
    background-color: transparent;
    border: 0px;
    outline: none;
    cursor: pointer;

    &-in-header {
      padding: @spacing-0;
    }

    .njc-icon-button-hover  {
      display: block !important;
      .njc-sizing-sq(0);
      overflow: hidden;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.2s linear;
      z-index: 100;
    }

    &:hover {
      svg {
        color: var(--grayscale-30);
      }

      .njc-icon-button-hover {
        position: absolute;
        right: 50%;
        top: -30px;
        visibility: visible;
        opacity: 1;
        .njc-sizing-sq(auto);
        overflow: visible;



        .njc-icon-button-hover-arrow {
          background: var(--grayscale-100);
          position: relative;
          right: -50%;
          padding: @spacing-1 @spacing-2;
          border-radius: 5px;
          white-space: nowrap;
          .njc-text-icon-hover;


          &:after {
            top: 100%;
            left: 50%;
            border: 5px solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-top-color: var(--grayscale-100);
            margin-left: -5px;
          }
        }

      }
    }
  }

}

.njc-center-button-wrap {
  .njc-flex-direction-row-center;
  margin-top: @spacing-5;
}

.njc-img-text-item {
  .njc-flex-direction-row;
  .njc-flex-startcenter;

  img , svg {
    margin-right: @spacing-2;
  }
}

.njc-heading-3 {
  .njc-text-heading-3;
}
.njc-form-input-tag {
  font-weight: 500;
  font-size: 13px;
  color: var(--grayscale-0);
  border-radius: 10px;
  display: inline-block;
  height: 24px;
  margin: @spacing-0 @spacing-2;
  padding: 2px @spacing-2;
  background-color: var(--grayscale-40);
  .njc-letter-spacing;
}

.njc-form-input-upload {
  .njc-text-paragraph;
  &::-webkit-file-upload-button {
    .njc-text-button-tiny;
    color: var(--grayscale-0);
    background-color: var(--primary-color);
    border-width: 0px;
    margin: 0 @spacing-2 @spacing-3 0;
    display: inline-flex;

    &:hover {
      color: var(--grayscale-0);
      background-color: var(--primary-color);
    }
  }
}

.njc-nodata-small-content() {
  .njc-flex-direction-column-center;

  &-image {
    .njc-flex-direction-column-center;
    border-radius: 50%;
    margin-bottom: @spacing-4;

    svg {
      .njc-sizing-sq(100px);
    }
  }
  &-msg {
    .njc-text-paragraph-light;
    max-width: 350px;
    text-align: center;

    a {
      .njc-text-link;
      cursor: pointer;
    }
  }
}

.njc-app-scrollbars() {
  overflow-y: overlay;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px var(--grayscale-10);

  }
  &::-webkit-scrollbar-thumb {
    background: var(--grayscale-30);
    border-left: 1px solid var(--grayscale-0);
    border-right: 1px solid var(--grayscale-0);
    border-radius: 5px;
  }
}

.njc-app-scrollbars-horizontal() {
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px var(--grayscale-10);

  }
  &::-webkit-scrollbar-thumb {
    background: var(--grayscale-30);
    border-left: 1px solid var(--grayscale-0);
    border-right: 1px solid var(--grayscale-0);
    border-radius: 5px;
  }
}


#toast-container , .njc-toast {
  .njc-flex-direction-column;
  position: fixed;
  z-index: 999999;
  pointer-events: none;
  bottom: 0;
  right: 0;
  width: 100%;

  .toast {
    height: 36px;
    padding: 0px;
    border-radius: 5px;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 12px 50%;
    width: auto !important;
    box-shadow: none;
    position: relative;
    margin: 0 auto 6px auto;
    opacity: 0.8;


    &:hover {
      opacity: 0.8;
      box-shadow: none;
    }

    .toast-title {
      display: none;
    }

    .toast-message {
      padding: 0 0 0 40px;
      height: 100%;
      margin-right: 30px;
      opacity: 0.8;
      .njc-flex-direction-row;
      .njc-flex-startcenter;
      .njc-text-toast;
    }

    .toast-close-button {
      right: 3px;
      top: 0;
      height: 100%;
      color: transparent;
      background: none;
      background-position: 50%;
      background-image: url("/images/njc/njc-small-close-grayscale-10.svg");
      background-repeat: no-repeat;
      padding: 0 5px;
      min-width: 24px;
      text-indent: 300%;
      white-space: nowrap;
      overflow: hidden;
      border: 0;
      cursor: pointer;
      float: right;
      opacity: 0.8;

      &:hover {
        background-image: url("/images/njc/njc-small-close-grayscale-0.svg");
        opacity: 1;
      }
    }

    &.toast-info {
      background-image: url("/images/njc/njc-icon-info-grayscale-0.svg") !important;
      background-color: var(--grayscale-100);
    }
    &.toast-error {
      background-image: url("/images/njc/njc-icon-error-grayscale-0.svg") !important;
      background-color: var(--critical-color);
    }
    &.toast-success {
      background-image: url("/images/njc/njc-icon-circle-check-grayscale-0.svg") !important;
      background-color: var(--grayscale-100)
    }
    &.toast-warning {
      background-image: url("/images/njc/njc-icon-error-grayscale-0.svg") !important;
      background-color: var(--warning-color);
    }
  }
}

.njc-checkbox-label() {
  position: relative;
  cursor: pointer;
  padding: 0;
  .njc-flex-direction-row !important;
  .njc-flex-startcenter;
}
.njc-checkbox-label-box() {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  .njc-sizing-sq(16px);
  background: white;
  border: 1px solid var(--grayscale-70);
  border-radius: 5px;
}
.njc-checkbox-label-hover() {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.njc-checkbox-label-focus() {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.njc-checkbox-label-checked() {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.njc-checkbox-label-disabled() {
  color: var(--grayscale-30);
  cursor: auto;
}
.njc-checkbox-label-disabled-box() {
  box-shadow: none;
  background: var(--grayscale-20);
}
.njc-checkbox-label-box-animated() {
  content: '';
  position: absolute;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity:1;
  transition: all 0.1s ease-out;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  .njc-sizing-sq(18px);
  background-image: url("/images/njc/njc-checkbox.svg");
  background-repeat: no-repeat;
  background-position: 4px 50%;
}
.njc-checkbox-label-check() {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);

}

.njc-checkbox-group {
  .njc-flex-direction-row;
  flex-wrap: wrap;

  &.njc-checkbox-group-column {
    .njc-flex-direction-column-start;
  }
}

.njc-checkbox {
  input {
    opacity: 0; // hide it
    height: 0 !important; //important is to overwrite custom comparison date range issues
    width: 0;
    margin-right: -6px;

    & + label {
      .njc-checkbox-label;
    }

    // Box.
    & + label:before {
      .njc-checkbox-label-box;
    }

    // Box hover
    &:hover + label:before {
      .njc-checkbox-label-hover;
    }

    // Box focus
    &:focus + label:before {
      .njc-checkbox-label-focus;
    }

    // Box checked
    &:checked + label:before {
      .njc-checkbox-label-checked;
    }

    // Disabled state label.
    &:disabled + label {
      .njc-checkbox-label-disabled;
    }

    // Disabled box.
    &:disabled + label:before {
      .njc-checkbox-label-disabled-box;
    }

    & + label:after{
      .njc-checkbox-label-box-animated;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      .njc-checkbox-label-check;
    }
  }

  label {
    .njc-checkbox-label;

    &:before {
      .njc-checkbox-label-box;

      input:hover {
        .njc-checkbox-label-hover;
      }
      input:focus {
        .njc-checkbox-label-focus;
      }
      input:checked {
        .njc-checkbox-label-checked;
      }
      input:disabled {
        .njc-checkbox-label-disabled-box;
      }
    }

    &:after {
      .njc-checkbox-label-box-animated;
      input {
        &:checked {
          .njc-checkbox-label-check;
        }
      }
    }

    input {
      &:disabled {
        .njc-checkbox-label-disabled;
      }
    }
  }
}

.njc-alert() {

  border:1px solid var(--grayscale-30);
  .njc-shadow-modal;
  padding: 24px 40px 40px 40px !important;
  background-color: var(--grayscale-0);
  border-radius: 8px;

  .swal2-icon {
    transform: scale(0.75);
    margin: 0 auto;
  }
  h2 {
    .njc-font-1;
    .njc-text-heading-2;
    line-height: 40px;
  }
  .swal2-icon.swal2-warning {
    color: var(--critical-color);
    border-color: var(--critical-color) !important;
  }
  .swal2-icon.swal2-question {
    color: var(--primary-color);
    border-color: var(--primary-color) !important;
  }
  .swal2-content , .njc-alert-content {
    .njc-font-1;
    .njc-text-paragraph;
  }
  .swal2-confirm {
    .njc-btn-primary !important;
  }
  .swal2-cancel {
    .njc-btn-secondary !important;
  }
  .swal2-styled {
    display: inline-flex !important;
  }
  select {
    .njc-text-form-border;
    .njc-text-form-select;
    width: 100%;
    &::placeholder {
      font-weight: 500;
      color: var(--grayscale-50);
    }
  }
}

@keyframes crazyColor {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  75% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.njc-password-strength() {
  position: relative;

  .njc-password-toggle-icon {
    position: absolute;
    top: 39px;
    right: 16px;
    cursor: pointer;
    .njc-sizing-sq(18px);
  }

  .njc-login-password-toggle-icon {
    position: absolute;
    top: 12px;
    right: 16px;
    cursor: pointer;
    .njc-sizing-sq(18px);
  }

  .njc-security-bar , .njc-login-security-bar {
    .njc-flex-direction-column-start;

    meter {
      width: 100%;
      height: @spacing-2;
      border-radius: 6px;
      margin: @spacing-2 @spacing-0;
      appearance: none;
      background-color: var(--grayscale-30);
      clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%, 67% 100%, 67% 0, 65% 0, 65% 100%, 34% 100%, 34% 0, 32% 0, 32% 100%);

      &[value="1"] {
        background: linear-gradient(90deg, var(--critical-color) 33%, var(--grayscale-30) 33%);
        & + label span {
          color: var(--critical-color);
          font-weight: 600;
        }
      }
      &[value="2"] {
        background: linear-gradient(90deg, var(--warning-color-dark) 66%, var(--grayscale-30) 66%);
        & + label span {
          color: var(--warning-color-dark);
          font-weight: 600;
        }
      }
      &[value="3"] {
        background-color: var(--success-color);
        & + label span {
          color: var(--success-color);
          font-weight: 600;
        }
      }
      &[value="4"] {
        background: linear-gradient(45deg, #FF1493,  #ee7752, #e73c7e, #23a6d5, #23d5ab, #FF0000, #FFA500, #FFFF00, #7FFF00, #00FFFF, #0000FF,  #9932CC, #FF1493,  #ee7752, #e73c7e, #23a6d5, #23d5ab, #FF0000, #FFA500, #FFFF00, #7FFF00, #00FFFF, #0000FF,  #9932CC);
        background-size: 1500% 100%;
        animation: crazyColor 5s linear infinite;
        & + label span {
          color: var(--primary-color);
          font-weight: 600;
        }
      }
    }
  }
}

.njc-modal-small .Modal .Modal__container {
    width: 100%;
    max-width: 600px;
}
.njc-modal-large .Modal .Modal__container {
    width: 100%;
    max-width: 1040px;
}

// To override the small modal styling to look like the large modal
.njc-full-modal-override {
    
    .Modal {
        .njc-modal;
        
        .Modal__container {
            .njc-modal-full;
            
            .Modal__content {
                margin: 0px;

                .njc-modal-buttons {
                    position: absolute;
                    right: -34px;
                    top: -40px;
                }
                .njc-modal-full-body;
                
                .njc-card-header-2-row {
                    h3 {
                        .njc-text-heading-3;
                        margin: @spacing-0;
                        padding: @spacing-large @spacing-0 @spacing-0 @spacing-0;
                        text-transform: capitalize;
                    }
                }

                .njc-date-modal-footer-row , .nc-date-modal-footer-row {
                    margin-top: 40px;
                }
            }
        }
    }
}