.copilot-launcher-wrapper{
    position: fixed;
    right: 26px;
    z-index: 1000;
    transition: bottom 0.5s ease-out 0s; /* only transition top property */

    &.flying-solo{
        bottom: 20px;
    }

    &.flying-duo{
        bottom: 100px;
    }

    .copilot-launcher-button{
        border-radius: 30px;
        height: 60px;
        width: 60px;

        svg{
            margin: 0;
            scale: 1.7;
            position: relative;
            left: 1px;
            bottom: 1px;
        }

        .copilot-logo-st0{
            opacity:0.6;
            fill-rule:evenodd;
            clip-rule:evenodd;
            fill:#FFFFFF;
            enable-background:new;
        }
        .copilot-logo-st1{
            opacity:0.8;
            fill-rule:evenodd;
            clip-rule:evenodd;
            fill:#FFFFFF;
            enable-background:new;
        }
        .copilot-logo-st2{
            fill-rule:evenodd;
            clip-rule:evenodd;
            fill:#FFFFFF;
        }
    }

    .copilot-embed-iframe-wrapper{
        position: fixed;
        z-index: 1;
        bottom: 0px;
        right: 0px;
        border-left: 1px solid grey;
        background-color: white;
        transition: transform 300ms ease-out;
    }

    .copilot-embed-iframe-actions{
        position: absolute;
        display: flex;
        flex-direction: column;
        background-color: white;
        border: 1px solid grey;
        padding: 3px;
        border-radius: 20px;
        top: 9px;
        left: -51px;
    }

    .copilot-embed-iframe-close{
        padding-top: 4px;
    }

    .copilot-embed-iframe{
        height: 100%;
        width: 100%;
        border: none;

        &:before {
            content: " ";
            height: 100%;
            position: absolute;
            top: 0;
            width: 15px;
            box-shadow: -15px 0 15px -15px inset;
            left: -15px;
        }
    }
}