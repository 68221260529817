@import url("https://rsms.me/inter/inter.css");

/*App Resources*/
@import "app_colors.less";
@import "app_prebuilds.less";
@import "app_nav.less";
@import "app_styling.less";
@import "app_styling_1140.less";
@import "app_styling_height_767.less";
@import "app_cz.less";

/*Template Builder Resources*/
@import "template_colors.less";
@import "template_prebuilds.less";
@import "template_styling.less";

/*CoPilot*/
@import "modules/copilot";