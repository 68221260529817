:root {
  --template-primary-color: #1E66F0;
  --template-primary-color-2: #EFF4FE;
  --template-primary-color-5: #E5EDFF;
  --template-primary-color-10: #CCDBFC;
  --template-primary-color-20: #99B5FA;
  --template-primary-color-50: #6691FA;
  --template-primary-color-variant: #1A5AD7;
  --template-canvas: #585E68;
  --template-border: #000000;
  --template-contour: #151C25;
  --template-hover: #202832;
  --template-main-100: #2d3540;
  --template-main-90: #424A58;
  --template-main-80: #545D6B;
  --template-main-70: #697280;
  --template-main-60: #7E8694;
  --template-main-50: #939AA7;
  --template-main-40: #A8AEBA;
  --template-main-30: #BDC2CC;
  --template-main-20: #D5DAE1;
  --template-main-10: #E7EAEF;
  --template-main-5: #F2F4F7;
  --template-main-3: #F9FAFB;
  --template-main-0: #FFFFFF;
  --template-modal-background: #2D354090;
}