@import url("https://rsms.me/inter/inter.css");
/*App Resources*/
:root {
  --primary-color: #1E66F0;
  --primary-color-2: #EFF4FE;
  --primary-color-5: #E5EDFF;
  --primary-color-10: #CCDBFC;
  --primary-color-20: #99B5FA;
  --primary-color-50: #6691FA;
  --primary-color-dark: #1855CD;
  --primary-color-variant: #1A5AD7;
  --primary-color-bg: #EEEFFC;
  --primary-color-hover: #1B5EDF;
  --secondary-color: #E5E3FB;
  --secondary-color-variant: #8E95B6;
  --tertiary-color: #FF608C;
  --grayscale-100: #2D3540;
  --grayscale-90: #424A58;
  --grayscale-80: #545D6B;
  --grayscale-70: #697280;
  --grayscale-60: #7E8694;
  --grayscale-50: #939AA7;
  --grayscale-40: #A8AEBA;
  --grayscale-30: #BDC2CC;
  --grayscale-20: #D5DAE1;
  --grayscale-10: #E7EAEF;
  --grayscale-5: #F2F4F7;
  --grayscale-3: #F9FAFB;
  --grayscale-0: #FFFFFF;
  --success-color: #239B2A;
  --success-color-50: #8BCA8E;
  --success-color-5: #F4FAF4;
  --success-bg-color: #C2E9C6;
  --warning-color: #F5A523;
  --warning-color-50: #FAD08C;
  --warning-color-5: #FEF6E9;
  --warning-color-dark: #D27412;
  --warning-bg-color: #FCE4BD;
  --critical-color: #EB0057;
  --critical-color-50: #F27DA8;
  --critical-color-5: #F8EDF1;
  --purple-dark: #450082;
  --purple-100: #7C1EFA;
  --purple-50: #BA8CF9;
  --purple-5: #F3EEF9;
  --modal-background: #D5DAF090;
  --listhovercolor: #EFF4FE;
}
/*Spacing Prebuilds*/
/*Flex Prebuilds*/
.njc-position-relative {
  position: relative;
}
/* Text Prebuilds */
.njc-h3-big-description {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--grayscale-100);
  margin: 16px 0 0 0;
}
.njc-text-tag-success {
  color: var(--grayscale-0);
  font-weight: 700;
  font-size: 12px;
  border-radius: 10px;
  padding: 0px 12px;
  background-color: var(--success-color);
}
.njc-text-tag-warning {
  color: var(--grayscale-0);
  font-weight: 700;
  font-size: 12px;
  border-radius: 10px;
  padding: 0px 12px;
  background-color: var(--warning-color-dark);
}
.njc-text-tag-critical {
  color: var(--grayscale-0);
  font-weight: 700;
  font-size: 12px;
  border-radius: 10px;
  padding: 0px 12px;
  background-color: var(--critical-color);
}
.njc-text-tag-groovy-info {
  color: var(--grayscale-0);
  font-weight: 700;
  font-size: 12px;
  border-radius: 10px;
  padding: 0px 12px;
  background-color: var(--purple-100);
}
.njc-text-tag-disabled {
  color: var(--grayscale-0);
  font-weight: 700;
  font-size: 12px;
  border-radius: 10px;
  padding: 0px 12px;
  background-color: var(--grayscale-70);
}
.njc-text-tag-info {
  color: var(--grayscale-0);
  font-weight: 700;
  font-size: 12px;
  border-radius: 10px;
  padding: 0px 12px;
  background-color: var(--grayscale-100);
}
.njc-text-heading-subtext {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-50);
  text-transform: uppercase;
}
.njc-text-heading-subtext a {
  font-size: 13px;
  font-wight: 500;
  color: var(--primary-color);
  text-decoration: none;
  text-transform: capitalize;
  cursor: pointer;
}
.njc-text-warning {
  font-weight: 400;
  font-size: 12px;
  color: var(--critical-color);
  line-height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  display: inline-block;
}
.njc-text-warning svg {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}
.njc-text-token-warning {
  font-weight: 600;
  font-size: 12px;
  color: var(--critical-color);
  line-height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
}
.njc-text-token-warning svg {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}
.njc-text-align-left {
  text-align: left !important;
}
.njc-clickable-row .njc-clickable {
  cursor: pointer !important;
}
.njc-clickable-row.njc-list-table-header .njc-clickable:hover .text-7b7b7b,
.njc-clickable-row.njc-list-table-header .njc-clickable:hover {
  color: var(--grayscale-70) !important;
}
.njc-clickable-row.njc-list-table-row .njc-non-clickable-table-row {
  cursor: default;
}
.njc-cursor-pointer {
  cursor: pointer !important;
}
.njc-text-table-standard {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-text-table-standard-bold {
  font-weight: 500;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-text-table-sub-text,
.njc-text-table-sub-text strong {
  font-size: 11px !important;
  font-weight: 400 !important;
  color: var(--grayscale-60) !important;
  line-height: 11px !important;
  margin: 0px;
}
.njc-text-table-small {
  font-weight: 400;
  font-size: 12px;
  color: var(--grayscale-60);
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-text-table-small-bold {
  font-weight: 500;
  font-size: 12px;
  color: var(--grayscale-60);
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-text-table-small-link,
.njc-text-table-small-link a {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: var(--primary-color) !important;
  text-decoration: none !important;
  cursor: pointer !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-text-table-small-link-med {
  font-size: 13px !important;
  color: var(--primary-color) !important;
  text-decoration: none !important;
  font-weight: 400 !important;
  cursor: pointer !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
}
.njc-text-table-small-link-med:hover {
  color: var(--primary-color) !important;
  text-decoration: underline !important;
}
.njc-text-table-small-link-med svg {
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
  margin-right: 10px !important;
  color: var(--primary-color) !important;
}
.njc-text-table-small-link-larger {
  font-size: 14px !important;
  color: var(--primary-color) !important;
  text-decoration: none !important;
  font-weight: 400 !important;
  cursor: pointer !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
}
.njc-text-table-small-link-larger:hover {
  color: var(--primary-color) !important;
  text-decoration: underline !important;
}
.njc-text-table-small-link-larger svg {
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
  color: var(--primary-color) !important;
}
.njc-text-table-small-link:hover {
  color: var(--primary-color) !important;
  text-decoration: underline !important;
}
.njc-text-table-small-link-click-padded {
  padding: 12px;
  margin: -12px;
}
.njc-text-table-small-link svg {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}
.njc-text-table-small-link-right-align {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: var(--primary-color) !important;
  text-decoration: none !important;
  cursor: pointer !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  text-align: right;
}
.njc-text-table-small-link-right-align-med {
  font-size: 13px !important;
  color: var(--primary-color) !important;
  text-decoration: none !important;
  font-weight: 400 !important;
  cursor: pointer !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
}
.njc-text-table-small-link-right-align-med:hover {
  color: var(--primary-color) !important;
  text-decoration: underline !important;
}
.njc-text-table-small-link-right-align-med svg {
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
  margin-right: 10px !important;
  color: var(--primary-color) !important;
}
.njc-text-table-small-link-right-align-larger {
  font-size: 14px !important;
  color: var(--primary-color) !important;
  text-decoration: none !important;
  font-weight: 400 !important;
  cursor: pointer !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
}
.njc-text-table-small-link-right-align-larger:hover {
  color: var(--primary-color) !important;
  text-decoration: underline !important;
}
.njc-text-table-small-link-right-align-larger svg {
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
  color: var(--primary-color) !important;
}
.njc-text-table-small-link-right-align:hover {
  color: var(--primary-color) !important;
  text-decoration: underline !important;
}
.njc-text-table-leading {
  padding-top: 4px !important;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-text-table-leading-alternative {
  padding-top: 2px !important;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-text-status-message-primary {
  font-weight: 500;
  font-size: 12px;
  color: var(--primary-color);
}
.njc-text-status-message-primary svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-text-status-message-success {
  font-weight: 500;
  font-size: 12px;
  color: var(--success-color);
}
.njc-text-status-message-success svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-text-status-message-warning {
  font-weight: 500;
  font-size: 12px;
  color: var(--warning-color-dark);
}
.njc-text-status-message-warning svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-text-status-message-critical {
  font-weight: 500;
  font-size: 12px;
  color: var(--critical-color);
}
.njc-text-status-message-critical svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-text-status-message-groovy-info {
  font-weight: 500;
  font-size: 12px;
  color: var(--purple-100);
}
.njc-text-status-message-groovy-info svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-text-status-message-disabled {
  font-weight: 500;
  font-size: 12px;
  color: var(--grayscale-70);
}
.njc-text-status-message-disabled svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-text-link {
  font-weight: 400;
  font-size: 12px;
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-text-link-med {
  font-size: 13px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-text-link-med:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-text-link-med svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  margin-right: 10px;
  color: var(--primary-color);
}
.njc-text-link-larger {
  font-size: 14px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-text-link-larger:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-text-link-larger svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  color: var(--primary-color);
}
.njc-text-link:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-br:before {
  content: "\a";
  white-space: pre;
}
/*Grid Prebuilds*/
.njc-table-grid-right,
.njc-table-grid-right .njc-list-table-cell-content {
  justify-content: flex-end !important;
  text-align: right;
}
.njc-table-grid-right .njc-list-table-cell-content {
  flex-direction: row !important;
}
.njc-table-grid-0 {
  flex: 0 0 0%;
  max-width: 0%;
  padding: 0 0 0 16px;
  padding: 0px;
}
.njc-table-grid-1 {
  flex: 0 0 6.25%;
  max-width: 6.25%;
  padding: 0 0 0 16px;
}
.njc-table-grid-2 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
  padding: 0 0 0 16px;
}
.njc-table-grid-3 {
  flex: 0 0 18.75%;
  max-width: 18.75%;
  padding: 0 0 0 16px;
}
.njc-table-grid-4 {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 0 0 16px;
}
.njc-table-grid-5 {
  flex: 0 0 31.25%;
  max-width: 31.25%;
  padding: 0 0 0 16px;
}
.njc-table-grid-6 {
  flex: 0 0 37.5%;
  max-width: 37.5%;
  padding: 0 0 0 16px;
}
.njc-table-grid-7 {
  flex: 0 0 43.75%;
  max-width: 43.75%;
  padding: 0 0 0 16px;
}
.njc-table-grid-8 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 0 0 16px;
}
.njc-table-grid-9 {
  flex: 0 0 56.25%;
  max-width: 56.25%;
  padding: 0 0 0 16px;
}
.njc-table-grid-10 {
  flex: 0 0 62.5%;
  max-width: 62.5%;
  padding: 0 0 0 16px;
}
.njc-table-grid-11 {
  flex: 0 0 68.75%;
  max-width: 68.75%;
  padding: 0 0 0 16px;
}
.njc-table-grid-12 {
  flex: 0 0 75%;
  max-width: 75%;
  padding: 0 0 0 16px;
}
.njc-table-grid-13 {
  flex: 0 0 81.25%;
  max-width: 81.25%;
  padding: 0 0 0 16px;
}
.njc-table-grid-14 {
  flex: 0 0 87.5%;
  max-width: 87.5%;
  padding: 0 0 0 16px;
}
.njc-table-grid-15 {
  flex: 0 0 93.75%;
  max-width: 93.75%;
  padding: 0 0 0 16px;
}
.njc-table-grid-16 {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 0 0 16px;
}
.njc-table-grid-fill {
  flex: 1 1 0;
  max-width: 100%;
  padding: 0 0 0 16px;
}
.njc-table-grid-110px {
  width: 110px;
  padding: 0 0 0 16px;
}
.njc-table-icon-grid-1-1 {
  flex: 0 0 6.25%;
}
.njc-table-icon-grid-2-1 {
  flex: 0 0 12.5%;
}
.njc-table-icon-grid-2-2 {
  flex: 0 0 6.25%;
}
.njc-table-icon-grid-2-3 {
  flex: 0 0 4.16666667%;
}
.njc-table-icon-grid-3-4 {
  flex: 0 0 4.6875%;
}
.njc-table-break {
  padding: 16px 0;
}
/*Other Prebuilds*/
.njc-hidden {
  display: none !important;
}
.njc-show {
  display: block !important;
}
.njc-show-flex {
  display: flex !important;
}
.njc-overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}
.njc-block {
  display: block;
}
.njc-inline-block {
  display: inline-block;
}
.njc-width-content {
  width: fit-content;
}
.njc-full-width {
  width: 100%;
  flex-basis: 100%;
  padding: 0px;
  margin: 0px;
}
.njc-90p-width {
  width: 90%;
}
.njc-80p-width {
  width: 80%;
}
.njc-70p-width {
  width: 70%;
}
.njc-60p-width {
  width: 60%;
}
.njc-50p-width {
  width: 50%;
}
.njc-40p-width {
  width: 40%;
}
.njc-33p-width {
  width: 33.33%;
}
.njc-30p-width {
  width: 30%;
}
.njc-20p-width {
  width: 20%;
}
.njc-10p-width {
  width: 10%;
}
@keyframes njc-modal-bg-animate {
  0% {
    background-color: transparent;
    overflow-y: hidden;
  }
  100% {
    background-color: rgba(213, 218, 225, 0.9);
    overflow-y: hidden;
  }
}
@keyframes njc-modal-animate {
  0% {
    transform: translateY(300%);
  }
  90% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes njc-menu-animate-agency-logo {
  0% {
    padding: 0 0 0 20px;
  }
  100% {
    padding: 0 0 0 0px;
  }
}
@keyframes njc-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.njc-margin-top-5 {
  margin-top: 20px;
}
.njc-margin-top-large {
  margin-top: 32px;
}
.njc-checkbox-input {
  appearance: none;
  width: 18px !important;
  height: 18px !important;
  border: 1px solid var(--grayscale-70);
  border-radius: 5px;
}
.njc-checkbox-input:checked {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-image: url("/images/njc/njc-checkbox.svg");
  background-repeat: no-repeat;
  background-position: 3px 50%;
}
.njc-checkbox-input:focus {
  outline: none;
}
.njc-table-icon-more-action {
  width: 26px;
  height: 26px;
  position: relative;
}
.njc-table-icon-more-action-menu {
  position: absolute;
  top: 8px;
  right: 50px;
  flex-direction: column-reverse;
  padding: 0px;
  color: var(--grayscale-80);
  z-index: 11;
  border-radius: 5px;
  background-color: var(--grayscale-0);
  border: 1px solid var(--grayscale-30);
  min-width: 160px;
  box-shadow: 2px 2px 4px rgba(45, 53, 64, 0.1);
}
.njc-table-icon-more-action-menu.show {
  display: flex !important;
  flex-direction: column-reverse !important;
}
.njc-table-icon-more-action-menu .njc-table-icon-more-action-menu-item {
  height: 40px;
  z-index: 12;
  position: relative;
}
.njc-table-icon-more-action-menu .njc-table-icon-more-action-menu-item a {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px !important;
  color: var(--grayscale-80) !important;
  text-decoration: none !important;
  cursor: pointer;
  padding: 0 12px;
  height: 100%;
  white-space: nowrap;
}
.njc-table-icon-more-action-menu .njc-table-icon-more-action-menu-item a:hover {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-5) !important;
}
.njc-table-icon-more-action-menu .njc-table-icon-more-action-menu-item a svg {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
.njc-table-icon-more-action-menu .njc-table-icon-more-action-menu-item:first-child {
  border-radius: 0 0 5px 5px;
}
.njc-table-icon-more-action-menu .njc-table-icon-more-action-menu-item:first-child a {
  border-radius: 0 0 5px 5px;
}
.njc-table-icon-more-action-menu .njc-table-icon-more-action-menu-item:first-child:before {
  border-bottom: 0px solid transparent;
}
.njc-table-icon-more-action-menu .njc-table-icon-more-action-menu-item:nth-last-child(2) {
  border-radius: 5px 5px 0 0;
}
.njc-table-icon-more-action-menu .njc-table-icon-more-action-menu-item:nth-last-child(2) a {
  border-radius: 5px 5px 0 0;
}
.njc-table-icon-more-action-menu .njc-table-icon-more-action-menu-item:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid var(--grayscale-30);
}
.njc-table-icon-more-action-menu-exit {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.njc-table-icon-more-action-menu-exit a {
  display: block;
  width: 100%;
  height: 100%;
}
.njc-table-icon-more-action-menu-alternate {
  border: 0px solid transparent;
  box-shadow: none;
}
.njc-table-icon-more-action-menu-alternate .njc-table-icon-more-action-menu-extra {
  border: 1px solid var(--grayscale-30);
  background-color: var(--grayscale-0);
  position: absolute;
  right: 0;
  top: 11px;
  min-width: 160px;
  border-radius: 5px;
  display: flex;
  flex-direction: column-reverse;
  box-shadow: 2px 2px 4px rgba(45, 53, 64, 0.1);
}
.njc-table-icon-more-action-menu-alternate .njc-table-icon-more-action-menu-extra .njc-table-icon-more-action-menu-item:first-child {
  border-radius: 5px 5px 0 0;
}
.njc-table-icon-more-action-menu-alternate .njc-table-icon-more-action-menu-extra .njc-table-icon-more-action-menu-item:first-child a {
  border-radius: 5px 5px 0 0;
}
.njc-table-icon-more-action-menu-alternate .njc-table-icon-more-action-menu-extra .njc-table-icon-more-action-menu-item:nth-last-child(2) {
  border-radius: 0 0 5px 5px;
}
.njc-table-icon-more-action-menu-alternate .njc-table-icon-more-action-menu-extra .njc-table-icon-more-action-menu-item:nth-last-child(2) a {
  border-radius: 0 0 5px 5px;
}
.njc-table-column-grow {
  flex-grow: 1;
}
.njc-table-column-w70px {
  flex-basis: 70px;
}
.njc-table-column-w10 {
  flex-basis: 10%;
  min-width: 10%;
}
.njc-table-column-w15 {
  flex-basis: 15%;
  min-width: 15%;
}
.njc-table-column-w20 {
  flex-basis: 20%;
  min-width: 20%;
}
.njc-table-column-w25 {
  flex-basis: 25%;
  min-width: 25%;
}
.njc-table-column-w30 {
  flex-basis: 30%;
  min-width: 30%;
}
.njc-table-column-w35 {
  flex-basis: 35%;
  min-width: 35%;
}
.njc-table-column-w40 {
  flex-basis: 40%;
  min-width: 40%;
}
.njc-table-column-w45 {
  flex-basis: 45%;
  min-width: 45%;
}
.njc-table-column-w50 {
  flex-basis: 50%;
  min-width: 50%;
}
.njc-table-column-w55 {
  flex-basis: 55%;
  min-width: 55%;
}
.njc-table-first-text-column .nc-text-ellipsis,
.njc-table-first-text-column .njc-table-first-text,
.njc-table-first-text-column strong,
.njc-table-first-text-column .template-title-text {
  font-weight: 500;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-status-bar {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  min-height: 44px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  border: 1px solid var(--grayscale-50) !important;
  background-color: var(--grayscale-0);
  padding: 12px 16px;
  text-align: left;
}
.njc-status-bar svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  margin: 0px 9px 0px 0;
}
.njc-status-bar-success {
  border: 1px solid var(--success-color) !important;
  background-color: var(--success-bg-color);
}
.njc-status-bar-success svg {
  color: var(--success-color);
}
.njc-status-bar-info {
  border: 1px solid var(--primary-color-50) !important;
  background-color: var(--primary-color-5);
}
.njc-status-bar-info svg {
  color: var(--primary-color);
}
.njc-status-bar-warning {
  border: 1px solid var(--warning-color-dark) !important;
  background-color: var(--warning-bg-color);
}
.njc-status-bar-warning svg {
  color: var(--warning-color-dark);
}
.njc-status-bar-error {
  border: 1px solid var(--critical-color) !important;
  background-color: var(--critical-color-5);
}
.njc-status-bar-error svg {
  color: var(--critical-color);
}
.njc-status-bar h3 {
  padding: 0px !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  color: var(--grayscale-100) !important;
  line-height: 18px !important;
}
.njc-status-bar .link {
  font-weight: 400;
  font-size: 12px;
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 13px;
}
.njc-status-bar .link-med {
  font-size: 13px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-status-bar .link-med:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-status-bar .link-med svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  margin-right: 10px;
  color: var(--primary-color);
}
.njc-status-bar .link-larger {
  font-size: 14px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-status-bar .link-larger:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-status-bar .link-larger svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  color: var(--primary-color);
}
.njc-status-bar .link:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-status-bar-inner p {
  display: inline;
  text-align: left !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  color: var(--grayscale-100) !important;
  line-height: 18px !important;
}
.njc-status-bar-inner p a {
  color: var(--grayscale-100);
}
.njc-btn-primary {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
  padding: 0px 20px;
  height: 44px;
  min-width: 44px;
  border-radius: 5px;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--grayscale-0);
  background-color: var(--primary-color);
  border-width: 0px;
}
.njc-btn-primary svg {
  margin: 0px 8px 0px 0px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-btn-primary svg.njc-icon-right-aligned {
  margin: 0px 0px 0px 8px;
}
.njc-btn-primary svg.njc-icon-progress-arrow {
  width: 12px;
  height: 12px;
}
.njc-btn-primary:disabled {
  background-color: var(--grayscale-60);
  color: var(--grayscale-30);
  border: 1px solid var(--grayscale-30);
}
.njc-btn-primary:disabled:hover {
  background-color: var(--grayscale-60);
  cursor: not-allowed;
  color: var(--grayscale-30);
}
.njc-btn-primary-tiny {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  cursor: pointer;
  padding: 0px 12px;
  height: 28px;
  border-radius: 5px;
  text-decoration: none;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--grayscale-0);
  background-color: var(--primary-color);
  border-width: 0px;
}
.njc-btn-primary-tiny svg {
  margin: 0px 8px 0px 0px;
  width: 18px;
  height: 18px;
}
.njc-btn-primary-tiny:disabled {
  background-color: var(--grayscale-60);
}
.njc-btn-primary-tiny:disabled:hover {
  background-color: var(--grayscale-60);
  cursor: not-allowed;
}
.njc-btn-primary-round {
  border-radius: 30px;
}
.njc-btn-primary:hover {
  color: var(--grayscale-0);
  background-color: var(--primary-color);
}
.njc-btn-secondary {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
  padding: 0px 20px;
  height: 44px;
  min-width: 44px;
  border-radius: 5px;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--primary-color);
  background-color: var(--grayscale-0);
  border: 1px solid var(--primary-color);
}
.njc-btn-secondary svg {
  margin: 0px 8px 0px 0px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-btn-secondary svg.njc-icon-right-aligned {
  margin: 0px 0px 0px 8px;
}
.njc-btn-secondary svg.njc-icon-progress-arrow {
  width: 12px;
  height: 12px;
}
.njc-btn-secondary:disabled {
  background-color: var(--grayscale-60);
  color: var(--grayscale-30);
  border: 1px solid var(--grayscale-30);
}
.njc-btn-secondary:disabled:hover {
  background-color: var(--grayscale-60);
  cursor: not-allowed;
  color: var(--grayscale-30);
}
.njc-btn-secondary-tiny {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  cursor: pointer;
  padding: 0px 12px;
  height: 28px;
  border-radius: 5px;
  text-decoration: none;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--primary-color);
  background-color: var(--grayscale-0);
  border: 1px solid var(--primary-color);
}
.njc-btn-secondary-tiny svg {
  margin: 0px 8px 0px 0px;
  width: 18px;
  height: 18px;
}
.njc-btn-secondary-tiny:disabled {
  background-color: var(--grayscale-60);
}
.njc-btn-secondary-tiny:disabled:hover {
  background-color: var(--grayscale-60);
  cursor: not-allowed;
}
.njc-btn-secondary-round {
  border-radius: 30px;
}
.njc-btn-secondary:hover {
  color: var(--primary-color);
  background-color: var(--grayscale-0);
}
.njc-btn-delete {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
  padding: 0px 20px;
  height: 44px;
  min-width: 44px;
  border-radius: 5px;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--critical-color);
  background-color: var(--grayscale-0);
  border: 1px solid var(--critical-color);
}
.njc-btn-delete svg {
  margin: 0px 8px 0px 0px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-btn-delete svg.njc-icon-right-aligned {
  margin: 0px 0px 0px 8px;
}
.njc-btn-delete svg.njc-icon-progress-arrow {
  width: 12px;
  height: 12px;
}
.njc-btn-delete:disabled {
  background-color: var(--grayscale-60);
  color: var(--grayscale-30);
  border: 1px solid var(--grayscale-30);
}
.njc-btn-delete:disabled:hover {
  background-color: var(--grayscale-60);
  cursor: not-allowed;
  color: var(--grayscale-30);
}
.njc-btn-delete-tiny {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  cursor: pointer;
  padding: 0px 12px;
  height: 28px;
  border-radius: 5px;
  text-decoration: none;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--critical-color);
  background-color: var(--grayscale-0);
  border: 1px solid var(--critical-color);
}
.njc-btn-delete-tiny svg {
  margin: 0px 8px 0px 0px;
  width: 18px;
  height: 18px;
}
.njc-btn-delete-tiny:disabled {
  background-color: var(--grayscale-60);
}
.njc-btn-delete-tiny:disabled:hover {
  background-color: var(--grayscale-60);
  cursor: not-allowed;
}
.njc-button-disabled .njc-btn-primary,
.njc-button-disabled .njc-btn-secondary,
.njc-button-disabled .njc-btn-delete {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
.njc-btn-dashed-add {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
  padding: 0px 20px;
  height: 44px;
  min-width: 44px;
  border-radius: 5px;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--grayscale-60);
  background-color: var(--grayscale-0);
  border: 1px dashed var(--grayscale-60);
  width: 100%;
}
.njc-btn-dashed-add svg {
  margin: 0px 8px 0px 0px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-btn-dashed-add svg.njc-icon-right-aligned {
  margin: 0px 0px 0px 8px;
}
.njc-btn-dashed-add svg.njc-icon-progress-arrow {
  width: 12px;
  height: 12px;
}
.njc-btn-dashed-add:disabled {
  background-color: var(--grayscale-60);
  color: var(--grayscale-30);
  border: 1px solid var(--grayscale-30);
}
.njc-btn-dashed-add:disabled:hover {
  background-color: var(--grayscale-60);
  cursor: not-allowed;
  color: var(--grayscale-30);
}
.njc-btn-secondary-dropdown {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
  padding: 0px 20px;
  height: 44px;
  min-width: 44px;
  border-radius: 5px;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--primary-color);
  background-color: var(--grayscale-0);
  border: 1px solid var(--primary-color);
  position: relative;
}
.njc-btn-secondary-dropdown svg {
  margin: 0px 8px 0px 0px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-btn-secondary-dropdown svg.njc-icon-right-aligned {
  margin: 0px 0px 0px 8px;
}
.njc-btn-secondary-dropdown svg.njc-icon-progress-arrow {
  width: 12px;
  height: 12px;
}
.njc-btn-secondary-dropdown:disabled {
  background-color: var(--grayscale-60);
  color: var(--grayscale-30);
  border: 1px solid var(--grayscale-30);
}
.njc-btn-secondary-dropdown:disabled:hover {
  background-color: var(--grayscale-60);
  cursor: not-allowed;
  color: var(--grayscale-30);
}
.njc-btn-secondary-dropdown svg {
  width: 16px;
  height: 16px;
  margin: 0px 0px 0px 12px;
}
.njc-btn-secondary-dropdown:hover {
  background-color: var(--grayscale-0);
}
.dropdown,
.njc-dropdown {
  position: relative;
}
.njc-btn-secondary-dropdown.njc-active .njc-secondary-dropdown-menu {
  display: block;
}
.njc-secondary-dropdown-menu {
  display: none;
  position: absolute;
  top: 53px !important;
  right: auto !important;
  left: 50% !important;
  transform: translate(-50%, 0%) !important;
  box-shadow: 2px 2px 4px rgba(45, 53, 64, 0.1);
  z-index: 99;
  margin: 0px;
  padding: 0px;
  min-width: 158px;
  border: 1px solid var(--grayscale-60);
  border-radius: 5px;
  background-color: var(--grayscale-0);
  color: inherit;
}
.njc-secondary-dropdown-menu:before {
  left: 50%;
  bottom: 100%;
  border: 5px solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: var(--grayscale-0);
  margin-left: -5px;
  z-index: 2;
}
.njc-secondary-dropdown-menu:after {
  left: 50%;
  bottom: 100%;
  border: 6px solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: var(--grayscale-60);
  margin-left: -6px;
}
.njc-secondary-dropdown-menu.show {
  display: block !important;
}
.njc-secondary-dropdown-menu-item {
  min-height: 36px;
  display: flex;
  align-items: center;
  background-color: var(--grayscale-0);
  text-transform: capitalize;
  position: relative;
}
.njc-secondary-dropdown-menu-item:after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid var(--secondary-color-variant);
}
.njc-secondary-dropdown-menu-item:first-child {
  border-radius: 5px 5px 0 0;
}
.njc-secondary-dropdown-menu-item:last-child {
  border-radius: 0 0 5px 5px;
}
.njc-secondary-dropdown-menu-item:hover {
  background-color: var(--listhovercolor);
  color: var(--primary-color);
}
.njc-secondary-dropdown-menu-item svg {
  margin: 0px 8px 0px 0px;
  width: 18px;
  height: 18px;
}
.njc-secondary-dropdown-menu-item a,
.njc-secondary-dropdown-menu-item span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 14px;
  color: var(--grayscale-80);
  white-space: nowrap;
  min-height: 36px;
  padding: 0px 20px 0px 12px;
  text-decoration: none;
  width: 100%;
}
.njc-secondary-dropdown-menu-item a:hover,
.njc-secondary-dropdown-menu-item span:hover {
  color: var(--primary-color);
  cursor: pointer;
}
.njc-secondary-dropdown-menu-item.njc-delete-option a,
.njc-secondary-dropdown-menu-item.njc-delete-option span {
  color: var(--critical-color);
}
.njc-icon-28 svg {
  width: 28px;
  height: 28px;
  min-width: 28px;
  color: var(--grayscale-80);
}
.njc-icon-24 svg {
  width: 24px;
  height: 24px;
  min-width: 24px;
  color: var(--grayscale-80);
}
.njc-icon-20 svg {
  width: 20px;
  height: 20px;
  min-width: 20px;
  color: var(--grayscale-80);
}
.njc-icon-18 svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  color: var(--grayscale-80);
}
.njc-icon-16 svg {
  width: 16px;
  height: 16px;
  min-width: 16px;
  color: var(--grayscale-80);
}
.njc-icon-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
}
.njc-icon-text svg {
  margin-right: 4px;
}
.njc-icon-word-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.njc-icon-word-wrap:hover,
.njc-icon-word-wrap:hover svg {
  color: var(--grayscale-30);
}
.njc-icon-button {
  padding: 4px;
  position: relative;
  display: inline-block;
  background-color: transparent;
  border: 0px;
  outline: none;
  cursor: pointer;
}
.njc-icon-button-in-header {
  padding: 0px;
}
.njc-icon-button .njc-icon-button-hover {
  display: block !important;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  z-index: 100;
}
.njc-icon-button:hover svg {
  color: var(--grayscale-30);
}
.njc-icon-button:hover .njc-icon-button-hover {
  position: absolute;
  right: 50%;
  top: -30px;
  visibility: visible;
  opacity: 1;
  width: auto;
  height: auto;
  overflow: visible;
}
.njc-icon-button:hover .njc-icon-button-hover .njc-icon-button-hover-arrow {
  background: var(--grayscale-100);
  position: relative;
  right: -50%;
  padding: 4px 8px;
  border-radius: 5px;
  white-space: nowrap;
  font-weight: 500;
  font-size: 12px;
  text-transform: capitalize;
  color: var(--grayscale-0);
}
.njc-icon-button:hover .njc-icon-button-hover .njc-icon-button-hover-arrow:after {
  top: 100%;
  left: 50%;
  border: 5px solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: var(--grayscale-100);
  margin-left: -5px;
}
.njc-center-button-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.njc-img-text-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-img-text-item img,
.njc-img-text-item svg {
  margin-right: 8px;
}
.njc-heading-3 {
  font-weight: 700;
  font-size: 18px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-form-input-tag {
  font-weight: 500;
  font-size: 13px;
  color: var(--grayscale-0);
  border-radius: 10px;
  display: inline-block;
  height: 24px;
  margin: 0px 8px;
  padding: 2px 8px;
  background-color: var(--grayscale-40);
}
.njc-form-input-upload {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
}
.njc-form-input-upload::-webkit-file-upload-button {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  cursor: pointer;
  padding: 0px 12px;
  height: 28px;
  border-radius: 5px;
  text-decoration: none;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--grayscale-0);
  background-color: var(--primary-color);
  border-width: 0px;
  margin: 0 8px 12px 0;
  display: inline-flex;
}
.njc-form-input-upload::-webkit-file-upload-button svg {
  margin: 0px 8px 0px 0px;
  width: 18px;
  height: 18px;
}
.njc-form-input-upload::-webkit-file-upload-button:disabled {
  background-color: var(--grayscale-60);
}
.njc-form-input-upload::-webkit-file-upload-button:disabled:hover {
  background-color: var(--grayscale-60);
  cursor: not-allowed;
}
.njc-form-input-upload::-webkit-file-upload-button:hover {
  color: var(--grayscale-0);
  background-color: var(--primary-color);
}
#toast-container,
.njc-toast {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 999999;
  pointer-events: none;
  bottom: 0;
  right: 0;
  width: 100%;
}
#toast-container .toast,
.njc-toast .toast {
  height: 36px;
  padding: 0px;
  border-radius: 5px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 12px 50%;
  width: auto !important;
  box-shadow: none;
  position: relative;
  margin: 0 auto 6px auto;
  opacity: 0.8;
}
#toast-container .toast:hover,
.njc-toast .toast:hover {
  opacity: 0.8;
  box-shadow: none;
}
#toast-container .toast .toast-title,
.njc-toast .toast .toast-title {
  display: none;
}
#toast-container .toast .toast-message,
.njc-toast .toast .toast-message {
  padding: 0 0 0 40px;
  height: 100%;
  margin-right: 30px;
  opacity: 0.8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-weight: 500;
  font-size: 14px;
  color: var(--grayscale-0);
}
#toast-container .toast .toast-close-button,
.njc-toast .toast .toast-close-button {
  right: 3px;
  top: 0;
  height: 100%;
  color: transparent;
  background: none;
  background-position: 50%;
  background-image: url("/images/njc/njc-small-close-grayscale-10.svg");
  background-repeat: no-repeat;
  padding: 0 5px;
  min-width: 24px;
  text-indent: 300%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  cursor: pointer;
  float: right;
  opacity: 0.8;
}
#toast-container .toast .toast-close-button:hover,
.njc-toast .toast .toast-close-button:hover {
  background-image: url("/images/njc/njc-small-close-grayscale-0.svg");
  opacity: 1;
}
#toast-container .toast.toast-info,
.njc-toast .toast.toast-info {
  background-image: url("/images/njc/njc-icon-info-grayscale-0.svg") !important;
  background-color: var(--grayscale-100);
}
#toast-container .toast.toast-error,
.njc-toast .toast.toast-error {
  background-image: url("/images/njc/njc-icon-error-grayscale-0.svg") !important;
  background-color: var(--critical-color);
}
#toast-container .toast.toast-success,
.njc-toast .toast.toast-success {
  background-image: url("/images/njc/njc-icon-circle-check-grayscale-0.svg") !important;
  background-color: var(--grayscale-100);
}
#toast-container .toast.toast-warning,
.njc-toast .toast.toast-warning {
  background-image: url("/images/njc/njc-icon-error-grayscale-0.svg") !important;
  background-color: var(--warning-color);
}
.njc-checkbox-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.njc-checkbox-group.njc-checkbox-group-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-checkbox input {
  opacity: 0;
  height: 0 !important;
  width: 0;
  margin-right: -6px;
}
.njc-checkbox input + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-start;
}
.njc-checkbox input + label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 16px;
  height: 16px;
  background: white;
  border: 1px solid var(--grayscale-70);
  border-radius: 5px;
}
.njc-checkbox input:hover + label:before {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.njc-checkbox input:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.njc-checkbox input:checked + label:before {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.njc-checkbox input:disabled + label {
  color: var(--grayscale-30);
  cursor: auto;
}
.njc-checkbox input:disabled + label:before {
  box-shadow: none;
  background: var(--grayscale-20);
}
.njc-checkbox input + label:after {
  content: '';
  position: absolute;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 1;
  transition: all 0.1s ease-out;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  width: 18px;
  height: 18px;
  background-image: url("/images/njc/njc-checkbox.svg");
  background-repeat: no-repeat;
  background-position: 4px 50%;
}
.njc-checkbox input:checked + label:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.njc-checkbox label {
  position: relative;
  cursor: pointer;
  padding: 0;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-start;
}
.njc-checkbox label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 16px;
  height: 16px;
  background: white;
  border: 1px solid var(--grayscale-70);
  border-radius: 5px;
}
.njc-checkbox label:before input:hover {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.njc-checkbox label:before input:focus {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.njc-checkbox label:before input:checked {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.njc-checkbox label:before input:disabled {
  box-shadow: none;
  background: var(--grayscale-20);
}
.njc-checkbox label:after {
  content: '';
  position: absolute;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 1;
  transition: all 0.1s ease-out;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  width: 18px;
  height: 18px;
  background-image: url("/images/njc/njc-checkbox.svg");
  background-repeat: no-repeat;
  background-position: 4px 50%;
}
.njc-checkbox label:after input:checked {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.njc-checkbox label input:disabled {
  color: var(--grayscale-30);
  cursor: auto;
}
@keyframes crazyColor {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  75% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
.njc-modal-small .Modal .Modal__container {
  width: 100%;
  max-width: 600px;
}
.njc-modal-large .Modal .Modal__container {
  width: 100%;
  max-width: 1040px;
}
.njc-full-modal-override .Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  min-height: 100%;
  display: flex;
  align-items: start;
  background-color: rgba(213, 218, 225, 0.9);
  z-index: 50;
  animation-name: njc-modal-bg-animate;
  animation-duration: 0.2s;
}
.njc-full-modal-override .Modal .Fullpage.animated,
.njc-full-modal-override .Modal .Modal__container.animated,
.njc-full-modal-override .Modal .modal-dialog {
  animation-name: njc-modal-animate;
  animation-duration: 0.2s;
}
.njc-full-modal-override .Modal.njc-modal-animation-stop {
  animation-name: none;
  animation-duration: 0s;
}
.njc-full-modal-override .Modal.njc-modal-animation-stop .Fullpage.animated,
.njc-full-modal-override .Modal.njc-modal-animation-stop .Modal__container.animated {
  animation-name: none;
  animation-duration: 0s;
}
.njc-full-modal-override .Modal .Modal__container {
  width: 85%;
  max-width: 1040px;
  margin: 50px auto;
  background-color: var(--grayscale-0);
  border-radius: 8px;
  border: 1px solid var(--grayscale-30);
  box-shadow: 2px 2px 8px rgba(45, 53, 64, 0.1);
}
.njc-full-modal-override .Modal .Modal__container .Modal__content {
  margin: 0px;
  position: relative;
  margin: 55px 50px 24px 50px;
}
.njc-full-modal-override .Modal .Modal__container .Modal__content .njc-modal-buttons {
  position: absolute;
  right: -34px;
  top: -40px;
}
.njc-full-modal-override .Modal .Modal__container .Modal__content .njc-card-header-2-row h3 {
  font-weight: 700;
  font-size: 18px;
  color: var(--grayscale-100);
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  margin: 0px;
  padding: 32px 0px 0px 0px;
  text-transform: capitalize;
}
.njc-full-modal-override .Modal .Modal__container .Modal__content .njc-date-modal-footer-row,
.njc-full-modal-override .Modal .Modal__container .Modal__content .nc-date-modal-footer-row {
  margin-top: 40px;
}
:root {
  --nav-user-display-height: 82px;
}
/*Main Navigation*/
.njc-main-menu {
  width: 240px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--primary-color);
  z-index: 11;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.njc-main-menu a {
  color: inherit;
}
.njc-main-menu * {
  text-decoration: none;
}
.njc-main-menu .njc-menu-collapsed-button {
  display: none;
}
.njc-main-menu .njc-main-menu-body {
  height: 100%;
}
.njc-main-menu .njc-main-menu-account {
  height: var(--nav-user-display-height);
  align-items: flex-start;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  position: relative;
}
.njc-main-menu .njc-main-menu-account .njc-main-menu-item {
  padding: 0 20px;
  width: 100%;
}
.njc-main-menu .njc-main-menu-account .njc-main-menu-item-label {
  height: 68px;
}
.njc-main-menu .njc-main-menu-account .njc-agency-logo {
  max-height: 44px;
  max-width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.njc-main-menu .njc-main-menu-account .njc-agency-logo img,
.njc-main-menu .njc-main-menu-account .njc-agency-logo svg {
  width: 44px;
  height: auto;
  max-height: 44px !important;
  max-width: 44px !important;
}
.njc-main-menu .njc-main-menu-account .njc-menu-info {
  height: 36px;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.njc-main-menu .njc-main-menu-account .njc-menu-info .njc-menu-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: var(--grayscale-0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-main-menu .njc-main-menu-account .njc-menu-info .njc-menu-title .njc-menu-icon {
  position: relative;
}
.njc-main-menu .njc-main-menu-account .njc-menu-info .njc-menu-title .njc-menu-icon .njc-user-dropdown {
  display: none;
  position: absolute;
  z-index: 100;
  left: -13px;
  top: 28px;
}
.njc-main-menu .njc-main-menu-account .njc-menu-info .njc-menu-title .njc-menu-icon .njc-user-dropdown-arrow {
  position: relative;
  border: var(--grayscale-30);
  background-color: var(--grayscale-0);
  min-width: 180px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  z-index: 99;
  display: flex;
  flex-direction: column;
}
.njc-main-menu .njc-main-menu-account .njc-menu-info .njc-menu-title .njc-menu-icon .njc-user-dropdown-arrow a {
  height: 35px;
  padding: 0 12px;
  font-weight: 400;
  font-size: 14px;
  color: var(--grayscale-80);
  white-space: nowrap;
  min-height: 36px;
  padding: 0px 20px 0px 12px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.njc-main-menu .njc-main-menu-account .njc-menu-info .njc-menu-title .njc-menu-icon .njc-user-dropdown-arrow a:hover {
  color: var(--primary-color);
  cursor: pointer;
}
.njc-main-menu .njc-main-menu-account .njc-menu-info .njc-menu-title .njc-menu-icon .njc-user-dropdown-arrow a:before {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  border-bottom: 1px solid var(--grayscale-30);
}
.njc-main-menu .njc-main-menu-account .njc-menu-info .njc-menu-title .njc-menu-icon .njc-user-dropdown-arrow a:last-child:before {
  border-bottom: 0px;
}
.njc-main-menu .njc-main-menu-account .njc-menu-info .njc-menu-title .njc-menu-icon .njc-user-dropdown-arrow a:hover svg {
  color: var(--primary-color);
}
.njc-main-menu .njc-main-menu-account .njc-menu-info .njc-menu-title .njc-menu-icon .njc-user-dropdown-arrow a svg {
  margin-right: 8px;
}
.njc-main-menu .njc-main-menu-account .njc-menu-info .njc-menu-title .njc-menu-icon .njc-user-dropdown-arrow:after,
.njc-main-menu .njc-main-menu-account .njc-menu-info .njc-menu-title .njc-menu-icon .njc-user-dropdown-arrow:before {
  left: 35px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.njc-main-menu .njc-main-menu-account .njc-menu-info .njc-menu-title .njc-menu-icon .njc-user-dropdown-arrow:after {
  top: -12px;
  border-bottom-color: var(--grayscale-0);
  border-width: 6px;
  margin-left: -20px;
  z-index: 101;
}
.njc-main-menu .njc-main-menu-account .njc-menu-info .njc-menu-title .njc-menu-icon .njc-user-dropdown-arrow:before {
  top: -14px;
  border-bottom-color: var(--grayscale-30);
  border-width: 7px;
  margin-left: -21px;
}
.njc-main-menu .njc-main-menu-account .njc-menu-info .njc-menu-title .njc-menu-icon .show-account-menu {
  display: block;
}
.njc-main-menu .njc-main-menu-account .njc-menu-info .njc-menu-subtext {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: var(--primary-color-20);
}
.njc-main-menu .njc-main-menu-account .njc-menu-info .chevron {
  width: 18px;
  height: 18px;
}
.njc-main-menu .njc-main-menu-account .njc-menu-info .chevron svg {
  width: 18px;
  height: 18px;
  color: var(--primary-color-20);
}
.njc-main-menu .njc-main-menu-dropdowns {
  height: calc(100% - var(--nav-user-display-height));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: overlay;
  overflow-x: hidden;
  z-index: 1;
  position: relative;
}
.njc-main-menu .njc-main-menu-dropdowns::-webkit-scrollbar {
  width: 6px;
}
.njc-main-menu .njc-main-menu-dropdowns::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px var(--grayscale-10);
}
.njc-main-menu .njc-main-menu-dropdowns::-webkit-scrollbar-thumb {
  background: var(--grayscale-30);
  border-left: 1px solid var(--grayscale-0);
  border-right: 1px solid var(--grayscale-0);
  border-radius: 5px;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-section {
  width: 100%;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-section:first-child {
  padding-bottom: 15px;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-section .njc-main-menu-section-title {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-color-10);
  text-transform: uppercase;
  margin: 24px 20px 10px 20px;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: var(--primary-color-10);
  cursor: pointer;
  position: relative;
  width: 100%;
  background-color: var(--primary-color);
  margin: 4px 0;
  padding: 0 8px;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item:hover .njc-main-menu-item-label {
  color: var(--grayscale-0);
  background-color: var(--primary-color-hover);
  width: 100%;
  border-radius: 5px;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item:hover .njc-menu-icon {
  color: var(--primary-color-5);
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-main-menu-item-label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  padding: 9px 12px;
  font-size: 14px;
  transition: all 0.2s;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-menu-icon {
  width: 24px;
  height: 24px;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-menu-icon svg {
  width: 24px;
  height: 24px;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-menu-icon.link-icon {
  margin-left: 10px;
  color: var(--primary-color-20);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  width: 14px;
  height: 14px;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-menu-icon.link-icon svg {
  width: 14px;
  height: 14px;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-menu-icon.ai-icon {
  margin-left: 10px;
  width: 18px;
  height: 18px;
  color: var(--primary-color-10);
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-menu-icon.ai-icon svg {
  width: 18px;
  height: 18px;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-menu-text {
  margin-left: 12px;
  display: flex;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-main-menu-item-dropwrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--primary-color);
  max-height: 0;
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  -ms-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-main-menu-item-dropwrap .njc-menu-item {
  font-weight: 500;
  font-size: 13px;
  color: var(--primary-color-10);
  max-height: 0px;
  padding: 0 12px 0 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  -ms-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
  transition: height 1s;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-main-menu-item-dropwrap .njc-menu-item:hover {
  color: var(--grayscale-0);
  transition: color 0.2s;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-main-menu-item-dropwrap .njc-menu-item .njc-menu-text {
  font-size: 13px;
  margin-left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-main-menu-item-dropwrap .njc-menu-item .njc-menu-text .link-icon {
  color: var(--primary-color-10);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-main-menu-item-dropwrap .njc-menu-item.active,
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-main-menu-item-dropwrap .njc-menu-item.w--current {
  font-weight: 600;
  color: var(--grayscale-0);
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item.active .njc-main-menu-item-label {
  font-weight: 600;
  color: var(--grayscale-0);
  background-color: var(--primary-color-dark);
  width: 100%;
  border-radius: 5px;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item.active .njc-main-menu-item-dropwrap {
  max-height: 400px;
  overflow: hidden;
  padding: 4px 0px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item.active .njc-main-menu-item-dropwrap .njc-menu-item {
  min-height: 33px;
  max-height: 40px;
  transition: height 0s;
  transition-delay: 0s;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-bottom {
  margin-top: auto;
  padding: 10px 0 16px 0;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-bottom,
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-marketing {
  width: 100%;
  position: relative;
}
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-bottom:before,
.njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-marketing:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 20px;
  width: calc(100% - 40px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.njc-main-menu .njc-main-menu-item-label {
  display: flex;
  align-items: center;
  width: 100%;
}
.njc-menu-full-page-close {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 98;
  left: 0;
  top: 0;
}
.njc-tertiary {
  margin: -25px 0px 20px 0px;
  border-bottom: 1px solid var(--grayscale-20);
  width: 100%;
}
.njc-tertiary-tabs {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}
.njc-command-center-settings-tabs .njc-tertiary-tabs {
  justify-content: space-between;
}
.njc-tertiary-tabs-link {
  padding: 8px 20px;
  font-weight: 500;
  font-size: 13px;
  color: var(--grayscale-100);
  margin-bottom: -1px;
  text-align: center;
  border-bottom: 3px solid transparent;
  text-decoration: none;
}
.njc-tertiary-tabs-link.njc-tertiary-tabs-link-current {
  font-weight: 600;
}
.njc-tertiary-tabs-link:hover {
  cursor: pointer;
}
.njc-tertiary-tabs-link.njc-tertiary-tabs-link-disabled {
  color: var(--grayscale-70);
  font-weight: 500;
  cursor: not-allowed;
}
.njc-tertiary-tabs-link.njc-tertiary-tab-link-complete {
  padding: 8px 28px 8px 12px;
  background-image: url("/images/njc/njc-green-check.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) 50%;
}
.njc-tertiary-tabs-link.njc-tertiary-tab-link-complete:hover {
  cursor: default;
  color: var(--grayscale-100);
}
.njc-command-center-settings-tabs .njc-tertiary-tabs-link {
  padding: 8px 12px;
}
.njc-tertiary-tabs-link-current {
  border-bottom: 4px solid var(--tertiary-color);
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#root-vue {
  display: flex;
  flex-direction: row;
}
#root-vue > div {
  width: 100%;
  flex-basis: 100%;
  padding: 0px;
  margin: 0px;
}
html {
  height: 100%;
}
body {
  height: 100%;
  background-color: var(--grayscale-3);
  line-height: normal;
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  margin: 0px;
}
button {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-loader-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.njc-loader-container svg {
  color: var(--primary-color);
}
.njc-loader-table-overlay {
  position: absolute;
  z-index: 10;
  padding-top: 10px;
  top: 40px;
  width: 100%;
  height: calc(100% - 40px);
}
.njc-loader-table-overlay .njc-loader-container {
  align-items: flex-start;
  justify-content: center;
}
.njc-align-left {
  margin-right: auto;
}
.njc-align-right {
  margin-left: auto;
}
.njc-align-text-right {
  text-align: right;
}
/*Account list*/
.njc-body {
  margin-left: 240px;
  margin-bottom: 80px;
  padding: 0px;
  transition: width 1s;
}
.njc-body.minimize {
  margin-left: 55px;
}
.njc-body-content {
  margin: 0 auto;
  width: 90%;
}
.njc-body-content .njc-widget-card .njc-nodata-card {
  border: 1px dashed var(--grayscale-30);
  border-radius: 5px;
  min-height: 200px;
  padding: 34px 12px 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.njc-body-content .njc-widget-card .njc-nodata-card .njc-command-center-nodata {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.njc-body-content .njc-widget-card .njc-nodata-card .njc-command-center-nodata-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 16px;
}
.njc-body-content .njc-widget-card .njc-nodata-card .njc-command-center-nodata-image svg {
  width: 100px;
  height: 100px;
}
.njc-body-content .njc-widget-card .njc-nodata-card .njc-command-center-nodata-msg {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-50);
  line-height: 18px;
  max-width: 350px;
  text-align: center;
}
.njc-body-content .njc-widget-card .njc-nodata-card .njc-command-center-nodata-msg a {
  font-weight: 400;
  font-size: 12px;
  color: var(--primary-color);
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  cursor: pointer;
}
.njc-body-content .njc-widget-card .njc-nodata-card .njc-command-center-nodata-msg a-med {
  font-size: 13px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-body-content .njc-widget-card .njc-nodata-card .njc-command-center-nodata-msg a-med:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-body-content .njc-widget-card .njc-nodata-card .njc-command-center-nodata-msg a-med svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  margin-right: 10px;
  color: var(--primary-color);
}
.njc-body-content .njc-widget-card .njc-nodata-card .njc-command-center-nodata-msg a-larger {
  font-size: 14px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-body-content .njc-widget-card .njc-nodata-card .njc-command-center-nodata-msg a-larger:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-body-content .njc-widget-card .njc-nodata-card .njc-command-center-nodata-msg a-larger svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  color: var(--primary-color);
}
.njc-body-content .njc-widget-card .njc-nodata-card .njc-command-center-nodata-msg a:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-body-content .njc-widget-card .njc-nodata-card .njc-nodata-card-content {
  display: none !important;
}
.njc-body-content .njc-widget-card .njc-nodata-card .njc-command-center-nodata {
  display: flex !important;
}
.njc-body-content .njc-widget-card .nc-table-page-footer {
  border-top: 0px solid transparent;
}
.njc-body-content .njc-command-center-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.njc-body-content .njc-command-center-row .njc-flex-expand {
  flex: 1;
}
.njc-body-content .njc-command-center-row .njc-command-center-panel {
  margin-bottom: 32px;
}
.njc-body-content .njc-command-center-row .njc-command-center-panel .njc-command-center-title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
}
.njc-body-content .njc-command-center-row .njc-command-center-panel .njc-command-center-title-row .njc-command-center-title h4 {
  font-weight: 700;
  font-size: 24px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  margin: 0px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-body-content .njc-command-center-row .njc-command-center-panel .njc-command-center-title-row .njc-command-center-title-add {
  margin-right: -4px;
  width: 36px;
  height: 36px;
}
.njc-body-content .njc-command-center-row .njc-command-center-panel .njc-command-center-title-row .njc-command-center-title-add .njc-icon-button svg {
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: 4px;
  background-color: transparent;
  color: var(--primary-color);
}
.njc-body-content .njc-command-center-row .njc-command-center-panel .njc-command-center-title-row .njc-command-center-title-add .njc-icon-button:hover svg {
  background-color: var(--primary-color);
  color: var(--grayscale-0);
}
.njc-body-content .njc-command-center-row .njc-command-center-panel .nc-table-page-footer {
  display: none;
}
.njc-body-content .njc-command-center-datasources {
  margin-right: 32px;
}
.njc-body-content .njc-command-center-datasources .njc-list-table-row.njc-clickable-row {
  cursor: pointer;
}
.njc-body-content .njc-command-center-datasources .njc-list-table {
  max-height: 320px;
  overflow-y: overlay;
}
.njc-body-content .njc-command-center-datasources .njc-list-table::-webkit-scrollbar {
  width: 6px;
}
.njc-body-content .njc-command-center-datasources .njc-list-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px var(--grayscale-10);
}
.njc-body-content .njc-command-center-datasources .njc-list-table::-webkit-scrollbar-thumb {
  background: var(--grayscale-30);
  border-left: 1px solid var(--grayscale-0);
  border-right: 1px solid var(--grayscale-0);
  border-radius: 5px;
}
.njc-body-content .njc-command-center-ninjatrack {
  margin-left: 8px;
}
.njc-body-content .njc-command-center-ninjatrack .njc-list-table {
  max-height: 320px;
  overflow-x: hidden;
  overflow-y: overlay;
}
.njc-body-content .njc-command-center-ninjatrack .njc-list-table::-webkit-scrollbar {
  width: 6px;
}
.njc-body-content .njc-command-center-ninjatrack .njc-list-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px var(--grayscale-10);
}
.njc-body-content .njc-command-center-ninjatrack .njc-list-table::-webkit-scrollbar-thumb {
  background: var(--grayscale-30);
  border-left: 1px solid var(--grayscale-0);
  border-right: 1px solid var(--grayscale-0);
  border-radius: 5px;
}
.njc-body-content .njc-command-center-ninjatrack .njc-list-table .njc-table-icon-more-action-menu {
  right: 110px;
}
.njc-body-content .njc-command-center-ninjatrack .njc-list-table .njc-icon-button-hover {
  top: 0;
  right: 75px;
}
.njc-body-content .njc-command-center-ninjatrack .njc-list-table .njc-icon-button-hover .njc-icon-button-hover-arrow:after {
  border-left-color: var(--grayscale-100);
  border-top-color: transparent;
  top: 7px;
  right: -10px;
  left: auto;
}
.njc-body-content .njc-command-center-reports-legacy .njc-table-icon-more-action-menu {
  right: 110px;
}
.njc-body-content .njc-command-center-kpis .nc-bullet-graph .nc-bullet-graph-container {
  position: relative;
}
.njc-body-content .njc-command-center-kpis .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-wrap {
  width: 100%;
  z-index: 1;
  height: 8px;
  border-radius: 5px;
  background-color: var(--grayscale-10);
  margin: 8px 0px;
}
.njc-body-content .njc-command-center-kpis .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-projected {
  position: absolute;
  left: 0;
  top: 0;
  height: 8px;
  z-index: 2;
  border-radius: 5px;
  padding: 0px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.njc-body-content .njc-command-center-kpis .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-projected:hover {
  border: 1px solid var(--grayscale-0);
}
.njc-body-content .njc-command-center-kpis .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-projected:hover .njc-icon-button-hover {
  left: 99%;
  right: auto;
}
.njc-body-content .njc-command-center-kpis .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-projected:hover .njc-icon-button-hover .njc-icon-button-hover-arrow {
  left: -50%;
  right: auto;
}
.njc-body-content .njc-command-center-kpis .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-current {
  position: absolute;
  left: 0;
  top: 0px;
  height: 8px;
  z-index: 3;
  border-radius: 5px 0 0 5px;
  padding: 0px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.njc-body-content .njc-command-center-kpis .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-current:hover {
  border: 1px solid var(--grayscale-0);
}
.njc-body-content .njc-command-center-kpis .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-current:hover .njc-icon-button-hover {
  left: 100%;
  right: auto;
}
.njc-body-content .njc-command-center-kpis .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-current:hover .njc-icon-button-hover .njc-icon-button-hover-arrow {
  left: -50%;
  right: auto;
}
.njc-body-content .njc-command-center-kpis .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-target {
  position: absolute;
  top: 0px;
  height: 0;
  width: 1px !important;
  z-index: 4;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.njc-body-content .njc-command-center-kpis .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-target .njc-kpi-triangle {
  position: relative;
  color: var(--grayscale-50);
  top: -9px;
  left: -5px;
  width: 9px;
  height: 9px;
}
.njc-body-content .njc-command-center-kpis .nc-bullet-graph {
  width: 100%;
}
.njc-body-content .njc-command-center-kpis .nc-bullet-graph .justify-space-around:first-child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.njc-body-content .njc-command-center-kpis .nc-bullet-graph .nc-bullet-graph-container {
  margin: 8px 0 0 0;
}
.njc-body-content .njc-command-center-kpis .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-projected {
  width: 100%;
  background-color: var(--grayscale-10);
}
.njc-body-content .njc-command-center-kpis .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-target {
  height: 8px;
  border-left: 1px solid var(--grayscale-100);
  border-right: 1px solid var(--grayscale-100);
}
.njc-body-content .njc-command-center-kpis .njc-table-icon-more-action-menu {
  right: 110px;
}
.njc-body-content .njc-command-center-campaigngroup {
  margin-top: 20px;
}
.njc-body-content .njc-command-center-campaigngroup .njc-command-center-panel {
  margin-bottom: 0px;
}
.njc-body-content .njc-command-center-campaigngroup .njc-command-center-panel .njc-list-card {
  border: 1px solid var(--grayscale-30);
  box-shadow: 2px 2px 4px rgba(45, 53, 64, 0.1);
}
.njc-body-content .njc-command-center-campaigngroup .njc-command-center-panel .njc-list-card .njc-list-table {
  overflow-y: visible;
  overflow-x: visible;
}
.njc-body-content .njc-command-center-campaigngroup .njc-command-center-panel .njc-list-card .njc-list-table .njc-list-table-header {
  border-bottom: 1px solid var(--grayscale-30);
  border-radius: 5px 5px 0 0;
}
.njc-body-content .njc-command-center-datasources .njc-widget-card .njc-list-card,
.njc-body-content .njc-command-center-reports-legacy .njc-widget-card .njc-list-card,
.njc-body-content .njc-command-center-changehistory .njc-widget-card .njc-list-card,
.njc-body-content .njc-command-center-ninjatrack .njc-widget-card .njc-list-card,
.njc-body-content .njc-command-center-kpis .njc-widget-card .njc-list-card,
.njc-body-content .njc-command-center-campaigngroup .njc-widget-card .njc-list-card {
  border-top: 0px solid #fff;
  border-radius: 0 0 5px 5px;
}
.njc-body-content .njc-command-center-datasources .njc-widget-card .nc-display-table-card-header,
.njc-body-content .njc-command-center-reports-legacy .njc-widget-card .nc-display-table-card-header,
.njc-body-content .njc-command-center-changehistory .njc-widget-card .nc-display-table-card-header,
.njc-body-content .njc-command-center-ninjatrack .njc-widget-card .nc-display-table-card-header,
.njc-body-content .njc-command-center-kpis .njc-widget-card .nc-display-table-card-header,
.njc-body-content .njc-command-center-campaigngroup .njc-widget-card .nc-display-table-card-header {
  border-bottom: 0px solid #fff;
  border-top: 1px solid var(--grayscale-30);
  border-radius: 5px 5px 0 0;
}
.njc-command-center-settings .njc-command-center-settings-extra-buttons {
  display: flex;
  flex-direction: row;
}
.njc-command-center-settings .njc-command-center-settings-extra-buttons .njc-btn-secondary {
  margin-left: 8px;
}
.njc-command-center-settings .njc-first-container .njc-card-header-2-row h3,
.njc-command-center-settings .njc-second-container .njc-card-header-2-row h3 {
  padding-top: 20px !important;
}
.njc-command-center-settings .njc-second-container {
  padding-top: 20px;
}
.njc-command-center-settings .njc-search-bar-row {
  margin-top: 20px;
}
.njc-list-card {
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  background-color: var(--grayscale-0);
  box-shadow: 2px 2px 4px rgba(45, 53, 64, 0.1);
}
.njc-list-card.njc-reorder-table-wrap {
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.njc-list-card.njc-reorder-table-wrap .njc-list-table .njc-list-table-header {
  border-bottom: none;
  border-radius: 5px;
}
.njc-list-card.njc-reorder-table-wrap .njc-list-table .njc-list-table-header .njc-list-table-cell:nth-child(2) {
  padding-left: 0px;
}
.njc-list-card.njc-reorder-table-wrap .njc-list-table .njc-list-table-row:last-child:before {
  border-bottom: 1px solid var(--grayscale-20);
}
.njc-list-card.njc-reorder-table-wrap .njc-list-table .njc-list-table-row .njc-list-table-cell {
  padding-top: 8px;
  padding-bottom: 8px;
}
.njc-list-card.njc-reorder-table-wrap .njc-list-table .njc-list-table-row .njc-list-table-cell .template-content-wrap {
  padding-left: 0px !important;
  display: flex;
  flex-direction: column;
}
.njc-list-card.njc-reorder-table-wrap .njc-list-table .njc-list-table-row .njc-list-table-cell .njc-form-input {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  height: 36px;
}
.njc-list-card.njc-reorder-table-wrap .njc-list-table .njc-list-table-row .njc-list-table-cell .njc-form-input::placeholder {
  color: var(--grayscale-70);
}
.njc-list-card.njc-reorder-table-wrap .njc-list-table .njc-list-table-row .njc-list-table-cell .njc-form-input:hover {
  border: 1px solid var(--grayscale-50);
}
.njc-list-card.njc-reorder-table-wrap .njc-list-table .njc-list-table-row .njc-list-table-cell .njc-form-input:active,
.njc-list-card.njc-reorder-table-wrap .njc-list-table .njc-list-table-row .njc-list-table-cell .njc-form-input:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.njc-list-card.njc-reorder-table-wrap .njc-list-table .njc-list-table-row .njc-list-table-cell .njc-form-input:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.njc-list-card.njc-reorder-table-wrap .njc-list-table .njc-list-table-row .njc-list-table-cell .njc-form-input[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.njc-list-card.njc-reorder-table-wrap .njc-list-table .njc-list-table-row .njc-list-table-cell .njc-form-input[type="number"]::-webkit-outer-spin-button,
.njc-list-card.njc-reorder-table-wrap .njc-list-table .njc-list-table-row .njc-list-table-cell .njc-form-input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.njc-list-card.njc-reorder-table-wrap .njc-list-table .njc-list-table-row .njc-list-table-cell.njc-table-column-icon .njc-icon-button {
  padding: 4px !important;
}
.njc-list-card.njc-reorder-table-wrap .njc-list-table .njc-list-table-row .njc-list-table-cell .njc-form-text-default {
  padding: 0px;
}
.njc-list-card.njc-reorder-table-wrap .njc-list-table .njc-list-table-row .njc-list-table-cell .njc-form-text-default .njc-form-input-wrap {
  margin-top: 0px;
}
.njc-list-card.njc-reorder-table-wrap .njc-list-table .njc-form-text-default-input-container {
  min-width: auto;
  flex-basis: auto;
}
.njc-list-card .njc-list-table {
  display: flex;
  flex-direction: column;
}
.njc-list-card .njc-list-table .njc-list-table-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  position: relative;
  padding: 0px 20px !important;
}
.njc-list-card .njc-list-table .njc-list-table-row:before {
  content: "";
  position: absolute;
  left: 20px;
  bottom: 0;
  height: 1px;
  width: calc(100% - 40px);
  border-bottom: 1px solid var(--grayscale-20);
}
.njc-list-card .njc-list-table .njc-list-table-row:last-child:before {
  border-bottom: 0px solid var(--grayscale-0);
}
.njc-list-card .njc-list-table .njc-list-table-row.njc-clickable-row:hover {
  background-color: var(--listhovercolor);
}
.njc-list-card .njc-list-table .njc-list-table-row-flex-s .njc-list-table-cell {
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-list-card .njc-list-table .njc-list-table-row.njc-table-column-bold {
  font-weight: 500;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-list-card .njc-list-table .njc-list-table-row .njc-list-status-column div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.njc-list-card .njc-list-table .njc-list-table-row .njc-table-column-icon {
  justify-content: flex-end;
  display: flex;
}
.njc-list-card .njc-list-table .njc-list-table-row .njc-table-column-icon a {
  display: inline-block;
}
.njc-list-card .njc-list-table .njc-list-table-header {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  background-color: var(--grayscale-5);
  height: 40px;
  border-bottom: 1px solid var(--grayscale-30);
  border-radius: 5px 5px 0 0;
  padding: 0px 20px;
  position: sticky;
  top: 0;
  z-index: 9;
}
.njc-list-card .njc-list-table .njc-list-table-header.njc-list-table-header-expanded-height {
  height: 56px;
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-table-column-icon {
  text-indent: 500%;
  white-space: nowrap;
  overflow: hidden;
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell {
  font-weight: 700;
  font-size: 13px;
  color: var(--grayscale-60);
  text-transform: capitalize;
  text-decoration: none;
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell.disabled {
  color: var(--grayscale-30);
  cursor: not-allowed;
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell.disabled:hover {
  color: var(--grayscale-30);
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell.w--current {
  color: var(--primary-color);
  border-radius: 5px;
  background-color: var(--secondary-color);
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell.w--current:hover {
  color: var(--primary-color);
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-weight: 700;
  font-size: 13px;
  color: var(--grayscale-60);
  text-transform: capitalize;
  text-decoration: none;
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content.disabled {
  color: var(--grayscale-30);
  cursor: not-allowed;
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content.disabled:hover {
  color: var(--grayscale-30);
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content.w--current {
  color: var(--primary-color);
  border-radius: 5px;
  background-color: var(--secondary-color);
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content.w--current:hover {
  color: var(--primary-color);
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .njc-list-table-cell-content-h-scroll {
  overflow-y: auto;
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .icon-image-wrap,
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .njc-table-icon-sort {
  width: 24px;
  height: 24px;
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .icon-image-wrap img,
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .njc-table-icon-sort img {
  padding-top: 24px;
  overflow: hidden;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .icon-image-wrap img[src*="sort_list"],
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .njc-table-icon-sort img[src*="sort_list"] {
  background-image: url("/images/njc/njc-icon-sort-list.svg");
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .icon-image-wrap img[src*="sort_list_down"],
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .njc-table-icon-sort img[src*="sort_list_down"] {
  background-image: url("/images/njc/njc-icon-sort-up.png");
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .icon-image-wrap img[src*="sort_list_up"],
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .njc-table-icon-sort img[src*="sort_list_up"] {
  background-image: url("/images/njc/njc-icon-sort-down.png");
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .text-7b7b7b,
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .njc-list-table-cell-content-text {
  margin: 0px;
  font-weight: 700;
  font-size: 13px;
  color: var(--grayscale-60);
  text-transform: capitalize;
  text-decoration: none;
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .text-7b7b7b.disabled,
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .njc-list-table-cell-content-text.disabled {
  color: var(--grayscale-30);
  cursor: not-allowed;
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .text-7b7b7b.disabled:hover,
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .njc-list-table-cell-content-text.disabled:hover {
  color: var(--grayscale-30);
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .text-7b7b7b.w--current,
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .njc-list-table-cell-content-text.w--current {
  color: var(--primary-color);
  border-radius: 5px;
  background-color: var(--secondary-color);
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .text-7b7b7b.w--current:hover,
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-list-table-cell-content .njc-list-table-cell-content-text.w--current:hover {
  color: var(--primary-color);
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell.sorting_asc .njc-table-icon-sort-standalone {
  background-image: url("/images/njc/njc-icon-sort-down.png");
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell.sorting_desc .njc-table-icon-sort-standalone {
  background-image: url("/images/njc/njc-icon-sort-up.png");
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell .njc-table-icon-sort-standalone {
  width: 24px;
  height: 24px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.njc-list-card .njc-list-table .njc-list-table-header .njc-list-table-cell.njc-list-table-cell-column .njc-list-table-cell-content {
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}
.njc-list-card .njc-list-table .njc-list-table-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
}
.njc-list-card .njc-list-table .njc-list-table-cell:first-child {
  padding-left: 0px;
}
.njc-list-card .njc-list-table .njc-list-table-cell:last-child {
  padding-right: 0px;
}
.njc-list-card .njc-list-table .njc-list-table-cell-flex-dc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-list-card .njc-list-table .njc-list-table-cell-flex-dr {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-list-card .njc-list-table .njc-list-table-cell-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-list-card .njc-list-table .njc-list-table-cell .round-image {
  width: 30px;
  height: 30px;
  max-height: 30px !important;
  max-width: 30px !important;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 12px;
}
.njc-list-card .njc-list-table .njc-list-table-cell .round-image.generic-account {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-0);
  background-color: var(--primary-color);
  text-transform: uppercase;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-reports {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-reports-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap !important;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-reports-content svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-reports .dark-text-link {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: var(--primary-color) !important;
  text-decoration: none !important;
  cursor: pointer !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
  margin-left: 23px;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-reports .dark-text-link-med {
  font-size: 13px !important;
  color: var(--primary-color) !important;
  text-decoration: none !important;
  font-weight: 400 !important;
  cursor: pointer !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-reports .dark-text-link-med:hover {
  color: var(--primary-color) !important;
  text-decoration: underline !important;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-reports .dark-text-link-med svg {
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
  margin-right: 10px !important;
  color: var(--primary-color) !important;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-reports .dark-text-link-larger {
  font-size: 14px !important;
  color: var(--primary-color) !important;
  text-decoration: none !important;
  font-weight: 400 !important;
  cursor: pointer !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-reports .dark-text-link-larger:hover {
  color: var(--primary-color) !important;
  text-decoration: underline !important;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-reports .dark-text-link-larger svg {
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
  color: var(--primary-color) !important;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-reports .dark-text-link:hover {
  color: var(--primary-color) !important;
  text-decoration: underline !important;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-scheduled {
  font-weight: 500;
  font-size: 12px;
  color: var(--primary-color);
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-scheduled svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-ready {
  font-weight: 500;
  font-size: 12px;
  color: var(--primary-color);
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-ready svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-sent {
  font-weight: 500;
  font-size: 12px;
  color: var(--success-color);
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-sent svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-dataerror {
  font-weight: 500;
  font-size: 12px;
  color: var(--warning-color-dark);
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-dataerror svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-criticalerror {
  font-weight: 500;
  font-size: 12px;
  color: var(--critical-color);
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-criticalerror svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-emailerror {
  font-weight: 500;
  font-size: 12px;
  color: var(--warning-color-dark);
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-emailerror svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-readytorun {
  font-weight: 500;
  font-size: 12px;
  color: var(--primary-color);
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-readytorun svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-inprogress {
  font-weight: 500;
  font-size: 12px;
  color: var(--purple-100);
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-inprogress svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-paused {
  font-weight: 500;
  font-size: 12px;
  color: var(--grayscale-70);
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-col-status-paused svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-status-text-connected {
  font-weight: 500;
  font-size: 12px;
  color: var(--success-color);
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-status-text-connected svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-status-text-error {
  font-weight: 500;
  font-size: 12px;
  color: var(--critical-color);
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-status-text-error svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-list-card .njc-list-table .njc-list-table-cell .icon-image-wrap img {
  width: 24px;
  height: 24px;
}
.njc-list-card .njc-list-table .njc-list-table-cell .template-body-text {
  font-weight: 400;
  font-size: 12px;
  color: var(--grayscale-60);
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-list-card .njc-list-table .njc-list-table-cell .template-body-text strong {
  font-weight: 500;
  font-size: 12px;
  color: var(--grayscale-60);
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-template-portrait img {
  width: 100%;
}
.njc-list-card .njc-list-table .njc-list-table-cell .template-networks-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.njc-list-card .njc-list-table .njc-list-table-cell .template-networks-wrap .network-icon-wrap {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  background-color: transparent;
  margin-top: 8px;
}
.njc-list-card .njc-list-table .njc-list-table-cell .template-networks-wrap .network-icon-wrap img {
  width: 18px;
  height: 18px;
  border-radius: 3px;
}
.njc-list-card .njc-list-table .njc-list-table-cell.njc-list-table-cell-column .njc-list-table-cell-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-user-status-active,
.njc-list-card .njc-list-table .njc-list-table-cell .njc-user-status-suspended,
.njc-list-card .njc-list-table .njc-list-table-cell .njc-user-status-invited,
.njc-list-card .njc-list-table .njc-list-table-cell .njc-user-status-expired {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-user-status-suspended,
.njc-list-card .njc-list-table .njc-list-table-cell .njc-user-status-expired {
  font-weight: 500;
  font-size: 12px;
  color: var(--critical-color);
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-user-status-suspended svg,
.njc-list-card .njc-list-table .njc-list-table-cell .njc-user-status-expired svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-user-status-active {
  font-weight: 500;
  font-size: 12px;
  color: var(--success-color);
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-user-status-active svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-user-status-invited {
  font-weight: 500;
  font-size: 12px;
  color: var(--primary-color);
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-user-status-invited svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-list-card .njc-list-table .njc-list-table-cell .njc-user-status-signin {
  font-size: 13px;
  color: var(--grayscale-70);
}
.njc-list-card .njc-list-table .njc-list-empty {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.njc-list-card .njc-list-table .njc-list-empty span,
.njc-list-card .njc-list-table .njc-list-empty .dataTables_empty {
  font-weight: 400;
  font-size: 12px;
  color: var(--grayscale-60);
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-list-card .njc-profile-column .round-image {
  border-radius: 50% !important;
}
.njc-list-card.njc-list-card-alternate {
  margin-top: -10px;
}
.njc-list-card.njc-list-card-alternate .njc-tertiary {
  margin: 0;
  padding: 10px 50px 0;
}
.njc-list-card.njc-list-card-alternate .njc-list-card-container {
  margin: 0 auto 20px;
  width: calc(100% - 100px);
}
.njc-list-card.njc-list-card-alternate .njc-list-card-container .njc-form-buttons-bar [class^="njc-btn"] {
  margin-left: 8px;
}
.njc-list-card.njc-list-card-alternate .njc-list-card-container .njc-alternate-table-margin {
  margin-top: 20px;
}
.njc-list-card.njc-list-card-alternate .njc-list-card {
  border: 0 solid transparent;
  border-radius: 0;
  box-shadow: none;
}
.njc-list-card.njc-list-card-alternate .njc-list-card .njc-list-table-header {
  border-radius: 5px;
  padding: 0 12px;
  border: 0px solid transparent;
}
.njc-list-card.njc-list-card-alternate .njc-list-card .njc-list-table-header .text-8a8a8a {
  margin: 0px;
  font-weight: 700;
  font-size: 13px;
  color: var(--grayscale-60);
  text-transform: capitalize;
  text-decoration: none;
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-list-card.njc-list-card-alternate .njc-list-card .njc-list-table-header .text-8a8a8a.disabled {
  color: var(--grayscale-30);
  cursor: not-allowed;
}
.njc-list-card.njc-list-card-alternate .njc-list-card .njc-list-table-header .text-8a8a8a.disabled:hover {
  color: var(--grayscale-30);
}
.njc-list-card.njc-list-card-alternate .njc-list-card .njc-list-table-header .text-8a8a8a.w--current {
  color: var(--primary-color);
  border-radius: 5px;
  background-color: var(--secondary-color);
}
.njc-list-card.njc-list-card-alternate .njc-list-card .njc-list-table-header .text-8a8a8a.w--current:hover {
  color: var(--primary-color);
}
.njc-list-card.njc-list-card-alternate .njc-list-card .njc-list-table-row {
  padding: 0 12px !important;
}
.njc-list-card.njc-list-card-alternate .njc-list-card .njc-list-table-row:before {
  left: 0;
  width: 100%;
}
.nc-table-page-footer {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
  border-top: 1px solid var(--grayscale-20);
}
.nc-table-page-footer .pagination-footer {
  display: flex;
  height: 70px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.nc-table-page-footer .pagination-footer .njc-table-footer-control-group,
.nc-table-page-footer .pagination-footer .njc-table-footer-control-group .paging_simple_numbers,
.nc-table-page-footer .pagination-footer .njc-table-footer-control-group span {
  display: flex;
  align-items: center;
}
.nc-table-page-footer .pagination-footer .pagination-navlink {
  font-weight: 400;
  font-size: 14px;
  border-radius: 5px;
  margin: 0 1px;
  color: var(--grayscale-70);
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.nc-table-page-footer .pagination-footer .pagination-navlink.disabled {
  color: var(--grayscale-30);
  cursor: not-allowed;
}
.nc-table-page-footer .pagination-footer .pagination-navlink.disabled:hover {
  color: var(--grayscale-30);
  background-color: transparent;
}
.nc-table-page-footer .pagination-footer .pagination-navlink.w--current {
  color: var(--primary-color);
  font-weight: 700;
  border-radius: 5px;
  background-color: var(--primary-color-5);
  cursor: initial;
}
.nc-table-page-footer .pagination-footer .pagination-navlink.w--current:hover {
  color: var(--primary-color);
  background-color: var(--primary-color-5);
}
.nc-table-page-footer .pagination-footer .pagination-navlink.njc-table-footer-page-display {
  text-transform: lowercase;
  cursor: default;
}
.nc-table-page-footer .pagination-footer .pagination-navlink.njc-table-footer-page-display:first-letter {
  text-transform: capitalize;
}
.nc-table-page-footer .pagination-footer .pagination-navlink.njc-table-footer-page-display:hover {
  background-color: transparent;
}
.nc-table-page-footer .pagination-footer .pagination-navlink.njc-table-footer-show-all {
  padding: 0 20px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.nc-table-page-footer .pagination-footer .pagination-navlink.njc-table-footer-prev {
  padding: 0 12px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.nc-table-page-footer .pagination-footer .pagination-navlink.njc-table-footer-pages,
.nc-table-page-footer .pagination-footer .pagination-navlink .njc-table-footer-pages {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.nc-table-page-footer .pagination-footer .pagination-navlink.njc-table-footer-next {
  padding: 0px 12px;
  margin-right: -8px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.nc-table-page-footer .pagination-footer .pagination-navlink:hover {
  background-color: var(--grayscale-5);
  color: var(--grayscale-70);
}
.nc-table-page-footer .pagination-footer .dataTables_info .njc-table-footer-page-display {
  text-transform: initial;
}
.njc-has-tertiary-nav .njc-nodata-card {
  border-top: 0px solid transparent;
}
.njc-kpi-list .njc-list-card .njc-list-table .njc-list-table-row:nth-last-child(2):before {
  border-bottom: 0px solid var(--grayscale-0);
}
.njc-table-kpi-title {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--grayscale-60);
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.account-wrap.njc-list-table-row:hover {
  background-color: transparent !important;
}
.kpi-row.njc-list-table-row .njc-kpi-performance-graph {
  flex-wrap: wrap;
}
.kpi-row.njc-list-table-row .njc-list-table-cell:first-child {
  padding-left: 20px !important;
}
.kpi-row.njc-list-table-row:before {
  margin-left: 20px;
  width: calc(100% - 60px) !important;
}
.kpi-row.njc-list-table-row .kpi-bullet-graph-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.kpi-row.njc-list-table-row .kpi-bullet-graph-wrap .kpi-bullet-graph-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.kpi-row.njc-list-table-row .kpi-bullet-graph-wrap .nc-kpi-bullet-graph-area .nc-bullet-graph .nc-bullet-graph-container {
  position: relative;
}
.kpi-row.njc-list-table-row .kpi-bullet-graph-wrap .nc-kpi-bullet-graph-area .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-wrap {
  width: 100%;
  z-index: 1;
  height: 8px;
  border-radius: 5px;
  background-color: var(--grayscale-10);
  margin: 8px 0px;
}
.kpi-row.njc-list-table-row .kpi-bullet-graph-wrap .nc-kpi-bullet-graph-area .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-projected {
  position: absolute;
  left: 0;
  top: 0;
  height: 8px;
  z-index: 2;
  border-radius: 5px;
  padding: 0px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.kpi-row.njc-list-table-row .kpi-bullet-graph-wrap .nc-kpi-bullet-graph-area .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-projected:hover {
  border: 1px solid var(--grayscale-0);
}
.kpi-row.njc-list-table-row .kpi-bullet-graph-wrap .nc-kpi-bullet-graph-area .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-projected:hover .njc-icon-button-hover {
  left: 99%;
  right: auto;
}
.kpi-row.njc-list-table-row .kpi-bullet-graph-wrap .nc-kpi-bullet-graph-area .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-projected:hover .njc-icon-button-hover .njc-icon-button-hover-arrow {
  left: -50%;
  right: auto;
}
.kpi-row.njc-list-table-row .kpi-bullet-graph-wrap .nc-kpi-bullet-graph-area .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-current {
  position: absolute;
  left: 0;
  top: 0px;
  height: 8px;
  z-index: 3;
  border-radius: 5px 0 0 5px;
  padding: 0px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.kpi-row.njc-list-table-row .kpi-bullet-graph-wrap .nc-kpi-bullet-graph-area .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-current:hover {
  border: 1px solid var(--grayscale-0);
}
.kpi-row.njc-list-table-row .kpi-bullet-graph-wrap .nc-kpi-bullet-graph-area .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-current:hover .njc-icon-button-hover {
  left: 100%;
  right: auto;
}
.kpi-row.njc-list-table-row .kpi-bullet-graph-wrap .nc-kpi-bullet-graph-area .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-current:hover .njc-icon-button-hover .njc-icon-button-hover-arrow {
  left: -50%;
  right: auto;
}
.kpi-row.njc-list-table-row .kpi-bullet-graph-wrap .nc-kpi-bullet-graph-area .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-target {
  position: absolute;
  top: 0px;
  height: 0;
  width: 1px !important;
  z-index: 4;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.kpi-row.njc-list-table-row .kpi-bullet-graph-wrap .nc-kpi-bullet-graph-area .nc-bullet-graph .nc-bullet-graph-container .nc-bullet-graph-target .njc-kpi-triangle {
  position: relative;
  color: var(--grayscale-50);
  top: -9px;
  left: -5px;
  width: 9px;
  height: 9px;
}
.kpi-row.njc-list-table-row .kpi-bullet-graph-wrap .kpi-bullet-graph-bottom {
  font-weight: 400;
  font-size: 12px;
  color: var(--grayscale-60);
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.kpi-row.njc-list-table-row .nc-kpi-rate-arrow-graph {
  width: 100%;
}
.kpi-row.njc-list-table-row .nc-kpi-rate-arrow-graph .date-cycle {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-weight: 400;
  font-size: 12px;
  color: var(--grayscale-60);
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.kpi-row.njc-list-table-row .nc-kpi-rate-arrow-graph .date-cycle strong {
  font-weight: 500;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.kpi-row.njc-list-table-row .nc-kpi-rate-arrow-graph .kpi-current,
.kpi-row.njc-list-table-row .nc-kpi-rate-arrow-graph .kpi-projected,
.kpi-row.njc-list-table-row .nc-kpi-rate-arrow-graph .kpi-target {
  width: 20%;
  font-weight: 500;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.kpi-row.njc-list-table-row .nc-kpi-rate-arrow-graph .kpi-current span,
.kpi-row.njc-list-table-row .nc-kpi-rate-arrow-graph .kpi-projected span,
.kpi-row.njc-list-table-row .nc-kpi-rate-arrow-graph .kpi-target span {
  font-weight: 400;
  font-size: 12px;
  color: var(--grayscale-60);
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.kpi-row.njc-list-table-row .njc-kpi-gap-text {
  font-weight: 500;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.kpi-row.njc-list-table-row.normal .nc-bullet-graph-projected {
  background-color: var(--success-bg-color);
}
.kpi-row.njc-list-table-row.normal .nc-bullet-graph-current {
  background-color: var(--success-color);
}
.kpi-row.njc-list-table-row.normal.kpi_never_process .kpi-msg:after {
  content: 'We are currently gathering data. Come back later.';
  display: block;
  margin-bottom: 8px;
}
.kpi-row.njc-list-table-row.normal.kpi_never_process .kpi-bullet-graph-wrap {
  display: none;
}
.kpi-row.njc-list-table-row.normal.kpi_process_waiting_data .kpi-msg:after {
  content: "Still gathering yesterday's data. Come back later.";
  display: block;
  margin-bottom: 8px;
}
.kpi-row.njc-list-table-row.normal.kpi_created .njc-kpi-performance-graph {
  justify-content: center !important;
}
.kpi-row.njc-list-table-row.normal.kpi_created .kpi-msg:after {
  content: "Gathering Data. Come back later.";
  display: block;
}
.kpi-row.njc-list-table-row.normal.kpi_error {
  background-color: var(--critical-color-5);
}
.kpi-row.njc-list-table-row.normal.kpi_error .kpi-msg:after {
  content: "There was an error receiving one or more data sources.";
  display: block;
  margin-bottom: 8px;
}
.kpi-row.njc-list-table-row.normal .njc-kpi-gap-text {
  color: var(--success-color);
}
.kpi-row.njc-list-table-row.normal .njc-kpi-tag-normal {
  color: var(--grayscale-0);
  font-weight: 700;
  font-size: 12px;
  border-radius: 10px;
  padding: 0px 12px;
  background-color: var(--success-color);
  display: block !important;
}
.kpi-row.njc-list-table-row.warning .nc-bullet-graph-projected {
  background-color: var(--warning-bg-color);
}
.kpi-row.njc-list-table-row.warning .nc-bullet-graph-current {
  background-color: var(--warning-color-dark);
}
.kpi-row.njc-list-table-row.warning .njc-kpi-gap-text {
  color: var(--warning-color-dark);
}
.kpi-row.njc-list-table-row.warning .njc-kpi-tag-warning {
  color: var(--grayscale-0);
  font-weight: 700;
  font-size: 12px;
  border-radius: 10px;
  padding: 0px 12px;
  background-color: var(--warning-color-dark);
  display: block !important;
}
.kpi-row.njc-list-table-row.critical .nc-bullet-graph-projected {
  background-color: var(--critical-color-50);
}
.kpi-row.njc-list-table-row.critical .nc-bullet-graph-current {
  background-color: var(--critical-color);
}
.kpi-row.njc-list-table-row.critical .njc-kpi-gap-text {
  color: var(--critical-color);
}
.kpi-row.njc-list-table-row.critical .njc-kpi-tag-critical {
  color: var(--grayscale-0);
  font-weight: 700;
  font-size: 12px;
  border-radius: 10px;
  padding: 0px 12px;
  background-color: var(--critical-color);
  display: block !important;
}
.njc-list-table-row-last-overwrite .njc-list-card .njc-list-table .njc-list-table-row:nth-last-child(2):before {
  border-bottom: 0px solid #FFFFFF;
}
.njc-nodata-card {
  border-top: 1px solid var(--grayscale-20);
}
.njc-nodata-card .njc-command-center-nodata {
  display: none !important;
}
.njc-nodata-card-content {
  margin: 32px auto;
  padding-right: 425px;
  width: 90%;
  max-width: 800px;
  height: 280px;
  background-image: url("/images/njc/njc-nodata-default.png");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start;
  justify-content: center;
}
.njc-nodata-card-content h2 {
  margin: 12px 0px 0px 0px;
}
.njc-nodata-card-content p {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
}
.njc-nodata-card-content .njc-nodata-button-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-nodata-card-content .njc-nodata-button-wrap a {
  white-space: nowrap;
  margin-right: 12px;
}
.njc-nodata-card-content.njc-nodata-card-accounts {
  background-image: url("/images/njc/njc-nodata-accounts.png");
}
.njc-nodata-card-content.njc-nodata-card-account-groups {
  background-image: url("/images/njc/njc-nodata-account-groups.png");
}
.njc-nodata-card-content.njc-nodata-card-campaign-monitoring {
  background-image: url("/images/njc/njc-nodata-campaign-monitoring.png");
}
.njc-nodata-card-content.njc-nodata-card-dashboards {
  background-image: url("/images/njc/njc-nodata-dashboard.png");
}
.njc-nodata-card-content.njc-nodata-card-reports {
  background-image: url("/images/njc/njc-nodata-reports.png");
}
.njc-nodata-card-content.njc-nodata-card-templates {
  background-image: url("/images/njc/njc-nodata-templates.png");
}
.njc-nodata-card .njc-nodata-small-nodata {
  border: 1px dashed var(--grayscale-30);
  border-radius: 5px;
  min-height: 200px;
  padding: 34px 12px 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.njc-nodata-card .njc-nodata-small-nodata-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 16px;
}
.njc-nodata-card .njc-nodata-small-nodata-image svg {
  width: 100px;
  height: 100px;
}
.njc-nodata-card .njc-nodata-small-nodata-msg {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-50);
  line-height: 18px;
  max-width: 350px;
  text-align: center;
}
.njc-nodata-card .njc-nodata-small-nodata-msg a {
  font-weight: 400;
  font-size: 12px;
  color: var(--primary-color);
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  cursor: pointer;
}
.njc-nodata-card .njc-nodata-small-nodata-msg a-med {
  font-size: 13px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-nodata-card .njc-nodata-small-nodata-msg a-med:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-nodata-card .njc-nodata-small-nodata-msg a-med svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  margin-right: 10px;
  color: var(--primary-color);
}
.njc-nodata-card .njc-nodata-small-nodata-msg a-larger {
  font-size: 14px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-nodata-card .njc-nodata-small-nodata-msg a-larger:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-nodata-card .njc-nodata-small-nodata-msg a-larger svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  color: var(--primary-color);
}
.njc-nodata-card .njc-nodata-small-nodata-msg a:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-body-pagetitle {
  display: flex !important;
  min-height: 130px;
  align-items: center;
  justify-content: flex-start;
  font-weight: 700;
  font-size: 34px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-body-pagetitle .njc-beta-tag {
  color: var(--grayscale-0);
  font-weight: 700;
  font-size: 12px;
  border-radius: 10px;
  padding: 0px 12px;
  text-transform: uppercase;
  background-color: var(--primary-color);
  margin: 0px 0px 20px 8px;
}
.njc-body-pagetitle .njc-page-buttons {
  margin-left: auto;
  display: flex;
  flex-direction: row;
}
.njc-body-pagetitle .njc-page-buttons [class^="njc-btn"] {
  margin-left: 8px;
}
.njc-body-pagetitle .njc-pagetitle-title-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-body-pagetitle .njc-pagetitle-title-wrap .njc-pagetitle-subtext {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-50);
  text-transform: uppercase;
}
.njc-body-pagetitle .njc-pagetitle-title-wrap .njc-pagetitle-subtext a {
  font-size: 13px;
  font-wight: 500;
  color: var(--primary-color);
  text-decoration: none;
  text-transform: capitalize;
  cursor: pointer;
}
.njc-body-pagetitle .njc-pagetitle-title-wrap .njc-pagetitle-subtext a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-body-pagetitle .njc-pagetitle-title-wrap .njc-pagetitle-subtext a svg {
  width: 18px;
  height: 18px;
  transform: rotate(90deg);
}
.njc-body-pagetitle .njc-pagetitle-title-wrap .njc-pagetitle-title {
  margin-top: 4px;
  line-height: normal;
}
.njc-body-pagetitle .njc-pagetitle-title-wrap .njc-pagetitle-posttext {
  font-size: 12px;
  color: var(--grayscale-70);
  font-weight: 400;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-body-pagetitle .njc-pagetitle-title-wrap .njc-pagetitle-posttext svg {
  visibility: hidden;
  width: 14px;
  height: 14px;
  margin-left: 4px;
}
.njc-body-pagetitle .njc-pagetitle-title-wrap .njc-pagetitle-posttext:hover svg {
  visibility: visible;
}
.njc-body-pagetitle-tertiary {
  height: auto;
  margin: 0px 0px 12px 0px;
}
.njc-body-pagetitle-tertiary .njc-pagetitle-title-wrap .njc-pagetitle-title {
  margin-top: 12px;
  line-height: 24px;
  font-weight: 700;
  font-size: 14px;
  color: var(--grayscale-100);
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-pagetitle-title-smaller {
  font-weight: 700;
  font-size: 14px;
  color: var(--grayscale-100);
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  line-height: 24px;
  margin: -22px 0 10px 0;
}
.njc-pagetitle-3 {
  font-weight: 700;
  font-size: 18px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  min-height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-pagetitle-4 {
  font-weight: 700;
  font-size: 14px;
  color: var(--grayscale-100);
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  min-height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-search-bar-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-search-bar-row .njc-search-bar-wrap {
  flex-grow: 1;
  display: flex;
}
.njc-search-bar-row .justify {
  margin-left: auto;
}
.njc-search-bar-row .njc-form-search-bar {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  background-color: var(--grayscale-0);
  line-height: normal;
  padding: 12px 20px 12px 40px;
  margin: 0px 0px 20px 0px;
  width: 100%;
  min-width: 200px;
  background-image: url("/images/njc/njc-icon-search.svg");
  background-repeat: no-repeat;
  background-position: 16px 50%;
}
.njc-search-bar-row .njc-form-search-bar::placeholder {
  color: var(--grayscale-70);
}
.njc-search-bar-row .njc-form-search-bar:hover {
  border: 1px solid var(--grayscale-50);
}
.njc-search-bar-row .njc-form-search-bar:active,
.njc-search-bar-row .njc-form-search-bar:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.njc-search-bar-row .njc-form-search-bar:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.njc-search-bar-row .njc-form-search-bar[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.njc-search-bar-row .njc-form-search-bar[type="number"]::-webkit-outer-spin-button,
.njc-search-bar-row .njc-form-search-bar[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.njc-search-bar-row .njc-form-search-bar-less-padding {
  margin-bottom: 8px;
}
.njc-search-bar-row .njc-form-search-bar-no-padding {
  margin-bottom: 0px;
  border-radius: 5px 5px 0 0;
}
.njc-search-bar-row .njc-form-sort-bar {
  margin-left: auto;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-sort {
  background-color: #ffffff;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  height: 44px;
  line-height: normal;
  padding: 12px 32px 12px 16px;
  margin: 0px 0px 20px 8px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  max-width: 220px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("/images/njc/njc-icon-dropdown.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) 50%;
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-sort::placeholder {
  color: var(--grayscale-70);
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-sort select::-ms-expand {
  display: none;
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-sort-loading {
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  background-image: url("/images/njc/njc-icon-dropdown.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 35px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  min-width: 140px;
  position: relative;
  display: inline-flex;
  background-image: none;
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-sort-loading:hover {
  border: 1px solid var(--grayscale-50);
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-sort-loading:active,
.njc-search-bar-row .njc-form-sort-bar .njc-form-sort-loading:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-sort-loading:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-sort-loading[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-sort-loading[type="number"]::-webkit-outer-spin-button,
.njc-search-bar-row .njc-form-sort-bar .njc-form-sort-loading[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-sort-loading::-ms-expand {
  display: none;
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-sort-loading:disabled {
  background-image: url("/images/njc/njc-icon-dropdown-disabled.svg");
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-sort-loading::placeholder {
  color: var(--grayscale-70);
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-sort-loading:before {
  position: absolute;
  content: '';
  top: 13px;
  right: 13px;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  border: 2px solid rgba(0, 0, 0, 0.1);
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-sort-loading:after {
  position: absolute;
  content: '';
  top: 13px;
  right: 13px;
  width: 14px;
  height: 14px;
  animation: njc-spin 0.6s linear;
  animation-iteration-count: infinite;
  border-radius: 100%;
  border-color: #767676 transparent transparent;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 0 1px transparent;
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-date-filter {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  background-image: url("/images/njc/njc-calendar.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 35px;
  margin: 0 0 20px 8px;
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-date-filter::placeholder {
  color: var(--grayscale-70);
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-date-filter:hover {
  border: 1px solid var(--grayscale-50);
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-date-filter:active,
.njc-search-bar-row .njc-form-sort-bar .njc-form-date-filter:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-date-filter:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-date-filter[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.njc-search-bar-row .njc-form-sort-bar .njc-form-date-filter[type="number"]::-webkit-outer-spin-button,
.njc-search-bar-row .njc-form-sort-bar .njc-form-date-filter[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.njc-search-bar-row .njc-form-buttons-bar {
  margin-left: auto;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.njc-search-bar-row .njc-form-buttons-bar .njc-form-toggle-default {
  padding: 0px;
  margin-left: 12px;
  height: 44px;
  align-items: center;
  justify-content: flex-start;
}
.njc-search-bar-row .njc-form-buttons-bar .njc-form-toggle-default .nc-toggle-switch,
.njc-search-bar-row .njc-form-buttons-bar .njc-form-toggle-default .njc-toggle-switch {
  width: auto;
  padding-left: 58px;
  align-items: center;
  justify-content: flex-start;
}
.njc-search-bar-row .njc-form-buttons-bar .njc-btn-primary {
  margin: 0 0 20px 8px;
}
.njc-search-bar-row .njc-form-buttons-bar .njc-btn-secondary,
.njc-search-bar-row .njc-form-buttons-bar .njc-dropdown {
  margin-left: 12px;
}
.njc-report-error-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.njc-report-error-top .njc-report-error-top-data-error,
.njc-report-error-top .njc-report-error-top-crit-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 34px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-report-error-top .njc-report-error-top-data-error svg,
.njc-report-error-top .njc-report-error-top-crit-error svg {
  width: 44px;
  height: 44px;
  margin-bottom: 10px;
}
.njc-report-error-top .njc-report-error-top-data-error span,
.njc-report-error-top .njc-report-error-top-crit-error span {
  height: 32px;
  display: block;
}
.njc-report-error-top .njc-report-error-top-data-error * {
  color: var(--warning-color);
}
.njc-report-error-top .njc-report-error-top-crit-error * {
  color: var(--critical-color);
}
.njc-report-error-top .njc-report-error-top-label {
  padding: 2px 0;
}
.njc-report-error-top .njc-report-error-top-label span {
  font-weight: 700;
  font-size: 13px;
  color: var(--grayscale-100);
  text-transform: capitalize;
}
.nc-report-list-viewer .njc-search-bar-row .njc-form-search-bar {
  flex: 0 1 35%;
  width: 350px;
}
.nc-report-list-viewer .njc-search-bar-row .njc-form-sort-bar {
  flex: 1 1 auto;
}
.nc-report-list-viewer .njc-search-bar-row .njc-form-sort-bar .njc-form-sort {
  width: 33%;
  max-width: initial;
}
.njc-form-button-solo-override {
  margin-top: 20px;
}
.njc-form-button-solo-override .njc-btn-primary {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
  padding: 0px 20px;
  height: 44px;
  min-width: 44px;
  border-radius: 5px;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--primary-color);
  background-color: var(--grayscale-0);
  border: 1px solid var(--primary-color);
}
.njc-form-button-solo-override .njc-btn-primary svg {
  margin: 0px 8px 0px 0px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.njc-form-button-solo-override .njc-btn-primary svg.njc-icon-right-aligned {
  margin: 0px 0px 0px 8px;
}
.njc-form-button-solo-override .njc-btn-primary svg.njc-icon-progress-arrow {
  width: 12px;
  height: 12px;
}
.njc-form-button-solo-override .njc-btn-primary:disabled {
  background-color: var(--grayscale-60);
  color: var(--grayscale-30);
  border: 1px solid var(--grayscale-30);
}
.njc-form-button-solo-override .njc-btn-primary:disabled:hover {
  background-color: var(--grayscale-60);
  cursor: not-allowed;
  color: var(--grayscale-30);
}
.njc-form-button-solo-override .njc-btn-primary-tiny {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  cursor: pointer;
  padding: 0px 12px;
  height: 28px;
  border-radius: 5px;
  text-decoration: none;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--primary-color);
  background-color: var(--grayscale-0);
  border: 1px solid var(--primary-color);
}
.njc-form-button-solo-override .njc-btn-primary-tiny svg {
  margin: 0px 8px 0px 0px;
  width: 18px;
  height: 18px;
}
.njc-form-button-solo-override .njc-btn-primary-tiny:disabled {
  background-color: var(--grayscale-60);
}
.njc-form-button-solo-override .njc-btn-primary-tiny:disabled:hover {
  background-color: var(--grayscale-60);
  cursor: not-allowed;
}
.njc-form-button-solo-override .njc-btn-primary-round {
  border-radius: 30px;
}
.njc-form-button-solo-override .njc-btn-primary:hover {
  color: var(--primary-color);
  background-color: var(--grayscale-0);
}
.njc-form-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-basis: 50%;
  max-width: 50%;
  padding-left: 16px;
  margin-left: auto;
}
.njc-form-description .help-text-wrap {
  width: 100%;
}
.njc-form-description .nc-help-text,
.njc-form-description .help-text,
.njc-form-description .njc-help-text {
  font-size: 13px;
  margin: 0px;
  background-color: var(--grayscale-10);
  border-radius: 5px;
  padding: 12px 32px;
  min-height: 44px;
  width: 100%;
}
.njc-form-description .nc-help-text a,
.njc-form-description .help-text a,
.njc-form-description .njc-help-text a {
  font-weight: 400;
  font-size: 12px;
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-form-description .nc-help-text a-med,
.njc-form-description .help-text a-med,
.njc-form-description .njc-help-text a-med {
  font-size: 13px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-form-description .nc-help-text a-med:hover,
.njc-form-description .help-text a-med:hover,
.njc-form-description .njc-help-text a-med:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-form-description .nc-help-text a-med svg,
.njc-form-description .help-text a-med svg,
.njc-form-description .njc-help-text a-med svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  margin-right: 10px;
  color: var(--primary-color);
}
.njc-form-description .nc-help-text a-larger,
.njc-form-description .help-text a-larger,
.njc-form-description .njc-help-text a-larger {
  font-size: 14px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-form-description .nc-help-text a-larger:hover,
.njc-form-description .help-text a-larger:hover,
.njc-form-description .njc-help-text a-larger:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-form-description .nc-help-text a-larger svg,
.njc-form-description .help-text a-larger svg,
.njc-form-description .njc-help-text a-larger svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  color: var(--primary-color);
}
.njc-form-description .nc-help-text a:hover,
.njc-form-description .help-text a:hover,
.njc-form-description .njc-help-text a:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-form-description .nc-help-text .njc-help-text-title,
.njc-form-description .help-text .njc-help-text-title,
.njc-form-description .njc-help-text .njc-help-text-title {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  display: block;
}
.njc-form-description .expand.njc-form-description {
  flex-basis: 100%;
  max-width: 100%;
  margin-top: 0px;
  padding-left: 0px;
}
.njc-form-text-default-email-no-subject .njc-form-text-default-input-container .direction-column .njc-form-label-wrap {
  display: none;
}
.njc-form-text-default-email-no-subject .njc-form-text-default-input-container .direction-column .njc-form-label-wrap-second {
  margin-top: 0;
}
.njc-email-preview-btn {
  margin: 12px 0 0 auto;
}
.njc-email-template-modal .Fullpage__header_title .text-wrap {
  display: none;
}
.njc-email-template-modal .njc-email-preview-body {
  margin-top: 20px;
}
.card-header-2-row,
.njc-card-header-2-row {
  border-top: 1px solid var(--grayscale-30);
  margin-top: 32px;
}
.card-header-2-row h3,
.njc-card-header-2-row h3 {
  font-weight: 700;
  font-size: 18px;
  color: var(--grayscale-100);
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  margin: 0px;
  padding: 32px 0px 0px 0px;
  text-transform: capitalize;
}
.card-header-2-row .njc-h2-description,
.njc-card-header-2-row .njc-h2-description {
  margin: 4px 0px;
  font-weight: 400;
  font-size: 14px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-form-label-bolder {
  font-weight: 700;
  font-size: 14px;
  color: var(--grayscale-100);
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-first-container .card-header-2-row,
.njc-first-container .njc-card-header-2-row {
  margin-top: 0px;
  border-top: 0px solid var(--grayscale-0);
}
.njc-first-container.njc-first-container-tertiary .njc-card-header-2-row h3 {
  margin-top: 20px;
}
.njc-forms-full-width {
  display: flex;
  flex-direction: row;
}
.njc-forms-full-width .njc-form-text-default {
  flex-grow: 1;
  padding-right: 16px;
}
.njc-forms-full-width .njc-form-text-default .njc-form-text-default-input-container {
  flex-basis: 100%;
}
.njc-forms-full-width .njc-form-text-default:last-child {
  padding-right: 0px;
}
.njc-forms-full-width .njc-form-text-default.njc-forms-full-width-container-last {
  padding-right: 0px;
}
.njc-custom-metrics-full-width {
  display: flex;
  flex-direction: row;
}
.njc-custom-metrics-full-width .njc-form-text-default-input-container {
  flex-basis: 100%;
}
.njc-custom-metrics-full-width .njc-form-input-wrap .njc-btn-secondary-tiny {
  margin-bottom: 4px;
}
.njc-custom-metrics-full-width .njc-form-input-wrap .njc-btn-secondary-tiny span {
  margin-right: 8px;
}
.njc-custom-metrics-full-width .datasources-container {
  padding-right: 16px;
  flex-basis: calc(50% + 16px);
}
.njc-custom-metrics-full-width .datasources-container .njc-form-input {
  border-bottom: 0px;
  border-radius: 5px 5px 0 0;
}
.njc-custom-metrics-full-width .datasources-container .metric-list-item-container {
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid var(--grayscale-30);
  height: 96px;
  width: 100%;
  margin: 0 0 4px 0;
  padding: 4px;
}
.njc-custom-metrics-full-width .datasources-container .metric-list-item-container .metric-list-item {
  cursor: pointer;
  padding: 4px 12px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
}
.njc-custom-metrics-full-width .datasources-container .metric-list-item-container .metric-list-item::placeholder {
  color: var(--grayscale-70);
}
.njc-custom-metrics-full-width .datasources-container .metric-list-item-container .metric-list-item.active-metric {
  background-color: var(--primary-color-bg);
  cursor: default;
}
.njc-custom-metrics-full-width .njc-operators-container {
  padding-right: 16px;
  width: 150px;
}
.njc-custom-metrics-full-width .njc-constants-container {
  padding-right: 16px;
  width: 250px;
}
.njc-custom-metrics-formula-valid {
  margin-top: 6px;
}
.njc-custom-metrics-formula-valid .invalid-formula {
  color: var(--critical-color);
}
.njc-form-image-upload {
  padding: 20px 0px 0px 0px;
}
.njc-form-image-upload-container {
  display: flex;
  align-items: center;
}
.njc-form-image-upload-container .njc-form-image-upload-pic {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}
.njc-form-image-upload-container .njc-form-image-upload-pic-empty {
  width: 85px;
  height: 85px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--primary-color);
  background-color: var(--primary-color-5);
  border-radius: 5px;
  cursor: pointer;
}
.njc-form-image-upload-container .njc-form-image-upload-pic img {
  max-width: 85px !important;
  max-height: 85px !important;
  border-radius: 5px;
}
.njc-form-image-upload.njc-form-image-upload-pic-small img {
  max-width: 45px !important;
}
.njc-form-image-upload.njc-form-image-upload-pic-small .njc-form-image-upload-pic-empty {
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.njc-form-image-upload.njc-form-image-upload-pic-small .njc-empty-placeholder {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  font-weight: 700;
  color: var(--primary-color);
}
.njc-form-image-upload .upload-delete-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 52px;
}
.njc-form-image-upload .upload-delete-wrap h4 {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  margin: 0px 0px 8px 0px;
}
.njc-form-image-upload .upload-delete-wrap .upload-delete-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-form-image-upload .upload-delete-wrap .upload-delete-buttons .njc-btn-primary-tiny {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  cursor: pointer;
  padding: 0px 12px;
  height: 28px;
  border-radius: 5px;
  text-decoration: none;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  margin-right: 12px;
}
.njc-form-image-upload .upload-delete-wrap .upload-delete-buttons .njc-btn-primary-tiny svg {
  margin: 0px 8px 0px 0px;
  width: 18px;
  height: 18px;
}
.njc-form-image-upload .upload-delete-wrap .upload-delete-buttons .njc-btn-primary-tiny:disabled {
  background-color: var(--grayscale-60);
}
.njc-form-image-upload .upload-delete-wrap .upload-delete-buttons .njc-btn-primary-tiny:disabled:hover {
  background-color: var(--grayscale-60);
  cursor: not-allowed;
}
.njc-form-image-upload .upload-delete-wrap .upload-delete-buttons .njc-btn-secondary-tiny {
  margin-right: 12px;
}
/* Modal Styling */
.nc-modal-full-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  min-height: 100%;
  display: flex;
  align-items: start;
  background-color: rgba(213, 218, 225, 0.9);
  z-index: 50;
  animation-name: njc-modal-bg-animate;
  animation-duration: 0.2s;
}
.nc-modal-full-page .Fullpage.animated,
.nc-modal-full-page .Modal__container.animated,
.nc-modal-full-page .modal-dialog {
  animation-name: njc-modal-animate;
  animation-duration: 0.2s;
}
.nc-modal-full-page.njc-modal-animation-stop {
  animation-name: none;
  animation-duration: 0s;
}
.nc-modal-full-page.njc-modal-animation-stop .Fullpage.animated,
.nc-modal-full-page.njc-modal-animation-stop .Modal__container.animated {
  animation-name: none;
  animation-duration: 0s;
}
.nc-modal-full-page .Fullpage {
  width: 85%;
  max-width: 1040px;
  margin: 50px auto;
  background-color: var(--grayscale-0);
  border-radius: 8px;
  border: 1px solid var(--grayscale-30);
  box-shadow: 2px 2px 8px rgba(45, 53, 64, 0.1);
}
.nc-modal-full-page .Fullpage .Fullpage__container {
  position: relative;
  margin: 55px 50px 24px 50px;
}
.nc-modal-full-page .Fullpage .Fullpage__header_title h1 {
  display: none;
}
.nc-modal-full-page .Fullpage .modal-buttons {
  position: absolute;
  right: -34px;
  top: -40px;
  display: flex;
}
.nc-modal-full-page .Fullpage .modal-buttons .njc-icon-close {
  margin-left: 4px;
}
.nc-modal-full-page .Fullpage .njc-modal-title,
.nc-modal-full-page .Fullpage h2.caps {
  line-height: normal;
  margin: 0px;
  font-weight: 700;
  font-size: 34px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.nc-modal-full-page .Fullpage .njc-modal-title.njc-modal-title-bottom-space,
.nc-modal-full-page .Fullpage h2.caps.njc-modal-title-bottom-space {
  margin-bottom: 20px;
}
.nc-modal-full-page .Fullpage .njc-list-card {
  border: 0px solid transparent;
  box-shadow: none;
}
.nc-modal-full-page .Fullpage .njc-list-card .njc-list-table {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}
.nc-modal-full-page .Fullpage .njc-list-card .njc-list-table .njc-list-table-header {
  border-bottom: 0px solid transparent;
  border-radius: 5px;
}
.nc-modal-full-page .Fullpage .njc-custom-mappings .njc-list-card .njc-list-table {
  overflow: visible;
}
.nc-modal-full-page .Fullpage .Fullpage__content > .njc-nodata-card {
  border-top: 0px;
  margin-bottom: 50px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-body-pagetitle {
  align-items: flex-end;
  height: auto;
  padding-bottom: 32px;
  min-height: auto;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-body-pagetitle-modal-actions .expand {
  align-items: flex-end;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-body-pagetitle-modal-actions .njc-page-buttons {
  margin-left: auto;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-body-pagetitle-modal-actions .njc-page-buttons [class^="njc-btn"] {
  margin-left: 8px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-body-pagetitle .njc-pagetitle-subtext {
  margin-top: -14px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-search-bar-row-modal {
  margin-top: 32px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-tertiary {
  margin: 12px 0px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-h3-row {
  padding-top: 0px !important;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-h3-row .njc-h3-description {
  margin-top: 16px !important;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-h3-row .njc-form-input-message-link {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: var(--primary-color) !important;
  text-decoration: none !important;
  cursor: pointer !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
  padding: 4px 0px 0px 0px;
  width: 100%;
  display: block;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-h3-row .njc-form-input-message-link-med {
  font-size: 13px !important;
  color: var(--primary-color) !important;
  text-decoration: none !important;
  font-weight: 400 !important;
  cursor: pointer !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-h3-row .njc-form-input-message-link-med:hover {
  color: var(--primary-color) !important;
  text-decoration: underline !important;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-h3-row .njc-form-input-message-link-med svg {
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
  margin-right: 10px !important;
  color: var(--primary-color) !important;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-h3-row .njc-form-input-message-link-larger {
  font-size: 14px !important;
  color: var(--primary-color) !important;
  text-decoration: none !important;
  font-weight: 400 !important;
  cursor: pointer !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-h3-row .njc-form-input-message-link-larger:hover {
  color: var(--primary-color) !important;
  text-decoration: underline !important;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-h3-row .njc-form-input-message-link-larger svg {
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
  color: var(--primary-color) !important;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-h3-row .njc-form-input-message-link:hover {
  color: var(--primary-color) !important;
  text-decoration: underline !important;
}
.nc-modal-full-page .Fullpage .Fullpage__content h3 {
  font-weight: 700;
  font-size: 18px;
  color: var(--grayscale-100);
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  margin: 0px;
  padding: 32px 0px 0px 0px;
  text-transform: capitalize;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-card-header-text p {
  margin: 20px 0 0 0 !important;
  padding-left: 0px !important;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-card-header-text-more-margin {
  margin: 32px 0px 12px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-card-header-text-more-margin p {
  margin: 0px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-card-header-text-bold {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-container {
  border: 1px solid var(--grayscale-20);
  border-radius: 5px;
  height: 350px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow-y: auto;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-container div {
  width: 50%;
  min-height: 24px;
  padding: 0px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-container div:hover {
  color: var(--primary-color);
  cursor: pointer;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-container .nc-date-range-selected {
  color: var(--primary-color);
  background-color: var(--grayscale-10);
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-label {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-80);
  text-transform: capitalize;
  margin-bottom: 8px;
  display: inline-block;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-checkbox {
  margin-bottom: 8px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box1 {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  margin-right: 8px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box1::placeholder {
  color: var(--grayscale-70);
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box1:hover {
  border: 1px solid var(--grayscale-50);
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box1:active,
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box1:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box1:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box1[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box1[type="number"]::-webkit-outer-spin-button,
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box1[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box2 {
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  background-image: url("/images/njc/njc-icon-dropdown.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 35px;
  margin-right: 8px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box2::placeholder {
  color: var(--grayscale-70);
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box2:hover {
  border: 1px solid var(--grayscale-50);
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box2:active,
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box2:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box2:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box2[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box2[type="number"]::-webkit-outer-spin-button,
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box2[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box2::-ms-expand {
  display: none;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box2:disabled {
  background-image: url("/images/njc/njc-icon-dropdown-disabled.svg");
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box2.njc-date-range-box2-last {
  margin-left: 8px;
  margin-right: 0px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box3 {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  background-image: url("/images/njc/njc-calendar.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 35px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box3::placeholder {
  color: var(--grayscale-70);
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box3:hover {
  border: 1px solid var(--grayscale-50);
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box3:active,
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box3:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box3:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box3[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box3[type="number"]::-webkit-outer-spin-button,
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-box3[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-helptext .nc-help-text {
  padding: 0px;
  margin: 0px;
  background-color: transparent;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-helptext .njc-form-description {
  flex-basis: 100%;
  max-width: 100%;
  padding-left: 0px;
  margin-left: 0px;
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-70);
  text-transform: capitalize;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .form-field-row .njc-date-range-helptext .njc-form-description .nc-help-text {
  min-height: 30px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-date-range-relative .njc-form-text-default {
  padding-top: 0px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage {
  display: flex;
  flex-direction: row;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage h3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage h3 .njc-btn-secondary-tiny {
  margin-left: auto;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage-info-panel {
  width: 30%;
  padding-right: 8px;
  padding-bottom: 32px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage-info-panel .njc-form-label-wrap {
  margin-top: 32px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage-info-panel .njc-form-label-wrap .njc-form-label {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-80);
  text-transform: capitalize;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage-info-panel .njc-form-label-wrap p,
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage-info-panel .njc-form-label-wrap .njc-img-text-item {
  margin: 4px 0px 0px 0px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage-info-panel .njc-form-label-wrap ul {
  list-style: none;
  margin: 4px 0px;
  padding-left: 0px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage-info-panel .njc-form-label-wrap ul li:first-child {
  margin-bottom: 4px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage-info-panel .njc-form-label-wrap ul .njc-color-warning {
  color: var(--warning-color);
  font-weight: 500;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage-info-panel .njc-form-label-wrap ul .njc-color-critical {
  color: var(--critical-color);
  font-weight: 500;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage .vertical-divide {
  width: 1px;
  min-height: 100%;
  border-left: 1px solid var(--grayscale-30);
  margin: 32px 0px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage-input-panel {
  width: 70%;
  padding-left: 32px;
  padding-bottom: 32px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage-input-panel .njc-list-card.njc-reorder-table-wrap {
  margin-top: 16px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage-input-panel .njc-list-table-header .njc-list-table-cell:nth-child(2) {
  padding-left: 16px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage-input-panel .njc-list-table-row .njc-form-text-default {
  width: 100%;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage-input-panel .njc-list-table-row .njc-form-text-default-input-container {
  width: 100%;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage-input-panel .njc-list-table-row .njc-form-text-default-input-container .select2-selection {
  height: 36px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage-input-panel .njc-list-table-row:last-child:before {
  border-bottom: 0px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage-input-panel .njc-full-width-table-button {
  width: 100%;
  padding: 0px 16px;
  margin: 8px auto;
  background-color: var(--grayscale-0);
  border: 1px dashed var(--grayscale-30);
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-60);
  cursor: pointer;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage-input-panel .njc-full-width-table-button svg {
  margin-right: 8px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-2column-manage-input-panel .njc-table-empty-msg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--grayscale-60);
  height: 110px;
  border-bottom: 1px solid var(--grayscale-20);
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-add-network-list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-add-network-list-card {
  width: 175px;
  height: 180px;
  margin: 8px;
  cursor: pointer;
  border: 1px solid var(--grayscale-30);
  border-radius: 8px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-add-network-list-card:hover {
  background-color: var(--grayscale-5);
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-add-network-list-card .cata-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-add-network-list-card .cata-card .cata-card-bottom {
  display: none;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-add-network-list-card .cata-card .cata-card-top {
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-add-network-list-card .cata-card .cata-card-top .cata-logo-wrap {
  width: 100%;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-add-network-list-card .cata-card .cata-card-top img {
  max-height: 70px !important;
  max-width: 70px !important;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-add-network-list-card .cata-card .nc-board-text-container h5 {
  font-weight: 600;
  font-size: 14px;
  color: var(--grayscale-100);
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  margin: 0;
  text-align: center;
  width: 120px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-template-options-wrap {
  margin-bottom: 50px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .template-option-card-wrap {
  border-radius: 5px;
  border: 1px solid var(--grayscale-30);
  width: calc(50% - 4px);
  padding: 44px 44px 36px 44px;
  text-decoration: none;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .template-option-card-wrap:hover {
  background-color: var(--grayscale-5);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .template-option-card-wrap svg {
  max-width: 234px;
  max-height: 178px;
  margin: auto;
  display: block;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .template-option-card-wrap .template-content-wrap {
  margin-top: 28px;
  text-align: center;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .template-option-card-wrap .template-content-wrap .template-title-text {
  font-weight: 700;
  font-size: 18px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .template-option-card-wrap .template-content-wrap .template-body-text {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  margin: 12px 0px 0px 0px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap[row-index="0"][column-index="0"] .template-content-wrap .njc-premade-template-image {
  background-image: url('/images/starter/execSummary16_9.png');
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap[row-index="0"][column-index="1"] .template-content-wrap .njc-premade-template-image {
  background-image: url('/images/starter/execSummary4_3.png');
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap[row-index="0"][column-index="2"] .template-content-wrap .njc-premade-template-image {
  background-image: url('/images/starter/facebook16_9.png');
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap[row-index="1"][column-index="0"] .template-content-wrap .njc-premade-template-image {
  background-image: url('/images/starter/facebook4_3.png');
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap[row-index="1"][column-index="1"] .template-content-wrap .njc-premade-template-image {
  background-image: url('/images/starter/googleAds16_9.png');
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap[row-index="1"][column-index="2"] .template-content-wrap .njc-premade-template-image {
  background-image: url('/images/starter/googleAds4_3.png');
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap[row-index="2"][column-index="0"] .template-content-wrap .njc-premade-template-image {
  background-image: url('/images/starter/googleAnalytics16_9.png');
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap[row-index="2"][column-index="1"] .template-content-wrap .njc-premade-template-image {
  background-image: url('/images/starter/googleAnalytics4_3.png');
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap[row-index="2"][column-index="2"] .template-content-wrap .njc-premade-template-image {
  background-image: url('/images/starter/programmatic16_9.png');
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap[row-index="3"][column-index="0"] .template-content-wrap .njc-premade-template-image {
  background-image: url('/images/starter/programmatic4_3.png');
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap[row-index="3"][column-index="1"] .template-content-wrap .njc-premade-template-image {
  background-image: url('/images/starter/SEO16_9.png');
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap[row-index="3"][column-index="2"] .template-content-wrap .njc-premade-template-image {
  background-image: url('/images/starter/SEO4_3.png');
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap {
  flex: 1;
  width: 300px;
  margin: 0px 12px 24px;
  position: relative;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap .template-card {
  padding: 32px 20px 12px;
  border-radius: 5px;
  border: 1px solid var(--grayscale-30);
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  cursor: pointer;
  height: 100%;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap .template-card:hover {
  background-color: var(--grayscale-5);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap:first-child {
  margin-left: 0;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap:last-child {
  margin-right: 0;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap .template-content-wrap {
  text-decoration: none;
  width: 100%;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap .template-content-wrap .njc-premade-template-image {
  margin: 0 0 20px 0;
  height: 190px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap .template-content-wrap svg {
  margin: 0 32px 20px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap .template-content-wrap .template-title-buttons {
  position: absolute;
  top: 8px;
  right: 8px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap .template-content-wrap .template-title-buttons svg {
  margin: 0;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap .template-content-wrap .template-title-text {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  text-align: center;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap .template-content-wrap .template-body-text {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  text-align: center;
  margin: 4px 0;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap .template-content-wrap .template-networks-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap .template-content-wrap .template-networks-wrap .network-icon-wrap {
  margin: 10px 4px 0;
  background-color: transparent;
  border-radius: 5px;
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .template-card-row .template-card-wrap .template-content-wrap .template-networks-wrap .network-icon-wrap img {
  width: 16px;
  height: 16px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .nc-table-page-footer {
  border-top: 1px solid var(--grayscale-30);
  width: calc(100% + 100px);
  padding: 0 50px;
  margin: 0 -50px -20px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .nc-table-page-footer .pagination-footer {
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .nc-table-page-footer .pagination-footer .njc-table-footer-page-display {
  font-weight: 400;
  font-size: 14px;
  border-radius: 5px;
  margin: 0 1px;
  color: var(--grayscale-70);
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  text-transform: lowercase;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .nc-table-page-footer .pagination-footer .njc-table-footer-page-display.disabled {
  color: var(--grayscale-30);
  cursor: not-allowed;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .nc-table-page-footer .pagination-footer .njc-table-footer-page-display.disabled:hover {
  color: var(--grayscale-30);
  background-color: transparent;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .nc-table-page-footer .pagination-footer .njc-table-footer-page-display.w--current {
  color: var(--primary-color);
  font-weight: 700;
  border-radius: 5px;
  background-color: var(--primary-color-5);
  cursor: initial;
}
.nc-modal-full-page .Fullpage .Fullpage__content .template-options-wrap .nc-vue-table-template-picker-container .nc-table-page-footer .pagination-footer .njc-table-footer-page-display.w--current:hover {
  color: var(--primary-color);
  background-color: var(--primary-color-5);
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-template-create-margins {
  position: relative;
  padding: 66px 80px;
  width: 390px;
  margin-top: -10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-template-create-margins label {
  margin: 0;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-template-create-margins .njc-form-input-wrap {
  margin-top: 5px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-template-create-margins .njc-form-input {
  min-height: 36px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-template-create-margins .njc-template-create-margins-center {
  margin: auto;
  width: 100%;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-template-create-margins .njc-template-create-margins-center img {
  width: 100%;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-template-create-margins .njc-template-create-margins-top {
  top: -4px;
  left: calc(50% - 42px);
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-template-create-margins .njc-template-create-margins-left {
  left: 0;
  top: calc(50% - 46px);
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-template-create-margins .njc-template-create-margins-right {
  right: 0;
  top: calc(50% - 46px);
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-template-create-margins .njc-template-create-margins-bottom {
  bottom: -8px;
  left: calc(50% - 42px);
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-template-create-margins .njc-form-text-default {
  width: 70px;
  padding: 0px;
  position: absolute;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-template-create-margins .njc-form-text-default .njc-form-text-default-input-container {
  flex-basis: 100%;
  min-width: 100%;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-template-create-margins .njc-form-text-default .njc-form-text-default-input-container .njc-form-label-wrap {
  align-items: center;
  justify-content: center;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-template-create-margins .njc-form-text-default .njc-form-text-default-input-container .njc-form-label-wrap .njc-form-input {
  width: 70px !important;
  text-align: center;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-template-create-margins .njc-form-text-default .njc-form-description,
.nc-modal-full-page .Fullpage .Fullpage__content .njc-template-create-margins .njc-form-text-default .njc-form-sub-label {
  display: none;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-form-description-new-template {
  margin-top: 0px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-footer-full {
  border-top: 1px solid var(--grayscale-30);
  width: calc(100% + 100px);
  padding: 0 50px;
  margin: 20px -50px -20px;
}
.nc-modal-full-page .Fullpage .Fullpage__content .njc-modal-footer-full .align-start {
  flex-direction: row;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
  height: 70px;
}
.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  min-height: 100%;
  display: flex;
  align-items: start;
  background-color: rgba(213, 218, 225, 0.9);
  z-index: 50;
  animation-name: njc-modal-bg-animate;
  animation-duration: 0.2s;
}
.Modal .Fullpage.animated,
.Modal .Modal__container.animated,
.Modal .modal-dialog {
  animation-name: njc-modal-animate;
  animation-duration: 0.2s;
}
.Modal.njc-modal-animation-stop {
  animation-name: none;
  animation-duration: 0s;
}
.Modal.njc-modal-animation-stop .Fullpage.animated,
.Modal.njc-modal-animation-stop .Modal__container.animated {
  animation-name: none;
  animation-duration: 0s;
}
.Modal .Modal__container {
  width: 600px;
  margin: 100px auto auto auto;
  background-color: var(--grayscale-0);
  border-radius: 8px;
  border: 1px solid var(--grayscale-30);
  box-shadow: 2px 2px 8px rgba(45, 53, 64, 0.1);
}
.Modal .Modal__container .Modal__content {
  margin: 32px 40px 20px;
}
.Modal .Modal__container .Modal__content h3 {
  line-height: normal;
  padding: 0px;
  font-weight: 700;
  font-size: 24px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  margin: 0px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.Modal .Modal__container .Modal__content .njc-tertiary {
  margin: 12px 0px 20px 0px;
}
.Modal .Modal__container .Modal__content .accounts-picker-container {
  margin-top: 20px;
}
.Modal .Modal__container .Modal__content .accounts-picker-container .accounts-list {
  padding: 0px;
}
.Modal .Modal__container .Modal__content .accounts-picker-container .accounts-list .accounts-list-item {
  margin: 0px;
}
.Modal .Modal__container .Modal__content .accounts-picker-container .accounts-list .accounts-list-item label {
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 16px;
}
.Modal .Modal__container .Modal__content .accounts-picker-container .accounts-list .accounts-list-item label .njc-checkbox-input {
  margin-right: 8px;
}
.Modal .Modal__container .Modal__content .accounts-picker-container .sub-text {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-70);
  text-transform: capitalize;
  margin: 8px 0px 20px 0px;
}
.Modal .Modal__container .Modal__content .accounts-picker-container .target-threshold-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.Modal .Modal__container .Modal__content .accounts-picker-container .target-threshold-container label {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-80);
  text-transform: capitalize;
}
.Modal .Modal__container .Modal__content .accounts-picker-container .target-threshold-container input {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  margin-top: 8px;
  width: 100%;
}
.Modal .Modal__container .Modal__content .accounts-picker-container .target-threshold-container input::placeholder {
  color: var(--grayscale-70);
}
.Modal .Modal__container .Modal__content .accounts-picker-container .target-threshold-container input:hover {
  border: 1px solid var(--grayscale-50);
}
.Modal .Modal__container .Modal__content .accounts-picker-container .target-threshold-container input:active,
.Modal .Modal__container .Modal__content .accounts-picker-container .target-threshold-container input:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.Modal .Modal__container .Modal__content .accounts-picker-container .target-threshold-container input:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.Modal .Modal__container .Modal__content .accounts-picker-container .target-threshold-container input[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.Modal .Modal__container .Modal__content .accounts-picker-container .target-threshold-container input[type="number"]::-webkit-outer-spin-button,
.Modal .Modal__container .Modal__content .accounts-picker-container .target-threshold-container input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.njc-form-toggle-default {
  padding: 20px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  /* The switch - the box around the slider */
  /* Hide default HTML checkbox */
  /* The slider */
  /* Rounded sliders */
}
.njc-form-toggle-default .njc-form-input-wrap {
  flex-grow: 1;
}
.njc-form-toggle-default .njc-form-input-wrap.has-error .njc-form-input,
.njc-form-toggle-default .njc-form-input-wrap.has-error .njc-form-input-select {
  border: 1px solid var(--critical-color);
  box-shadow: inset 0 0 0 1px var(--critical-color);
}
.njc-form-toggle-default .njc-form-input-wrap.has-error .error-help-block {
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  color: var(--critical-color);
  line-height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  display: inline-block;
  display: block;
}
.njc-form-toggle-default .njc-form-input-wrap.has-error .error-help-block svg {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}
.njc-form-toggle-default .njc-form-input-wrap.has-error .error-help-block::first-letter {
  text-transform: capitalize;
}
.njc-form-toggle-default .njc-form-label-wrap {
  display: flex;
  flex-direction: row;
}
.njc-form-toggle-default .njc-form-label-wrap.njc-form-label-wrap-second {
  margin-top: 20px;
}
.njc-form-toggle-default .njc-form-label-wrap .njc-form-label {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-80);
  text-transform: capitalize;
}
.njc-form-toggle-default .njc-form-label-wrap .njc-form-sub-label {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-70);
  text-transform: capitalize;
  width: 6px;
  height: 14px;
  overflow: hidden;
  color: transparent;
  margin-left: 4px;
}
.njc-form-toggle-default .njc-form-label-wrap .njc-form-sub-label:before {
  content: " *";
  width: 20px;
  height: 10px;
  display: inline;
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-70);
  text-transform: capitalize;
}
.njc-form-toggle-default .njc-form-input-wrap {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-70);
  margin-top: 8px;
}
.njc-form-toggle-default .njc-form-toggle-default-multiple {
  flex-direction: column;
  flex-basis: 50%;
  min-width: 50%;
}
.njc-form-toggle-default .njc-form-toggle-default-multiple .njc-form-text-default-input-container {
  margin-bottom: 12px;
  flex-basis: 100%;
  min-width: 100%;
}
.njc-form-toggle-default .njc-form-text-default-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-form-toggle-default .njc-form-text-default-input-container-threshold {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-form-toggle-default .njc-form-text-default-input-container-threshold .njc-threshold-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0px;
}
.njc-form-toggle-default .njc-form-text-default-input-container-threshold .threshold-well {
  height: 0px;
  margin-left: 58px;
  overflow: hidden;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.njc-form-toggle-default .njc-form-text-default-input-container-threshold .threshold-well.active {
  height: 112px;
  display: block;
}
.njc-form-toggle-default .njc-form-text-default-input-container-threshold .threshold-well .flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
}
.njc-form-toggle-default .njc-form-text-default-input-container-threshold .threshold-well .flex-container .threshold-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 4px;
}
.njc-form-toggle-default .njc-form-text-default-input-container-threshold .threshold-well .flex-container .threshold-text .nc-color-warning {
  font-weight: 700;
  font-size: 12px;
  color: var(--grayscale-0);
  border-radius: 12px;
  display: inline-block;
  height: 24px;
  margin: 0px 8px;
  padding: 3px 10px;
  background-color: var(--warning-color);
}
.njc-form-toggle-default .njc-form-text-default-input-container-threshold .threshold-well .flex-container .threshold-text .nc-color-danger {
  font-weight: 700;
  font-size: 12px;
  color: var(--grayscale-0);
  border-radius: 12px;
  display: inline-block;
  height: 24px;
  margin: 0px 8px;
  padding: 3px 10px;
  background-color: var(--critical-color);
}
.njc-form-toggle-default .njc-form-text-default-input-container-threshold .threshold-well .flex-container .njc-form-input {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  padding: 0 20px;
}
.njc-form-toggle-default .njc-form-text-default-input-container-threshold .threshold-well .flex-container .njc-form-input::placeholder {
  color: var(--grayscale-70);
}
.njc-form-toggle-default .njc-form-text-default-input-container-threshold .threshold-well .flex-container .njc-form-input:hover {
  border: 1px solid var(--grayscale-50);
}
.njc-form-toggle-default .njc-form-text-default-input-container-threshold .threshold-well .flex-container .njc-form-input:active,
.njc-form-toggle-default .njc-form-text-default-input-container-threshold .threshold-well .flex-container .njc-form-input:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.njc-form-toggle-default .njc-form-text-default-input-container-threshold .threshold-well .flex-container .njc-form-input:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.njc-form-toggle-default .njc-form-text-default-input-container-threshold .threshold-well .flex-container .njc-form-input[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.njc-form-toggle-default .njc-form-text-default-input-container-threshold .threshold-well .flex-container .njc-form-input[type="number"]::-webkit-outer-spin-button,
.njc-form-toggle-default .njc-form-text-default-input-container-threshold .threshold-well .flex-container .njc-form-input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.njc-form-toggle-default .nc-toggle-switch,
.njc-form-toggle-default .njc-toggle-switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 24px;
}
.njc-form-toggle-default .nc-toggle-switch input,
.njc-form-toggle-default .njc-toggle-switch input {
  display: none;
}
.njc-form-toggle-default .nc-slider,
.njc-form-toggle-default .njc-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--grayscale-60);
  -webkit-transition: .4s;
  transition: .4s;
  width: 46px;
}
.njc-form-toggle-default .nc-slider:before,
.njc-form-toggle-default .njc-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
.njc-form-toggle-default input:checked + .nc-slider,
.njc-form-toggle-default input:checked + .njc-slider {
  background-color: var(--primary-color);
}
.njc-form-toggle-default input:focus + .nc-slider,
.njc-form-toggle-default input:focus + .njc-slider {
  box-shadow: 0 0 1px #2196F3;
}
.njc-form-toggle-default input:checked + .nc-slider:before,
.njc-form-toggle-default input:checked + .njc-slider:before {
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
}
.njc-form-toggle-default input:disabled + .nc-slider,
.njc-form-toggle-default input:disabled + .njc-slider {
  background-image: url("/images/njc/njc-lock-filled-unchecked.svg");
  background-repeat: no-repeat;
  background-position: 24px 50%;
  cursor: not-allowed;
}
.njc-form-toggle-default input:checked:disabled + .nc-slider,
.njc-form-toggle-default input:checked:disabled + .njc-slider {
  background-image: url("/images/njc/njc-lock-filled-checked.svg");
  background-repeat: no-repeat;
  background-position: 5px 50%;
  cursor: not-allowed;
  background-color: var(--primary-color);
}
.njc-form-toggle-default .nc-slider.nc-round,
.njc-form-toggle-default .njc-slider.njc-round {
  border-radius: 34px;
}
.njc-form-toggle-default .nc-slider.nc-round:before,
.njc-form-toggle-default .njc-slider.njc-round:before {
  border-radius: 50%;
}
.njc-form-toggle-default .toggle-text,
.njc-form-toggle-default .njc-toggle-text {
  font-size: 13px;
  font-weight: 400;
  margin-left: 12px;
  cursor: default;
}
.njc-form-toggle-default .njc-toggle-text-large {
  font-weight: 600;
  font-size: 14px;
  color: var(--grayscale-100);
}
.njc-form-toggle-default .njc-sub-toggle-text {
  width: 100%;
  margin: 8px 0 0 60px;
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
}
.njc-form-text-default {
  padding: 20px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-form-text-default.njc-form-text-repeat-override .njc-form-text-default {
  padding: 0px;
  flex-basis: 100%;
  min-width: 100%;
}
.njc-form-text-default.njc-form-text-repeat-override .njc-form-text-default .njc-form-text-default-input-container {
  flex-basis: 100%;
}
.njc-form-text-default-input-container {
  flex-basis: 50%;
  min-width: 50%;
}
.njc-form-text-default-input-container [class^="njc-btn"] {
  margin-top: 8px;
}
.njc-form-text-default-input-container.njc-full-width {
  width: 100%;
  flex-basis: 100%;
  padding: 0px;
  margin: 0px;
}
.njc-form-text-default-input-container .njc-form-text-default-input-container-multi {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-form-text-default-input-container .njc-form-text-default-input-container-multi .direction-column {
  padding-left: 20px;
}
.njc-form-text-default-input-container .njc-form-text-default-input-container-multi .direction-column:first-child {
  padding-left: 0px;
}
.njc-form-text-default-input-container .direction-column {
  display: flex;
  flex-direction: column;
}
.njc-form-text-default-input-container .njc-form-label-wrap {
  display: flex;
  flex-direction: row;
}
.njc-form-text-default-input-container .njc-form-label-wrap.njc-form-label-wrap-second {
  margin-top: 20px;
}
.njc-form-text-default-input-container .njc-form-label-wrap .njc-form-label {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-80);
  text-transform: capitalize;
}
.njc-form-text-default-input-container .njc-form-label-wrap .njc-form-sub-label {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-70);
  text-transform: capitalize;
  width: 6px;
  height: 14px;
  overflow: hidden;
  color: transparent;
  margin-left: 4px;
}
.njc-form-text-default-input-container .njc-form-label-wrap .njc-form-sub-label:before {
  content: " *";
  width: 20px;
  height: 10px;
  display: inline;
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-70);
  text-transform: capitalize;
}
.njc-form-text-default-input-container .njc-form-input-wrap {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-70);
  margin-top: 8px;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-multiple {
  display: flex;
  flex-direction: row;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-multiple .njc-form-input-select {
  margin: 4px;
  max-width: 80px;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-multiple .njc-form-input-select:first-child {
  margin-left: 0px;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-multiple .njc-form-input-select:last-child {
  margin-right: 0px;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-multiple .column {
  margin-right: 16px;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-multiple .column:last-child {
  margin-right: 0px;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-radio-button-wrap {
  display: flex;
  flex-direction: column;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-radio-button-wrap .njc-radio-button {
  border: 1px solid var(--grayscale-70);
  background: transparent;
  border-radius: 50%;
  padding: 0px;
  overflow: visible;
  height: 20px;
  width: 20px;
  white-space: nowrap;
  text-indent: 35px;
  margin-bottom: 12px;
  outline: none;
  color: var(--grayscale-100);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-radio-button-wrap .njc-radio-button.nc-button-group-blue,
.njc-form-text-default-input-container .njc-form-input-wrap.njc-radio-button-wrap .njc-radio-button:checked {
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  box-shadow: 0 0 0 3px var(--grayscale-0) inset;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-pretext {
  position: relative;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-pretext span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  background-color: var(--grayscale-10);
  color: var(--grayscale-60);
  min-height: 44px;
  width: 38px;
  border: 1px solid var(--grayscale-30);
  border-right: 0px;
  border-radius: 5px 0 0 5px;
  position: absolute;
  top: 0;
  left: 0;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-pretext span::placeholder {
  color: var(--grayscale-70);
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-pretext .njc-form-input {
  margin-left: 38px;
  width: calc(100% - 38px) !important;
  border-radius: 0 5px 5px 0;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-posttext {
  position: relative;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-posttext span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  background-color: var(--grayscale-10);
  color: var(--grayscale-60);
  min-height: 44px;
  width: 38px;
  border: 1px solid var(--grayscale-30);
  border-left: 0px;
  border-radius: 0 5px 5px 0;
  position: absolute;
  right: 0;
  top: 0;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-posttext span::placeholder {
  color: var(--grayscale-70);
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-posttext .njc-form-input {
  margin-right: 38px;
  width: calc(100% - 38px) !important;
  border-radius: 5px 0 0 5px;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-posttext.njc-form-input-180w span {
  left: 180px;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-after-label {
  position: relative;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-after-label span {
  position: absolute;
  left: 100%;
  font-weight: 400;
  font-size: 14px;
  color: var(--grayscale-70);
  margin-left: 8px;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-after-label.njc-form-input-80w span {
  left: 80px;
  top: 12px;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-180w .njc-form-input {
  width: 180px !important;
}
.njc-form-text-default-input-container .njc-form-input-wrap.njc-form-input-80w .njc-form-input {
  width: 80px !important;
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes-wrap {
  display: flex;
  flex-direction: row;
  width: 100% !important;
  flex-wrap: wrap;
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-post-link {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: var(--primary-color) !important;
  text-decoration: none !important;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  padding: 12px 0px;
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-post-link:hover {
  color: var(--listhovercolor) !important;
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px 12px !important;
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes input[class^="njc-day"] {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 16px 0px 20px 0px;
  appearance: none;
  width: 18px !important;
  height: 18px !important;
  border: 1px solid var(--grayscale-70);
  border-radius: 5px;
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes input[class^="njc-day"]:checked {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-image: url("/images/njc/njc-checkbox.svg");
  background-repeat: no-repeat;
  background-position: 3px 50%;
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes input[class^="njc-day"]:focus {
  outline: none;
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes input[class^="njc-day"]:before {
  position: absolute;
  top: -20px;
  font-size: 12px;
  font-weight: 400;
  color: var(--grayscale-100);
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-Monday:before {
  content: 'Mon';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-Tuesday:before {
  content: 'Tue';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-Wednesday:before {
  content: 'Wed';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-Thursday:before {
  content: 'Thu';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-Friday:before {
  content: 'Fri';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-Saturday:before {
  content: 'Sat';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-Sunday:before {
  content: 'Sun';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-1:before {
  content: '1';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-2:before {
  content: '2';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-3:before {
  content: '3';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-4:before {
  content: '4';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-5:before {
  content: '5';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-6:before {
  content: '6';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-7:before {
  content: '7';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-8:before {
  content: '8';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-9:before {
  content: '9';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-10:before {
  content: '10';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-11:before {
  content: '11';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-12:before {
  content: '12';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-13:before {
  content: '13';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-14:before {
  content: '14';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-15:before {
  content: '15';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-16:before {
  content: '16';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-17:before {
  content: '17';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-18:before {
  content: '18';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-19:before {
  content: '19';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-20:before {
  content: '20';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-21:before {
  content: '21';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-22:before {
  content: '22';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-23:before {
  content: '23';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-24:before {
  content: '24';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-25:before {
  content: '25';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-26:before {
  content: '26';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-27:before {
  content: '27';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-28:before {
  content: '28';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-29:before {
  content: '29';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-30:before {
  content: '30';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-boxes .njc-day-31:before {
  content: '31';
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-range {
  height: 44px;
  border: 1px dashed #8E95B6;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  text-decoration: none;
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-range span {
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
}
.njc-form-text-default-input-container .njc-form-input-wrap .njc-form-input-date-range svg {
  width: 18px;
  height: 18px;
  color: var(--grayscale-30);
}
.njc-form-text-default-input-container .njc-form-input-wrap.has-error .njc-form-input,
.njc-form-text-default-input-container .njc-form-input-wrap.has-error .njc-form-input-select {
  border: 1px solid var(--critical-color);
  box-shadow: inset 0 0 0 1px var(--critical-color);
}
.njc-form-text-default-input-container .njc-form-input-wrap.has-error .error-help-block {
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  color: var(--critical-color);
  line-height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  display: inline-block;
  display: block;
}
.njc-form-text-default-input-container .njc-form-input-wrap.has-error .error-help-block svg {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}
.njc-form-text-default-input-container .njc-form-input-wrap.has-error .error-help-block::first-letter {
  text-transform: capitalize;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__tags {
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__tags:hover {
  border: 1px solid var(--grayscale-50);
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__tags:active,
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__tags:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__tags:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__tags[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__tags[type="number"]::-webkit-outer-spin-button,
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__tags[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__tags .multiselect__tags-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__tags .multiselect__tags-wrap .multiselect__tag {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  margin: 8px 5px 8px 0;
  background-color: var(--primary-color-bg);
  display: flex;
  flex-direction: row;
  padding: 4px 12px;
  border-radius: 20px;
  color: var(--primary-color);
  border: 0px solid transparent;
  padding-right: 24px;
  align-items: center;
  min-height: 26px;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__tags .multiselect__tags-wrap .multiselect__tag::placeholder {
  color: var(--grayscale-70);
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__tags .multiselect__tags-wrap .multiselect__tag span {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__tags .multiselect__tags-wrap .multiselect__tag-icon {
  margin: 0px;
  padding: 0 0 0 8px;
  background-image: url("/images/njc/njc-small-close-primary.svg");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  color: transparent;
  margin-right: 8px;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__tags .multiselect__tags-wrap .multiselect__tag-icon:hover {
  background-image: url("/images/njc/njc-small-close-hover.svg");
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__tags .multiselect__tags-wrap .multiselect__tag-icon:hover {
  background-color: transparent;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__tags .multiselect__tags-wrap .multiselect__tag-icon:after {
  display: none;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__tags .multiselect__placeholder {
  margin: 0;
  padding: 0;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__tags .multiselect__input {
  font-size: 14px;
  margin: 0px;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__tags .multiselect__input:focus {
  width: 80% !important;
  padding: 4px 0 !important;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect.multiselect--above .multiselect__tags {
  flex-direction: column-reverse;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper {
  border: 1px solid var(--grayscale-30);
  overflow-y: overlay;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper .multiselect__content {
  position: relative;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper .multiselect__element:first-child {
  position: sticky;
  z-index: 10;
  top: 0;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper .multiselect__element .multiselect__option {
  padding: 0px;
  min-height: auto;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper .multiselect__element .multiselect__option > div {
  display: flex;
  align-items: center;
  background-color: var(--grayscale-0);
  position: relative;
  font-weight: 400;
  font-size: 14px;
  color: var(--grayscale-80);
  white-space: nowrap;
  min-height: 36px;
  padding: 0px 20px 0px 12px;
  text-decoration: none;
  border-radius: 0 !important;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper .multiselect__element .multiselect__option > div:after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid var(--secondary-color-variant);
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper .multiselect__element .multiselect__option > div:first-child {
  border-radius: 5px 5px 0 0;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper .multiselect__element .multiselect__option > div:last-child {
  border-radius: 0 0 5px 5px;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper .multiselect__element .multiselect__option > div:hover {
  background-color: var(--listhovercolor);
  color: var(--primary-color);
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper .multiselect__element .multiselect__option > div svg {
  margin: 0px 8px 0px 0px;
  width: 18px;
  height: 18px;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper .multiselect__element .multiselect__option > div:hover {
  color: var(--primary-color);
  cursor: pointer;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper .multiselect__element .multiselect__option > div:after {
  border-bottom: 1px solid var(--secondary-color-variant) !important;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper .multiselect__element .multiselect__option.multiselect__option--highlight > div,
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper .multiselect__element .multiselect__option.multiselect__option--group-selected > div,
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper .multiselect__element .multiselect__option.multiselect__option--selected > div {
  background-color: var(--primary-color-10);
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper .multiselect__element .multiselect__option input[type="checkbox"] {
  visibility: hidden;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper .multiselect__element .multiselect__option:after {
  display: none;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper::-webkit-scrollbar {
  width: 6px;
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px var(--grayscale-10);
}
.njc-form-text-default-input-container .njc-form-input-wrap .multiselect .multiselect__content-wrapper::-webkit-scrollbar-thumb {
  background: var(--grayscale-30);
  border-left: 1px solid var(--grayscale-0);
  border-right: 1px solid var(--grayscale-0);
  border-radius: 5px;
}
.njc-form-text-default-input-container .njc-form-input-wrap-address {
  font-weight: 500;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  margin-top: 8px;
}
.njc-form-text-default-input-container .select-calendar-row {
  display: flex;
  flex-direction: row;
}
.njc-form-text-default-input-container .select-calendar-row div {
  margin: 2px;
}
.njc-form-text-default-input-container .select-calendar-row div button {
  height: 34px;
  width: 40px;
  background-color: var(--grayscale-5);
  border: 0px solid transparent;
  border-radius: 2px;
}
.njc-form-text-default-input-container .select-calendar-row div button:hover {
  background-color: var(--grayscale-20);
  cursor: pointer;
}
.njc-form-text-default-input-container .select-calendar-row div button:disabled {
  background-color: var(--grayscale-5);
  color: var(--grayscale-30);
}
.njc-form-text-default-input-container .select-calendar-row div button:disabled:hover {
  background-color: var(--grayscale-5);
  cursor: not-allowed;
}
.njc-form-text-default-input-container .select-calendar-row div.active button {
  background-color: var(--primary-color);
  color: var(--grayscale-0);
}
.njc-form-text-default-input-container .security-row {
  position: relative;
}
.njc-form-text-default-input-container .security-row .njc-password-toggle-icon {
  position: absolute;
  top: 39px;
  right: 16px;
  cursor: pointer;
  width: 18px;
  height: 18px;
}
.njc-form-text-default-input-container .security-row .njc-login-password-toggle-icon {
  position: absolute;
  top: 12px;
  right: 16px;
  cursor: pointer;
  width: 18px;
  height: 18px;
}
.njc-form-text-default-input-container .security-row .njc-security-bar,
.njc-form-text-default-input-container .security-row .njc-login-security-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-form-text-default-input-container .security-row .njc-security-bar meter,
.njc-form-text-default-input-container .security-row .njc-login-security-bar meter {
  width: 100%;
  height: 8px;
  border-radius: 6px;
  margin: 8px 0px;
  appearance: none;
  background-color: var(--grayscale-30);
  clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%, 67% 100%, 67% 0, 65% 0, 65% 100%, 34% 100%, 34% 0, 32% 0, 32% 100%);
}
.njc-form-text-default-input-container .security-row .njc-security-bar meter[value="1"],
.njc-form-text-default-input-container .security-row .njc-login-security-bar meter[value="1"] {
  background: linear-gradient(90deg, var(--critical-color) 33%, var(--grayscale-30) 33%);
}
.njc-form-text-default-input-container .security-row .njc-security-bar meter[value="1"] + label span,
.njc-form-text-default-input-container .security-row .njc-login-security-bar meter[value="1"] + label span {
  color: var(--critical-color);
  font-weight: 600;
}
.njc-form-text-default-input-container .security-row .njc-security-bar meter[value="2"],
.njc-form-text-default-input-container .security-row .njc-login-security-bar meter[value="2"] {
  background: linear-gradient(90deg, var(--warning-color-dark) 66%, var(--grayscale-30) 66%);
}
.njc-form-text-default-input-container .security-row .njc-security-bar meter[value="2"] + label span,
.njc-form-text-default-input-container .security-row .njc-login-security-bar meter[value="2"] + label span {
  color: var(--warning-color-dark);
  font-weight: 600;
}
.njc-form-text-default-input-container .security-row .njc-security-bar meter[value="3"],
.njc-form-text-default-input-container .security-row .njc-login-security-bar meter[value="3"] {
  background-color: var(--success-color);
}
.njc-form-text-default-input-container .security-row .njc-security-bar meter[value="3"] + label span,
.njc-form-text-default-input-container .security-row .njc-login-security-bar meter[value="3"] + label span {
  color: var(--success-color);
  font-weight: 600;
}
.njc-form-text-default-input-container .security-row .njc-security-bar meter[value="4"],
.njc-form-text-default-input-container .security-row .njc-login-security-bar meter[value="4"] {
  background: linear-gradient(45deg, #FF1493, #ee7752, #e73c7e, #23a6d5, #23d5ab, #FF0000, #FFA500, #FFFF00, #7FFF00, #00FFFF, #0000FF, #9932CC, #FF1493, #ee7752, #e73c7e, #23a6d5, #23d5ab, #FF0000, #FFA500, #FFFF00, #7FFF00, #00FFFF, #0000FF, #9932CC);
  background-size: 1500% 100%;
  animation: crazyColor 5s linear infinite;
}
.njc-form-text-default-input-container .security-row .njc-security-bar meter[value="4"] + label span,
.njc-form-text-default-input-container .security-row .njc-login-security-bar meter[value="4"] + label span {
  color: var(--primary-color);
  font-weight: 600;
}
.njc-form-text-default .njc-form-input-message-link {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: var(--primary-color) !important;
  text-decoration: none !important;
  cursor: pointer !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
  padding: 4px 0px 0px 0px;
  width: 100%;
  display: block;
}
.njc-form-text-default .njc-form-input-message-link-med {
  font-size: 13px !important;
  color: var(--primary-color) !important;
  text-decoration: none !important;
  font-weight: 400 !important;
  cursor: pointer !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
}
.njc-form-text-default .njc-form-input-message-link-med:hover {
  color: var(--primary-color) !important;
  text-decoration: underline !important;
}
.njc-form-text-default .njc-form-input-message-link-med svg {
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
  margin-right: 10px !important;
  color: var(--primary-color) !important;
}
.njc-form-text-default .njc-form-input-message-link-larger {
  font-size: 14px !important;
  color: var(--primary-color) !important;
  text-decoration: none !important;
  font-weight: 400 !important;
  cursor: pointer !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
}
.njc-form-text-default .njc-form-input-message-link-larger:hover {
  color: var(--primary-color) !important;
  text-decoration: underline !important;
}
.njc-form-text-default .njc-form-input-message-link-larger svg {
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
  color: var(--primary-color) !important;
}
.njc-form-text-default .njc-form-input-message-link:hover {
  color: var(--primary-color) !important;
  text-decoration: underline !important;
}
.njc-form-text-default .njc-form-input-message-default {
  font-weight: 400;
  font-size: 12px;
  color: var(--grayscale-100);
  padding: 4px 0px 0px 0px;
  width: 100%;
  display: block;
}
.njc-form-text-default .njc-form-input-message-error {
  font-weight: 400;
  font-size: 12px;
  color: var(--critical-color);
  padding: 4px 0px 0px 0px;
  width: 100%;
  display: block;
}
.njc-form-text-default .njc-form-description {
  margin-top: 26px;
}
.njc-form-text-default .njc-form-description-notopmargin {
  margin-top: 0px;
}
.njc-form-text-default.njc-form-text-default-no-label .njc-form-label-wrap {
  display: none;
}
.njc-form-text-default.njc-form-text-default-no-label .njc-form-input-wrap {
  margin-top: 0px;
}
.njc-form-text-default.njc-form-text-default-no-label .njc-form-description {
  margin-top: 0px;
}
.njc-form-has-children .njc-form-text-default-input-container {
  flex-wrap: wrap;
}
.njc-form-has-children .njc-form-text-default-input-container .njc-form-has-children-content {
  flex-basis: 100%;
  padding: 12px 0 0 60px;
}
.njc-form-has-children .njc-form-text-default-input-container .njc-form-has-children-content .njc-form-text-default {
  padding: 0px;
}
.njc-form-has-children .njc-form-text-default-input-container .njc-form-has-children-content .njc-form-text-default-input-container.njc-full-width > div {
  width: 100%;
}
.njc-form-input,
.select2-selection,
.njc-form-input-select {
  width: 100% !important;
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
}
.njc-form-input:hover,
.select2-selection:hover,
.njc-form-input-select:hover {
  border: 1px solid var(--grayscale-50);
}
.njc-form-input:active,
.select2-selection:active,
.njc-form-input-select:active,
.njc-form-input:focus,
.select2-selection:focus,
.njc-form-input-select:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.njc-form-input:disabled,
.select2-selection:disabled,
.njc-form-input-select:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.njc-form-input[type="number"],
.select2-selection[type="number"],
.njc-form-input-select[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.njc-form-input[type="number"]::-webkit-outer-spin-button,
.select2-selection[type="number"]::-webkit-outer-spin-button,
.njc-form-input-select[type="number"]::-webkit-outer-spin-button,
.njc-form-input[type="number"]::-webkit-inner-spin-button,
.select2-selection[type="number"]::-webkit-inner-spin-button,
.njc-form-input-select[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.njc-form-input::placeholder,
.select2-selection::placeholder,
.njc-form-input-select::placeholder {
  color: var(--grayscale-70);
}
.njc-form-input.njc-50p-width,
.select2-selection.njc-50p-width,
.njc-form-input-select.njc-50p-width {
  width: 50% !important;
}
.njc-form-input.select2-hidden-accessible,
.select2-selection.select2-hidden-accessible,
.njc-form-input-select.select2-hidden-accessible {
  width: 1px !important;
}
.njc-form-input-select:required:invalid {
  color: var(--grayscale-70);
}
.njc-form-input-text-tags {
  background-color: transparent;
}
.njc-form-text-default .njc-form-input-wrap .njc-form-input-text-tags {
  max-width: none;
}
.njc-form-input-text-tags div.ti-input {
  padding: 0px;
  border: 0px solid transparent;
  width: 100%;
}
.njc-form-input-text-tags div.ti-input .ti-tags {
  align-items: center;
}
.njc-form-input-text-tags div.ti-input .ti-tags li {
  margin: 0;
  padding: 0;
}
.njc-form-input-text-tags div.ti-input .ti-tags li.ti-tag {
  margin: 8px 5px 8px 0;
  background-color: var(--primary-color-bg);
  display: flex;
  flex-direction: row;
  padding: 4px 12px;
  border-radius: 20px;
  color: var(--primary-color);
  border: 0px solid transparent;
  min-height: 28px;
  margin: 2px 5px 2px 0;
  word-break: break-all;
}
.njc-form-input-text-tags div.ti-input .ti-tags li.ti-tag .ti-actions {
  margin-left: 6px;
  font-size: 1em;
}
.njc-form-input-text-tags div.ti-input .ti-tags li.ti-tag .ti-actions:hover {
  color: var(--primary-color-50);
}
.njc-form-input-text-tags div.ti-input .ti-tags li.ti-tag.invalid {
  background-color: var(--critical-color-5);
  color: var(--critical-color);
}
.njc-form-input-text-tags div.ti-input .ti-tags li.ti-tag.invalid .ti-actions:hover {
  color: var(--critical-color-50);
}
.njc-form-input-text-tags div.ti-input .ti-tags .njc-form-input-text-tags {
  border: 0px solid transparent;
  box-shadow: none;
  min-height: 42px;
}
.njc-form-input-text-tags div.ti-input .ti-tags .njc-form-input-text-tags:focus {
  border: 0px solid transparent;
  box-shadow: none;
}
.njc-form-input-text-tags div.ti-input .ti-tags .njc-form-input-text-tags:hover {
  border: 0px solid transparent;
}
.njc-form-input-text-tags div.ti-input .ti-tags .njc-form-input-text-tags:read-only {
  border: 0px solid transparent !important;
}
.njc-form-input-text-tags .ti-autocomplete {
  overflow-y: scroll;
  max-height: 20em;
  left: 0px;
  top: 43px;
}
.njc-form-input-text-tags .ti-autocomplete .ti-item {
  word-break: break-all;
}
.njc-body .select2 {
  width: 100% !important;
}
.njc-body .select2.select2-container--focus .select2-selection {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.njc-body .select2 .select2-selection__choice,
.njc-body .select2 .select12-selection--single .select2-selection__rendered {
  margin: 8px 5px 8px 0;
  background-color: var(--primary-color-bg);
  flex-direction: row;
  padding: 4px 12px;
  border-radius: 20px;
  color: var(--primary-color);
  border: 0px solid transparent;
  display: flex;
  flex-direction: row-reverse !important;
  flex-wrap: wrap;
}
.njc-body .select2 .select2-selection__choice .select2-selection__choice__remove,
.njc-body .select2 .select12-selection--single .select2-selection__rendered .select2-selection__choice__remove,
.njc-body .select2 .select2-selection__choice .select2-selection__clear,
.njc-body .select2 .select12-selection--single .select2-selection__rendered .select2-selection__clear {
  margin: 0px;
  padding: 0 0 0 8px;
  background-image: url("/images/njc/njc-small-close-primary.svg");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  color: transparent;
}
.njc-body .select2 .select2-selection__choice .select2-selection__choice__remove:hover,
.njc-body .select2 .select12-selection--single .select2-selection__rendered .select2-selection__choice__remove:hover,
.njc-body .select2 .select2-selection__choice .select2-selection__clear:hover,
.njc-body .select2 .select12-selection--single .select2-selection__rendered .select2-selection__clear:hover {
  background-image: url("/images/njc/njc-small-close-hover.svg");
}
.njc-body .select2 .select2-search--inline {
  margin: 8px 5px 8px 0;
  flex-grow: 1;
}
.njc-body .select2 .select2-search--inline input {
  width: 100%;
  margin-top: 0px;
  height: 100%;
}
.njc-body .select2.select2-container--disabled .select2-selection--single,
.njc-body .select2.select2-container--disabled .select2-selection--multiple {
  background-image: url("/images/njc/njc-icon-dropdown-disabled.svg");
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.njc-body .select2.select2-container--disabled .select2-selection--single .select2-selection__rendered,
.njc-body .select2.select2-container--disabled .select2-selection--multiple .select2-selection__rendered,
.njc-body .select2.select2-container--disabled .select2-selection--single .select2-selection__choice,
.njc-body .select2.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  color: var(--grayscale-100);
}
.njc-body .select2.select2-container--disabled .select2-selection--single .select2-selection__rendered .select2-selection__choice__remove,
.njc-body .select2.select2-container--disabled .select2-selection--multiple .select2-selection__rendered .select2-selection__choice__remove,
.njc-body .select2.select2-container--disabled .select2-selection--single .select2-selection__choice .select2-selection__choice__remove,
.njc-body .select2.select2-container--disabled .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  display: inline-block;
  background-image: url("/images/njc/njc-small-close-grayscale-80.svg");
}
.njc-body .select2.select2-container--disabled .select2-selection--single .select2-selection__choice,
.njc-body .select2.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  background-color: var(--grayscale-20);
}
.njc-body .select2.select2-container--disabled .select2-selection--single:focus,
.njc-body .select2.select2-container--disabled .select2-selection--multiple:focus,
.njc-body .select2.select2-container--disabled .select2-selection--single:active,
.njc-body .select2.select2-container--disabled .select2-selection--multiple:active {
  box-shadow: none !important;
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10) !important;
  color: var(--grayscale-80) !important;
  cursor: not-allowed !important;
}
.njc-body .select2-selection,
.njc-body .njc-form-input-select {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  background-image: url("/images/njc/njc-icon-dropdown.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 35px;
  display: flex;
  flex-direction: row;
}
.njc-body .select2-selection::-ms-expand,
.njc-body .njc-form-input-select::-ms-expand {
  display: none;
}
.njc-body .select2-selection:disabled,
.njc-body .njc-form-input-select:disabled {
  background-image: url("/images/njc/njc-icon-dropdown-disabled.svg");
}
.njc-body .select2-selection__rendered {
  padding: 0px !important;
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
}
.njc-body .select2-selection__rendered .select2-selection__clear {
  display: none;
}
.njc-body .select2-selection__rendered[title="Select"] {
  color: var(--grayscale-40);
}
.njc-body .select2-selection__arrow {
  display: none;
}
input.njc-form-input:read-only {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.njc-margin-date-select-column input.njc-form-input:read-only {
  cursor: default;
}
.njc-form-input[type="file"] {
  padding: 9px 20px;
}
.njc-form-input::-webkit-calendar-picker-indicator {
  background-image: url("/images/njc/njc-calendar.svg");
  background-size: 16px 16px;
  background-repeat: no-repeat;
}
.njc-form-input.njc-form-input-date-select {
  background-image: url("/images/njc/njc-calendar.svg");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;
  padding: 0 28px 0 4px;
}
.njc-form-input.njc-form-input-date-select:read-only {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-0);
  color: var(--grayscale-100);
}
.njc-form-input::-webkit-file-upload-button {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  cursor: pointer;
  padding: 0px 12px;
  height: 28px;
  border-radius: 5px;
  text-decoration: none;
  background-color: var(--grayscale-0);
  border: 1px solid var(--secondary-color) !important;
  color: var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
textarea.njc-form-input {
  height: 200px;
  padding: 12px 20px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-70);
}
.margins-wrap .njc-margin-date-select-column {
  min-width: 90px;
}
.margins-wrap .njc-form-input[type="number"] {
  padding: 0 4px 0 8px;
}
.margins-wrap .njc-form-text-default .njc-form-description {
  margin-top: 0px;
}
.margins-wrap h6.njc-margin-error-text {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: var(--critical-color) !important;
  line-height: 18px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
  display: inline-block !important;
  margin: 8px 0;
}
.margins-wrap h6.njc-margin-error-text svg {
  width: 18px !important;
  height: 18px !important;
  margin-right: 4px !important;
}
.njc-form-color-select {
  display: flex;
  flex-direction: row;
  padding: 20px 0px 0px 0px;
}
.njc-form-color-select .njc-form-text-default-input-container .direction-column {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-form-color-select .njc-form-text-default-input-container .njc-form-color-select-pic .nc-sample {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(45, 53, 64, 0.3);
  border-radius: 5px;
  margin-right: 20px;
  cursor: pointer;
}
.njc-form-color-select .njc-form-text-default-input-container .njc-form-color-select-pic .nc-sample:hover {
  border: 2px solid rgba(45, 53, 64, 0.3);
}
.njc-form-color-select .njc-form-text-default-input-container .njc-form-color-select-pic .Modal__container {
  max-width: 355px !important;
  max-height: 500px !important;
}
.njc-form-color-select .njc-form-text-default-input-container .njc-form-color-select-pic .Modal__container .vue-color__sketch {
  width: 250px;
  box-shadow: none;
  padding-top: 20px;
}
.njc-form-color-select .njc-form-text-default-input-container .njc-form-color-select-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.njc-form-color-select .njc-form-text-default-input-container .njc-form-color-select-buttons span {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
}
.njc-form-color-select-small {
  padding: 20px 0px 0px 0px;
}
.njc-form-color-select-small .njc-form-color-select-pic {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;
  flex-wrap: wrap;
}
.njc-form-color-select-small .njc-form-color-select-pic .nc-sample {
  margin-right: 8px !important;
  margin-bottom: 8px;
}
.njc-form-color-select-small .njc-form-color-select-pic .Modal__container {
  max-width: 355px !important;
  max-height: 500px !important;
}
.njc-form-color-select-small .njc-form-color-select-pic .Modal__container .vue-color__sketch {
  width: 250px;
  box-shadow: none;
  padding-top: 20px;
}
.njc-adding-dropdown-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.njc-adding-dropdown-wrap .njc-adding-dropdown {
  flex-grow: 1;
  margin-right: 8px;
}
.njc-adding-dropdown-wrap .njc-adding-dropdown .njc-form-text-default-input-container {
  min-width: 100%;
  flex-basis: 100%;
}
.njc-dashboard-form .Fullpage .Fullpage__content .njc-nodata-card {
  border-top: 0px;
}
.njc-dashboard-form .Fullpage .Fullpage__content .njc-nodata-card h3 {
  display: none;
}
.njc-dashboard-form .Fullpage .Fullpage__content .njc-nodata-card .njc-adding-dropdown-message {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-60);
  line-height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  background-color: var(--grayscale-5);
  border-radius: 5px;
}
.njc-form-footer-row {
  padding: 40px 0px 0px 0px;
}
.njc-form-footer-row-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid var(--grayscale-30);
  padding: 20px 50px 0px 50px;
  margin: 0 -50px;
}
.njc-form-footer-row-container-steps {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--grayscale-30);
  padding: 20px 50px 0px 50px;
  margin: 0 -50px;
}
.njc-form-footer-row-container-steps .forward-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.njc-form-footer-row-container-steps .forward-actions button {
  margin-left: 12px;
}
.njc-form-footer-row-container .njc-btn-primary,
.njc-form-footer-row-container .njc-btn-secondary {
  margin-left: 12px;
}
.njc-form-footer-row-small {
  padding: 0px;
}
.njc-form-footer-row-small .njc-form-footer-row-container {
  margin: 20px -40px 0;
  padding: 20px 40px 0px;
}
.njc-form-footer-row .njc-form-footer-row-container-alternative {
  flex-direction: row-reverse;
}
.njc-flex-flip {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
.njc-error-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  border: 1px dashed var(--grayscale-30);
}
.njc-error-wrap .njc-error-img {
  width: 500px;
  height: 176px;
  margin-bottom: 30px;
}
.njc-error-wrap .njc-error-heading {
  font-weight: 700;
  font-size: 18px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  text-align: center;
}
.njc-modal-dimension-wrap {
  max-height: none !important;
  overflow-y: visible !important;
}
.njc-modal-dimension-wrap .replacement-overflow {
  overflow-y: auto;
  max-height: 250px;
}
.njc-modal-dimension-wrap .replacement-overflow::-webkit-scrollbar {
  width: 4px;
}
.njc-modal-dimension-wrap .replacement-overflow::-webkit-scrollbar-track {
  background-color: transparent;
}
.njc-modal-dimension-wrap .replacement-overflow::-webkit-scrollbar-thumb {
  background: var(--template-main-50);
  border: 0px solid transparent;
  border-radius: 4px;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content {
  margin: 8px 0 0 0;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content input {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  background-color: var(--grayscale-0);
  line-height: 16px;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content input::placeholder {
  color: var(--grayscale-70);
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content input:hover {
  border: 1px solid var(--grayscale-50);
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content input:active,
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content input:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content input:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content input[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content input[type="number"]::-webkit-outer-spin-button,
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content:first-child {
  margin-right: 10px;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-label {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-80);
  text-transform: capitalize;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-input {
  padding: 0px !important;
  width: 100%;
  position: relative;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-input span {
  color: var(--grayscale-60);
  font-size: 14px;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-input span:first-child {
  position: absolute;
  left: 8px;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-input span:nth-child(2) {
  position: absolute;
  right: 12px;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-input span:last-child {
  position: absolute;
  right: 8px;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-checkbox-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-checkbox-wrap b {
  display: none;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-checkbox-wrap input[type="checkbox"] {
  margin: 0px 6px 0 0;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-checkbox-wrap .njc-temp-modal-find-repalce-checkbox-text {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  white-space: nowrap;
  margin-bottom: 0px;
  padding-right: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button {
  font-weight: 400;
  font-size: 12px;
  color: var(--primary-color);
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  border: 0px;
  background-color: transparent !important;
  height: auto !important;
  margin-top: 8px;
  margin-left: auto;
  padding: 0px;
  cursor: pointer;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button-med {
  font-size: 13px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button-med:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button-med svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  margin-right: 10px;
  color: var(--primary-color);
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button-larger {
  font-size: 14px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button-larger:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button-larger svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  color: var(--primary-color);
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-nodata {
  color: var(--grayscale-30);
  text-align: center;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-add {
  border-top: 1px solid var(--grayscale-30);
  padding-top: 10px;
  margin-top: 10px;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-add button {
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
  padding: 0px 20px !important;
  height: 44px !important;
  min-width: 44px !important;
  border-radius: 5px !important;
  text-decoration: none !important;
  text-align: left !important;
  white-space: nowrap !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
  color: var(--grayscale-60) !important;
  background-color: var(--grayscale-0) !important;
  border: 1px dashed var(--grayscale-60) !important;
  width: 100% !important;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-add button svg {
  margin: 0px 8px 0px 0px !important;
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-add button svg.njc-icon-right-aligned {
  margin: 0px 0px 0px 8px !important;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-add button svg.njc-icon-progress-arrow {
  width: 12px !important;
  height: 12px !important;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-add button:disabled {
  background-color: var(--grayscale-60) !important;
  color: var(--grayscale-30) !important;
  border: 1px solid var(--grayscale-30) !important;
}
.njc-modal-dimension-wrap .njc-temp-modal-find-replace-add button:disabled:hover {
  background-color: var(--grayscale-60) !important;
  cursor: not-allowed !important;
  color: var(--grayscale-30) !important;
}
.njc-modal-dimension-wrap hr {
  display: none;
}
/*Confirmation Modal*/
.swal2-modal {
  border: 1px solid var(--grayscale-30);
  box-shadow: 2px 2px 8px rgba(45, 53, 64, 0.1);
  padding: 24px 40px 40px 40px !important;
  background-color: var(--grayscale-0);
  border-radius: 8px;
}
.swal2-modal .swal2-icon {
  transform: scale(0.75);
  margin: 0 auto;
}
.swal2-modal h2 {
  font-weight: 700;
  font-size: 24px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  margin: 0px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  line-height: 40px;
}
.swal2-modal .swal2-icon.swal2-warning {
  color: var(--critical-color);
  border-color: var(--critical-color) !important;
}
.swal2-modal .swal2-icon.swal2-question {
  color: var(--primary-color);
  border-color: var(--primary-color) !important;
}
.swal2-modal .swal2-content,
.swal2-modal .njc-alert-content {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
}
.swal2-modal .swal2-confirm {
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
  padding: 0px 20px !important;
  height: 44px !important;
  min-width: 44px !important;
  border-radius: 5px !important;
  text-decoration: none !important;
  text-align: left !important;
  white-space: nowrap !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
  color: var(--grayscale-0) !important;
  background-color: var(--primary-color) !important;
  border-width: 0px !important;
}
.swal2-modal .swal2-confirm svg {
  margin: 0px 8px 0px 0px !important;
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
}
.swal2-modal .swal2-confirm svg.njc-icon-right-aligned {
  margin: 0px 0px 0px 8px !important;
}
.swal2-modal .swal2-confirm svg.njc-icon-progress-arrow {
  width: 12px !important;
  height: 12px !important;
}
.swal2-modal .swal2-confirm:disabled {
  background-color: var(--grayscale-60) !important;
  color: var(--grayscale-30) !important;
  border: 1px solid var(--grayscale-30) !important;
}
.swal2-modal .swal2-confirm:disabled:hover {
  background-color: var(--grayscale-60) !important;
  cursor: not-allowed !important;
  color: var(--grayscale-30) !important;
}
.swal2-modal .swal2-confirm-tiny {
  font-size: 14px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
  padding: 0px 12px !important;
  height: 28px !important;
  border-radius: 5px !important;
  text-decoration: none !important;
  text-align: left !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
  color: var(--grayscale-0) !important;
  background-color: var(--primary-color) !important;
  border-width: 0px !important;
}
.swal2-modal .swal2-confirm-tiny svg {
  margin: 0px 8px 0px 0px !important;
  width: 18px !important;
  height: 18px !important;
}
.swal2-modal .swal2-confirm-tiny:disabled {
  background-color: var(--grayscale-60) !important;
}
.swal2-modal .swal2-confirm-tiny:disabled:hover {
  background-color: var(--grayscale-60) !important;
  cursor: not-allowed !important;
}
.swal2-modal .swal2-confirm-round {
  border-radius: 30px !important;
}
.swal2-modal .swal2-confirm:hover {
  color: var(--grayscale-0) !important;
  background-color: var(--primary-color) !important;
}
.swal2-modal .swal2-cancel {
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
  padding: 0px 20px !important;
  height: 44px !important;
  min-width: 44px !important;
  border-radius: 5px !important;
  text-decoration: none !important;
  text-align: left !important;
  white-space: nowrap !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
  color: var(--primary-color) !important;
  background-color: var(--grayscale-0) !important;
  border: 1px solid var(--primary-color) !important;
}
.swal2-modal .swal2-cancel svg {
  margin: 0px 8px 0px 0px !important;
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
}
.swal2-modal .swal2-cancel svg.njc-icon-right-aligned {
  margin: 0px 0px 0px 8px !important;
}
.swal2-modal .swal2-cancel svg.njc-icon-progress-arrow {
  width: 12px !important;
  height: 12px !important;
}
.swal2-modal .swal2-cancel:disabled {
  background-color: var(--grayscale-60) !important;
  color: var(--grayscale-30) !important;
  border: 1px solid var(--grayscale-30) !important;
}
.swal2-modal .swal2-cancel:disabled:hover {
  background-color: var(--grayscale-60) !important;
  cursor: not-allowed !important;
  color: var(--grayscale-30) !important;
}
.swal2-modal .swal2-cancel-tiny {
  font-size: 14px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
  padding: 0px 12px !important;
  height: 28px !important;
  border-radius: 5px !important;
  text-decoration: none !important;
  text-align: left !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
  color: var(--primary-color) !important;
  background-color: var(--grayscale-0) !important;
  border: 1px solid var(--primary-color) !important;
}
.swal2-modal .swal2-cancel-tiny svg {
  margin: 0px 8px 0px 0px !important;
  width: 18px !important;
  height: 18px !important;
}
.swal2-modal .swal2-cancel-tiny:disabled {
  background-color: var(--grayscale-60) !important;
}
.swal2-modal .swal2-cancel-tiny:disabled:hover {
  background-color: var(--grayscale-60) !important;
  cursor: not-allowed !important;
}
.swal2-modal .swal2-cancel-round {
  border-radius: 30px !important;
}
.swal2-modal .swal2-cancel:hover {
  color: var(--primary-color) !important;
  background-color: var(--grayscale-0) !important;
}
.swal2-modal .swal2-styled {
  display: inline-flex !important;
}
.swal2-modal select {
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  background-image: url("/images/njc/njc-icon-dropdown.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 35px;
  width: 100%;
}
.swal2-modal select:hover {
  border: 1px solid var(--grayscale-50);
}
.swal2-modal select:active,
.swal2-modal select:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.swal2-modal select:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.swal2-modal select[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.swal2-modal select[type="number"]::-webkit-outer-spin-button,
.swal2-modal select[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.swal2-modal select::-ms-expand {
  display: none;
}
.swal2-modal select:disabled {
  background-image: url("/images/njc/njc-icon-dropdown-disabled.svg");
}
.swal2-modal select::placeholder {
  font-weight: 500;
  color: var(--grayscale-50);
}
.njc-empty-placeholder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 700;
  font-size: 24px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  margin: 0px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--primary-color);
  text-transform: uppercase;
}
/*Select 2 Dropdown*/
.select2-container--open .select2-dropdown {
  border-radius: 5px;
  z-index: 1061;
}
.select2-container--open .select2-dropdown--below {
  border-radius: 5px;
  border-top: 1px solid var(--secondary-color-variant);
  margin-top: 4px;
}
.select2-container--open .select2-dropdown--above {
  border-radius: 5px;
  border-bottom: 0px solid transparent;
  position: relative;
  bottom: 4px;
}
.select2-container--open .select2-dropdown .select2-search .select2-search__field {
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
}
.select2-container--open .select2-dropdown .select2-search .select2-search__field:hover {
  border: 1px solid var(--grayscale-50);
}
.select2-container--open .select2-dropdown .select2-search .select2-search__field:active,
.select2-container--open .select2-dropdown .select2-search .select2-search__field:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.select2-container--open .select2-dropdown .select2-search .select2-search__field:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.select2-container--open .select2-dropdown .select2-search .select2-search__field[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.select2-container--open .select2-dropdown .select2-search .select2-search__field[type="number"]::-webkit-outer-spin-button,
.select2-container--open .select2-dropdown .select2-search .select2-search__field[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.select2-container--open .select2-dropdown .select2-search .select2-search__field::placeholder {
  color: var(--grayscale-70);
}
.select2-container--open .select2-dropdown .select2-results__options {
  overflow-y: overlay;
}
.select2-container--open .select2-dropdown .select2-results__options::-webkit-scrollbar {
  width: 6px;
}
.select2-container--open .select2-dropdown .select2-results__options::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px var(--grayscale-10);
}
.select2-container--open .select2-dropdown .select2-results__options::-webkit-scrollbar-thumb {
  background: var(--grayscale-30);
  border-left: 1px solid var(--grayscale-0);
  border-right: 1px solid var(--grayscale-0);
  border-radius: 5px;
}
.select2-container--open .select2-dropdown .select2-results__option {
  align-items: center;
  background-color: var(--grayscale-0);
  position: relative;
  font-weight: 400;
  font-size: 14px;
  color: var(--grayscale-80);
  white-space: nowrap;
  min-height: 36px;
  padding: 0px 20px 0px 12px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.select2-container--open .select2-dropdown .select2-results__option:after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid var(--secondary-color-variant);
}
.select2-container--open .select2-dropdown .select2-results__option:first-child {
  border-radius: 5px 5px 0 0;
}
.select2-container--open .select2-dropdown .select2-results__option:last-child {
  border-radius: 0 0 5px 5px;
}
.select2-container--open .select2-dropdown .select2-results__option:hover {
  background-color: var(--listhovercolor);
  color: var(--primary-color);
}
.select2-container--open .select2-dropdown .select2-results__option svg {
  margin: 0px 8px 0px 0px;
  width: 18px;
  height: 18px;
}
.select2-container--open .select2-dropdown .select2-results__option:hover {
  color: var(--primary-color);
  cursor: pointer;
}
.select2-container--open .select2-dropdown .select2-results__option[aria-selected=true] {
  background-color: var(--primary-color-5);
  color: var(--primary-color);
  font-weight: 500;
}
.select2-container--open .select2-dropdown .select2-results__option[role=group] .select2-results__options--nested .select2-results__option {
  padding: 0 20px 0 40px;
  border-radius: 0px;
  background-image: url("/images/njc/njc-new-line.svg");
  background-repeat: no-repeat;
  background-position: 20px 50%;
}
.select2-container--open .select2-dropdown .select2-results__option[role=group]:hover {
  background-color: var(--grayscale-0);
  color: var(--grayscale-100);
}
.select2-container--open .select2-dropdown .select2-results__option.select2-results__message:hover {
  background-color: transparent;
  color: var(--grayscale-100);
}
.select2-container--open .select2-dropdown .select2-results__option--highlighted {
  background-color: transparent;
  color: var(--primary-color);
}
.select2-container--open .select2-dropdown .select2-results__option .select2-results__group {
  padding: 6px 0;
}
.select2-container--open .select2-dropdown .select2-results__option .select2-results__options--nested {
  width: calc(100% + 40px);
  margin: 0 -20px;
}
.select2-container--open .select2-dropdown .select2-results__option .select2-results__options--nested .select2-results__option {
  border: 0px;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-radius: 5px !important;
}
/* Popup calendar */
body .pika-single {
  border-radius: 5px;
  margin-top: 4px;
  border: 1px solid var(--grayscale-30);
}
body .pika-single.is-bound {
  box-shadow: 2px 2px 4px rgba(45, 53, 64, 0.1);
}
body .pika-single .pika-lendar {
  margin: 0px;
  width: 275px;
}
body .pika-single .pika-lendar .pika-title {
  height: 42px;
  padding: 12px 8px;
  border-bottom: 1px solid var(--grayscale-30);
}
body .pika-single .pika-lendar .pika-title .pika-label {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  line-height: normal;
  padding: 0px 4px;
}
body .pika-single .pika-lendar .pika-title .pika-prev,
body .pika-single .pika-lendar .pika-title .pika-next {
  width: 14px;
  height: 14px;
  background-size: 100%;
  opacity: 1;
}
body .pika-single .pika-lendar .pika-title .pika-prev {
  background-image: url("/images/njc/njc-calendar-left.svg");
}
body .pika-single .pika-lendar .pika-title .pika-prev:hover {
  background-image: url("/images/njc/njc-calendar-left-hover.svg");
}
body .pika-single .pika-lendar .pika-title .pika-next {
  background-image: url("/images/njc/njc-calendar-right.svg");
}
body .pika-single .pika-lendar .pika-title .pika-next:hover {
  background-image: url("/images/njc/njc-calendar-right-hover.svg");
}
body .pika-single .pika-lendar .pika-table {
  width: calc(100% - 16px);
  margin: 8px;
}
body .pika-single .pika-lendar .pika-table tr {
  height: 24px;
}
body .pika-single .pika-lendar .pika-table tr th {
  line-height: 24px;
  padding-bottom: 8px;
}
body .pika-single .pika-lendar .pika-table tr abbr {
  cursor: default;
  text-decoration: none;
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
}
body .pika-single .pika-lendar .pika-table td .pika-button {
  height: 28px;
  padding: 0px;
  background-color: transparent;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
}
body .pika-single .pika-lendar .pika-table td .pika-button:hover {
  background-color: var(--grayscale-10);
}
body .pika-single .pika-lendar .pika-table td.is-today .pika-button {
  color: var(--primary-color);
}
body .pika-single .pika-lendar .pika-table td[aria-selected="true"] .pika-button {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  color: var(--grayscale-0);
  background-color: var(--primary-color);
}
body .pika-single .pika-lendar .pika-table td[aria-selected="true"] .pika-button:hover {
  background-color: var(--primary-color);
}
/* New Error Styling */
.nc-error-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
}
.nc-error-wrap-border {
  border: 1px dashed #BDC2CC;
  border-radius: 5px;
  margin: 10px;
}
.njc-error-img {
  width: 500px;
  height: 176px;
}
.nc-error-heading,
.nc-error-message {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--grayscale-100);
}
.nc-error-heading {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 26px;
}
.nc-error-message {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 8px;
}
.nc-error-link {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  cursor: pointer;
  padding: 0 20px;
  height: 30px;
  min-width: 44px;
  border-radius: 5px;
  text-decoration: none;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #545D6B;
}
.dataTables_processing {
  position: relative;
}
.njc-login-wrap {
  display: flex;
  flex-direction: row;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  height: 100%;
}
.njc-login-wrap .njc-login-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45%;
  padding: 50px 40px;
}
.njc-login-wrap .njc-login-left .njc-login-logo-row,
.njc-login-wrap .njc-login-left .njc-login-form-wrap,
.njc-login-wrap .njc-login-left .njc-login-form-wrap,
.njc-login-wrap .njc-login-left .njc-login-footer-wrap {
  max-width: 450px;
  width: 100%;
}
.njc-login-wrap .njc-login-left .njc-login-logo-row {
  margin-bottom: auto;
}
.njc-login-wrap .njc-login-left .njc-login-logo-row .njc-login-logo-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-login-wrap .njc-login-left .njc-login-header {
  font-weight: 700;
  font-size: 24px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  margin: 0px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  margin-bottom: 32px;
}
.njc-login-wrap .njc-login-left .njc-login-header .njc-login-header-desc {
  padding-top: 12px;
  text-transform: initial;
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
}
.njc-login-wrap .njc-login-left .njc-login-header .njc-login-header-desc .njc-login-mfa-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-login-wrap .njc-login-left .njc-login-header .njc-login-header-desc .njc-login-mfa-wrap .njc-login-mfa-instruction {
  padding: 0 8px 0 0;
  text-align: left;
}
.njc-login-wrap .njc-login-left .njc-form-text-default-input-container {
  margin-bottom: 20px;
}
.njc-login-wrap .njc-login-left .njc-login-remember-me {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}
.njc-login-wrap .njc-login-left .njc-login-remember-me .njc-checkbox {
  display: flex;
  flex-direction: row;
  font-size: 14px;
}
.njc-login-wrap .njc-login-left .njc-login-remember-me .njc-text-link-larger {
  text-align: right;
}
.njc-login-wrap .njc-login-left .njc-login-button {
  margin-top: 32px;
}
.njc-login-wrap .njc-login-left .njc-login-footer-wrap {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.njc-login-wrap .njc-login-left .njc-login-footer-wrap .njc-login-social-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}
.njc-login-wrap .njc-login-left .njc-login-footer-wrap .njc-login-social-wrap .njc-login-social-item {
  margin: 0 16px;
  width: 18px;
  height: 18px;
}
.njc-login-wrap .njc-login-left .njc-login-footer-wrap .njc-login-social-wrap .njc-login-social-item svg {
  width: 18px;
  height: 18px;
  color: var(--primary-color);
}
.njc-login-wrap .njc-login-left .njc-login-footer-wrap .njc-login-powered-by {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
}
.njc-login-wrap .njc-login-left .njc-login-footer-wrap .njc-login-powered-by a {
  color: var(--primary-color);
  text-decoration: none;
}
.njc-login-wrap .njc-login-left .njc-form-input-password {
  position: relative;
}
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-password-toggle-icon {
  position: absolute;
  top: 39px;
  right: 16px;
  cursor: pointer;
  width: 18px;
  height: 18px;
}
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-login-password-toggle-icon {
  position: absolute;
  top: 12px;
  right: 16px;
  cursor: pointer;
  width: 18px;
  height: 18px;
}
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-security-bar,
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-login-security-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-security-bar meter,
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-login-security-bar meter {
  width: 100%;
  height: 8px;
  border-radius: 6px;
  margin: 8px 0px;
  appearance: none;
  background-color: var(--grayscale-30);
  clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%, 67% 100%, 67% 0, 65% 0, 65% 100%, 34% 100%, 34% 0, 32% 0, 32% 100%);
}
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-security-bar meter[value="1"],
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-login-security-bar meter[value="1"] {
  background: linear-gradient(90deg, var(--critical-color) 33%, var(--grayscale-30) 33%);
}
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-security-bar meter[value="1"] + label span,
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-login-security-bar meter[value="1"] + label span {
  color: var(--critical-color);
  font-weight: 600;
}
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-security-bar meter[value="2"],
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-login-security-bar meter[value="2"] {
  background: linear-gradient(90deg, var(--warning-color-dark) 66%, var(--grayscale-30) 66%);
}
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-security-bar meter[value="2"] + label span,
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-login-security-bar meter[value="2"] + label span {
  color: var(--warning-color-dark);
  font-weight: 600;
}
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-security-bar meter[value="3"],
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-login-security-bar meter[value="3"] {
  background-color: var(--success-color);
}
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-security-bar meter[value="3"] + label span,
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-login-security-bar meter[value="3"] + label span {
  color: var(--success-color);
  font-weight: 600;
}
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-security-bar meter[value="4"],
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-login-security-bar meter[value="4"] {
  background: linear-gradient(45deg, #FF1493, #ee7752, #e73c7e, #23a6d5, #23d5ab, #FF0000, #FFA500, #FFFF00, #7FFF00, #00FFFF, #0000FF, #9932CC, #FF1493, #ee7752, #e73c7e, #23a6d5, #23d5ab, #FF0000, #FFA500, #FFFF00, #7FFF00, #00FFFF, #0000FF, #9932CC);
  background-size: 1500% 100%;
  animation: crazyColor 5s linear infinite;
}
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-security-bar meter[value="4"] + label span,
.njc-login-wrap .njc-login-left .njc-form-input-password .njc-login-security-bar meter[value="4"] + label span {
  color: var(--primary-color);
  font-weight: 600;
}
.njc-login-wrap .njc-login-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 55%;
  background-size: contain, contain, cover;
  background-repeat: no-repeat;
  background-position: center center, center center, top left;
  background-image: url("/images/njc/login-image-stock.png"), url("/images/njc/login-image-bg.svg"), url("/images/njc/login-bg.png");
}
/*Bulk Management Styling*/
.njc-bulk-pagetitle-subtext {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  padding-bottom: 8px;
  margin-top: -34px;
}
.njc-bulk-pagetitle-subtext .njc-status-bar {
  margin-top: 12px;
}
.njc-bulk-steps {
  font-weight: 700;
  font-size: 18px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  padding-top: 8px;
  padding-bottom: 8px;
}
.njc-bulk-dl-msg {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  padding-bottom: 8px;
}
.njc-bulk-dl-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 12px;
}
.njc-bulk-downloads {
  padding-bottom: 4px;
}
.njc-status-bar-bulk {
  margin-top: 0;
}
.njc-status-bar-bulk svg {
  margin: 0 9px 0 0;
}
.njc-bulk-box {
  width: 100%;
  margin: 0 0 4px 0;
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid var(--grayscale-30);
  background-color: var(--grayscale-0);
  transition: all 0.4s ease-out;
}
.njc-bulk-box-export {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.njc-bulk-box-export .njc-text-link-med {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.njc-bulk-box-detail-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 10px;
}
.njc-bulk-box-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: var(--grayscale-100);
}
.njc-bulk-box-title .njc-beta-tag {
  color: var(--grayscale-0);
  font-weight: 700;
  font-size: 12px;
  border-radius: 10px;
  padding: 0px 12px;
  text-transform: uppercase;
  background-color: var(--primary-color);
  margin: 0px 0px 20px 8px;
}
.njc-bulk-box-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.njc-bulk-box-text strong {
  font-weight: 700;
}
.njc-bulk-upload-box {
  width: 100%;
  margin: 4px 0 28px 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px dashed var(--grayscale-60);
  background-color: var(--grayscale-0);
}
.njc-bulk-upload-status-icon {
  width: 54px;
  height: 54px;
  margin-bottom: 12px;
}
.njc-bulk-upload-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.njc-bulk-upload-label {
  margin-bottom: 8px;
  margin-right: auto;
  color: var(--grayscale-80);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}
.njc-bulk-upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.njc-bulk-upload-form select {
  margin: 0 0 8px 0;
  width: auto !important;
}
.njc-bulk-upload-form .njc-bulk-upload-text-link {
  margin-bottom: 12px;
  text-decoration: underline;
}
.njc-bulk-upload-form .njc-btn-primary {
  min-width: 143px;
}
.njc-bulk-upload-refresh-icon {
  width: 54px;
  height: 54px;
  margin-bottom: 4px;
}
.njc-bulk-upload-title {
  line-height: 24px;
  font-weight: 700;
  font-size: 14px;
  color: var(--grayscale-100);
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  margin-bottom: 8px;
}
.njc-bulk-upload-filename {
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.njc-bulk-upload-filename svg {
  margin-left: 8px;
  padding: 3px;
  width: 18px;
  height: 18px;
  color: var(--critical-color);
  cursor: pointer;
}
.njc-bulk-upload-process {
  color: var(--grayscale-70);
  font-size: 12px;
  line-height: 18px;
}
.njc-bulk-import-history-title {
  margin-top: 4px;
}
.njc-pre-table-info {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  margin: 20px 1px;
}
/* Margin builder styling */
.njc-list-table-margin {
  overflow: visible !important;
}
.njc-month-calendar-wrap {
  position: relative;
}
.datepicker.dropdown-menu {
  position: fixed;
  z-index: 12;
  float: left;
  min-width: 190px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: var(--grayscale-0);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}
.datepicker:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 6px;
}
.datepicker:after {
  content: "";
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid var(--grayscale-0);
  position: absolute;
  top: -6px;
  left: 7px;
}
.datepicker table {
  width: 100%;
  margin: 0;
}
.datepicker thead tr:first-child th {
  cursor: pointer;
}
.datepicker th.next,
.datepicker th.prev {
  font-size: 21px;
}
.datepicker td,
.datepicker th {
  text-align: center;
  width: 20px;
  height: 24px;
  border-radius: 4px;
}
.datepicker td span {
  display: block;
  width: 52px;
  height: 28px;
  line-height: 28px;
  float: left;
  margin: 4px;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--grayscale-10);
}
.datepicker td span.active {
  background-color: var(--primary-color);
  color: var(--grayscale-0);
}
.datepicker td span:hover {
  background-color: var(--grayscale-40);
}
.njc-account-group-list-advertisers-loader,
.njc-account-group-list-advertisers {
  margin-top: 24px;
}
.njc-account-group-list-advertisers .njc-account-group-list-advertisers-title {
  font-weight: 700;
  font-size: 18px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  margin-bottom: 12px;
}
.njc-account-group-list-advertisers .njc-search-bar-row {
  display: flex;
  flex-direction: column;
}
.njc-account-group-list-advertisers .njc-search-bar-wrap,
.njc-account-group-list-advertisers .njc-form-buttons-bar,
.njc-account-group-list-advertisers .njc-adding-dropdown-wrap {
  width: 100%;
  margin-right: 0;
}
.njc-account-group-list-advertisers .njc-form-search-bar {
  margin-bottom: 0px;
}
.njc-account-group-list-advertisers .njc-form-text-default {
  padding: 0px;
}
.njc-account-group-list-advertisers .njc-list-table {
  max-height: 400px;
}
.njc-account-group-list-advertisers .njc-list-table .njc-list-table-header .njc-list-table-cell-content {
  cursor: pointer;
}
.njc-account-group-list-advertisers .njc-list-table .njc-list-table-cell-content {
  width: 100%;
}
.njc-account-group-list-advertisers .njc-list-table .njc-list-table-cell-content .njc-associated-with {
  font-weight: 400;
  font-size: 12px;
  color: var(--grayscale-60);
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  text-align: right;
  margin-left: auto;
}
.njc-account-group-list-advertisers .njc-adding-dropdown-wrap {
  margin-bottom: 16px;
}
.njc-account-group-list-advertisers .njc-adding-dropdown-wrap .select2-selection {
  min-height: 44px;
}
.njc-account-group-list-advertisers .njc-adding-dropdown-wrap .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
  width: auto;
  height: auto;
  background-image: none;
}
.njc-account-group-list-advertisers .select2-container--default .select2-selection--single {
  border: 1px solid var(--grayscale-30);
}
.njc-account-group-list-advertisers .njc-list-card .njc-list-table .njc-list-table-cell {
  padding-top: 4px;
  padding-bottom: 4px;
}
.njc-associated-dropdown-row-wrap {
  width: 100%;
  white-space: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.njc-associated-dropdown-row-wrap .njc-associated-with {
  font-weight: 400;
  font-size: 12px;
  color: var(--grayscale-60);
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-weight: bold;
  white-space: nowrap;
  text-align: right;
}
.njc-associated-dropdown-row-wrap .njc-associated-with::before {
  content: "Associated with";
  margin-right: 4px;
  font-weight: normal;
}
.njc-body-pagetitle.njc-within-modal {
  min-height: auto;
  padding: 0px !important;
}
.njc-body-pagetitle.njc-within-modal .njc-pagetitle-title-wrap {
  width: 100%;
}
.njc-body-pagetitle.njc-within-modal .njc-pagetitle-title {
  min-height: 44px;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}
.njc-body-pagetitle.njc-within-modal .njc-pagetitle-subtext {
  margin-top: 0px !important;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.3px;
  color: var(--grayscale-70);
}
.njc-solo-tag-wrap {
  flex-wrap: wrap;
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  background-color: var(--grayscale-10);
}
.njc-solo-tag-wrap:hover {
  border: 1px solid var(--grayscale-50);
}
.njc-solo-tag-wrap:active,
.njc-solo-tag-wrap:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.njc-solo-tag-wrap:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.njc-solo-tag-wrap[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.njc-solo-tag-wrap[type="number"]::-webkit-outer-spin-button,
.njc-solo-tag-wrap[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.njc-solo-tag-wrap.disabled {
  background-color: var(--grayscale-10);
}
.njc-solo-tag-wrap .njc-solo-tag {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  margin: 8px 5px 8px 0;
  background-color: var(--primary-color-bg);
  display: flex;
  flex-direction: row;
  padding: 4px 12px;
  border-radius: 20px;
  color: var(--primary-color);
  border: 0px solid transparent;
  align-items: center;
  min-height: 26px;
  background-color: var(--grayscale-20);
  color: var(--grayscale-100);
}
.njc-solo-tag-wrap .njc-solo-tag::placeholder {
  color: var(--grayscale-70);
}
.njc-data-cloud-demo-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
}
.njc-data-cloud-bg {
  width: 100%;
  height: auto;
  max-width: 765px;
}
.njc-data-cloud-demo-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 36px;
  row-gap: 20px;
}
.njc-data-cloud-demo-content .njc-data-cloud-demo-title {
  font-size: 34px;
  font-weight: 700;
  line-height: 40px;
  max-width: 380px;
  text-align: center;
}
.njc-data-cloud-demo-content .njc-data-cloud-demo-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  max-width: 600px;
  text-align: center;
}
.njc-data-cloud-demo-content .njc-data-cloud-demo-button-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}
.njc-data-cloud-demo-content .njc-data-cloud-demo-button-wrap .njc-btn-primary,
.njc-data-cloud-demo-content .njc-data-cloud-demo-button-wrap .njc-btn-secondary {
  min-width: 160px;
}
.njc-extra-login-wrap {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
  gap: 4px;
}
.njc-extra-login-wrap a {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-form-input-error {
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  color: var(--critical-color);
  line-height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  display: inline-block;
  display: block;
}
.njc-form-input-error svg {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}
.njc-toggle-button-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;
}
.njc-toggle-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18px 24px;
  border: 3px solid var(--grayscale-0);
  border-radius: 8px;
  background-color: var(--grayscale-5);
  cursor: pointer;
  gap: 8px;
  font-weight: 700;
  font-size: 13px;
  color: var(--grayscale-70);
}
.njc-toggle-button.selected {
  background-color: var(--grayscale-0);
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.njc-full-modal-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-full-modal-title .njc-modal-sub-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: var(--grayscale-70);
  text-transform: uppercase;
}
.njc-full-modal-title .njc-modal-title {
  font-size: 34px;
  font-weight: 700;
  line-height: 40px;
  color: var(--grayscale-100);
}
.njc-small-modal-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-small-modal-title .njc-modal-sub-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: var(--grayscale-70);
  text-transform: uppercase;
}
.njc-small-modal-title .njc-modal-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  color: var(--grayscale-100);
}
.njc-dashboard-template-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 0;
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list {
  width: 100%;
  border: 1px solid var(--grayscale-30);
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-list-table-body-empty {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: var(--grayscale-60);
  font-weight: 400;
  line-height: 16px;
  text-align: center;
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-list-table {
  max-height: 600px;
  overflow-y: auto;
  position: relative;
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-list-table .njc-list-table-cell span {
  font-weight: 500;
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-list-table-header {
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid var(--grayscale-30);
  position: sticky;
  top: 0;
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-list-table-cell-with-network-icons {
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  align-items: flex-start;
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-network-icon-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-network-icon-wrap .njc-network-icon {
  width: 18px;
  height: 18px;
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-list-table-body-included .njc-list-table-row,
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-list-table-body-excluded .njc-list-table-row {
  border-bottom: 1px solid var(--grayscale-30);
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-list-table-body-included .njc-list-table-row:before,
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-list-table-body-excluded .njc-list-table-row:before {
  display: none;
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-list-table-body-included .njc-list-table-row:last-child,
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-list-table-body-excluded .njc-list-table-row:last-child {
  border-bottom: none;
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-list-table-body-excluded .njc-list-table-cell {
  opacity: 0.5;
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-list-table-body-excluded .njc-table-column-icon {
  opacity: 1;
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-list-header-separator {
  background-color: var(--grayscale-5);
  border-top: 1px solid var(--grayscale-30);
  border-bottom: 1px solid var(--grayscale-30);
  padding: 0 20px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-list-header-separator span {
  font-weight: 700;
  color: var(--grayscale-60);
  text-transform: uppercase;
  font-size: 12px;
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-table-column-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-icon-button svg {
  color: var(--grayscale-50);
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-icon-button.njc-icon-remove-button:hover svg {
  color: var(--critical-color);
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-icon-button.njc-icon-add-button:hover svg {
  color: var(--primary-color);
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list .njc-icon-left-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  text-indent: 0;
  cursor: pointer;
}
#report-workflow-ui {
  margin: 0 auto;
  width: 90%;
  padding-bottom: 8px;
}
.word-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}
@media screen and (max-width: 1140px) {
  .njc-main-menu {
    width: 64px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    /*------------------Opened menu----------------*/
  }
  .njc-main-menu .njc-menu-collapsed-button {
    height: 36px;
    width: 15px;
    border-radius: 0 5px 5px 0;
    background-color: var(--primary-color-dark);
    color: var(--primary-color-5);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -15px;
    top: 30px;
    z-index: 10;
    cursor: pointer;
  }
  .njc-main-menu .njc-menu-collapsed-button svg {
    transform: rotate(270deg);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    width: 18px;
    height: 18px;
  }
  .njc-main-menu .njc-main-menu-item.account-item {
    position: relative;
    padding: 0px;
    width: 100%;
    transition: padding 0.2s;
  }
  .njc-main-menu .njc-main-menu-item.account-item .njc-main-menu-item-label {
    padding: 0 0 0 14px;
    transition: padding 0.2s;
  }
  .njc-main-menu .njc-main-menu-item.account-item .njc-main-menu-item-label .njc-agency-logo img {
    width: 36px;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -ms-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
  }
  .njc-main-menu .njc-main-menu-item.account-item .njc-main-menu-item-label .njc-menu-info {
    width: 0%;
    margin-left: 0px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }
  .njc-main-menu .njc-main-menu-item.account-item .njc-main-menu-item-label .njc-menu-info .njc-menu-title,
  .njc-main-menu .njc-main-menu-item.account-item .njc-main-menu-item-label .njc-menu-info .njc-menu-subtext {
    color: transparent;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -ms-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
    width: 0;
    white-space: nowrap;
    overflow: hidden;
  }
  .njc-main-menu .njc-main-menu-item.account-item .njc-main-menu-item-label .njc-menu-info .njc-menu-icon {
    position: static;
  }
  .njc-main-menu .njc-main-menu-item.account-item .njc-main-menu-item-label .njc-menu-info .njc-menu-icon .njc-user-dropdown {
    visibility: visible;
    left: 11px;
    top: 78px;
  }
  .njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-section .njc-main-menu-section-title {
    color: transparent;
    height: 0px;
    overflow: hidden;
    white-space: nowrap;
    margin: 16px 0 0 0;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -ms-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
  }
  .njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-main-menu-item-label .njc-menu-text {
    max-width: 0px;
    overflow: hidden;
    margin-left: 0px;
    color: transparent;
    white-space: nowrap;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -ms-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
  }
  .njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-main-menu-item-label .link-icon {
    display: none;
  }
  .njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-main-menu-item-dropwrap {
    color: transparent;
  }
  .njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-main-menu-item-dropwrap .njc-menu-item {
    color: transparent;
  }
  .njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item .njc-main-menu-item-dropwrap .njc-menu-item.active {
    color: transparent;
  }
  .njc-main-menu .njc-main-menu-dropdowns .njc-main-menu-item.active .njc-main-menu-item-dropwrap {
    max-height: 0px;
    padding: 0px;
    white-space: nowrap;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -ms-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
    color: var(--grayscale-0);
  }
  .njc-main-menu.njc-menu-uncollapse {
    width: 240px;
  }
  .njc-main-menu.njc-menu-uncollapse .njc-menu-collapsed-button svg {
    transform: rotate(90deg);
  }
  .njc-main-menu.njc-menu-uncollapse .njc-main-menu-item.account-item {
    padding: 0 20px;
  }
  .njc-main-menu.njc-menu-uncollapse .njc-main-menu-item.account-item .njc-main-menu-item-label {
    padding: 0;
  }
  .njc-main-menu.njc-menu-uncollapse .njc-main-menu-item.account-item .njc-main-menu-item-label .njc-agency-logo img {
    width: 44px;
    height: auto;
  }
  .njc-main-menu.njc-menu-uncollapse .njc-main-menu-item.account-item .njc-main-menu-item-label .njc-menu-info {
    visibility: visible;
    height: 36px;
    margin-left: 8px;
    position: static;
  }
  .njc-main-menu.njc-menu-uncollapse .njc-main-menu-item.account-item .njc-main-menu-item-label .njc-menu-info .njc-menu-title,
  .njc-main-menu.njc-menu-uncollapse .njc-main-menu-item.account-item .njc-main-menu-item-label .njc-menu-info .njc-menu-subtext {
    width: 100%;
    white-space: nowrap;
    overflow: visible;
  }
  .njc-main-menu.njc-menu-uncollapse .njc-main-menu-item.account-item .njc-main-menu-item-label .njc-menu-info .njc-menu-title {
    color: var(--grayscale-0);
  }
  .njc-main-menu.njc-menu-uncollapse .njc-main-menu-item.account-item .njc-main-menu-item-label .njc-menu-info .njc-menu-subtext {
    color: var(--primary-color-20);
  }
  .njc-main-menu.njc-menu-uncollapse .njc-main-menu-item.account-item .njc-main-menu-item-label .njc-menu-info .njc-menu-icon {
    position: relative;
  }
  .njc-main-menu.njc-menu-uncollapse .njc-main-menu-item.account-item .njc-main-menu-item-label .njc-menu-info .njc-menu-icon .njc-user-dropdown {
    visibility: visible;
    left: -13px;
    top: 28px;
  }
  .njc-main-menu.njc-menu-uncollapse .njc-main-menu-dropdowns .njc-main-menu-section .njc-main-menu-section-title {
    color: var(--primary-color-10);
    margin: 24px 20px 10px 20px;
    height: auto;
  }
  .njc-main-menu.njc-menu-uncollapse .njc-main-menu-dropdowns .njc-main-menu-item .njc-main-menu-item-label .njc-menu-text {
    max-width: 100%;
    overflow: visible;
    margin-left: 12px;
    color: var(--primary-color-10);
  }
  .njc-main-menu.njc-menu-uncollapse .njc-main-menu-dropdowns .njc-main-menu-item .njc-main-menu-item-label .link-icon {
    display: block;
  }
  .njc-main-menu.njc-menu-uncollapse .njc-main-menu-dropdowns .njc-main-menu-item.active {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }
  .njc-main-menu.njc-menu-uncollapse .njc-main-menu-dropdowns .njc-main-menu-item.active .njc-menu-text {
    color: var(--grayscale-0);
  }
  .njc-main-menu.njc-menu-uncollapse .njc-main-menu-dropdowns .njc-main-menu-item.active .njc-main-menu-item-dropwrap {
    max-height: 400px;
    padding: 4px 0px;
  }
  .njc-main-menu.njc-menu-uncollapse .njc-main-menu-dropdowns .njc-main-menu-item.active .njc-main-menu-item-dropwrap .njc-menu-item .njc-menu-text {
    color: var(--primary-color-10);
  }
  .njc-main-menu.njc-menu-uncollapse .njc-main-menu-dropdowns .njc-main-menu-item.active .njc-main-menu-item-dropwrap .njc-menu-item:hover .njc-menu-text,
  .njc-main-menu.njc-menu-uncollapse .njc-main-menu-dropdowns .njc-main-menu-item.active .njc-main-menu-item-dropwrap .njc-menu-item.active .njc-menu-text {
    color: var(--grayscale-0);
  }
  .njc-body {
    margin-left: 64px;
    transition: margin-left 0.2s;
  }
  .njc-body.njc-menu-uncollapse {
    margin-left: 240px;
  }
}
@media screen and (max-width: 767px) {
  .njc-login-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .njc-login-wrap .njc-login-left {
    z-index: 1;
    background-color: var(--grayscale-0);
    width: 90%;
    height: 95%;
    border-radius: 5px;
    box-shadow: 2px 2px 4px rgba(45, 53, 64, 0.1);
  }
  .njc-login-wrap .njc-login-left .njc-login-header .njc-login-header-desc .njc-login-mfa-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .njc-login-wrap .njc-login-left .njc-login-header .njc-login-header-desc .njc-login-mfa-wrap .njc-login-mfa-instruction {
    padding: 0 0 8px 0;
    text-align: center;
  }
  .njc-login-wrap .njc-login-right {
    position: fixed;
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-height: 767px) {
  .njc-main-menu .njc-main-menu-account {
    height: 80px;
    transition: all 0.2s;
  }
  .njc-main-menu .njc-main-menu-account .njc-agency-logo img,
  .njc-main-menu .njc-main-menu-account .njc-agency-logo svg {
    width: 40px;
    height: auto;
    transition: all 0.2s;
  }
  .njc-main-menu .njc-main-menu-account .njc-menu-info .njc-menu-title {
    font-size: 13px;
    transition: all 0.2s;
  }
  .njc-main-menu .njc-main-menu-account .njc-menu-info .njc-menu-subtext {
    font-size: 12px;
    transition: all 0.2s;
  }
  .njc-main-menu .njc-main-menu-dropdowns {
    height: calc(100% - 80px);
  }
  .njc-main-menu .njc-main-menu-item .njc-main-menu-item-label {
    height: 48px;
    transition: all 0.2s;
  }
  .njc-main-menu .njc-main-menu-item .njc-main-menu-item-label .njc-menu-icon svg {
    width: 18px;
    height: 18px;
    transition: all 0.2s;
  }
  .njc-main-menu .njc-main-menu-item .njc-main-menu-item-label .njc-menu-text {
    font-size: 13px;
    margin-left: 12px;
    transition: all 0.2s;
  }
  .njc-main-menu .njc-main-menu-item.active .njc-main-menu-item-dropwrap .njc-menu-item {
    min-height: 32px;
    max-height: 32px;
    font-size: 13px;
    padding: 0px 20px 0px 51px;
    transition: all 0.2s;
  }
}
body #cz_success_center_container {
  z-index: 10 !important;
}
body #cz_success_center_container .success-center-panel-frame-container.pos-left {
  left: 240px !important;
  transition: left 0.2s ease-in-out, right 0.2s ease-in-out !important;
  z-index: 10 !important;
}
@media screen and (max-width: 1140px) {
  body #cz_success_center_container .success-center-panel-frame-container.pos-left {
    left: 65px !important;
  }
  body.njc-body-uncollapse #cz_success_center_container .success-center-panel-frame-container.pos-left {
    left: 240px !important;
  }
}
/*Template Builder Resources*/
:root {
  --template-primary-color: #1E66F0;
  --template-primary-color-2: #EFF4FE;
  --template-primary-color-5: #E5EDFF;
  --template-primary-color-10: #CCDBFC;
  --template-primary-color-20: #99B5FA;
  --template-primary-color-50: #6691FA;
  --template-primary-color-variant: #1A5AD7;
  --template-canvas: #585E68;
  --template-border: #000000;
  --template-contour: #151C25;
  --template-hover: #202832;
  --template-main-100: #2d3540;
  --template-main-90: #424A58;
  --template-main-80: #545D6B;
  --template-main-70: #697280;
  --template-main-60: #7E8694;
  --template-main-50: #939AA7;
  --template-main-40: #A8AEBA;
  --template-main-30: #BDC2CC;
  --template-main-20: #D5DAE1;
  --template-main-10: #E7EAEF;
  --template-main-5: #F2F4F7;
  --template-main-3: #F9FAFB;
  --template-main-0: #FFFFFF;
  --template-modal-background: #2D354090;
}
/* New UI Template Editor Prebuilts*/
.njc-temp-right-add-widget-text {
  color: var(--template-main-10);
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-temp-right-panel-accord-border-2 {
  margin: 0px;
  background-color: var(--template-contour);
  border-radius: 0;
  padding: 0 13px;
  border-bottom: 1px solid var(--template-main-100);
  min-height: 28px;
  height: auto;
}
.njc-temp-color-select-wrap .colorpicker-bit-inner {
  width: 100%;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-temp-color-select-wrap .colorpicker-bit-inner .njc-temp-styling-left-label {
  width: 55px;
  display: flex !important;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-temp-color-select-wrap .colorpicker-bit-inner .sp-replacer {
  top: 0;
  z-index: 1;
}
.njc-temp-color-select-wrap .colorpicker-bit-inner .sp-replacer .sp-preview {
  width: 26px;
  height: 26px;
  border: 1px solid var(--template-contour);
  border-radius: 3px;
  overflow: hidden;
}
.njc-temp-color-select-wrap .colorpicker-bit-inner .sp-replacer .sp-preview .sp-preview-inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.njc-temp-color-select-wrap .colorpicker-bit-inner .sp-replacer .sp-preview .sp-preview-inner:after {
  content: " ";
  width: 15px;
  height: 15px;
  background-color: var(--grayscale-0);
  display: block;
  position: absolute;
  bottom: -8px;
  right: -8px;
  transform: rotate(45deg);
}
.njc-temp-color-select-wrap .colorpicker-bit-inner img {
  display: none;
}
.njc-temp-color-select-wrap .colorpicker-bit-inner .colorpicker-bit-inner {
  width: 26px;
  height: 26px;
  margin-left: -26px;
  margin-right: 0px;
  border: 1px solid var(--template-contour);
  border-radius: 3px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--template-hover) !important;
}
.njc-temp-color-select-wrap .colorpicker-bit-inner .colorpicker-bit-inner svg {
  width: 14px;
  height: 14px;
  background-color: var(--grayscale-0);
  border-radius: 3px;
}
.njc-body .nc-modal-full-page.njc-template-editor {
  align-items: center;
}
/* New UI Template Editor Styling*/
.njc-template-editor {
  overflow: visible !important;
}
.njc-template-editor input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.njc-template-editor input[type="number"]::-webkit-outer-spin-button,
.njc-template-editor input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.njc-template-editor .builder-brand-wrap,
.njc-template-editor .builder-brand-wrap-with-whitelabel {
  display: none;
}
.njc-template-editor .builder-navbar {
  height: 40px;
  width: calc(100% - 241px);
  background-color: var(--template-main-100);
  box-shadow: none;
  border-bottom: 1px solid var(--template-border);
  cursor: default;
}
.njc-template-editor .builder-navbar .document-title-text-wrap {
  max-width: calc(100% - 200px);
}
.njc-template-editor .builder-navbar .document-title-text {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  padding-left: 16px;
  color: var(--template-main-0);
  font-style: normal;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-template-editor .builder-navbar .document-title-text span {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 4px;
  cursor: pointer;
}
.njc-template-editor .builder-navbar .document-title-text .njc-temp-template-name-edit {
  display: none;
}
.njc-template-editor .builder-navbar .document-title-text:hover span {
  width: auto;
  max-width: calc(100% - 88px);
}
.njc-template-editor .builder-navbar .document-title-text:hover .njc-temp-template-name-edit {
  display: flex;
}
.njc-template-editor .builder-navbar .document-title-text:before {
  content: "Template: ";
  font-size: 12px;
  color: var(--template-main-60);
}
.njc-template-editor .builder-navbar .document-title-text.document-title-text-notes:before {
  content: "Account/Report/Template: ";
}
.njc-template-editor .builder-navbar .document-title-text .property-label {
  display: none;
}
.njc-template-editor .builder-navbar .document-title-text .setting-properties-wrap {
  background-color: transparent;
  width: calc(100% - 60px);
}
.njc-template-editor .builder-navbar .document-title-text .setting-properties-wrap .settings-panel-property-content {
  padding: 0;
  margin: 0;
}
.njc-template-editor .builder-navbar .document-title-text .setting-properties-wrap .settings-panel-property-content .w-input {
  width: 100%;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 11px;
  color: var(--template-main-20);
  font-weight: 400;
  text-align: left;
  line-height: 12px;
  border: 1px solid var(--template-contour);
  border-radius: 5px;
  background-color: var(--template-hover);
  height: 26px;
  background-image: none;
  padding: 0 8px;
  margin-bottom: 0px;
  margin-left: 4px;
}
.njc-template-editor .builder-navbar .document-title-text .njc-temp-template-name-edit {
  color: var(--template-main-50);
  width: 16px;
  height: 16px;
  margin-left: 8px;
  cursor: pointer;
}
.njc-template-editor .builder-navbar .select-account-data-field {
  padding: 0px;
  border-left: 1px solid #10151D;
  border-right: 1px solid #10151D;
  flex: 0 1 40% !important;
  max-width: 400px;
}
.njc-template-editor .builder-navbar .select-account-data-field .select2-selection--single {
  min-height: 39px !important;
  height: 39px;
  border: 0px solid #fff;
  background-color: transparent;
}
.njc-template-editor .builder-navbar .select-account-data-field .select2-selection--single .select2-selection__rendered {
  color: var(--template-main-30);
  font-size: 12px;
  line-height: 34px !important;
}
.njc-template-editor .builder-navbar .select-account-data-field .select2-selection {
  background-image: url("/images/njc/njc-account-grayscale-50.svg"), url("/images/njc/njc-icon-dropdown-medium.svg");
  padding-left: 40px;
  background-position: 14px 9px, calc(100% - 20px);
  background-repeat: no-repeat;
}
.njc-template-editor .builder-navbar .select-account-data-field .select2-container--open .select2-selection {
  background-color: var(--template-hover);
}
.njc-template-editor .builder-navbar .template-nav-button-wrap {
  flex-basis: auto !important;
  height: 40px;
  align-items: center;
}
.njc-template-editor .builder-navbar .template-nav-button-wrap .builder-nav-links {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #10151D;
}
.njc-template-editor .builder-navbar .template-nav-button-wrap .builder-nav-links:last-child {
  border-right: 0px solid #10151D;
}
.njc-template-editor .builder-navbar .template-nav-button-wrap .builder-nav-links svg {
  width: 18px;
  height: 18px;
  color: var(--template-main-50);
}
.njc-template-editor .builder-navbar .template-nav-button-wrap .builder-nav-links img {
  display: none;
}
.njc-template-editor .builder-navbar .template-nav-button-wrap .builder-nav-links.njc-template-save svg {
  color: var(--template-main-50);
}
.njc-template-editor .builder-navbar .template-nav-button-wrap .builder-nav-links.njc-template-saving .njc-temp-more-info-popup-wrap {
  display: flex !important;
  visibility: visible;
  opacity: 1;
  width: auto;
  height: auto;
  overflow: visible;
}
.njc-template-editor .builder-navbar .template-nav-button-wrap .builder-nav-links.njc-template-saving svg {
  color: var(--template-main-50);
}
.njc-template-editor .builder-navbar .template-nav-button-wrap .builder-nav-links.njc-template-saved svg {
  color: var(--success-color);
}
.njc-template-editor .builder-navbar .template-nav-button-wrap .builder-nav-links .njc-temp-more-info-popup-wrap {
  position: absolute;
  left: 50%;
  top: 35px;
  z-index: 2;
  display: block !important;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  transition-delay: 0.5s;
  top: 45px;
}
.njc-template-editor .builder-navbar .template-nav-button-wrap .builder-nav-links .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content {
  background-color: var(--template-main-5);
  border: 0px solid var(--template-contour);
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  position: relative;
  left: -50%;
  color: var(--template-main-100);
  z-index: 1;
  padding: 6px 8px;
  min-width: 76px;
  max-width: 140px;
  box-shadow: 2px 2px 4px 0 var(--template-border);
  white-space: normal;
}
.njc-template-editor .builder-navbar .template-nav-button-wrap .builder-nav-links .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border: 5px solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border-bottom-color: var(--template-main-5);
  z-index: 2;
}
.njc-template-editor .builder-navbar .template-nav-button-wrap .builder-nav-links .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content:before {
  bottom: 100%;
  left: 50%;
  border: 6px solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border-bottom-color: var(--template-contour);
  z-index: 0;
  margin-left: -6px;
}
.njc-template-editor .builder-navbar .template-nav-button-wrap .builder-nav-links:hover .njc-temp-more-info-popup-wrap {
  display: flex !important;
  visibility: visible;
  opacity: 1;
  width: auto;
  height: auto;
  overflow: visible;
}
.njc-template-editor .builder-wrap {
  margin-top: 40px;
  background-color: var(--template-canvas);
}
.njc-template-editor .builder-wrap .left-sidebar {
  background-color: var(--template-main-100);
  border-right: 1px solid var(--template-border);
  flex-basis: 280px;
}
.njc-template-editor .builder-wrap .left-sidebar .left-sidebar-heading {
  margin: 0px;
  height: 48px;
  border-bottom: 1px solid #10151D;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-top: 2px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 18px;
  font-weight: 700;
  color: var(--template-main-20);
  text-transform: capitalize;
}
.njc-template-editor .builder-wrap .left-sidebar .left-sidebar-overflow {
  max-height: calc(100% - 140px);
}
.njc-template-editor .builder-wrap .left-sidebar .left-sidebar-overflow::-webkit-scrollbar {
  width: 4px;
}
.njc-template-editor .builder-wrap .left-sidebar .left-sidebar-overflow::-webkit-scrollbar-track {
  background-color: transparent;
}
.njc-template-editor .builder-wrap .left-sidebar .left-sidebar-overflow::-webkit-scrollbar-thumb {
  background: var(--template-main-50);
  border: 0px solid transparent;
  border-radius: 4px;
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap {
  width: 100%;
  position: relative;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-image-wrap {
  height: 40px;
  padding-left: 16px;
  position: static;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-image-wrap .njc-widget-panel-icon {
  width: 18px;
  height: 18px;
  color: var(--template-main-30);
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-image-wrap .handle {
  color: transparent;
  width: 280px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  cursor: pointer;
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-image-wrap .page-number-text {
  width: 30px;
  height: 20px;
  border: 1px solid var(--template-main-30);
  color: var(--template-main-30);
  border-radius: 3px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  align-self: auto;
  font-size: 12px;
  font-weight: 700;
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-image-wrap .page-image {
  display: none;
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-image-wrap .trash-wrap,
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-image-wrap .lock-wrap {
  margin: 0px 8px 0 0;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-image-wrap .trash-wrap .njc-icon-button,
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-image-wrap .lock-wrap .njc-icon-button {
  height: 26px;
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-image-wrap .trash-wrap .njc-icon-button svg,
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-image-wrap .lock-wrap .njc-icon-button svg {
  color: var(--grayscale-60);
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-image-wrap .lock-wrap {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1003;
  width: 21px;
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap:hover .trash-wrap .njc-icon-button svg {
  display: block !important;
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-title-text {
  padding: 10px 28px 10px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-title-text .njc-temp-page-title-text {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  text-align: left;
  color: var(--template-main-40);
  font-weight: 600;
  font-size: 12px;
  align-items: center;
  justify-content: flex-start;
  padding: 1px 2px;
  max-width: 199px;
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-title-text .njc-temp-page-title-text::selection {
  background-color: var(--template-primary-color);
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-title-text .njc-temp-page-title-text span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-title-text input {
  max-width: 0px;
  height: 22px;
  z-index: 2;
  padding: 0px;
  outline: none;
  background-color: var(--template-main-100);
  border-radius: 3px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  text-align: left;
  color: var(--template-main-40);
  font-weight: 600;
  font-size: 12px;
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-title-text input::selection {
  background-color: var(--template-primary-color);
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-title-text.njc-temp-page-currently-edited .njc-temp-page-title-text {
  display: none !important;
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap .page-title-text.njc-temp-page-currently-edited input {
  max-width: 199px;
  width: 199px;
  border: 1px solid var(--template-border);
  padding: 0 2px;
  margin-left: -2px;
  display: flex;
  flex-direction: row;
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap:hover {
  background-color: var(--template-hover);
}
.njc-template-editor .builder-wrap .left-sidebar .image-title-wrap.selected {
  background-color: var(--template-hover);
  color: var(--template-main-5);
  box-shadow: inset 5px 0 0 0 var(--template-primary-color);
}
.njc-template-editor .builder-wrap .left-sidebar .section-list-item-selected .image-title-wrap {
  background-color: var(--template-contour);
  box-shadow: 4px 0 inset var(--template-primary-color);
}
.njc-template-editor .builder-wrap .left-sidebar .add-page-flow-row {
  margin: 0px;
}
.njc-template-editor .builder-wrap .left-sidebar .add-page-flow-row .button-dark-gray {
  width: calc(100% - 32px);
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  background-color: transparent;
  border-radius: 5px;
  border: 1px dashed var(--template-main-60);
  color: var(--template-main-50);
  height: 34px;
  margin: 10px 0 0 0;
  padding: 0 10px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}
.njc-template-editor .builder-wrap .left-sidebar .add-page-flow-row .button-dark-gray svg {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.njc-template-editor .builder-wrap .left-sidebar .add-page-flow-row .button-dark-gray:hover {
  border-color: var(--template-main-50);
  color: var(--template-main-20);
  cursor: pointer;
}
.njc-template-editor .builder-wrap .left-sidebar .njc-temp-left-help {
  padding-left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 38px;
  width: 280px;
  color: var(--template-main-40);
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 12px;
  font-weight: 400;
}
.njc-template-editor .builder-wrap .left-sidebar .njc-temp-left-help svg {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
.njc-template-editor .builder-wrap .left-sidebar .njc-temp-left-help:hover {
  color: var(--template-main-20);
  cursor: pointer;
}
.njc-template-editor .builder-wrap .left-sidebar .njc-page-wrap-tabs {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  padding: 6px 13px 0;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--template-contour);
  width: 100%;
}
.njc-template-editor .builder-wrap .left-sidebar .njc-page-wrap-tabs .njc-page-wrap-tabs-content {
  flex: 1 0 50%;
  height: 32px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  text-align: left;
  color: var(--template-main-20);
  font-weight: 700;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--template-main-30);
  border-bottom: 3px solid transparent;
}
.njc-template-editor .builder-wrap .left-sidebar .njc-page-wrap-tabs .njc-page-wrap-tabs-content svg {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.njc-template-editor .builder-wrap .left-sidebar .njc-page-wrap-tabs .njc-page-wrap-tabs-content.selected {
  color: var(--template-main-0);
  cursor: default;
  border-bottom: 3px solid var(--template-primary-color);
}
.njc-template-editor .builder-wrap .left-sidebar .njc-current-page-wrap {
  width: calc(100% - 20px);
  padding: 0 8px 16px;
  max-width: 280px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--template-contour);
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  text-align: left;
  color: var(--template-main-40);
  font-weight: 600;
  font-size: 12px;
}
.njc-template-editor .builder-wrap .left-sidebar .njc-current-page-wrap::selection {
  background-color: var(--template-primary-color);
}
.njc-template-editor .builder-wrap .left-sidebar .njc-current-page-wrap a {
  margin-right: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.njc-template-editor .builder-wrap .left-sidebar .njc-current-page-wrap a svg {
  color: var(--template-main-5);
}
.njc-template-editor .builder-wrap .left-sidebar .njc-current-page-wrap .njc-current-page-label {
  font-weight: 600;
  color: var(--template-main-5);
  text-transform: uppercase;
}
.njc-template-editor .builder-wrap .left-sidebar .njc-current-page-wrap .njc-current-page-name {
  font-weight: 400;
  color: var(--template-main-5);
}
.njc-template-editor .builder-wrap .left-sidebar:hover .njc-menu-collapse-button {
  opacity: 1;
}
.njc-template-editor .builder-wrap .center-area .grid-underlay {
  box-shadow: 2px 2px 4px rgba(45, 53, 64, 0.1);
}
.njc-template-editor .builder-wrap .center-area .transform-box .msg-area .message {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-template-editor .builder-wrap .center-area .support-bar {
  height: 40px;
  background-color: var(--template-main-10);
  border: 0px solid #fff;
  border-top: 1px solid var(--template-contour);
  padding: 0px;
}
.njc-template-editor .builder-wrap .center-area .support-bar button {
  margin: 0px;
  height: auto;
  padding: 0 12px;
  border: 0px solid #fff;
  outline: none;
  background-color: var(--template-main-20);
  width: 125px;
}
.njc-template-editor .builder-wrap .center-area .support-bar button span {
  font-weight: 600;
  color: var(--template-main-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .center-area .support-bar button span svg {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.njc-template-editor .builder-wrap .center-area .support-bar button span.template-editor-snap-off {
  color: var(--template-main-50);
}
.njc-template-editor .builder-wrap .right-sidebar {
  margin-top: -40px;
  max-width: 241px;
}
.njc-template-editor .builder-wrap .right-sidebar .inner-row-container {
  background-color: transparent;
}
.njc-template-editor .builder-wrap .right-sidebar .inner-row-container .inner-row-container {
  padding-left: 0 !important;
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu {
  height: 40px;
  min-height: 40px;
  background-color: var(--template-contour);
  padding-left: 0px;
  border-left: 1px solid var(--template-border);
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2 {
  padding: 0px;
  border: 0px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--template-border);
  border-bottom: 1px solid var(--template-border);
  font-family: "Georgia", serif;
  background-color: var(--template-contour);
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2 .panel-tab-icon-wrap {
  position: relative;
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2 .njc-temp-more-info-popup-wrap {
  position: absolute;
  left: 50%;
  top: 35px;
  z-index: 2;
  display: block !important;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  transition-delay: 0.5s;
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2 .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content {
  background-color: var(--template-main-5);
  border: 0px solid var(--template-contour);
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  position: relative;
  left: -50%;
  color: var(--template-main-100);
  z-index: 1;
  padding: 6px 8px;
  min-width: 76px;
  max-width: 140px;
  box-shadow: 2px 2px 4px 0 var(--template-border);
  white-space: normal;
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2 .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border: 5px solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border-bottom-color: var(--template-main-5);
  z-index: 2;
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2 .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content:before {
  bottom: 100%;
  left: 50%;
  border: 6px solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border-bottom-color: var(--template-contour);
  z-index: 0;
  margin-left: -6px;
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2 img {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2 svg {
  width: 18px;
  height: 18px;
  color: var(--template-main-10);
  margin: auto;
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2.w--current {
  background-color: var(--template-main-100);
  border-bottom: 1px solid var(--template-main-100);
  color: var(--template-main-10);
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2.w--current svg {
  color: var(--template-main-10);
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2.w--current:hover {
  background-color: var(--template-main-100);
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2:first-child .njc-temp-more-info-popup-wrap {
  left: 0;
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2:first-child .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content {
  left: 0;
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2:first-child .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content:before {
  left: 4;
  margin-left: 0;
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2:first-child .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content:after {
  left: 5px;
  margin-left: 0;
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2:last-child .njc-temp-more-info-popup-wrap {
  left: auto;
  right: 0;
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2:last-child .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content {
  left: auto;
  right: 0;
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2:last-child .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content:before {
  left: auto;
  right: 4;
  margin-left: 0;
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2:last-child .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content:after {
  left: auto;
  right: 5px;
  margin-left: 0;
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2:hover {
  background-color: transparent;
}
.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu .panel-tab-link2:hover .njc-temp-more-info-popup-wrap {
  display: flex !important;
  visibility: visible;
  opacity: 1;
  width: auto;
  height: auto;
  overflow: visible;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content {
  height: calc(100% - 40px) !important;
  border-left: 1px solid var(--template-contour);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane {
  overflow-y: overlay;
  background-color: var(--template-main-100);
  position: static;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane::-webkit-scrollbar {
  width: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane::-webkit-scrollbar-track {
  background-color: transparent;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane::-webkit-scrollbar-thumb {
  background: var(--template-main-50);
  border: 0px solid transparent;
  border-radius: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .ninja-date-range-string {
  margin: 0px;
  border: 1px solid var(--template-contour);
  border-radius: 5px;
  background-color: var(--template-main-90);
  padding: 8px 0;
  min-height: 26px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 11px;
  color: var(--template-main-20);
  font-weight: 400;
  text-align: left;
  line-height: 12px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .ninja-date-range-string span {
  color: inherit;
  padding: 0px;
  margin-left: 8px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .ninja-date-range-string img {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .ninja-date-range-string svg {
  width: 16px;
  height: 16px;
  min-width: 16px;
  margin-right: 6px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .w-input {
  width: 100%;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 11px;
  color: var(--template-main-20);
  font-weight: 400;
  text-align: left;
  line-height: 12px;
  border: 1px solid var(--template-contour);
  border-radius: 5px;
  background-color: var(--template-hover);
  height: 26px;
  background-image: none;
  padding: 0 8px;
  margin-bottom: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane select {
  width: 100%;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 11px;
  color: var(--template-main-20);
  font-weight: 400;
  text-align: left;
  line-height: 12px;
  border: 1px solid var(--template-contour);
  border-radius: 5px;
  background-color: var(--template-main-90);
  min-height: 26px;
  background-image: none;
  padding: 4px 8px;
  margin: 0px;
  appearance: none;
  background-image: url("/images/njc/njc-icon-dropdown-medium.svg");
  background-position: calc(100% - 6px);
  background-repeat: no-repeat;
  white-space: normal;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .multi-select .select2-selection--multiple {
  width: 100%;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 11px;
  color: var(--template-main-20);
  font-weight: 400;
  text-align: left;
  line-height: 12px;
  border: 1px solid var(--template-contour);
  border-radius: 5px;
  background-color: var(--template-main-90);
  min-height: 26px;
  background-image: none;
  padding: 4px 8px;
  margin: 0px;
  appearance: none;
  background-image: url("/images/njc/njc-icon-dropdown-medium.svg");
  background-position: calc(100% - 6px);
  background-repeat: no-repeat;
  white-space: normal;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .multi-select .select2-selection--multiple .select2-search {
  margin: 0;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .multi-select .select2-selection--multiple .select2-search__field {
  max-width: 190px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .multi-select .select2-selection--multiple .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  background-image: url(/images/njc/njc-small-close-grayscale-50.svg);
  width: 9px;
  height: 9px;
  margin-right: 10px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-form-toggle-default {
  padding-top: 5px;
  padding-bottom: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-form-toggle-default label {
  margin-bottom: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-form-toggle-default .toggle-text {
  padding-left: 0px;
  margin-left: 8px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-form-toggle-default .nc-toggle-switch {
  width: 36px;
  height: 20px;
  min-width: 36px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-form-toggle-default .nc-toggle-switch .nc-slider {
  width: 36px;
  background-color: var(--template-contour);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-form-toggle-default .nc-toggle-switch .nc-slider:before {
  width: 16px;
  height: 16px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-form-toggle-default .nc-toggle-switch input:checked + .nc-slider {
  background-color: var(--template-primary-color);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-form-toggle-default input:checked + .nc-slider:before {
  transform: translateX(14px);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-right-widgets-title {
  padding: 16px 13px 8px;
  width: 100%;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  text-align: left;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 700;
  color: var(--template-main-5);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-search-wrap {
  padding: 0px 13px;
  margin: 0px 0px 8px 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-search-wrap input {
  width: 100%;
  height: 34px;
  border: 1px solid var(--template-contour);
  background-color: var(--template-hover);
  padding-left: 35px;
  background-image: url("/images/njc/njc-icon-search.svg");
  background-position: 14px 50%;
  background-size: 14px 14px;
  background-repeat: no-repeat;
  color: var(--template-main-10);
  font-size: 12px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-search-wrap input::placeholder {
  color: var(--template-main-40);
  font-size: 12px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-element-2 {
  background-color: var(--template-contour);
  height: 36px;
  padding: 0 13px 1px;
  border: 0px solid #fff;
  border-bottom: 1px solid var(--template-main-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 0px solid transparent;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-element-2 .njc-temp-accordion-icon {
  width: 18px;
  height: 18px;
  color: var(--template-main-20);
  margin-right: 6px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-element-2 .njc-temp-accordion-arrow {
  width: 16px;
  height: 16px;
  color: var(--grayscale-20);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-element-2 .panel-head-text-2 {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  text-transform: capitalize;
  font-size: 14px;
  color: var(--template-main-10);
  font-weight: 700;
  padding: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-element-2 .direction-arrow-wrap {
  margin: 0 0 0 auto;
  min-width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-element-2 .direction-arrow-wrap img {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-element-2.inner-panel-element-2 {
  margin: 0px;
  background-color: var(--template-contour);
  border-radius: 0;
  padding: 0 13px;
  border-bottom: 1px solid var(--template-main-100);
  min-height: 28px;
  height: auto;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-element-2.inner-panel-element-2 .panel-head-text-2 {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  text-transform: capitalize;
  font-size: 12px;
  color: var(--template-main-10);
  font-weight: 700;
  padding: 4px 0px;
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-element-2.direction-arrow-closed .njc-temp-accordion-arrow {
  transition: 0.1s all;
  transform: rotate(-90deg);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-element-2.direction-arrow-open .njc-temp-accordion-arrow {
  transition: 0.1s all;
  transform: rotate(0deg);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 {
  padding: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel {
  padding: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .element-row {
  margin: 0px;
  background-color: var(--template-main-100);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .element-row .element-icon-wrap {
  flex: 0 0 33.33%;
  justify-content: center;
  height: 94px;
  border-right: 1px solid var(--template-contour);
  border-bottom: 1px solid var(--template-contour);
  padding: 5px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .element-row .element-icon-wrap .element-icon-image {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .element-row .element-icon-wrap svg {
  width: 36px;
  height: 36px;
  color: var(--template-main-30);
  margin-top: 6px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .element-row .element-icon-wrap .element-icon-text {
  color: var(--template-main-10);
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  margin-top: 12px;
  min-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .element-row .element-icon-wrap:hover {
  background-color: var(--template-hover);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row {
  margin: 0px;
  background-color: transparent;
  border-bottom: 0px solid var(--template-border);
  padding-bottom: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget {
  padding-top: 12px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .colorpicker-bit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .colorpicker-bit .colorpicker-bit-inner {
  width: 100%;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .colorpicker-bit .colorpicker-bit-inner .njc-temp-styling-left-label {
  width: 55px;
  display: flex !important;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .colorpicker-bit .colorpicker-bit-inner .sp-replacer {
  top: 0;
  z-index: 1;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .colorpicker-bit .colorpicker-bit-inner .sp-replacer .sp-preview {
  width: 26px;
  height: 26px;
  border: 1px solid var(--template-contour);
  border-radius: 3px;
  overflow: hidden;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .colorpicker-bit .colorpicker-bit-inner .sp-replacer .sp-preview .sp-preview-inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .colorpicker-bit .colorpicker-bit-inner .sp-replacer .sp-preview .sp-preview-inner:after {
  content: " ";
  width: 15px;
  height: 15px;
  background-color: var(--grayscale-0);
  display: block;
  position: absolute;
  bottom: -8px;
  right: -8px;
  transform: rotate(45deg);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .colorpicker-bit .colorpicker-bit-inner img {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .colorpicker-bit .colorpicker-bit-inner .colorpicker-bit-inner {
  width: 26px;
  height: 26px;
  margin-left: -26px;
  margin-right: 0px;
  border: 1px solid var(--template-contour);
  border-radius: 3px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--template-hover) !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .colorpicker-bit .colorpicker-bit-inner .colorpicker-bit-inner svg {
  width: 14px;
  height: 14px;
  background-color: var(--grayscale-0);
  border-radius: 3px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .colorpicker-bit .njc-temp-styling-left-label {
  width: 55px;
  display: flex !important;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .njc-temp-horizontal-align-wrap {
  display: flex;
  flex-direction: row;
  margin: 4px 0;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .njc-temp-horizontal-align-wrap .setting-properties-wrap {
  display: flex;
  flex-direction: row;
  padding-right: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .njc-temp-horizontal-align-wrap .setting-properties-wrap .property-label {
  min-width: 55px;
  align-items: center;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .njc-temp-horizontal-align-wrap .setting-properties-wrap .w-input {
  width: 38px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .njc-temp-horizontal-align-wrap .border-style-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .njc-temp-horizontal-align-wrap .border-style-buttons .border-style-button-single {
  width: 53px;
  height: 28px;
  border: 1px solid var(--template-contour);
  border-right: 0px;
  background-color: var(--template-main-90);
  color: var(--template-main-20);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .njc-temp-horizontal-align-wrap .border-style-buttons .border-style-button-single:first-child {
  border-radius: 5px 0 0 5px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .njc-temp-horizontal-align-wrap .border-style-buttons .border-style-button-single:last-child {
  border-radius: 0 5px 5px 0;
  border-right: 1px solid var(--template-contour);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .njc-temp-horizontal-align-wrap .border-style-buttons .border-style-button-single.selected {
  background-color: var(--template-hover);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .njc-temp-horizontal-align-wrap .border-style-buttons .border-style-button-single .border-style-button-single-icon {
  width: 18px;
  height: 1px;
  border-width: 2px 0 0 0;
  border-color: var(--template-main-20);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-shape-widget .njc-temp-widget-column-select-option {
  margin-top: -12px;
  margin-bottom: 12px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-right-properties-wrap {
  padding: 8px 0;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-right-properties-wrap .setting-properties-wrap {
  border-bottom: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-right-properties-wrap .font-style-bit .setting-properties-wrap {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .settings-properties-row .property-label {
  padding: 8px 13px 2px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .settings-properties-row .property-label > div {
  padding: 4px 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap {
  background-color: transparent;
  padding: 0px 13px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .property-label {
  padding: 4px 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .property-label .njc-temp-right-add-metric-wrap {
  position: relative;
  cursor: pointer;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .property-label .njc-temp-right-add-metric-wrap .njc-temp-right-add-widget-main-button {
  color: var(--template-main-50);
  z-index: 2;
  padding-left: 2px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .property-label .njc-temp-right-add-metric-wrap .njc-temp-right-add-widget-main-button:hover {
  color: var(--template-main-0);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .property-label .njc-temp-right-add-metric-wrap svg {
  width: 18px;
  height: 16px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .property-label .njc-temp-right-add-metric-wrap .njc-temp-right-more-action-widget-button {
  color: var(--template-main-50);
  z-index: 2;
  padding: 0 2px;
  visibility: hidden;
  width: 20px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .property-label .njc-temp-right-add-metric-wrap .njc-temp-right-more-action-widget-button:hover {
  color: var(--template-main-0);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .property-label .njc-temp-right-add-metric-wrap .njc-temp-right-add-metric-multi-wrap {
  display: none;
  position: absolute;
  top: 5px;
  right: 20px;
  z-index: 3;
  padding: 22px 3px 3px 3px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .property-label .njc-temp-right-add-metric-wrap .njc-temp-right-add-metric-multi-wrap .njc-temp-right-add-metric-multi-content {
  background-color: var(--template-main-90);
  border: 1px solid var(--template-contour);
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 2px 2px 4px rgba(45, 53, 64, 0.1);
  color: var(--template-main-0);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .property-label .njc-temp-right-add-metric-wrap .njc-temp-right-add-metric-multi-wrap .njc-temp-right-add-metric-multi-content:after {
  top: 13px;
  right: 6px;
  margin-left: -2px;
  border: 5px solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border-bottom-color: var(--template-main-90);
  z-index: 2;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .property-label .njc-temp-right-add-metric-wrap .njc-temp-right-add-metric-multi-wrap .njc-temp-right-add-metric-multi-content:before {
  top: 11px;
  right: 5px;
  border: 6px solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border-bottom-color: var(--template-contour);
  z-index: 0;
  margin-left: -3px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .property-label .njc-temp-right-add-metric-wrap .njc-temp-right-add-metric-multi-wrap .njc-temp-right-add-metric-multi-content .njc-temp-right-add-metric-item {
  width: 150px;
  height: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 8px;
  border-bottom: 1px solid var(--template-contour);
  white-space: nowrap;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .property-label .njc-temp-right-add-metric-wrap .njc-temp-right-add-metric-multi-wrap .njc-temp-right-add-metric-multi-content .njc-temp-right-add-metric-item:first-child {
  border-radius: 5px 3px 0 0;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .property-label .njc-temp-right-add-metric-wrap .njc-temp-right-add-metric-multi-wrap .njc-temp-right-add-metric-multi-content .njc-temp-right-add-metric-item:last-child {
  border-bottom: 0px;
  border-radius: 0 0 5px 5px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .property-label .njc-temp-right-add-metric-wrap .njc-temp-right-add-metric-multi-wrap .njc-temp-right-add-metric-multi-content .njc-temp-right-add-metric-item svg {
  margin-right: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .property-label .njc-temp-right-add-metric-wrap .njc-temp-right-add-metric-multi-wrap .njc-temp-right-add-metric-multi-content .njc-temp-right-add-metric-item:hover {
  background-color: var(--template-main-80);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .property-label .njc-temp-right-add-metric-wrap .active {
  display: flex;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .property-label .options-wrap {
  margin: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .settings-panel-property-content {
  margin: 4px 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 11px;
  color: var(--template-main-5);
  font-weight: 700;
  text-align: left;
  background-color: transparent;
  min-height: 26px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .settings-panel-property-content .has-error {
  color: var(--template-main-0);
  font-style: italic;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .settings-panel-property-content .njc-property-content-buttons {
  margin-left: auto;
  visibility: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .settings-panel-property-content .njc-property-content-buttons .property-icon-wrap {
  color: inherit;
  min-width: 20px;
  max-width: 20px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .settings-panel-property-content .njc-property-content-buttons svg {
  width: 18px;
  height: 18px;
  margin-left: 4px;
  color: var(--template-main-50);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .settings-panel-property-content:hover {
  top: 0px;
  cursor: default;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .settings-panel-property-content:hover .njc-property-content-buttons {
  visibility: visible;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .settings-panel-add-property-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .add-property-button {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  background-color: transparent;
  border-radius: 5px;
  border: 1px dashed var(--template-main-60);
  color: var(--template-main-50);
  height: 34px;
  margin: 10px 0 0 0;
  padding: 0 10px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  width: 100%;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .add-property-button svg {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .add-property-button:hover {
  border-color: var(--template-main-50);
  color: var(--template-main-20);
  cursor: pointer;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .add-property-button .direction-arrow-wrap {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-coverpage .setting-properties-wrap {
  padding: 0px;
  border-bottom: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-coverpage .setting-properties-wrap .property-label {
  padding: 4px 0 3px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-coverpage .setting-properties-wrap .settings-panel-property-content {
  margin: 0 0 6px 0;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-coverpage .setting-properties-wrap .settings-panel-property-content .inline-property-label {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-coverpage .setting-properties-wrap .settings-panel-property-content .setting-properties-wrap {
  flex: 1;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-coverpage .setting-properties-wrap .settings-panel-property-content .setting-properties-wrap .nc-ctx-drop-down-bit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-coverpage .setting-properties-wrap .settings-panel-property-content .setting-properties-wrap .nc-ctx-drop-down-bit .njc-temp-styling-left-label {
  width: 55px;
  display: flex !important;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-coverpage .setting-properties-wrap .settings-panel-property-content .setting-properties-wrap .nc-ctx-drop-down-bit .settings-panel-property-dropdown {
  flex: 1;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-coverpage .setting-properties-wrap .colorpicker-bit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-coverpage .setting-properties-wrap .colorpicker-bit .colorpicker-bit-inner {
  width: 100%;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-coverpage .setting-properties-wrap .colorpicker-bit .colorpicker-bit-inner .njc-temp-styling-left-label {
  width: 55px;
  display: flex !important;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-coverpage .setting-properties-wrap .colorpicker-bit .colorpicker-bit-inner .sp-replacer {
  top: 0;
  z-index: 1;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-coverpage .setting-properties-wrap .colorpicker-bit .colorpicker-bit-inner .sp-replacer .sp-preview {
  width: 26px;
  height: 26px;
  border: 1px solid var(--template-contour);
  border-radius: 3px;
  overflow: hidden;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-coverpage .setting-properties-wrap .colorpicker-bit .colorpicker-bit-inner .sp-replacer .sp-preview .sp-preview-inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-coverpage .setting-properties-wrap .colorpicker-bit .colorpicker-bit-inner .sp-replacer .sp-preview .sp-preview-inner:after {
  content: " ";
  width: 15px;
  height: 15px;
  background-color: var(--grayscale-0);
  display: block;
  position: absolute;
  bottom: -8px;
  right: -8px;
  transform: rotate(45deg);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-coverpage .setting-properties-wrap .colorpicker-bit .colorpicker-bit-inner img {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-coverpage .setting-properties-wrap .colorpicker-bit .colorpicker-bit-inner .colorpicker-bit-inner {
  width: 26px;
  height: 26px;
  margin-left: -26px;
  margin-right: 0px;
  border: 1px solid var(--template-contour);
  border-radius: 3px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--template-hover) !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-coverpage .setting-properties-wrap .colorpicker-bit .colorpicker-bit-inner .colorpicker-bit-inner svg {
  width: 14px;
  height: 14px;
  background-color: var(--grayscale-0);
  border-radius: 3px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-coverpage .setting-properties-wrap .colorpicker-bit input[type="checkbox"] {
  position: static !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-column-select-option {
  padding: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-column-select-option .njc-temp-widget-column-select-option-content {
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: var(--template-main-10);
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-column-select-option .njc-temp-widget-column-select-option-content-h1,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-column-select-option .njc-temp-widget-column-select-option-content-h2,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-column-select-option .njc-temp-widget-column-select-option-content-h3,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-column-select-option .njc-temp-widget-column-select-option-content-h4,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-column-select-option .njc-temp-widget-column-select-option-content-h5,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-column-select-option .njc-temp-widget-column-select-option-content-h6 {
  font-family: "Georgia", serif;
  font-weight: 700;
  min-height: 36px;
  color: var(--template-main-0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-column-select-option .njc-temp-widget-column-select-option-content-h1 {
  font-size: 28px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-column-select-option .njc-temp-widget-column-select-option-content-h2 {
  font-size: 24px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-column-select-option .njc-temp-widget-column-select-option-content-h3 {
  font-size: 20px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-column-select-option .njc-temp-widget-column-select-option-content-h4 {
  font-size: 18px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-column-select-option .njc-temp-widget-column-select-option-content-h5 {
  font-size: 16px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap.njc-temp-widget-column-select-option .njc-temp-widget-column-select-option-content-h6 {
  font-size: 14px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image {
  padding: 8px 13px 6px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .setting-properties-wrap {
  padding: 0px;
  border-bottom: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .setting-properties-wrap .property-label {
  padding: 4px 0 3px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .setting-properties-wrap .settings-panel-property-content {
  margin: 0 0 6px 0;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .setting-properties-wrap .settings-panel-property-content .inline-property-label {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .setting-properties-wrap .settings-panel-property-content .setting-properties-wrap {
  flex: 1;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .setting-properties-wrap .settings-panel-property-content .setting-properties-wrap .nc-ctx-drop-down-bit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .setting-properties-wrap .settings-panel-property-content .setting-properties-wrap .nc-ctx-drop-down-bit .njc-temp-styling-left-label {
  width: 55px;
  display: flex !important;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .setting-properties-wrap .settings-panel-property-content .setting-properties-wrap .nc-ctx-drop-down-bit .settings-panel-property-dropdown {
  flex: 1;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .setting-properties-wrap .colorpicker-bit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .setting-properties-wrap .colorpicker-bit .colorpicker-bit-inner {
  width: 100%;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .setting-properties-wrap .colorpicker-bit .colorpicker-bit-inner .njc-temp-styling-left-label {
  width: 55px;
  display: flex !important;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .setting-properties-wrap .colorpicker-bit .colorpicker-bit-inner .sp-replacer {
  top: 0;
  z-index: 1;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .setting-properties-wrap .colorpicker-bit .colorpicker-bit-inner .sp-replacer .sp-preview {
  width: 26px;
  height: 26px;
  border: 1px solid var(--template-contour);
  border-radius: 3px;
  overflow: hidden;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .setting-properties-wrap .colorpicker-bit .colorpicker-bit-inner .sp-replacer .sp-preview .sp-preview-inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .setting-properties-wrap .colorpicker-bit .colorpicker-bit-inner .sp-replacer .sp-preview .sp-preview-inner:after {
  content: " ";
  width: 15px;
  height: 15px;
  background-color: var(--grayscale-0);
  display: block;
  position: absolute;
  bottom: -8px;
  right: -8px;
  transform: rotate(45deg);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .setting-properties-wrap .colorpicker-bit .colorpicker-bit-inner img {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .setting-properties-wrap .colorpicker-bit .colorpicker-bit-inner .colorpicker-bit-inner {
  width: 26px;
  height: 26px;
  margin-left: -26px;
  margin-right: 0px;
  border: 1px solid var(--template-contour);
  border-radius: 3px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--template-hover) !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .setting-properties-wrap .colorpicker-bit .colorpicker-bit-inner .colorpicker-bit-inner svg {
  width: 14px;
  height: 14px;
  background-color: var(--grayscale-0);
  border-radius: 3px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .setting-properties-wrap .colorpicker-bit input[type="checkbox"] {
  position: static !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .image-url-bit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .image-url-bit .settings-panel-property-content {
  margin: 0px;
  padding: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .image-url-bit .settings-panel-property-content:first-child {
  flex: 1;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .image-url-bit .settings-panel-property-content input[type="text"] {
  margin-right: 8px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .image-url-bit .settings-panel-property-content .image-url-button {
  margin-bottom: 4px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 11px;
  color: var(--template-main-5);
  font-weight: 700;
  text-align: left;
  text-transform: lowercase;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .image-url-bit .settings-panel-property-content .image-url-button .has-error {
  color: var(--template-main-0);
  font-style: italic;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-widget-image .image-url-bit .settings-panel-property-content .image-url-button:first-letter {
  text-transform: capitalize;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-right-data-row {
  border-bottom: 1px solid var(--template-border);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-right-data-row .setting-properties-wrap {
  padding: 8px 13px 10px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-right-data-row .setting-properties-wrap .settings-panel-property-content {
  border-radius: 3px;
  padding: 4px 10px;
  margin: 4px 0px 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 28px;
  background-color: var(--template-primary-color);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-right-data-row .setting-properties-wrap .settings-panel-property-content:hover {
  background-color: var(--template-primary-color-variant);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-right-data-row .setting-properties-wrap .settings-panel-property-content.njc-temp-right-item-warning {
  background-color: var(--critical-color) !important;
  color: var(--template-main-0);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-right-data-row .setting-properties-wrap .settings-panel-property-content.njc-temp-right-item-warning .property-icon-wrap {
  order: 1;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-right-data-row .setting-properties-wrap .settings-panel-property-content.njc-temp-right-item-warning .property-icon-wrap.njc-temp-warning-icon {
  order: 2;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-right-data-row .setting-properties-wrap .settings-panel-property-content.njc-temp-right-item-warning .property-icon-wrap.njc-temp-warning-icon:hover .njc-temp-more-info-popup-wrap {
  position: absolute;
  left: 50%;
  top: 35px;
  z-index: 2;
  display: block !important;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  transition-delay: 0.5s;
  display: flex !important;
  left: auto;
  right: -2px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-right-data-row .setting-properties-wrap .settings-panel-property-content.njc-temp-right-item-warning .property-icon-wrap.njc-temp-warning-icon:hover .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content {
  background-color: var(--template-main-5);
  border: 0px solid var(--template-contour);
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  position: relative;
  left: -50%;
  color: var(--template-main-100);
  z-index: 1;
  padding: 6px 8px;
  min-width: 76px;
  max-width: 140px;
  box-shadow: 2px 2px 4px 0 var(--template-border);
  white-space: normal;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-right-data-row .setting-properties-wrap .settings-panel-property-content.njc-temp-right-item-warning .property-icon-wrap.njc-temp-warning-icon:hover .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border: 5px solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border-bottom-color: var(--template-main-5);
  z-index: 2;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-right-data-row .setting-properties-wrap .settings-panel-property-content.njc-temp-right-item-warning .property-icon-wrap.njc-temp-warning-icon:hover .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content:before {
  bottom: 100%;
  left: 50%;
  border: 6px solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border-bottom-color: var(--template-contour);
  z-index: 0;
  margin-left: -6px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-right-data-row .setting-properties-wrap .settings-panel-property-content.njc-temp-right-item-warning .property-icon-wrap.njc-temp-warning-icon:hover .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content {
  left: auto;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-right-data-row .setting-properties-wrap .settings-panel-property-content.njc-temp-right-item-warning .property-icon-wrap.njc-temp-warning-icon:hover .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content:before {
  left: auto;
  right: 1px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-right-data-row .setting-properties-wrap .settings-panel-property-content.njc-temp-right-item-warning .property-icon-wrap.njc-temp-warning-icon:hover .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content:after {
  left: auto;
  right: 2px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row.njc-temp-right-data-row:hover .njc-temp-right-more-action-widget-button {
  visibility: visible !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .njc-temp-right-empty-widget {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-30);
  margin: 0px 13px;
  padding: 18px;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--template-main-90);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 10px 13px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .njc-temp-right-empty-widget svg {
  width: 24px;
  height: 24px;
  margin: 0 auto 6px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .element-widget-row {
  border: 0px solid var(--template-contour);
  border-bottom: 1px solid var(--template-contour);
  background-color: var(--template-main-100);
  min-height: 40px;
  padding: 6px 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .element-widget-row .left-element-widget-wrap {
  flex-basis: 39px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .element-widget-row .left-element-widget-wrap .element-widget-icon-wrap {
  margin: 0 0 0 13px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .element-widget-row .left-element-widget-wrap .element-widget-icon-wrap svg {
  color: var(--template-main-0);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .element-widget-row .center-element-widget-wrap {
  padding-left: 8px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .element-widget-row .element-widget-text {
  color: var(--template-main-10);
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .element-widget-row .element-widget-icon-wrap {
  margin-right: 13px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .element-widget-row .element-widget-icon-wrap .element-widget-image {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .element-widget-row:hover {
  background-color: var(--template-hover);
  cursor: pointer !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .element-widget-row:hover .njc-icon-button svg {
  display: block !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .njc-template-aggregate {
  padding: 4px 12px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab {
  padding: 0px 13px;
  display: flex;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .w-form {
  width: 100%;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .w-form .w-tabs {
  margin: 0 -13px;
  width: calc(100% + 26px);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .w-form .w-tabs .w-tab-menu {
  border-left: 0px;
  background-color: transparent;
  border-top: 1px solid var(--template-border);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .w-form .w-tabs .w-tab-menu .panel-tab-link2 {
  background-color: var(--template-hover);
  border-bottom: 1px solid var(--template-border);
  border-right: 1px solid var(--template-border);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .w-form .w-tabs .w-tab-menu .panel-tab-link2.w--current {
  background-color: transparent;
  border-bottom: 1px solid transparent;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .w-form .w-tabs .w-tab-menu .panel-tab-link2:last-child {
  border-right: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .w-form .w-tabs .njc-temp-right-tab-title {
  padding: 12px 13px 4px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 12px;
  font-weight: 500;
  color: var(--template-main-10);
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .w-form .w-tabs .w-tab-content {
  background-color: transparent !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .w-form .w-tabs .w-tab-content .settings-properties-row {
  border-bottom: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .njc-template-aggregate .metric-row,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .metric-row {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  text-align: left;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  color: var(--template-main-10);
  margin: 4px 0;
  background-color: var(--template-main-100);
  padding: 0px;
  border: 0px solid #fff;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .njc-template-aggregate .metric-row.no-hover,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .metric-row.no-hover {
  cursor: default;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  text-transform: capitalize;
  font-size: 12px;
  color: var(--template-main-10);
  font-weight: 700;
  padding: 4px 0px;
  text-align: left;
  margin: 0px;
  background-color: var(--template-contour);
  border-radius: 0;
  padding: 0 13px;
  border-bottom: 1px solid var(--template-main-100);
  min-height: 28px;
  height: auto;
  align-items: center;
  margin: -4px -13px 0;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .njc-template-aggregate .settings-properties-row.SORT_BY,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .settings-properties-row.SORT_BY,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .njc-template-aggregate .settings-properties-row.ACTION_ATTRIBUTION_WINDOWS,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .settings-properties-row.ACTION_ATTRIBUTION_WINDOWS {
  display: flex;
  flex-direction: column;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .njc-template-aggregate .settings-properties-row.SORT_BY .option-item,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .settings-properties-row.SORT_BY .option-item,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .njc-template-aggregate .settings-properties-row.ACTION_ATTRIBUTION_WINDOWS .option-item,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .settings-properties-row.ACTION_ATTRIBUTION_WINDOWS .option-item {
  width: 100%;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .njc-template-aggregate .settings-properties-row.FORCE_UNIQUE,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .settings-properties-row.FORCE_UNIQUE,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .njc-template-aggregate .settings-properties-row.zeroImpressionReporting,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .settings-properties-row.zeroImpressionReporting {
  flex-direction: row;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .njc-template-aggregate .settings-properties-row.FORCE_UNIQUE .option-item,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .settings-properties-row.FORCE_UNIQUE .option-item,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .njc-template-aggregate .settings-properties-row.zeroImpressionReporting .option-item,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .settings-properties-row.zeroImpressionReporting .option-item {
  order: 1;
  margin-right: 8px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .njc-template-aggregate .settings-properties-row.FORCE_UNIQUE .setting-properties-wrap,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .settings-properties-row.FORCE_UNIQUE .setting-properties-wrap,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .njc-template-aggregate .settings-properties-row.zeroImpressionReporting .setting-properties-wrap,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .settings-properties-row.zeroImpressionReporting .setting-properties-wrap {
  order: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .njc-template-aggregate .settings-properties-row:last-child,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .settings-properties-row:last-child {
  border-bottom: 0px solid #fff;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .njc-template-aggregate .setting-properties-wrap,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .setting-properties-wrap {
  padding: 0px;
  border-bottom: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .njc-template-aggregate .setting-properties-wrap .settings-panel-property-content,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .setting-properties-wrap .settings-panel-property-content {
  padding: 0px;
  background-color: transparent !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .njc-template-aggregate .gray-back.property-label,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .property-tab .gray-back.property-label {
  padding: 4px 0px;
  background-color: transparent;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .font-style-bit .setting-properties-wrap {
  padding: 8px 13px 6px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .font-style-bit .setting-properties-wrap .setting-properties-wrap {
  padding: 0px;
  border-bottom: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .font-style-bit .setting-properties-wrap .setting-properties-wrap .settings-panel-property-content {
  padding: 0px;
  background-color: transparent !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .font-style-bit.njc-temp-border-wrap .njc-temp-styling-wrap .colorpicker-bit {
  order: 1;
  flex: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .font-style-bit.njc-temp-border-wrap .njc-temp-styling-wrap .font-size-wrap {
  margin-bottom: 6px;
  z-index: 1;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .font-style-bit.njc-temp-border-wrap .njc-temp-styling-wrap .font-size-wrap:after {
  content: "px";
  height: 26px;
  width: 20px;
  text-transform: uppercase;
  z-index: 2;
  margin-left: -25px;
  color: var(--template-main-20);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .font-style-bit.njc-temp-border-wrap .njc-temp-styling-wrap .font-size-wrap input[type="number"] {
  padding-right: 30px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal {
  z-index: 1010;
  background-color: rgba(45, 53, 64, 0.9);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container {
  padding: 0px;
  width: 500px;
  border-radius: 8px;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content {
  padding: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .property-label {
  padding: 20px 0 0 0;
  color: var(--grayscale-50);
  font-weight: 600;
  font-size: 14px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap {
  padding: 0;
  border: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .property-label {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-80);
  text-transform: capitalize;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content {
  margin: 8px 0 0 0;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content input[type="text"],
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content input[type="number"],
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content select {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  background-color: var(--grayscale-0);
  line-height: 16px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content input[type="text"]::placeholder,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content input[type="number"]::placeholder,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content select::placeholder {
  color: var(--grayscale-70);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content input[type="text"]:hover,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content input[type="number"]:hover,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content select:hover {
  border: 1px solid var(--grayscale-50);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content input[type="text"]:active,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content input[type="number"]:active,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content select:active,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content input[type="text"]:focus,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content input[type="number"]:focus,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content select:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content input[type="text"]:disabled,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content input[type="number"]:disabled,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content select:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content input[type="text"][type="number"],
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content input[type="number"][type="number"],
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content select[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content input[type="text"][type="number"]::-webkit-outer-spin-button,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content input[type="number"][type="number"]::-webkit-outer-spin-button,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content select[type="number"]::-webkit-outer-spin-button,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content input[type="text"][type="number"]::-webkit-inner-spin-button,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content input[type="number"][type="number"]::-webkit-inner-spin-button,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content select[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content select {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  background-image: url("/images/njc/njc-icon-dropdown.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 35px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content select::-ms-expand {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content select:disabled {
  background-image: url("/images/njc/njc-icon-dropdown-disabled.svg");
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .setting-properties-wrap .settings-panel-property-content.abbrev-selection {
  margin: 8px 8px 0 0;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-buttons-wrap {
  justify-content: flex-end !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-buttons-wrap .button-blue {
  font-weight: 400;
  font-size: 12px;
  color: var(--primary-color);
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  cursor: pointer;
  border: 0px;
  background-color: transparent;
  text-transform: capitalize;
  padding: 10px 0px 0px 20px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-buttons-wrap .button-blue-med {
  font-size: 13px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-buttons-wrap .button-blue-med:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-buttons-wrap .button-blue-med svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  margin-right: 10px;
  color: var(--primary-color);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-buttons-wrap .button-blue-larger {
  font-size: 14px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-buttons-wrap .button-blue-larger:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-buttons-wrap .button-blue-larger svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  color: var(--primary-color);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-buttons-wrap .button-blue:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-form-footer-row {
  padding: 28px 0 0 0;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-form-footer-row .njc-form-footer-row-container {
  border: 0px;
  padding: 0px;
  margin: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap {
  max-height: none !important;
  overflow-y: visible !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .njc-temp-heading-3 {
  font-weight: 700;
  font-size: 18px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap {
  display: flex;
  flex-direction: column;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .replacement-overflow {
  overflow-y: auto;
  max-height: 250px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .replacement-overflow::-webkit-scrollbar {
  width: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .replacement-overflow::-webkit-scrollbar-track {
  background-color: transparent;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .replacement-overflow::-webkit-scrollbar-thumb {
  background: var(--template-main-50);
  border: 0px solid transparent;
  border-radius: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content:first-child {
  margin-right: 10px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-label {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-80);
  text-transform: capitalize;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-input {
  padding: 0px !important;
  width: 100%;
  position: relative;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-input span {
  color: var(--grayscale-60);
  font-size: 14px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-input span:first-child {
  position: absolute;
  left: 8px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-input span:nth-child(2) {
  position: absolute;
  right: 12px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-input span:last-child {
  position: absolute;
  right: 8px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-checkbox-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-checkbox-wrap b {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-checkbox-wrap input[type="checkbox"] {
  margin: 0px 6px 0 0;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-checkbox-wrap .njc-temp-modal-find-repalce-checkbox-text {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  white-space: nowrap;
  margin-bottom: 0px;
  padding-right: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button {
  font-weight: 400;
  font-size: 12px;
  color: var(--primary-color);
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  border: 0px;
  background-color: transparent !important;
  height: auto !important;
  margin-top: 8px;
  margin-left: auto;
  padding: 0px;
  cursor: pointer;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button-med {
  font-size: 13px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button-med:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button-med svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  margin-right: 10px;
  color: var(--primary-color);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button-larger {
  font-size: 14px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button-larger:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button-larger svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  color: var(--primary-color);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-nodata {
  color: var(--grayscale-30);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-add {
  border-top: 1px solid var(--grayscale-30);
  padding-top: 10px;
  margin-top: 10px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-add button {
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
  padding: 0px 20px !important;
  height: 44px !important;
  min-width: 44px !important;
  border-radius: 5px !important;
  text-decoration: none !important;
  text-align: left !important;
  white-space: nowrap !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
  color: var(--grayscale-60) !important;
  background-color: var(--grayscale-0) !important;
  border: 1px dashed var(--grayscale-60) !important;
  width: 100% !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-add button svg {
  margin: 0px 8px 0px 0px !important;
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-add button svg.njc-icon-right-aligned {
  margin: 0px 0px 0px 8px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-add button svg.njc-icon-progress-arrow {
  width: 12px !important;
  height: 12px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-add button:disabled {
  background-color: var(--grayscale-60) !important;
  color: var(--grayscale-30) !important;
  border: 1px solid var(--grayscale-30) !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap .setting-properties-wrap .njc-temp-modal-find-replace-add button:disabled:hover {
  background-color: var(--grayscale-60) !important;
  cursor: not-allowed !important;
  color: var(--grayscale-30) !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-temp-modal-dimension-wrap .njc-temp-modal-find-replace-wrap hr {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .abbrev-description {
  margin: 0 12px;
  margin: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .abbrev-description .abbrev-description-title {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 11px;
  color: var(--template-main-5);
  font-weight: 700;
  text-align: left;
  color: var(--template-main-40);
  font-weight: 400;
  margin-left: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .abbrev-description .abbrev-description-title .has-error {
  color: var(--template-main-0);
  font-style: italic;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .abbrev-description .abbrev-description-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 11px;
  color: var(--template-main-5);
  font-weight: 700;
  text-align: left;
  color: var(--template-main-20);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .abbrev-description .abbrev-description-item .has-error {
  color: var(--template-main-0);
  font-style: italic;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .abbrev-description .abbrev-description-title,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .abbrev-description .abbrev-description-item {
  color: var(--grayscale-80);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .filter-and-clause .filter-or-clause {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .filter-and-clause .filter-or-clause .setting-properties-wrap {
  width: 100%;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .filter-and-clause .filter-or-clause .setting-properties-wrap:last-child {
  min-width: 60px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .filter-and-clause .filter-or-clause .setting-properties-wrap:last-child .settings-panel-property-content {
  color: var(--grayscale-80);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .filter-and-clause .filter-or-clause .setting-properties-wrap:last-child .settings-panel-property-content > span {
  padding-right: 8px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .filter-and-clause .filter-or-clause .setting-properties-wrap:last-child .settings-panel-property-content > span input {
  margin: 0 4px;
  min-width: 60px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .filter-and-clause .filter-or-clause .setting-properties-wrap:last-child .settings-panel-property-content > span input[type="checkbox"] {
  min-width: 20px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .filter-and-clause .filter-or-clause .setting-properties-wrap:last-child .settings-panel-property-content span:last-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .filter-and-clause .filter-or-clause .setting-properties-wrap:last-child .settings-panel-property-content span:last-child b {
  padding-right: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .card-footer-row {
  flex-direction: row;
  padding-bottom: 0px;
  border-top: 0px solid #FFFFFF;
  margin: 0 -40px;
  padding: 20px 40px 0px;
  justify-content: flex-end;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .card-footer-row .njc-btn-primary,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .card-footer-row .njc-btn-secondary {
  margin-left: 12px;
  font-size: 14px;
  padding: 0px 20px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap {
  flex-wrap: wrap;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-name-option {
  order: 1;
  width: 60%;
  padding-right: 10px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-hide-option {
  order: 3;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-hide-option .settings-panel-property-content {
  margin: 13px 0 0 0;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-hide-option .nc-toggle-switch .nc-slider:after {
  content: "Hide";
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-non-segmenting-option {
  order: 4;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-non-segmenting-option .settings-panel-property-content {
  margin: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-non-segmenting-option .nc-toggle-switch .nc-slider:after {
  content: "Non Segmenting";
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-hide-option,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-non-segmenting-option {
  width: 100%;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-hide-option .nc-toggle-switch input,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-non-segmenting-option .nc-toggle-switch input {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-hide-option .nc-toggle-switch input:checked + .nc-slider,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-non-segmenting-option .nc-toggle-switch input:checked + .nc-slider {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-image: url(/images/njc/njc-checkbox.svg);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-hide-option .nc-toggle-switch .nc-slider,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-non-segmenting-option .nc-toggle-switch .nc-slider {
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: 1px solid var(--grayscale-30);
  background-color: var(--grayscale-0);
  background-repeat: no-repeat;
  background-position: 3px 50%;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-hide-option .nc-toggle-switch .nc-slider:before,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-non-segmenting-option .nc-toggle-switch .nc-slider:before {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-hide-option .nc-toggle-switch .nc-slider:after,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-non-segmenting-option .nc-toggle-switch .nc-slider:after {
  margin-left: 25px;
  white-space: nowrap;
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-name-row-wrap .njc-custom-dimension-display-option {
  order: 2;
  width: 40%;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .property-label {
  font-weight: 700;
  font-size: 18px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate {
  margin: 0px;
  max-height: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-shrink {
  padding: 30px 5px 15px 0 !important;
  border-bottom: 1px solid var(--grayscale-30);
  min-width: 140px;
  text-align: left;
  font-weight: 700;
  font-size: 13px;
  color: var(--grayscale-60);
  text-transform: capitalize;
  text-decoration: none;
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-shrink.disabled {
  color: var(--grayscale-30);
  cursor: not-allowed;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-shrink.disabled:hover {
  color: var(--grayscale-30);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-shrink.w--current {
  color: var(--primary-color);
  border-radius: 5px;
  background-color: var(--secondary-color);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-shrink.w--current:hover {
  color: var(--primary-color);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand {
  padding: 15px 0 !important;
  border-bottom: 1px solid var(--grayscale-30);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap {
  border: 0px solid var(--grayscale-30) !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .select2 .select2-selection {
  min-height: 44px !important;
  border-radius: 5px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content:first-child {
  margin-right: 10px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-label {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-80);
  text-transform: capitalize;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-input {
  padding: 0px !important;
  width: 100%;
  position: relative;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-input span {
  color: var(--grayscale-60);
  font-size: 14px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-input span:first-child {
  position: absolute;
  left: 8px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-input span:nth-child(2) {
  position: absolute;
  right: 12px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-input span:last-child {
  position: absolute;
  right: 8px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-checkbox-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-checkbox-wrap b {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-checkbox-wrap input[type="checkbox"] {
  margin: 0px 6px 0 0;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content .njc-temp-modal-find-repalce-checkbox-wrap .njc-temp-modal-find-repalce-checkbox-text {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  white-space: nowrap;
  margin-bottom: 0px;
  padding-right: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button {
  font-weight: 400;
  font-size: 12px;
  color: var(--primary-color);
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  border: 0px;
  background-color: transparent !important;
  height: auto !important;
  margin-top: 8px;
  margin-left: auto;
  padding: 0px;
  cursor: pointer;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button-med {
  font-size: 13px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button-med:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button-med svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  margin-right: 10px;
  color: var(--primary-color);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button-larger {
  font-size: 14px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button-larger:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button-larger svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  color: var(--primary-color);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .replacement-overflow .njc-temp-modal-find-replace-entry-wrap .settings-panel-property-content button:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .njc-temp-modal-find-replace-add {
  margin-top: 10px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .njc-temp-modal-find-replace-add button {
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
  padding: 0px 20px !important;
  height: 44px !important;
  min-width: 44px !important;
  border-radius: 5px !important;
  text-decoration: none !important;
  text-align: left !important;
  white-space: nowrap !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
  color: var(--grayscale-60) !important;
  background-color: var(--grayscale-0) !important;
  border: 1px dashed var(--grayscale-60) !important;
  width: 100% !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .njc-temp-modal-find-replace-add button svg {
  margin: 0px 8px 0px 0px !important;
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .njc-temp-modal-find-replace-add button svg.njc-icon-right-aligned {
  margin: 0px 0px 0px 8px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .njc-temp-modal-find-replace-add button svg.njc-icon-progress-arrow {
  width: 12px !important;
  height: 12px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .njc-temp-modal-find-replace-add button:disabled {
  background-color: var(--grayscale-60) !important;
  color: var(--grayscale-30) !important;
  border: 1px solid var(--grayscale-30) !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .table-column-expand .setting-properties-wrap .njc-temp-modal-find-replace-add button:disabled:hover {
  background-color: var(--grayscale-60) !important;
  cursor: not-allowed !important;
  color: var(--grayscale-30) !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .custom-dimension-table-aggregate {
  border-collapse: collapse;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .custom-dimension-table-aggregate tr:last-child .table-column-shrink,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .njc-custom-dimension-aggregate-row-wrap .custom-dimension-body-aggregate .custom-dimension-table-aggregate tr:last-child .table-column-expand {
  border-bottom: 0px solid #fff;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal .Modal .Modal__container .Modal__content .nc-date-range-error-text {
  margin: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.custom-dimension-edit-modal .Modal__container {
  max-height: none !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .Modal .Modal__container {
  max-height: none !important;
  margin-bottom: 30px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .Modal .Modal__container::-webkit-scrollbar {
  width: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .Modal .Modal__container::-webkit-scrollbar-track {
  background-color: transparent;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .Modal .Modal__container::-webkit-scrollbar-thumb {
  background: var(--template-main-50);
  border: 0px solid transparent;
  border-radius: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .Modal .Modal__container .Modal__content {
  display: flex;
  flex-direction: column;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  order: 1;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-1 .setting-properties-wrap {
  width: 100%;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-1 .njc-temp-custom-metric-editor-toggle .property-label {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-1 .njc-temp-custom-metric-editor-toggle .nc-toggle-switch input[type="checkbox"] {
  display: block;
  margin: 0px;
  appearance: none;
  width: 18px !important;
  height: 18px !important;
  border: 1px solid var(--grayscale-70);
  border-radius: 5px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-1 .njc-temp-custom-metric-editor-toggle .nc-toggle-switch input[type="checkbox"]:checked {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-image: url("/images/njc/njc-checkbox.svg");
  background-repeat: no-repeat;
  background-position: 3px 50%;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-1 .njc-temp-custom-metric-editor-toggle .nc-toggle-switch input[type="checkbox"]:focus {
  outline: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-1 .njc-temp-custom-metric-editor-toggle .nc-toggle-switch input[type="checkbox"]:after {
  content: "Hide Metric";
  margin-left: 24px;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  padding: 0;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-1 .njc-temp-custom-metric-editor-toggle .nc-toggle-switch .nc-slider {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  order: 2;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-2 .setting-properties-wrap:first-child .settings-panel-property-content {
  margin: 8px 8px 0 0;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .abbrev-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 8px 0 0 12px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .abbrev-description .abbrev-description-title {
  font-weight: 400 !important;
  font-size: 13px !important;
  color: var(--grayscale-50) !important;
  line-height: 18px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .abbrev-description .abbrev-description-item {
  font-weight: 400 !important;
  font-size: 13px !important;
  color: var(--grayscale-100) !important;
  line-height: 18px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-3 {
  order: 4;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-3 .property-label {
  margin-right: 0px;
  padding-top: 48px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-3 .njc-temp-custom-metric-editor-heading-3 {
  font-weight: 700;
  font-size: 18px;
  color: var(--grayscale-100);
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-3 .njc-temp-custom-metric-editor-formula-wrap {
  display: flex;
  flex-direction: row;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-3 .njc-temp-custom-metric-editor-formula-wrap select {
  background-color: var(--grayscale-10);
  background-image: url(/images/njc/njc-icon-dropdown.svg);
  height: 30px;
  margin-left: 10px;
  border: 0px solid var(--grayscale-0);
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  padding: 0 14px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-3 textarea.njc-form-input {
  height: 180px;
  font-size: 14px;
  padding: 12px 20px;
  line-height: 18px;
  color: var(--grayscale-100);
  border: 1px solid var(--grayscale-30);
  background-color: var(--grayscale-0);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-3 textarea.njc-form-input::-webkit-scrollbar {
  width: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-3 textarea.njc-form-input::-webkit-scrollbar-track {
  background-color: transparent;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-3 textarea.njc-form-input::-webkit-scrollbar-thumb {
  background: var(--template-main-50);
  border: 0px solid transparent;
  border-radius: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-3 .njc-temp-custom-metric-editor-valid-msg {
  margin-top: 8px;
  text-align: left;
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-3 .njc-temp-custom-metric-editor-valid-msg-error {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  color: var(--critical-color);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-4 {
  min-height: auto !important;
  max-height: none !important;
  order: 3;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-4 .settings-panel-property-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-4 .njc-temp-custom-metric-editor-metricselect-wrap {
  min-width: 100% !important;
  max-width: 100% !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-4 .njc-temp-custom-metric-editor-metricselect-wrap .njc-temp-custom-metric-editor-metricselect-3column .item-picker,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-4 .njc-temp-custom-metric-editor-metricselect-wrap .njc-temp-custom-metric-editor-metricselect-group .item-picker {
  height: 240px;
  background-image: none !important;
  margin-right: 8px;
  padding: 0px !important;
  overflow-y: overlay;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-4 .njc-temp-custom-metric-editor-metricselect-wrap .njc-temp-custom-metric-editor-metricselect-3column .item-picker::-webkit-scrollbar,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-4 .njc-temp-custom-metric-editor-metricselect-wrap .njc-temp-custom-metric-editor-metricselect-group .item-picker::-webkit-scrollbar {
  width: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-4 .njc-temp-custom-metric-editor-metricselect-wrap .njc-temp-custom-metric-editor-metricselect-3column .item-picker::-webkit-scrollbar-track,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-4 .njc-temp-custom-metric-editor-metricselect-wrap .njc-temp-custom-metric-editor-metricselect-group .item-picker::-webkit-scrollbar-track {
  background-color: transparent;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-4 .njc-temp-custom-metric-editor-metricselect-wrap .njc-temp-custom-metric-editor-metricselect-3column .item-picker::-webkit-scrollbar-thumb,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-4 .njc-temp-custom-metric-editor-metricselect-wrap .njc-temp-custom-metric-editor-metricselect-group .item-picker::-webkit-scrollbar-thumb {
  background: var(--template-main-50);
  border: 0px solid transparent;
  border-radius: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-4 .njc-temp-custom-metric-editor-metricselect-wrap .njc-temp-custom-metric-editor-metricselect-3column .item-picker:last-child,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-4 .njc-temp-custom-metric-editor-metricselect-wrap .njc-temp-custom-metric-editor-metricselect-group .item-picker:last-child {
  margin-right: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-4 .njc-temp-custom-metric-editor-metricselect-wrap .njc-temp-custom-metric-editor-metricselect-3column .item-picker option,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-4 .njc-temp-custom-metric-editor-metricselect-wrap .njc-temp-custom-metric-editor-metricselect-group .item-picker option {
  padding: 5px 16px;
  min-height: 28px;
  white-space: normal;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-4 .njc-temp-custom-metric-editor-metricselect-wrap .njc-temp-custom-metric-editor-metricselect-3column .item-picker option::placeholder,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-4 .njc-temp-custom-metric-editor-metricselect-wrap .njc-temp-custom-metric-editor-metricselect-group .item-picker option::placeholder {
  color: var(--grayscale-70);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-4 .njc-temp-custom-metric-editor-metricselect-wrap .njc-temp-custom-metric-editor-metricselect-3column .item-picker optgroup,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-temp-custom-metric-editor-row-4 .njc-temp-custom-metric-editor-metricselect-wrap .njc-temp-custom-metric-editor-metricselect-group .item-picker optgroup {
  padding: 5px 16px 0px;
  margin-top: 5px;
  min-height: 28px;
  white-space: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .custom-metric-choice {
  min-height: 40px !important;
  max-height: 40px !important;
  margin: 8px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .custom-metric-choice h5,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .custom-metric-choice h4 {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  white-space: nowrap;
  margin: 0;
  text-transform: capitalize;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .custom-metric-choice h5 span,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .custom-metric-choice h4 span {
  display: inline !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .custom-metric-choice p {
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
  white-space: nowrap;
  margin: 0;
  padding-left: 6px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .nc-template-builder-modal.njc-temp-custom-metric-editor .njc-form-footer-row {
  order: 5;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .horiz-element-button-wrap {
  background-color: var(--template-contour);
  height: 36px;
  padding: 0 13px 1px;
  border: 0px solid #fff;
  border-bottom: 1px solid var(--template-main-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .horiz-element-button-wrap .njc-temp-accordion-icon {
  width: 18px;
  height: 18px;
  color: var(--template-main-20);
  margin-right: 6px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .horiz-element-button-wrap .njc-temp-accordion-arrow {
  width: 16px;
  height: 16px;
  color: var(--grayscale-20);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .horiz-element-button-wrap .element-image-wrap {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .horiz-element-button-wrap .element-button-icon-text-2 {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  text-transform: capitalize;
  font-size: 14px;
  color: var(--template-main-10);
  font-weight: 700;
  padding: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap {
  position: relative;
  padding: 6px 13px 0;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--template-contour);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .njc-property-wrap-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .njc-property-wrap-tabs .njc-property-wrap-tabs-content {
  flex: 1 0 50%;
  height: 32px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  text-align: left;
  color: var(--template-main-20);
  font-weight: 700;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--template-main-30);
  border-bottom: 3px solid transparent;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .njc-property-wrap-tabs .njc-property-wrap-tabs-content svg {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .njc-property-wrap-tabs .njc-property-wrap-tabs-content.selected {
  color: var(--template-main-0);
  cursor: default;
  border-bottom: 3px solid var(--template-primary-color);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .panel-element-2 {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .panel-element-2.inner-panel-element-2 {
  display: flex;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .njc-temp-right-global-properties-wrap,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .njc-temp-right-global-styling-wrap {
  position: absolute;
  top: 55px;
  transition: left 0.2s;
  overflow: hidden;
  max-height: 600px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .njc-temp-right-global-properties-wrap.selected,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .njc-temp-right-global-styling-wrap.selected {
  display: flex;
  left: 0;
  border-bottom: 0px;
  max-height: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .njc-temp-right-global-properties-wrap {
  left: -100%;
  margin-top: -10px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .njc-temp-right-global-properties-wrap .setting-properties-wrap {
  border-bottom: 0px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .njc-temp-right-global-styling-wrap {
  left: 100%;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row {
  padding-bottom: 0px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row {
  padding-bottom: 8px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .settings-properties-row {
  padding-bottom: 0px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .settings-panel-property-content {
  padding: 0px !important;
  border-bottom: 0px;
  background-color: transparent !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .settings-panel-property-content .ninja-date-range-string {
  margin: 0px;
  border: 1px solid var(--template-contour);
  border-radius: 5px;
  background-color: var(--template-main-90);
  padding: 8px 0;
  min-height: 26px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 11px;
  color: var(--template-main-20);
  font-weight: 400;
  text-align: left;
  line-height: 12px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .settings-panel-property-content .ninja-date-range-string span {
  color: inherit;
  padding: 0px;
  margin-left: 8px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .settings-panel-property-content .ninja-date-range-string img {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .settings-panel-property-content .ninja-date-range-string svg {
  width: 16px;
  height: 16px;
  min-width: 16px;
  margin-right: 6px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .settings-panel-property-content .w-input {
  width: 100%;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 11px;
  color: var(--template-main-20);
  font-weight: 400;
  text-align: left;
  line-height: 12px;
  border: 1px solid var(--template-contour);
  border-radius: 5px;
  background-color: var(--template-hover);
  height: 26px;
  background-image: none;
  padding: 0 8px;
  margin-bottom: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .settings-panel-property-content select {
  width: 100%;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 11px;
  color: var(--template-main-20);
  font-weight: 400;
  text-align: left;
  line-height: 12px;
  border: 1px solid var(--template-contour);
  border-radius: 5px;
  background-color: var(--template-main-90);
  min-height: 26px;
  background-image: none;
  padding: 4px 8px;
  margin: 0px;
  appearance: none;
  background-image: url("/images/njc/njc-icon-dropdown-medium.svg");
  background-position: calc(100% - 6px);
  background-repeat: no-repeat;
  white-space: normal;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .settings-panel-property-content .njc-form-toggle-default {
  padding-top: 5px;
  padding-bottom: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .settings-panel-property-content .njc-form-toggle-default label {
  margin-bottom: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .settings-panel-property-content .njc-form-toggle-default .toggle-text {
  padding-left: 0px;
  margin-left: 8px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .settings-panel-property-content .njc-form-toggle-default .nc-toggle-switch {
  width: 36px;
  height: 20px;
  min-width: 36px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .settings-panel-property-content .njc-form-toggle-default .nc-toggle-switch .nc-slider {
  width: 36px;
  background-color: var(--template-contour);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .settings-panel-property-content .njc-form-toggle-default .nc-toggle-switch .nc-slider:before {
  width: 16px;
  height: 16px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .settings-panel-property-content .njc-form-toggle-default .nc-toggle-switch input:checked + .nc-slider {
  background-color: var(--template-primary-color);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .settings-panel-property-content .njc-form-toggle-default input:checked + .nc-slider:before {
  transform: translateX(14px);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .abbrev-description {
  margin: 0 12px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .abbrev-description .abbrev-description-title {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 11px;
  color: var(--template-main-5);
  font-weight: 700;
  text-align: left;
  color: var(--template-main-40);
  font-weight: 400;
  margin-left: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .abbrev-description .abbrev-description-title .has-error {
  color: var(--template-main-0);
  font-style: italic;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .abbrev-description .abbrev-description-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 11px;
  color: var(--template-main-5);
  font-weight: 700;
  text-align: left;
  color: var(--template-main-20);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .settings-properties-row .abbrev-description .abbrev-description-item .has-error {
  color: var(--template-main-0);
  font-style: italic;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-property-wrap .settings-properties-row .property-label {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-title-wrap {
  border: 0px solid #fff;
  padding: 0px;
  background-color: transparent;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-title-wrap .njc-temp-right-empty-widget {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-30);
  margin: 0px 13px;
  padding: 18px;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--template-main-90);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-title-wrap .njc-temp-right-empty-widget svg {
  width: 24px;
  height: 24px;
  margin: 0 auto 6px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-temp-right-global-styling-wrap .njc-temp-right-color-select-only .setting-properties-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-temp-right-global-styling-wrap .njc-temp-right-color-select-only .setting-properties-wrap .nc-row-item .colorpicker-bit .sp-replacer {
  top: 0;
  z-index: 1;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-temp-right-global-styling-wrap .njc-temp-right-color-select-only .setting-properties-wrap .nc-row-item .colorpicker-bit .sp-replacer .sp-preview {
  width: 26px;
  height: 26px;
  border: 1px solid var(--template-contour);
  border-radius: 3px;
  overflow: hidden;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-temp-right-global-styling-wrap .njc-temp-right-color-select-only .setting-properties-wrap .nc-row-item .colorpicker-bit .sp-replacer .sp-preview .sp-preview-inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-temp-right-global-styling-wrap .njc-temp-right-color-select-only .setting-properties-wrap .nc-row-item .colorpicker-bit .sp-replacer .sp-preview .sp-preview-inner:after {
  content: " ";
  width: 15px;
  height: 15px;
  background-color: var(--grayscale-0);
  display: block;
  position: absolute;
  bottom: -8px;
  right: -8px;
  transform: rotate(45deg);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-temp-right-global-styling-wrap .njc-temp-right-color-select-only .setting-properties-wrap .nc-row-item .colorpicker-bit img {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-temp-right-global-styling-wrap .inner-row-container .panel-wrap-2 .hidden-layout-panel .settings-properties-row .settings-properties-row {
  border-bottom: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-temp-right-global-styling-wrap .inner-row-container .panel-wrap-2 .hidden-layout-panel .settings-properties-row .settings-properties-row .setting-properties-wrap .njc-temp-styling-wrap .setting-properties-wrap {
  padding: 0px !important;
  border-bottom: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane.widgets-menu .panel-element-2,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane.premade-widgets-menu .panel-element-2 {
  height: 28px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane.widgets-menu .panel-element-2 .panel-head-text-2,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane.premade-widgets-menu .panel-element-2 .panel-head-text-2 {
  font-size: 12px;
  font-weight: 600;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-temp-right-text-select-instruction {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-30);
  margin: 0px 13px;
  padding: 18px;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--template-main-90);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 8px 13px;
  width: auto;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-temp-right-text-select-instruction svg {
  width: 24px;
  height: 24px;
  margin: 0 auto 6px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .legacy-kpi-widget-menu .setting-properties-wrap .setting-properties-wrap {
  border-bottom: 0px solid transparent !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .njc-temp-right-override-wrap .property-label {
  padding: 4px 0px 0 0 !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .njc-temp-right-override-wrap .property-label .setting-panel-icon-wrap {
  width: 100%;
  padding: 4px 0px 0 0 !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .njc-temp-right-override-wrap .property-label .setting-panel-icon-wrap .njc-temp-right-override.njc-notoverriden {
  background-color: var(--template-main-90);
  margin-bottom: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .njc-temp-right-override-wrap .property-label .setting-panel-icon-wrap .njc-temp-right-override.njc-notoverriden .njc-temp-right-override-state {
  color: var(--template-main-30);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .njc-temp-right-override-wrap .property-label .setting-panel-icon-wrap .njc-temp-right-override.njc-overriden {
  background-color: var(--template-primary-color);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .njc-temp-right-override-wrap .property-label .setting-panel-icon-wrap .njc-temp-right-override.njc-overriden .njc-temp-right-override-state {
  color: var(--template-primary-color-5);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .njc-temp-right-override-wrap .property-label .setting-panel-icon-wrap .njc-temp-right-override .njc-temp-right-override-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  min-height: 28px;
  padding: 4px 13px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .njc-temp-right-override-wrap .property-label .setting-panel-icon-wrap .njc-temp-right-override .njc-temp-right-override-content .njc-temp-right-override-text {
  font-size: 12px;
  font-weight: 600;
  color: var(--template-main-10);
  text-align: left;
  padding-right: 20px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .njc-temp-right-override-wrap .property-label .setting-panel-icon-wrap .njc-temp-right-override .njc-temp-right-override-content .njc-temp-right-override-state {
  font-size: 11px;
  font-weight: 800;
  text-transform: uppercase;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .njc-temp-right-override-wrap .settings-properties-row,
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .njc-temp-right-override-wrap .setting-properties-wrap {
  border-bottom: 0px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-temp-widget-column-select-option {
  margin: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-temp-widget-column-select-option .element-icon-wrap.selected {
  background-color: var(--template-contour);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-temp-widget-column-select-option .arrow-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  gap: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .njc-temp-widget-column-select-option .arrow-preview svg {
  width: auto !important;
  height: auto !important;
  color: inherit !important;
  margin-top: 0px !important;
  font-size: 14px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap {
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .setting-properties-wrap {
  flex: 1 0 100% !important;
  margin: 0 0 6px 0 !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .setting-properties-wrap .nc-ctx-drop-down-bit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .setting-properties-wrap .nc-ctx-drop-down-bit .njc-temp-styling-left-label {
  width: 55px;
  display: flex !important;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .setting-properties-wrap .nc-ctx-drop-down-bit select {
  flex: 1;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .font-size-wrap {
  order: 1;
  min-width: 110px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .option-picker {
  order: 2;
  width: 40px;
  height: 26px;
  margin: 0 0 6px 6px;
  background-color: var(--template-main-90) !important;
  border: 1px solid var(--template-contour) !important;
  color: var(--template-main-20) !important;
  border-radius: 3px;
  font-size: 12px;
  opacity: 1 !important;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  overflow: hidden;
  text-indent: 100px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .option-picker.selected {
  background-color: var(--template-hover) !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .option-picker svg {
  width: 18px;
  height: 18px;
  position: absolute;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .option-picker img {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .colorpicker-bit {
  order: 3;
  flex: 1 0 50%;
  margin-bottom: 6px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .colorpicker-bit .colorpicker-bit-inner {
  width: 100%;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .colorpicker-bit .colorpicker-bit-inner .njc-temp-styling-left-label {
  width: 55px;
  display: flex !important;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .colorpicker-bit .colorpicker-bit-inner .sp-replacer {
  top: 0;
  z-index: 1;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .colorpicker-bit .colorpicker-bit-inner .sp-replacer .sp-preview {
  width: 26px;
  height: 26px;
  border: 1px solid var(--template-contour);
  border-radius: 3px;
  overflow: hidden;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .colorpicker-bit .colorpicker-bit-inner .sp-replacer .sp-preview .sp-preview-inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .colorpicker-bit .colorpicker-bit-inner .sp-replacer .sp-preview .sp-preview-inner:after {
  content: " ";
  width: 15px;
  height: 15px;
  background-color: var(--grayscale-0);
  display: block;
  position: absolute;
  bottom: -8px;
  right: -8px;
  transform: rotate(45deg);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .colorpicker-bit .colorpicker-bit-inner img {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .colorpicker-bit .colorpicker-bit-inner .colorpicker-bit-inner {
  width: 26px;
  height: 26px;
  margin-left: -26px;
  margin-right: 0px;
  border: 1px solid var(--template-contour);
  border-radius: 3px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--template-hover) !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .colorpicker-bit .colorpicker-bit-inner .colorpicker-bit-inner svg {
  width: 14px;
  height: 14px;
  background-color: var(--grayscale-0);
  border-radius: 3px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .njc-temp-right-text-alignment-wrap {
  margin-left: 0px !important;
  margin-bottom: 6px !important;
  margin-right: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .njc-temp-right-text-alignment-wrap .njc-temp-styling-left-label {
  width: 55px;
  display: flex !important;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .njc-temp-right-text-alignment-wrap .njc-temp-right-text-alignment-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .njc-temp-right-text-alignment-wrap .njc-temp-right-text-alignment-inner .njc-temp-right-text-alignment-item {
  width: 40px;
  height: 28px;
  border: 1px solid var(--template-contour);
  border-right: 0px;
  background-color: var(--template-main-90);
  color: var(--template-main-20);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .njc-temp-right-text-alignment-wrap .njc-temp-right-text-alignment-inner .njc-temp-right-text-alignment-item:first-child {
  border-radius: 5px 0 0 5px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .njc-temp-right-text-alignment-wrap .njc-temp-right-text-alignment-inner .njc-temp-right-text-alignment-item:last-child {
  border-radius: 0 5px 5px 0;
  border-right: 1px solid var(--template-contour);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .njc-temp-right-text-alignment-wrap .njc-temp-right-text-alignment-inner .njc-temp-right-text-alignment-item.selected {
  background-color: var(--template-hover);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .font-style-bit .setting-properties-wrap .njc-temp-styling-wrap .njc-temp-right-text-alignment-wrap .njc-temp-right-text-alignment-inner .njc-temp-right-text-alignment-item svg {
  width: 18px;
  height: 18px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .colorpicker-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .colorpicker-group .colorpicker-bit .colorpicker-bit-inner {
  width: 100%;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .colorpicker-group .colorpicker-bit .colorpicker-bit-inner .njc-temp-styling-left-label {
  width: 55px;
  display: flex !important;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .colorpicker-group .colorpicker-bit .colorpicker-bit-inner .sp-replacer {
  top: 0;
  z-index: 1;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .colorpicker-group .colorpicker-bit .colorpicker-bit-inner .sp-replacer .sp-preview {
  width: 26px;
  height: 26px;
  border: 1px solid var(--template-contour);
  border-radius: 3px;
  overflow: hidden;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .colorpicker-group .colorpicker-bit .colorpicker-bit-inner .sp-replacer .sp-preview .sp-preview-inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .colorpicker-group .colorpicker-bit .colorpicker-bit-inner .sp-replacer .sp-preview .sp-preview-inner:after {
  content: " ";
  width: 15px;
  height: 15px;
  background-color: var(--grayscale-0);
  display: block;
  position: absolute;
  bottom: -8px;
  right: -8px;
  transform: rotate(45deg);
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .colorpicker-group .colorpicker-bit .colorpicker-bit-inner img {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .colorpicker-group .colorpicker-bit .colorpicker-bit-inner .colorpicker-bit-inner {
  width: 26px;
  height: 26px;
  margin-left: -26px;
  margin-right: 0px;
  border: 1px solid var(--template-contour);
  border-radius: 3px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--template-hover) !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .colorpicker-group .colorpicker-bit .colorpicker-bit-inner .colorpicker-bit-inner svg {
  width: 14px;
  height: 14px;
  background-color: var(--grayscale-0);
  border-radius: 3px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .colorpicker-group.label-60 .njc-temp-styling-left-label {
  width: 60px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .colorpicker-group.label-70 .njc-temp-styling-left-label {
  width: 70px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .colorpicker-group.label-80 .njc-temp-styling-left-label {
  width: 80px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content.njc-temp-intab-mixed {
  padding: 0 13px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content.njc-temp-intab-mixed .font-style-bit .setting-properties-wrap {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content.njc-temp-intab-mixed .settings-properties-row .property-label {
  padding: 4px 0 !important;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content.njc-temp-intab-mixed .settings-properties-row .settings-panel-property-content {
  margin: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content.njc-temp-intab-mixed .settings-properties-row .options-wrap {
  margin: 0px;
  padding-bottom: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content.njc-temp-intab-mixed .settings-properties-row .njc-temp-right-override-wrap {
  margin: 0 -13px;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel {
  width: 241px;
  height: 100%;
  overflow-y: visible;
  left: auto;
  background-color: var(--template-main-100);
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .njc-temp-adding-panel-top-row {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 13px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 18px;
  font-weight: 700;
  color: var(--template-main-20);
  text-transform: capitalize;
  border-bottom: 1px solid var(--template-contour);
  position: sticky;
  top: 0;
  background-color: var(--template-main-100);
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .njc-temp-adding-panel-top-row svg {
  width: 18px;
  height: 18px;
  color: var(--template-main-60);
  margin-right: 13px;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .panel-search-wrap {
  position: sticky;
  top: 50px;
  background-color: var(--template-main-100);
  padding: 8px !important;
  margin: 0px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row {
  padding: 0px;
  height: calc(100% - 40px);
  overflow-y: auto;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row::-webkit-scrollbar {
  width: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row::-webkit-scrollbar-track {
  background-color: transparent;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row::-webkit-scrollbar-thumb {
  background: var(--template-main-50);
  border: 0px solid transparent;
  border-radius: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics {
  padding: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics .metric-row {
  padding: 0px 13px;
  min-height: 30px;
  background-color: transparent;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 0px solid var(--template-contour);
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-20);
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics .metric-row:hover,
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics .metric-row.active {
  background-color: var(--template-hover);
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics .metrics-list {
  padding: 8px 13px 10px;
  margin: 0;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-selected {
  position: sticky;
  top: 0;
  background-color: var(--template-main-100);
  margin-bottom: 10px;
  max-height: 295px;
  overflow-y: auto;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-selected::-webkit-scrollbar {
  width: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-selected::-webkit-scrollbar-track {
  background-color: transparent;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-selected::-webkit-scrollbar-thumb {
  background: var(--template-main-50);
  border: 0px solid transparent;
  border-radius: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-selected .metric-row {
  height: 36px;
  padding: 0 13px 1px;
  border: 0px solid #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0px;
  background-color: var(--template-contour);
  border-radius: 0;
  padding: 0 13px;
  border-bottom: 1px solid var(--template-main-100);
  min-height: 28px;
  height: auto;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  text-transform: capitalize;
  font-size: 12px;
  color: var(--template-main-10);
  font-weight: 700;
  padding: 4px 0px;
  text-align: left;
  padding: 0 8px 0 13px;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-selected .metric-row .njc-temp-accordion-icon {
  width: 18px;
  height: 18px;
  color: var(--template-main-20);
  margin-right: 6px;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-selected .metric-row .njc-temp-accordion-arrow {
  width: 16px;
  height: 16px;
  color: var(--grayscale-20);
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-selected .metric-row .direction-arrow-wrap {
  margin: 0 0 0 auto;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-selected .metric-row .direction-arrow-wrap img {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-selected .metric-list-item {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 11px;
  color: var(--template-main-5);
  font-weight: 700;
  text-align: left;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 4px 0px 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 28px;
  background-color: var(--template-primary-color);
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-selected .metric-list-item .has-error {
  color: var(--template-main-0);
  font-style: italic;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-selected .metric-list-item:hover {
  background-color: var(--template-primary-color-variant);
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-available > div.metric-row {
  height: 36px;
  padding: 0 13px 1px;
  border: 0px solid #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0px;
  background-color: var(--template-contour);
  border-radius: 0;
  padding: 0 13px;
  border-bottom: 1px solid var(--template-main-100);
  min-height: 28px;
  height: auto;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  text-transform: capitalize;
  font-size: 12px;
  color: var(--template-main-10);
  font-weight: 700;
  padding: 4px 0px;
  text-align: left;
  padding: 0 8px 0 13px;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-available > div.metric-row .njc-temp-accordion-icon {
  width: 18px;
  height: 18px;
  color: var(--template-main-20);
  margin-right: 6px;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-available > div.metric-row .njc-temp-accordion-arrow {
  width: 16px;
  height: 16px;
  color: var(--grayscale-20);
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-available > div.metric-row .direction-arrow-wrap {
  margin: 0 0 0 auto;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-available > div.metric-row .direction-arrow-wrap img {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-available .hidden-sub-picker-metrics {
  padding: 8px 13px 10px;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-available .hidden-sub-picker-metrics .metric-row {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 11px;
  color: var(--template-main-5);
  font-weight: 700;
  text-align: left;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 4px 0px 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 28px;
  background-color: var(--template-main-90);
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-available .hidden-sub-picker-metrics .metric-row .has-error {
  color: var(--template-main-0);
  font-style: italic;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-available .hidden-sub-picker-metrics .metric-row:hover,
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-available .hidden-sub-picker-metrics .metric-row.active {
  background-color: var(--template-hover);
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics.njc-temp-right-metric-available .hidden-sub-picker-metrics .metric-row .njc-temp-right-selected-check {
  margin-right: 0px;
  margin-left: auto;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics .njc-temp-right-selected-check {
  width: 12px;
  height: 12px;
  min-width: 12px;
  margin-right: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel .horiz-element-row .hidden-picker-metrics .njc-temp-right-selected-close {
  width: 12px;
  height: 12px;
  min-width: 12px;
  margin-left: auto;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel::-webkit-scrollbar {
  width: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel::-webkit-scrollbar-track {
  background-color: transparent;
}
.njc-template-editor .builder-wrap .right-sidebar .metric-picker-panel::-webkit-scrollbar-thumb {
  background: var(--template-main-50);
  border: 0px solid transparent;
  border-radius: 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .njc-temp-right-override-options-wrap {
  border-left: 2px solid var(--template-primary-color);
}
.njc-template-editor .builder-wrap .right-sidebar .njc-temp-right-override-options-wrap .series-color-wrap {
  margin: 0px 13px 4px;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range *,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal * {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal {
  z-index: 1010;
  background-color: rgba(45, 53, 64, 0.9);
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container {
  width: 600px !important;
  padding: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content {
  padding: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-tertiary-tabs-link,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-tertiary-tabs-link {
  color: var(--grayscale-100) !important;
  border-bottom-width: 4px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  text-transform: capitalize;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-tertiary-tabs-link:hover,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-tertiary-tabs-link:hover {
  border-bottom-width: 4px;
  border-bottom-color: transparent;
  background-color: transparent;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-tertiary-tabs-link.njc-tertiary-tabs-link-current,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-tertiary-tabs-link.njc-tertiary-tabs-link-current {
  border-bottom-color: var(--critical-color);
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-container,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-container {
  border: 1px solid var(--grayscale-20);
  border-radius: 5px;
  height: 350px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow-y: auto;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-container div,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-container div {
  width: 50%;
  min-height: 24px;
  padding: 0px 16px;
  color: var(--grayscale-100);
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-container div:hover,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-container div:hover {
  color: var(--primary-color);
  cursor: pointer;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-container .nc-date-range-selected,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-container .nc-date-range-selected {
  color: var(--primary-color);
  background-color: var(--grayscale-10);
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative {
  margin: 0;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative label,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative label {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  margin-bottom: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .njc-checkbox label,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .njc-checkbox label {
  font-size: 13px;
  color: var(--grayscale-100);
  font-weight: 400;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .njc-date-range-box-wrap .njc-date-range-box1,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .njc-date-range-box-wrap .njc-date-range-box1 {
  width: 84px;
  line-height: 16px;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .njc-date-range-box-wrap .njc-date-range-box2,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .njc-date-range-box-wrap .njc-date-range-box2 {
  width: 121px;
  line-height: 16px;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .nc-help-text,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .nc-help-text {
  color: var(--grayscale-50);
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row {
  padding: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .expand.r-pad-30,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .expand.r-pad-30 {
  padding: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-label,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-label {
  font-weight: 600;
  font-size: 13px;
  color: var(--grayscale-80);
  text-transform: capitalize;
  margin-bottom: 8px;
  display: inline-block;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-checkbox,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-checkbox {
  margin-bottom: 8px;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box-wrap,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box1,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box1 {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  margin-right: 8px;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box1::placeholder,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box1::placeholder {
  color: var(--grayscale-70);
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box1:hover,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box1:hover {
  border: 1px solid var(--grayscale-50);
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box1:active,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box1:active,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box1:focus,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box1:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box1:disabled,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box1:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box1[type="number"],
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box1[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box1[type="number"]::-webkit-outer-spin-button,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box1[type="number"]::-webkit-outer-spin-button,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box1[type="number"]::-webkit-inner-spin-button,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box1[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2 {
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  background-image: url("/images/njc/njc-icon-dropdown.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 35px;
  margin-right: 8px;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2::placeholder,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2::placeholder {
  color: var(--grayscale-70);
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2:hover,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2:hover {
  border: 1px solid var(--grayscale-50);
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2:active,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2:active,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2:focus,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2:disabled,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2[type="number"],
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2[type="number"]::-webkit-outer-spin-button,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2[type="number"]::-webkit-outer-spin-button,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2[type="number"]::-webkit-inner-spin-button,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2::-ms-expand,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2::-ms-expand {
  display: none;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2:disabled,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2:disabled {
  background-image: url("/images/njc/njc-icon-dropdown-disabled.svg");
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2.njc-date-range-box2-last,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box2.njc-date-range-box2-last {
  margin-left: 8px;
  margin-right: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box3,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box3 {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  background-image: url("/images/njc/njc-calendar.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 35px;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box3::placeholder,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box3::placeholder {
  color: var(--grayscale-70);
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box3:hover,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box3:hover {
  border: 1px solid var(--grayscale-50);
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box3:active,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box3:active,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box3:focus,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box3:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box3:disabled,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box3:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box3[type="number"],
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box3[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box3[type="number"]::-webkit-outer-spin-button,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box3[type="number"]::-webkit-outer-spin-button,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box3[type="number"]::-webkit-inner-spin-button,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-box3[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-helptext .nc-help-text,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-helptext .nc-help-text {
  padding: 0px;
  margin: 0px;
  background-color: transparent;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-helptext .njc-form-description,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-helptext .njc-form-description {
  flex-basis: 100%;
  max-width: 100%;
  padding-left: 0px;
  margin-left: 0px;
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-70);
  text-transform: capitalize;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-helptext .njc-form-description .nc-help-text,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .form-field-row .njc-date-range-helptext .njc-form-description .nc-help-text {
  min-height: 30px;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .njc-date-range-relative .njc-form-text-default,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .njc-date-range-relative .njc-form-text-default {
  padding-top: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .Modal .Modal__container .Modal__content .w-input,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .Modal .Modal__container .Modal__content .w-input {
  background-color: var(--grayscale-0);
}
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.nc-quick-date-range .b-pad-15.t-pad-10.b-line,
.njc-template-editor .builder-wrap .right-sidebar .nc-template-builder-modal.mapping-changes-modal .b-pad-15.t-pad-10.b-line {
  padding: 0px;
  border-bottom: 0px;
}
.njc-template-editor .builder-wrap .right-sidebar .image-upload-bit {
  padding: 0px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .image-upload-bit .settings-panel-property-content {
  margin: 0px !important;
  min-height: auto !important;
  justify-content: flex-end !important;
}
.njc-template-editor .builder-wrap .right-sidebar .image-upload-bit .settings-panel-property-content .image-preview {
  display: none !important;
}
.njc-template-editor .builder-wrap .right-sidebar .image-upload-bit .settings-panel-property-content .file-container {
  margin-bottom: 0px !important;
  padding-left: 6px !important;
  display: flex !important;
  flex-direction: row !important;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-size-adjust: 100% !important;
  text-rendering: optimizeLegibility !important;
  font-size: 11px !important;
  color: var(--template-main-5) !important;
  font-weight: 700 !important;
  text-align: left !important;
  text-transform: lowercase !important;
}
.njc-template-editor .builder-wrap .right-sidebar .image-upload-bit .settings-panel-property-content .file-container .has-error {
  color: var(--template-main-0) !important;
  font-style: italic !important;
}
.njc-template-editor .builder-wrap .right-sidebar .image-upload-bit .settings-panel-property-content .file-container svg {
  width: 18px !important;
  height: 18px !important;
  margin-right: 4px !important;
}
.njc-template-editor .builder-wrap .right-sidebar .image-upload-bit .settings-panel-property-content .file-container:first-letter {
  text-transform: capitalize !important;
}
.njc-template-editor .builder-wrap .right-sidebar .image-upload-bit .settings-panel-property-content .file-container input[type="file"] {
  font-size: 12px !important;
}
.njc-template-editor .builder-wrap .right-sidebar:hover .njc-menu-collapse-button {
  opacity: 1;
}
.njc-template-editor .widget-container .resize-handles:hover {
  border: 2px solid var(--template-primary-color);
}
.njc-template-editor .widget-container .resize-handles.selected {
  border: 1px dashed var(--template-main-50);
}
.njc-template-editor .widget-container .resize-handles.selected:hover {
  border: 1px dashed var(--template-main-50);
}
.njc-template-editor .widget-container .table-resize-column-width-bar {
  border-color: var(--template-main-50) !important;
}
.njc-template-editor .widget-container .dndr-resize-handle {
  background-color: var(--template-main-0);
  border: 1px solid var(--template-main-80);
  width: 12px !important;
  height: 12px !important;
}
.njc-template-editor .widget-container .dndr-resize-handle.top {
  top: -6px !important;
}
.njc-template-editor .widget-container .dndr-resize-handle.right {
  right: -6px !important;
}
.njc-template-editor .widget-container .dndr-resize-handle.bottom {
  bottom: -6px !important;
}
.njc-template-editor .widget-container .dndr-resize-handle.left {
  left: -6px !important;
}
.njc-template-editor .widget-container .dndr-resize-handle.center {
  left: 0px !important;
  margin-left: 0px !important;
  width: 100% !important;
  border-radius: 0px;
  border: 0px solid transparent;
  background-color: transparent;
}
.njc-template-editor .widget-container .dndr-resize-handle.middle {
  top: 0px !important;
  margin-top: 0px !important;
  height: 100% !important;
  border-radius: 0px;
  background-color: transparent;
  border: 0px solid transparent;
}
.njc-template-editor .widget-container .dndr-resize-handle.top.left,
.njc-template-editor .widget-container .dndr-resize-handle.bottom.right {
  cursor: nwse-resize;
  z-index: 1010 !important;
}
.njc-template-editor .widget-container .dndr-resize-handle.top.center,
.njc-template-editor .widget-container .dndr-resize-handle.bottom.center {
  cursor: ns-resize;
}
.njc-template-editor .widget-container .dndr-resize-handle.top.right,
.njc-template-editor .widget-container .dndr-resize-handle.bottom.left {
  cursor: nesw-resize;
  z-index: 1010 !important;
}
.njc-template-editor .widget-container .dndr-resize-handle.middle.left,
.njc-template-editor .widget-container .dndr-resize-handle.middle.right {
  cursor: ew-resize;
}
.njc-template-editor .widget-container.selected .dndr-cancel,
.njc-template-editor .widget-container.selected .dndr-locked {
  display: none;
}
.njc-template-editor .widget-container.selected .dndr-cancel svg,
.njc-template-editor .widget-container.selected .dndr-locked svg {
  color: var(--template-main-80);
  cursor: pointer;
  background-color: var(--template-main-0);
  padding: 1px;
  border-radius: 5px;
  border: 1px solid var(--grayscale-30);
}
.njc-template-editor .widget-container.selected:hover .dndr-cancel,
.njc-template-editor .widget-container.selected:hover .dndr-locked {
  display: block;
}
.njc-template-editor .widget-container.selected .dndr-cancel svg:hover {
  color: var(--critical-color);
}
.njc-template-editor .widget-container.selected .dndr-locked .lockwidget-icon {
  background-color: var(--template-main-0);
}
.njc-template-editor .widget-container.selected .dndr-locked:hover .lockwidget-icon {
  display: none;
}
.njc-template-editor .widget-container.selected .dndr-locked:hover .unlockwidget-icon {
  display: block !important;
  opacity: 1;
}
.njc-template-editor .widget-container.selected .dndr-locked svg:hover {
  color: var(--template-primary-color);
}
.njc-template-editor .widget-container.locked .dndr-resize-handle {
  display: none;
}
.njc-template-editor .widget-container.locked .resize-handles.selected {
  border: 1px solid var(--template-main-50) !important;
}
.njc-template-editor .widget-container .tooltip-wrapper .njc-temp-more-info-popup-wrap {
  display: none;
  opacity: 0;
  position: absolute;
  color: var(--template-main-100);
  background-color: var(--template-main-5);
  border: 0px solid var(--template-contour);
  padding: 6px 8px;
  font-family: 'Inter', sans-serif;
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0 var(--template-border);
  width: 400px !important;
  right: -35px;
  font-size: 16px;
  bottom: 20px;
  white-space: pre-line;
  transition: opacity 0.2s linear;
}
.njc-template-editor .widget-container .tooltip-wrapper:hover .njc-temp-more-info-popup-wrap {
  display: flex !important;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}
.njc-template-editor .msg-area {
  background-color: rgba(255, 255, 255, 0.6);
}
.njc-template-editor .rc-menu .menu-container {
  border: 1px solid var(--template-border);
  background-color: var(--template-main-100);
  border-radius: 5px;
}
.njc-template-editor .rc-menu .menu-container .item {
  height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 4px 0 14px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 14px;
  background-color: transparent;
  border-bottom: 1px solid var(--template-border);
  cursor: pointer !important;
}
.njc-template-editor .rc-menu .menu-container .item .text {
  font-size: 13px;
  padding-right: 8px;
  cursor: pointer !important;
}
.njc-template-editor .rc-menu .menu-container .item .shortcut {
  font-size: 12px;
  color: var(--grayscale-50);
  margin-left: auto;
  margin-right: 4px;
  cursor: pointer !important;
}
.njc-template-editor .rc-menu .menu-container .item svg {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  cursor: pointer !important;
}
.njc-template-editor .rc-menu .menu-container .item.item-warn {
  color: var(--critical-color);
  border-radius: 0 0 5px 5px;
  border-bottom: 0px solid transparent;
}
.njc-template-editor .rc-menu .menu-container .item.item-warn:hover {
  color: var(--critical-color);
}
.njc-template-editor .rc-menu .menu-container .item:hover,
.njc-template-editor .rc-menu .menu-container .item.active {
  background-color: var(--template-contour);
  border-radius: 0px;
}
.njc-template-editor .rc-menu .menu-container .item .tiny-arrow-right {
  width: 18px;
  height: 18px;
  margin-left: auto;
  margin-right: 0px;
}
.njc-template-editor .rc-menu .menu-container .item .tiny-arrow-right {
  width: 18px;
  height: 18px;
  margin-left: auto;
  margin-right: 0px;
}
.njc-template-editor .rc-menu .menu-container .divider {
  display: none;
}
.njc-template-editor .rc-menu .menu-container > div .item {
  border-radius: 5px 5px 0 0;
}
.njc-template-editor .rc-menu .menu-container.secondary-menu {
  width: 170px;
}
/* Widget Status - moved out to also apply in dashboards */
.njc-widget-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--template-main-50);
  padding: 12px;
}
.njc-widget-status.njc-widget-status-error {
  color: var(--critical-color);
}
.njc-widget-status svg {
  width: 34px;
  height: 36px;
}
.njc-widget-status .njc-widget-status-message {
  margin-top: 12px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-transform: capitalize;
}
.njc-widget-status .njc-widget-status-message span {
  font-weight: 700;
}
.njc-widget-status .njc-widget-loading {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 10px solid var(--template-main-10);
  border-top-color: var(--template-main-50);
  animation: spin 1s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.select2-container--open .njc-temp-top-account-dropdown {
  margin-top: 0px;
  border-radius: 0px 0px 5px 5px;
  border: 1px solid var(--template-contour);
  border-top: 0px;
  background-color: var(--template-main-100);
}
.select2-container--open .njc-temp-top-account-dropdown .select2-search--dropdown {
  padding: 8px 13px;
}
.select2-container--open .njc-temp-top-account-dropdown .select2-search--dropdown .select2-search__field {
  width: 100%;
  height: 34px;
  border: 1px solid var(--template-contour);
  background-color: var(--template-hover);
  padding-left: 35px;
  background-image: url("/images/njc/njc-icon-search.svg");
  background-position: 14px 50%;
  background-size: 14px 14px;
  background-repeat: no-repeat;
  color: var(--template-main-10);
  font-size: 12px;
  background-color: #151c25;
  border: 1px solid var(--template-border);
  height: 28px;
  min-height: 28px;
}
.select2-container--open .njc-temp-top-account-dropdown .select2-search--dropdown .select2-search__field::placeholder {
  color: var(--template-main-40);
  font-size: 12px;
}
.select2-container--open .njc-temp-top-account-dropdown .select2-results__option {
  background-color: transparent;
  color: var(--template-main-0);
  font-weight: 500;
  padding: 8px 13px;
  min-height: 28px;
  font-size: 12px;
  white-space: normal;
}
.select2-container--open .njc-temp-top-account-dropdown .select2-results__option[aria-selected=true] {
  background-color: var(--template-main-90);
  color: var(--template-main-0);
}
.select2-container--open .njc-temp-top-account-dropdown .select2-results__option:hover {
  background-color: var(--template-main-90);
  color: var(--template-main-20);
}
.select2-container--open .njc-temp-top-account-dropdown .select2-results__option:after {
  border-bottom-color: var(--template-contour);
  bottom: auto;
  top: 0;
}
.select2-container--open .njc-temp-top-account-dropdown .select2-results__option:first-child {
  color: #BDC2CC;
}
.select2-container--open .njc-temp-top-account-dropdown .select2-results__option:first-child,
.select2-container--open .njc-temp-top-account-dropdown .select2-results__option:last-child {
  border-radius: 0;
}
.swal2-container.swal2-in {
  background-color: rgba(45, 53, 64, 0.9) !important;
}
/*Fix for custom date range in comparisons*/
.nc-modal-template-builder .builder-wrap .right-sidebar .nc-ctx-text-bit .njc-form-search-bar {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  font-weight: 400;
  color: var(--grayscale-100);
  min-height: 44px;
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  background-image: url("/images/njc/njc-icon-search.svg");
  padding: 12px 20px 12px 40px;
}
.nc-modal-template-builder .builder-wrap .right-sidebar .nc-ctx-text-bit .njc-form-search-bar::placeholder {
  color: var(--grayscale-70);
}
.nc-modal-template-builder .builder-wrap .right-sidebar .nc-ctx-text-bit .njc-form-search-bar:hover {
  border: 1px solid var(--grayscale-50);
}
.nc-modal-template-builder .builder-wrap .right-sidebar .nc-ctx-text-bit .njc-form-search-bar:active,
.nc-modal-template-builder .builder-wrap .right-sidebar .nc-ctx-text-bit .njc-form-search-bar:focus {
  border: 1px solid var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.nc-modal-template-builder .builder-wrap .right-sidebar .nc-ctx-text-bit .njc-form-search-bar:disabled {
  border: 1px solid var(--grayscale-30) !important;
  background-color: var(--grayscale-10);
  color: var(--grayscale-80);
  cursor: not-allowed;
}
.nc-modal-template-builder .builder-wrap .right-sidebar .nc-ctx-text-bit .njc-form-search-bar[type="number"] {
  -moz-appearance: spinner-upbutton;
}
.nc-modal-template-builder .builder-wrap .right-sidebar .nc-ctx-text-bit .njc-form-search-bar[type="number"]::-webkit-outer-spin-button,
.nc-modal-template-builder .builder-wrap .right-sidebar .nc-ctx-text-bit .njc-form-search-bar[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 -12px 0 2px;
  height: 28px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .settings-panel-property-content .njc-date-range-box-wrap {
  color: var(--grayscale-100);
  font-weight: 400;
  font-size: 13px;
}
.njc-template-editor .builder-wrap .right-sidebar .COMPARISON .Modal__content .njc-date-range-relative .njc-checkbox input {
  height: 18px !important;
  width: 18px;
  opacity: 0;
  z-index: 1;
  margin-right: -22px;
  margin-top: 5px;
}
.njc-tabled-wrap {
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  background-color: var(--grayscale-0);
  box-shadow: 2px 2px 4px rgba(45, 53, 64, 0.1);
}
.njc-tabled-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.njc-tabled-headers {
  background-color: var(--grayscale-5);
  height: 40px;
  border-bottom: 1px solid var(--grayscale-30);
  border-radius: 5px 5px 0 0;
  padding: 0 20px;
  z-index: 9;
}
.njc-tabled-headers .njc-tabled-cell {
  font-weight: 700;
  font-size: 13px;
  color: var(--grayscale-60);
  text-transform: capitalize;
  text-decoration: none;
  line-height: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: left;
}
.njc-tabled-headers .njc-tabled-cell:first-child {
  padding-left: 20px;
}
.njc-tabled-headers .njc-tabled-cell:last-child {
  padding-right: 20px;
}
.njc-tabled-row {
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  position: relative;
  padding: 0 20px!important;
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 16px;
  text-align: left;
  border-bottom: 1px solid var(--grayscale-30);
}
.njc-tabled-row .njc-tabled-cell {
  padding-top: 16px;
  padding-bottom: 16px;
  font-weight: 500;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 16px;
}
.njc-tabled-row .njc-tabled-cell:first-child {
  padding-left: 20px;
}
.njc-tabled-row .njc-tabled-cell:last-child {
  padding-right: 20px;
}
/*Search Input Close Button*/
input[type="search"] {
  box-sizing: border-box;
}
input[type="search"]::-webkit-search-cancel-button {
  background-image: url("/images/njc/njc-close.svg");
  width: 15px;
  height: 15px;
  background-size: contain;
}
.widget-extra-info {
  position: relative;
}
.widget-extra-info:hover .njc-temp-more-info-popup-wrap {
  position: absolute;
  left: 50%;
  top: 35px;
  z-index: 2;
  display: block !important;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  transition-delay: 0.5s;
  top: 95px;
  display: flex !important;
  visibility: visible;
  opacity: 1;
  width: 225px;
  height: auto;
  overflow: visible;
  transition-delay: 0s;
}
.widget-extra-info:hover .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content {
  background-color: var(--template-main-5);
  border: 0px solid var(--template-contour);
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  position: relative;
  left: -50%;
  color: var(--template-main-100);
  z-index: 1;
  padding: 6px 8px;
  min-width: 76px;
  max-width: 140px;
  box-shadow: 2px 2px 4px 0 var(--template-border);
  white-space: normal;
}
.widget-extra-info:hover .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border: 5px solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border-bottom-color: var(--template-main-5);
  z-index: 2;
}
.widget-extra-info:hover .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content:before {
  bottom: 100%;
  left: 50%;
  border: 6px solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border-bottom-color: var(--template-contour);
  z-index: 0;
  margin-left: -6px;
}
.widget-extra-info:hover .njc-temp-more-info-popup-wrap .njc-temp-more-info-popup-content {
  max-width: none;
}
/* Template full screen styling */
.njc-template-editor .builder-wrap .center-area .support-bar button.njc-template-full-screen-button {
  width: 42px;
  margin-right: auto;
}
.njc-template-editor .builder-wrap .center-area .support-bar button.njc-template-full-screen-button svg {
  margin-right: 0px;
}
.left-menu-collapsed.njc-template-editor .builder-wrap .center-area:before {
  content: "";
  position: fixed;
  top: 40px;
  left: 0px;
  width: 14px;
  height: 100%;
  z-index: 0;
  background-color: var(--template-main-100);
  background-image: url("/images/njc/njc-icon-arrow-right-light.svg");
  background-position: 4px center;
  background-repeat: no-repeat;
  background-size: 8px;
}
.right-menu-collapsed.njc-template-editor .builder-wrap .center-area:after {
  content: "";
  position: fixed;
  top: 40px;
  right: 0px;
  width: 14px;
  height: 100%;
  z-index: 0;
  background-color: var(--template-main-100);
  background-image: url("/images/njc/njc-icon-arrow-left-light.svg");
  background-position: 4px center;
  background-repeat: no-repeat;
  background-size: 8px;
}
.njc-menu-collapse-button {
  position: absolute;
  width: 18px;
  height: 36px;
  top: 4px;
  z-index: 4;
  background-color: var(--template-main-100);
  border: 1px solid var(--template-contour);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--template-main-0);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.njc-menu-collapse-button svg {
  width: 18px;
  height: 18px;
}
.njc-menu-collapse-button:before {
  content: "";
  position: absolute;
  top: -5px;
  width: 30px;
  height: 46px;
}
.njc-left-menu-collapse-button {
  position: absolute;
  width: 18px;
  height: 36px;
  top: 4px;
  z-index: 4;
  background-color: var(--template-main-100);
  border: 1px solid var(--template-contour);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--template-main-0);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  border-left: 0px;
  border-radius: 0 5px 5px 0;
  left: 280px;
}
.njc-left-menu-collapse-button svg {
  width: 18px;
  height: 18px;
}
.njc-left-menu-collapse-button:before {
  content: "";
  position: absolute;
  top: -5px;
  width: 30px;
  height: 46px;
}
.njc-left-menu-collapse-button svg {
  transform: rotate(90deg);
}
.njc-left-menu-collapse-button:before {
  left: 0;
}
.left-menu-collapsed.njc-template-editor .builder-wrap .left-sidebar {
  transition: margin-left 0.2s ease-in-out;
  margin-left: -280px;
  top: 0px;
  left: 0px;
  position: absolute;
  width: 280px;
  height: 100%;
}
.left-menu-collapsed.njc-template-editor .builder-wrap .left-sidebar .njc-temp-left-help {
  transition: margin-left 0.2s ease-in-out;
  margin-left: -280px;
}
.left-menu-collapsed.njc-template-editor .builder-wrap .left-sidebar:after {
  content: "";
  position: absolute;
  top: 0;
  right: -16px;
  width: 16px;
  height: calc(100% - 77px);
}
.left-menu-collapsed.njc-template-editor .builder-wrap .left-sidebar .njc-left-menu-collapse-button {
  opacity: 0;
}
.left-menu-collapsed.njc-template-editor .builder-wrap .left-sidebar:hover {
  margin-left: 0px;
}
.left-menu-collapsed.njc-template-editor .builder-wrap .left-sidebar:hover .njc-temp-left-help {
  margin-left: 0px;
}
.left-menu-collapsed.njc-template-editor .builder-wrap .left-sidebar:hover .njc-left-menu-collapse-button {
  opacity: 1;
}
.njc-right-menu-collapse-button {
  position: absolute;
  width: 18px;
  height: 36px;
  top: 4px;
  z-index: 4;
  background-color: var(--template-main-100);
  border: 1px solid var(--template-contour);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--template-main-0);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  right: 241px;
  border-radius: 5px 0 0 5px;
  border-right: 0px;
}
.njc-right-menu-collapse-button svg {
  width: 18px;
  height: 18px;
}
.njc-right-menu-collapse-button:before {
  content: "";
  position: absolute;
  top: -5px;
  width: 30px;
  height: 46px;
}
.njc-right-menu-collapse-button svg {
  transform: rotate(-90deg);
}
.njc-right-menu-collapse-button:before {
  right: 0;
}
.right-menu-collapsed.njc-template-editor .builder-wrap .right-sidebar {
  transition: margin-right 0.2s ease-in-out;
  margin-right: -241px;
  margin-top: 40px;
  right: 0px;
  top: 0px;
  position: fixed;
  width: 241px;
  height: 100%;
}
.right-menu-collapsed.njc-template-editor .builder-wrap .right-sidebar .panel-tabs-menu {
  border-left: 0px solid transparent;
}
.right-menu-collapsed.njc-template-editor .builder-wrap .right-sidebar:before {
  content: "";
  position: absolute;
  top: 0;
  left: -16px;
  width: 16px;
  height: calc(100% - 77px);
}
.right-menu-collapsed.njc-template-editor .builder-wrap .right-sidebar .njc-right-menu-collapse-button {
  opacity: 0;
}
.right-menu-collapsed.njc-template-editor .builder-wrap .right-sidebar:hover {
  margin-right: 0px;
}
.right-menu-collapsed.njc-template-editor .builder-wrap .right-sidebar:hover .njc-right-menu-collapse-button {
  opacity: 1;
}
.right-menu-collapsed.njc-template-editor .builder-navbar {
  width: 100%;
}
.left-menu-collapsed.njc-template-editor .builder-wrap .center-area .support-bar {
  width: calc(100% - 14px);
  margin: 0 0 0 14px;
}
.right-menu-collapsed.njc-template-editor .builder-wrap .center-area .support-bar {
  width: calc(100% - 14px);
  margin: 0 14px 0 0;
}
.left-menu-collapsed.right-menu-collapsed.njc-template-editor .builder-wrap .center-area .support-bar {
  width: calc(100% - 28px);
  margin: 0 auto;
}
.njc-template-editor-body .swal2-checkbox {
  text-align: center;
}
.njc-template-editor-body .swal2-checkbox input {
  margin: 0 8px 0 0;
}
.njc-template-editor-body .swal2-checkbox span {
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 18px;
}
.njc-template-editor-body .select2-container--open .select2-dropdown .select2-results__option {
  background-color: var(--template-main-90);
  font-size: 11px;
  color: var(--template-main-20);
  font-weight: 400;
  text-align: left;
  line-height: 12px;
}
.njc-template-editor-body .select2-container--open .select2-dropdown .select2-results__option:hover {
  background-color: var(--primary-color) !important;
  color: var(--template-main-5);
  cursor: pointer;
}
.comparison-metric-cell-inner-font {
  font-size: 15px;
  font-weight: normal;
}
.njc-range-bit-wrap {
  width: 100%;
  display: flex;
}
.njc-range-bit-wrap .property-label {
  min-width: 60px;
  margin-right: 8px;
  align-items: center;
}
.njc-range-bit {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  position: relative;
  width: 100%;
}
/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--template-main-80);
    height: 2px;
  }
  input[type='range']::-webkit-slider-runnable-track {
    height: 2px;
    -webkit-appearance: none;
    color: var(--template-main-0);
  }
  input[type='range']::-webkit-slider-thumb {
    width: 10px;
    height: 10px;
    cursor: ew-resize;
    background: var(--template-main-0);
    border-radius: 50%;
    margin-top: -4px;
    -webkit-appearance: none;
  }
}
/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: var(--template-main-0);
}
input[type="range"]::-moz-range-track {
  background-color: var(--template-main-80);
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: var(--template-main-0);
}
input[type="range"]::-ms-fill-upper {
  background-color: var(--template-main-80);
}
input[type="range"]:disabled {
  cursor: not-allowed;
}
input[type="range"]:disabled::-webkit-slider-thumb {
  background: var(--template-main-80);
  cursor: not-allowed;
}
.njc-range-number {
  width: 54px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 11px;
  color: var(--template-main-20);
  font-weight: 400;
  text-align: left;
  line-height: 12px;
  border: 1px solid var(--template-contour);
  border-radius: 5px;
  background-color: var(--template-hover);
  height: 26px;
  background-image: none;
  padding: 0 16px 0 8px;
  margin-bottom: 0px;
  margin-left: 8px;
}
.njc-range-number:disabled {
  color: var(--grayscale-70);
}
.njc-range-bit-symbol {
  position: absolute;
  top: 7px;
  right: 3px;
  font-size: 11px;
  color: var(--template-main-20);
  font-weight: 400;
  text-align: left;
  line-height: 12px;
}
.njc-temp-separator {
  width: calc(100% - 26px);
  height: 1px;
  background-color: var(--grayscale-80);
  margin: 15px 13px;
}
.njc-shadow-bit {
  flex-direction: column;
  align-items: flex-start;
  margin: 4px 0;
}
.njc-shadow-bit .colorpicker-bit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-shadow-bit .colorpicker-bit .colorpicker-bit-inner {
  width: 100%;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-shadow-bit .colorpicker-bit .colorpicker-bit-inner .njc-temp-styling-left-label {
  width: 55px;
  display: flex !important;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-shadow-bit .colorpicker-bit .colorpicker-bit-inner .sp-replacer {
  top: 0;
  z-index: 1;
}
.njc-shadow-bit .colorpicker-bit .colorpicker-bit-inner .sp-replacer .sp-preview {
  width: 26px;
  height: 26px;
  border: 1px solid var(--template-contour);
  border-radius: 3px;
  overflow: hidden;
}
.njc-shadow-bit .colorpicker-bit .colorpicker-bit-inner .sp-replacer .sp-preview .sp-preview-inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.njc-shadow-bit .colorpicker-bit .colorpicker-bit-inner .sp-replacer .sp-preview .sp-preview-inner:after {
  content: " ";
  width: 15px;
  height: 15px;
  background-color: var(--grayscale-0);
  display: block;
  position: absolute;
  bottom: -8px;
  right: -8px;
  transform: rotate(45deg);
}
.njc-shadow-bit .colorpicker-bit .colorpicker-bit-inner img {
  display: none;
}
.njc-shadow-bit .colorpicker-bit .colorpicker-bit-inner .colorpicker-bit-inner {
  width: 26px;
  height: 26px;
  margin-left: -26px;
  margin-right: 0px;
  border: 1px solid var(--template-contour);
  border-radius: 3px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--template-hover) !important;
}
.njc-shadow-bit .colorpicker-bit .colorpicker-bit-inner .colorpicker-bit-inner svg {
  width: 14px;
  height: 14px;
  background-color: var(--grayscale-0);
  border-radius: 3px;
}
.njc-shadow-bit .colorpicker-bit .njc-temp-styling-left-label {
  width: 60px !important;
  margin-right: 8px;
  display: flex !important;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}
.njc-temp-child-section .njc-shadow-bit {
  border-left: 2px solid var(--template-main-80);
}
.disabled .colorpicker-bit,
.disabled .sp-replacer,
.disabled .njc-range-bit-wrap {
  cursor: not-allowed;
}
.njc-template-editor-setting-title {
  padding: 12px 13px 4px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 12px;
  font-weight: 500;
  color: var(--template-main-10);
  text-align: left;
}
.njc-temp-shape-widget-menu .njc-temp-widget-column-select-option-content {
  height: 100% !important;
}
.njc-temp-shape-widget-menu .njc-temp-widget-column-select-option-content svg {
  margin-top: 12px !important;
}
.nc-modal-template-builder .table-widget .table-column-headers {
  border-bottom: 0px;
}
.nc-template-builder-modal .hasError .settings-panel-property-dropdown {
  border: 2px solid var(--critical-color) !important;
}
.nc-template-builder-modal .error-message {
  color: var(--critical-color);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 8px;
  text-align: left;
}
.nc-template-builder-modal .error-message .lowercase {
  text-transform: lowercase;
}
.nc-modal-template-builder .widget-container pre {
  font-family: inherit;
  margin: 0;
  display: inline;
}
.nc-modal-template-builder .widget-container.notLocked .resize-handles.selected {
  z-index: 250 !important;
}
.njc-template-widget-type-modal-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;
  padding: 8px 0 0 0;
}
.njc-template-widget-type-modal-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: calc(16% - 8px);
  gap: 4px;
  border-radius: 5px;
  border: 3px solid transparent;
  cursor: pointer;
  padding: 4px;
}
.njc-template-widget-type-modal-item .njc-template-widget-type-modal-item-text {
  font-size: 12px;
  color: var(--template-main-80);
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}
.njc-template-widget-type-modal-item svg {
  color: var(--template-main-80);
  width: 24px;
  height: 24px;
}
.njc-template-widget-type-modal-item.selected {
  border: 3px solid var(--template-main-80);
}
.njc-template-widget-type-modal-item.selected .njc-template-widget-type-modal-item-text {
  color: var(--template-main-100);
}
.njc-template-widget-type-modal-item.selected svg {
  color: var(--template-main-100);
}
.nc-modal-template-builder .widget-container .wrap pre,
.nc-modal-template-builder .widget-container .wrap-break-word pre {
  white-space: pre-wrap;
}
.njc-edit-data-cell {
  width: 100%;
  border: 2px solid transparent;
  border-radius: 5px;
  padding: 4px 3px;
  margin: 2px;
}
.njc-edit-data-modal .Modal.u-overlay.animated {
  overflow-y: auto;
  background-color: rgba(45, 53, 64, 0.9);
}
.njc-edit-data-modal.expanded .Modal__container.animated {
  max-width: 95%;
}
.njc-edit-data-modal .Modal__container.animated {
  width: 95%;
  max-width: 1040px;
  margin: auto;
  padding: 0px;
  border-radius: 8px;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
}
.njc-edit-data-modal .Modal__container.animated .njc-small-modal-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.njc-edit-data-modal .Modal__container.animated .njc-small-modal-title .njc-modal-title-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.njc-edit-data-modal .Modal__container.animated .njc-small-modal-title .njc-modal-title-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
.njc-edit-data-modal .Modal__container.animated .njc-small-modal-title .njc-modal-title-right .njc-modal-user {
  font-size: 12px;
  color: var(--grayscale-100);
  background-color: var(--grayscale-10);
  border-radius: 5px;
  padding: 4px;
  font-weight: 400;
  line-height: 18px;
}
.njc-edit-data-modal .Modal__container.animated .njc-form-footer-row {
  padding-bottom: 0px;
  width: 100%;
}
.njc-edit-data-modal .Modal__container.animated .njc-form-footer-row .njc-form-footer-row-container {
  width: calc(100% + 100px);
}
.njc-edit-data-modal .Modal__content {
  margin: 32px 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(95vh - 52px);
  position: relative;
}
.njc-edit-data-modal .Modal__content .modal-buttons {
  position: absolute;
  top: -20px;
  right: -26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.njc-edit-data-modal .Modal__content .modal-buttons .modal-button:hover {
  background-color: transparent;
}
.njc-edit-data-modal .Modal__content .modal-buttons .njc-icon-expand svg {
  width: 14px;
  height: 14px;
}
.njc-edit-data-modal .Modal__content .modal-buttons .njc-icon-close svg {
  width: 18px;
  height: 18px;
}
.njc-edit-data-modal .njc-table-container {
  max-height: 100%;
  height: fit-content;
  width: 100%;
  overflow: auto;
  margin-top: 22px;
  border-radius: 5px;
}
.njc-edit-data-modal .njc-table-container::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
.njc-edit-data-modal .njc-table-container::-webkit-scrollbar-track {
  background-color: var(--grayscale-0);
}
.njc-edit-data-modal .njc-table-container::-webkit-scrollbar-thumb {
  background: var(--grayscale-30);
  border: 2px solid var(--grayscale-0);
  border-radius: 7px;
  min-height: 10px;
  min-width: 10px;
}
.njc-edit-data-modal .njc-table-container::-webkit-scrollbar-thumb:hover {
  background: var(--grayscale-50);
}
.njc-edit-data-modal .njc-table-container::-webkit-scrollbar-corner {
  background: var(--grayscale-0);
}
.njc-edit-data-modal .njc-table-body {
  display: table;
  width: auto;
  min-width: 100%;
  table-layout: auto;
}
.njc-edit-data-modal .njc-table-row {
  display: table-row;
}
.njc-edit-data-modal .njc-table-row.new {
  background-color: var(--success-color-5);
}
.njc-edit-data-modal .njc-table-row .njc-table-cell {
  border-bottom: 1px solid var(--grayscale-30);
}
.njc-edit-data-modal .njc-table-row .njc-table-cell:first-child {
  border-left: 1px solid var(--grayscale-30);
}
.njc-edit-data-modal .njc-table-row .njc-table-cell:last-child {
  border-right: 1px solid var(--grayscale-30);
}
.njc-edit-data-modal .njc-table-row.njc-table-header-row .njc-table-cell {
  border-top: 1px solid var(--grayscale-30);
  border-bottom: 2px solid var(--grayscale-30);
}
.njc-edit-data-modal .njc-table-row.njc-table-header-row .njc-table-cell:first-child {
  border-radius: 5px 0 0 0;
}
.njc-edit-data-modal .njc-table-row.njc-table-header-row .njc-table-cell:last-child {
  border-radius: 0 5px 0 0;
}
.njc-edit-data-modal .njc-table-row.njc-table-footer-row {
  position: sticky;
  bottom: 0;
  z-index: 2;
  background-color: var(--grayscale-0);
}
.njc-edit-data-modal .njc-table-row.njc-table-footer-row.has-comparison-row {
  bottom: 43px;
}
.njc-edit-data-modal .njc-table-row.njc-table-footer-row.has-comparison-row .njc-table-cell:first-child,
.njc-edit-data-modal .njc-table-row.njc-table-footer-row.has-comparison-row .njc-table-cell:last-child {
  border-radius: 0;
}
.njc-edit-data-modal .njc-table-row.njc-table-footer-row .njc-table-cell {
  border-top: 2px solid var(--grayscale-30);
}
.njc-edit-data-modal .njc-table-row.njc-table-footer-row .njc-table-cell:first-child {
  border-radius: 0 0 0 5px;
}
.njc-edit-data-modal .njc-table-row.njc-table-footer-row .njc-table-cell:last-child {
  border-radius: 0 0 5px 0;
}
.njc-edit-data-modal .njc-table-row.selected {
  background-color: var(--primary-color-5);
}
.njc-edit-data-modal .njc-table-row.selected-for-delete {
  position: relative;
  animation: RowDeleteAnimation 300ms ease-in forwards;
  transform-origin: top;
}
.njc-edit-data-modal .njc-table-row.selected-for-delete::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: var(--critical-color-50);
  animation: RowDeleteCoverAnimation 200ms ease-in forwards;
}
.njc-edit-data-modal .njc-table-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 8px;
  font-size: 13px;
  color: var(--template-main-100);
  font-weight: 400;
  line-height: 16px;
  height: 40px;
  min-width: 100px;
  white-space: nowrap;
  text-align: left;
}
.njc-edit-data-modal .njc-table-cell.number {
  text-align: right;
}
.njc-edit-data-modal .njc-table-cell.njc-table-header-cell,
.njc-edit-data-modal .njc-table-cell.njc-table-footer-cell {
  font-weight: 700;
}
.njc-edit-data-modal .njc-data-cell {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  min-height: 24px;
  border-radius: 3px;
}
.njc-edit-data-modal .njc-data-cell.number {
  justify-content: flex-end;
}
.njc-edit-data-modal .njc-data-cell.empty {
  border: 2px dashed var(--grayscale-10);
}
.njc-edit-data-modal .njc-data-cell:hover {
  border: 2px solid var(--template-main-30);
}
.njc-edit-data-modal .njc-table-cell-checkbox {
  position: sticky;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: var(--grayscale-0);
  border-right: 1px solid var(--grayscale-30);
  width: 74px;
  min-width: 74px;
  text-align: center;
}
.njc-edit-data-modal .njc-table-cell-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid var(--grayscale-30);
  background-color: var(--grayscale-0);
  cursor: pointer;
  position: relative;
}
.njc-edit-data-modal .njc-table-cell-checkbox input[type="checkbox"]:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.njc-edit-data-modal .njc-table-cell-checkbox input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-image: url('/images/njc/njc-checkbox.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.njc-edit-data-modal .njc-table-cell-checkbox input[type="checkbox"].partial:checked::after {
  background-image: url('/images/njc/njc-checkbox-partial.svg');
}
.njc-edit-data-modal .njc-table-cell-checkbox input[type="checkbox"]:disabled {
  cursor: not-allowed;
  background-color: var(--grayscale-10);
}
.njc-edit-data-modal .njc-input-field {
  width: 100%;
  height: 24px;
}
.njc-edit-data-modal .njc-input-field[type="number"] {
  text-align: right;
}
.njc-edit-data-modal .njc-table-actions-container {
  width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.njc-edit-data-modal .njc-table-actions-container .njc-table-action-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 4px;
  margin-top: 18px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: var(--grayscale-100);
}
.njc-edit-data-modal .njc-table-actions-container .njc-table-action-link svg {
  width: 16px;
  height: 16px;
}
.njc-edit-data-modal .njc-table-actions-container .njc-table-action-link:hover {
  text-decoration: underline;
}
.njc-edit-data-modal .njc-table-actions-container .njc-table-action-link.disabled {
  cursor: not-allowed;
  color: var(--grayscale-50);
}
.njc-edit-data-modal .njc-table-actions-container .njc-table-action-link.disabled:hover {
  text-decoration: none;
}
.njc-edit-data-modal .njc-table-actions-container .njc-table-action-link.njc-table-action-delete-link {
  color: var(--critical-color);
}
.njc-widget-status-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
@keyframes RowDeleteAnimation {
  0%,
  66.67% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}
@keyframes RowDeleteCoverAnimation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
/*CoPilot*/
.copilot-launcher-wrapper {
  position: fixed;
  right: 26px;
  z-index: 1000;
  transition: bottom 0.5s ease-out 0s;
  /* only transition top property */
}
.copilot-launcher-wrapper.flying-solo {
  bottom: 20px;
}
.copilot-launcher-wrapper.flying-duo {
  bottom: 100px;
}
.copilot-launcher-wrapper .copilot-launcher-button {
  border-radius: 30px;
  height: 60px;
  width: 60px;
}
.copilot-launcher-wrapper .copilot-launcher-button svg {
  margin: 0;
  scale: 1.7;
  position: relative;
  left: 1px;
  bottom: 1px;
}
.copilot-launcher-wrapper .copilot-launcher-button .copilot-logo-st0 {
  opacity: 0.6;
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #FFFFFF;
  enable-background: new;
}
.copilot-launcher-wrapper .copilot-launcher-button .copilot-logo-st1 {
  opacity: 0.8;
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #FFFFFF;
  enable-background: new;
}
.copilot-launcher-wrapper .copilot-launcher-button .copilot-logo-st2 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #FFFFFF;
}
.copilot-launcher-wrapper .copilot-embed-iframe-wrapper {
  position: fixed;
  z-index: 1;
  bottom: 0px;
  right: 0px;
  border-left: 1px solid grey;
  background-color: white;
  transition: transform 300ms ease-out;
}
.copilot-launcher-wrapper .copilot-embed-iframe-actions {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid grey;
  padding: 3px;
  border-radius: 20px;
  top: 9px;
  left: -51px;
}
.copilot-launcher-wrapper .copilot-embed-iframe-close {
  padding-top: 4px;
}
.copilot-launcher-wrapper .copilot-embed-iframe {
  height: 100%;
  width: 100%;
  border: none;
}
.copilot-launcher-wrapper .copilot-embed-iframe:before {
  content: " ";
  height: 100%;
  position: absolute;
  top: 0;
  width: 15px;
  box-shadow: -15px 0 15px -15px inset;
  left: -15px;
}
