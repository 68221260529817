:root {
  --primary-color: #1E66F0;
  --primary-color-2: #EFF4FE;
  --primary-color-5: #E5EDFF;
  --primary-color-10: #CCDBFC;
  --primary-color-20: #99B5FA;
  --primary-color-50: #6691FA;
  --primary-color-dark: #1855CD;
  --primary-color-variant: #1A5AD7;
  --primary-color-bg: #EEEFFC;
  --primary-color-hover: #1B5EDF;
  --secondary-color: #E5E3FB;
  --secondary-color-variant: #8E95B6;
  --tertiary-color: #FF608C;

  --grayscale-100: #2D3540;
  --grayscale-90: #424A58;
  --grayscale-80: #545D6B;
  --grayscale-70: #697280;
  --grayscale-60: #7E8694;
  --grayscale-50: #939AA7;
  --grayscale-40: #A8AEBA;
  --grayscale-30: #BDC2CC;
  --grayscale-20: #D5DAE1;
  --grayscale-10: #E7EAEF;
  --grayscale-5: #F2F4F7;
  --grayscale-3: #F9FAFB;
  --grayscale-0: #FFFFFF;

  --success-color: #239B2A;
  --success-color-50: #8BCA8E;
  --success-color-5: #F4FAF4;
  --success-bg-color: #C2E9C6;
  --warning-color: #F5A523;
  --warning-color-50: #FAD08C;
  --warning-color-5: #FEF6E9;
  --warning-color-dark: #D27412;
  --warning-bg-color: #FCE4BD;
  --critical-color: #EB0057;
  --critical-color-50: #F27DA8;
  --critical-color-5: #F8EDF1;

  --purple-dark: #450082;
  --purple-100: #7C1EFA;
  --purple-50: #BA8CF9;
  --purple-5: #F3EEF9;

  --modal-background: #D5DAF090;
  --listhovercolor: #EFF4FE;
}