@media screen and (max-height: 767px) {
  .njc-main-menu {

    .njc-main-menu-account {
      height: 80px;
      transition: all 0.2s;

      .njc-agency-logo {
        img , svg {
          width: 40px;
          height: auto;
          transition: all 0.2s;
        }
      }
      .njc-menu-info {
        .njc-menu-title {
          font-size: 13px;
          transition: all 0.2s;
        }
        .njc-menu-subtext {
          font-size: 12px;
          transition: all 0.2s;
        }
      }
    }
    .njc-main-menu-dropdowns {
      height: calc(~"100% -" 80px);
    }
    .njc-main-menu-item {
      .njc-main-menu-item-label {
        height: 48px;
        transition: all 0.2s;

        .njc-menu-icon {
          svg {
            .njc-sizing-sq(18px);
            transition: all 0.2s;
          }
        }
        .njc-menu-text {
          font-size: 13px;
          margin-left: @spacing-3;
          transition: all 0.2s;
        }
      }

      &.active {
        .njc-main-menu-item-dropwrap {
          .njc-menu-item {
            min-height: 32px;
            max-height: 32px;
            font-size: 13px;
            padding: @spacing-0 @spacing-5 @spacing-0 51px;
            transition: all 0.2s;
          }
        }
      }
    }
  }
}