* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#root-vue {
  .njc-flex-direction-row;

  > div {
    .njc-full-width;
  }
}

html {
  height: 100%;
}

body {
  height: 100%;
  background-color: var(--grayscale-3);
  line-height: normal;
  .njc-text-paragraph;
  .njc-font-1;
  margin: @spacing-0;
}

button {
  .njc-font-1;
}

.njc-loader {
  &-container {
    height: 100%;
    .njc-flex-direction-row-center;
    svg {
      color: var(--primary-color);
    }
  }

  &-table-overlay {
    position: absolute;
    z-index: 10;
    padding-top: 10px;
    top: 40px;
    width: 100%;
    height: calc(~"100% -" 40px);
    .njc-loader-container {
      .njc-flex-centerstart;
    }
  }
}

.njc-align-left {
  margin-right: auto;
}
.njc-align-right {
  margin-left: auto;
}
.njc-align-text-right {
  text-align: right;
}
/*Account list*/

.njc-body {
  margin-left: 240px;
  margin-bottom: 80px;
  padding: 0px;
  transition: width 1s;
  &.minimize {
    margin-left: 55px;
  }
}
.njc-body-content {
  margin:0 auto;
  width: 90%;

  .njc-widget-card {
    .njc-nodata-card {
      border: 1px dashed var(--grayscale-30);
      border-radius: 5px;
      min-height: 200px;
      padding: 34px @spacing-3 56px;
      .njc-flex-direction-column-center;

      .njc-command-center-nodata {
        .njc-nodata-small-content;
      }

      .njc-nodata-card-content {
        display: none !important;
      }
      .njc-command-center-nodata {
        display: flex !important;
      }
    }
    .nc-table-page-footer {
      border-top: 0px solid transparent;
    }
  }

  .njc-command-center-row {
    width: 100%;
    .njc-flex-direction-row;
    flex-wrap: wrap;

    .njc-flex-expand {
      flex: 1;
    }

    .njc-command-center-panel {
      margin-bottom: @spacing-large;

      .njc-command-center-title-row {
        .njc-flex-direction-row;
        .njc-flex-justify;
        padding-bottom: @spacing-4;

        .njc-command-center-title {
          h4 {
            .njc-text-heading-2;
          }
        }
        .njc-command-center-title-add {
          margin-right: -4px;
          .njc-sizing-sq(36px);
          .njc-icon-button {
            svg {
              border: 1px solid var(--primary-color);
              border-radius: 5px;
              padding: 4px;
              background-color: transparent;
              color: var(--primary-color);
            }
            &:hover {
              svg {
                background-color: var(--primary-color);
                color: var(--grayscale-0);
              }
            }
          }
        }
      }

      .nc-table-page-footer {
        display: none;
      }
    }
  }

  .njc-command-center-datasources {
    margin-right: @spacing-large;

    .njc-list-table-row {
      &.njc-clickable-row {
        cursor: pointer;
      }
    }
    .njc-list-table {
      max-height: 320px;
      .njc-app-scrollbars;
    }
  }
  .njc-command-center-ninjatrack {
    margin-left: @spacing-2;
    .njc-list-table {
      max-height: 320px;
      overflow-x: hidden;
      .njc-app-scrollbars;

      .njc-table-icon-more-action-menu {
        right: 110px;
      }

      .njc-icon-button-hover {
        top: 0;
        right: 75px;

        .njc-icon-button-hover-arrow:after {
          border-left-color: var(--grayscale-100);
          border-top-color: transparent;
          top: 7px;
          right: -10px;
          left: auto;

        }
      }
    }
  }

  .njc-command-center-reports-legacy {
    .njc-table-icon-more-action-menu {
      right: 110px;
    }
  }

  .njc-command-center-kpis {
    .njc-campaign-bullet-graph;

    .nc-bullet-graph {
      width: 100%;
      .justify-space-around:first-child {
        .njc-flex-direction-row;
        justify-content: space-between;
      }
      .nc-bullet-graph-container {
        margin: 8px 0 0 0;

        .nc-bullet-graph-projected {
          width: 100%;
          background-color: var(--grayscale-10);
        }
        .nc-bullet-graph-target {
          height: 8px;
          border-left: 1px solid var(--grayscale-100);
          border-right: 1px solid var(--grayscale-100);
        }
      }
    }

    .njc-table-icon-more-action-menu {
      right: 110px;
    }
  }

  .njc-command-center-campaigngroup {
    margin-top: @spacing-5;
    .njc-command-center-panel {
      margin-bottom: @spacing-0;

      .njc-list-card {
        border: 1px solid var(--grayscale-30);
        .njc-shadow;

        .njc-list-table {
          overflow-y: visible;
          overflow-x: visible;
          .njc-list-table-header {
            border-bottom: 1px solid var(--grayscale-30);
            border-radius: 5px 5px 0 0;
          }
        }
      }
    }
  }

  .njc-command-center-datasources ,
  .njc-command-center-reports-legacy ,
  .njc-command-center-changehistory ,
  .njc-command-center-ninjatrack ,
  .njc-command-center-kpis ,
  .njc-command-center-campaigngroup {
    .njc-widget-card {
      .njc-list-card {
        border-top: 0px solid #fff;
        border-radius: 0 0 5px 5px;
      }
      .nc-display-table-card-header {
        border-bottom: 0px solid #fff;
        border-top: 1px solid var(--grayscale-30);
        border-radius: 5px 5px 0 0;
      }
    }
  }
}

.njc-command-center-settings {

  .njc-command-center-settings-extra-buttons {
    .njc-flex-direction-row;

    .njc-btn-secondary {
      margin-left: @spacing-2;
    }
  }

  .njc-first-container , .njc-second-container {
    .njc-card-header-2-row {
      h3 {
        padding-top: 20px !important;
      }
    }
  }
  .njc-second-container {
    padding-top: 20px;
  }

  .njc-search-bar-row {
    margin-top: @spacing-5;
  }
}




.njc-list-card {
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  background-color: var(--grayscale-0);
  .njc-shadow;

  &.njc-reorder-table-wrap {
    border: none;
    background-color: transparent;
    box-shadow: none;

    .njc-list-table {

      .njc-list-table-header {
        border-bottom: none;
        border-radius: 5px;

        .njc-list-table-cell:nth-child(2) {
          padding-left: 0px;
        }
      }
      .njc-list-table-row {

        &:last-child:before {
          border-bottom: 1px solid var(--grayscale-20);
        }

        .njc-list-table-cell {
          padding-top: @spacing-2;
          padding-bottom: @spacing-2;

          .template-content-wrap {
            padding-left: 0px !important;
            .njc-flex-direction-column;
          }

          .njc-form-input {
            .njc-text-form;
            .njc-text-form-border;
            height: 36px;
          }

          &.njc-table-column-icon {
            .njc-icon-button {
              padding: 4px !important;
            }
          }

          .njc-form-text-default {
            padding: @spacing-0;

            .njc-form-input-wrap {
              margin-top: @spacing-0;
            }
          }

        }
      }
      .njc-form-text-default-input-container {
        min-width: auto;
        flex-basis: auto;
      }

    }
  }

  .njc-list-table {
    .njc-flex-direction-column;

    .njc-list-table-row {
      .njc-flex-direction-row;
      .njc-flex-stretch;
      .njc-text-table-standard;
      position: relative;
      padding: @spacing-0 @spacing-5 !important;

      &:before {
        content: "";
        position: absolute;
        left: 20px;
        bottom: 0;
        height: 1px;
        width: calc(~"100% -" 40px);
        border-bottom: 1px solid var(--grayscale-20);
      }
      &:last-child {
        &:before {
          border-bottom: 0px solid var(--grayscale-0);
        }
      }

      &.njc-clickable-row:hover {
        background-color: var(--listhovercolor);
      }

      &-flex-s {
        .njc-list-table-cell {
          .njc-flex-start;
        }
      }
      &.njc-table-column-bold {
        .njc-text-table-standard-bold;
      }

      .njc-list-status-column {
        & div {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
      }

      .njc-table-column-icon {
        justify-content: flex-end;
        display: flex;


        a {
          display: inline-block;
        }

      }

    }
    .njc-list-table-header {
      .njc-flex-direction-row;
      .njc-flex-stretch;
      background-color: var(--grayscale-5);
      height: 40px;
      border-bottom: 1px solid var(--grayscale-30);
      border-radius: 5px 5px 0 0;
      padding: @spacing-0 @spacing-5;
      position: sticky;
      top: 0;
      z-index: 9;

      &.njc-list-table-header-expanded-height {
        height: 56px;
      }

      .njc-table-column-icon {
        text-indent: 500%;
        white-space: nowrap;
        overflow: hidden;
      }
      .njc-list-table-cell {
        .njc-text-table-header;


        .njc-list-table-cell-content {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          .njc-text-table-header;

          .njc-list-table-cell-content-h-scroll {
            overflow-y: auto;
          }

          .icon-image-wrap , .njc-table-icon-sort {
            .njc-sizing-sq(24px);
            img {
              padding-top: 24px;
              overflow: hidden;
              background-position: 50% 50%;
              background-repeat: no-repeat;
              .njc-sizing-sq(24px);
            }
            img[src*="sort_list"] {
              background-image: url("/images/njc/njc-icon-sort-list.svg");
            }
            img[src*="sort_list_down"] {
              background-image: url("/images/njc/njc-icon-sort-up.png");
            }
            img[src*="sort_list_up"] {
              background-image: url("/images/njc/njc-icon-sort-down.png");
            }
          }


          .text-7b7b7b , .njc-list-table-cell-content-text {
            margin: @spacing-0;
            .njc-text-table-header;
          }
        }

        &.sorting_asc .njc-table-icon-sort-standalone {
          background-image: url("/images/njc/njc-icon-sort-down.png");
        }
        &.sorting_desc .njc-table-icon-sort-standalone {
          background-image: url("/images/njc/njc-icon-sort-up.png");
        }
        .njc-table-icon-sort-standalone {
          .njc-sizing-sq(24px);
          background-position: 50% 50%;
          background-repeat: no-repeat;

        }

        &.njc-list-table-cell-column {
          .njc-list-table-cell-content {
            flex-direction: row-reverse;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }
    }
    .njc-list-table-cell {
      .njc-flex-direction-row;
      .njc-flex-startcenter;
      padding-top: @spacing-4;
      padding-bottom: @spacing-4;

      &:first-child {
        padding-left: @spacing-0;
      }
      &:last-child {
        padding-right: @spacing-0;
      }

      &-flex-dc {
        .njc-flex-direction-column-start;
      }
      &-flex-dr {
        .njc-flex-direction-row-start;
      }

      &-content {
        .njc-flex-direction-row;
        .njc-flex-startcenter;
      }

      .round-image {
        .njc-logo-pic(30px);
        margin-right: @spacing-3;

        &.generic-account {
          .njc-text-generic-logo;
          background-color: var(--primary-color);
          text-transform: uppercase;
          .njc-sizing-sq(36px);
          .njc-flex-center;
          .njc-flex-direction-row;
        }
      }

      .njc-col-status {
        &-reports {
          .njc-flex-direction-column-start !important;

          &-content {
            .njc-flex-direction-row;
            .njc-flex-startcenter;
            flex-wrap: nowrap !important;

            svg {
              margin-right: @spacing-1;
              .njc-sizing-sq(18px);
              min-width: 18px;
            }
          }
          .dark-text-link {
            .njc-text-link !important;
            margin-left: 23px;
          }
        }
        &-scheduled {
          .njc-text-status-message-primary;
        }
        &-ready {
          .njc-text-status-message-primary;
        }
        &-sent {
          .njc-text-status-message-success;
        }
        &-dataerror {
          .njc-text-status-message-warning;
        }
        &-criticalerror {
          .njc-text-status-message-critical;
        }
        &-emailerror {
          .njc-text-status-message-warning;
        }
        &-readytorun {
          .njc-text-status-message-primary;
        }
        &-inprogress {
          .njc-text-status-message-groovy-info;
        }
        &-paused {
          .njc-text-status-message-disabled;
        }
      }

      .njc-status-text-connected {
        .njc-text-status-message-success;
      }
      .njc-status-text-error {
        .njc-text-status-message-critical;
      }

      .icon-image-wrap {
        img {
          .njc-sizing-sq(24px);
        }

      }

      .template-body-text {
        .njc-text-table-small;
        strong {
          .njc-text-table-small-bold;
        }
      }
      .njc-template-portrait {
        img {
          width: 100%;
        }
      }
      .template-networks-wrap {
        .njc-flex-direction-row;
        flex-wrap: wrap;

        .network-icon-wrap {
          .njc-sizing-sq(24px);
          border-radius: 5px;
          .njc-flex-direction-row-center;
          margin-right: @spacing-2;
          background-color: transparent;
          margin-top: @spacing-2;

          img {
            .njc-sizing-sq(18px);
            border-radius: 3px;
          }
        }
      }

      &.njc-list-table-cell-column {
        .njc-list-table-cell-content {
          .njc-flex-direction-column;
          .njc-flex-centerstart;
        }
      }
      .njc-user-status-active,
      .njc-user-status-suspended ,
      .njc-user-status-invited,
      .njc-user-status-expired {
        .njc-flex-direction-row;
        .njc-flex-startcenter;
      }
      .njc-user-status-suspended ,
      .njc-user-status-expired {
        .njc-text-status-message-critical;
      }

      .njc-user-status-active {
        .njc-text-status-message-success;
      }

      .njc-user-status-invited {
        .njc-text-status-message-primary;
      }

      .njc-user-status-signin {
        font-size: 13px;
        color: var(--grayscale-70);
      }
    }

    .njc-list-empty {
      .njc-flex-direction-row-center;
      padding: @spacing-5;
      span , .dataTables_empty {
        .njc-text-table-small;
      }
    }
  }

  .njc-profile-column {
    .round-image{
      border-radius: 50% !important;
    }
  }



  &.njc-list-card-alternate {
    margin-top: -10px;
    .njc-tertiary {
      margin: 0;
      padding: 10px 50px 0;
    }
    .njc-list-card-container {
      margin: 0 auto 20px;
      width: calc(~"100% -" 100px);
      .njc-form-buttons-bar {
        [class^="njc-btn"] {
          margin-left: @spacing-2;
        }
      }

      .njc-alternate-table-margin {
        margin-top: @spacing-5;
      }
    }

    .njc-list-card {
      border: 0 solid transparent;
      border-radius: 0;
      box-shadow: none;

      .njc-list-table-header {
        border-radius: 5px;
        padding: 0 @spacing-3;
        border: 0px solid transparent;

        .text-8a8a8a {
          margin: @spacing-0;
          .njc-text-table-header;
        }
      }
      .njc-list-table-row {
        padding: 0 @spacing-3 !important;

        &:before {
          left: 0;
          width: 100%;
        }
      }
    }
  }
}

.nc-table-page-footer {
  display: flex;
  align-items: center;
  width: 100%;
  padding: @spacing-0 @spacing-5;
  border-top: 1px solid var(--grayscale-20);

  .pagination-footer {
    display: flex;
    height: 70px;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .njc-table-footer-control-group , .njc-table-footer-control-group .paging_simple_numbers , .njc-table-footer-control-group span {
      display: flex;
      align-items: center;
    }

    .pagination-navlink {
      .njc-text-table-footer;

      &.njc-table-footer-page-display {
        text-transform: lowercase;
        cursor: default;

        &:first-letter {
          text-transform: capitalize;
        }
        &:hover {
          background-color: transparent;
        }
      }

      &.njc-table-footer-show-all {
        padding: 0 @spacing-5;
        height: 30px;
        .njc-flex-direction-row-center;
      }
      &.njc-table-footer-prev {
        padding: 0 @spacing-3;
        height: 30px;
        .njc-flex-direction-row-center;
      }
      &.njc-table-footer-pages , .njc-table-footer-pages {
        .njc-sizing-sq(30px);
        .njc-flex-direction-row-center;
      }
      &.njc-table-footer-next {
        padding: @spacing-0 @spacing-3;
        margin-right: -8px;
        height: 30px;
        .njc-flex-direction-row-center;
      }

      &:hover {
        background-color: var(--grayscale-5);
        color: var(--grayscale-70);
      }
    }

    .dataTables_info {
      .njc-table-footer-page-display {
        text-transform: initial;
      }
    }
  }
}

.njc-has-tertiary-nav {
  .njc-nodata-card {
    border-top: 0px solid transparent;
  }
}

.njc-kpi-list {
  .njc-list-card .njc-list-table .njc-list-table-row:nth-last-child(2):before {
    border-bottom: 0px solid var(--grayscale-0);
  }
}
.njc-table-kpi-title {
  .njc-text-table-list-subhead;
  padding-top: @spacing-3 !important;
  padding-bottom: @spacing-3 !important;
}
.account-wrap.njc-list-table-row:hover {
  background-color: transparent !important;
}
.kpi-row.njc-list-table-row {
  .njc-kpi-performance-graph {
    flex-wrap: wrap;
  }
  .njc-list-table-cell:first-child {
    padding-left: @spacing-5 !important;
  }

  &:before {
    margin-left: 20px;
    width: calc(~"100% -" 60px) !important;
  }

  .kpi-bullet-graph-wrap {
    .njc-flex-direction-column;
    width: 100%;

    .kpi-bullet-graph-top {
      .njc-flex-direction-row;
      .njc-flex-justify;
    }
    .nc-kpi-bullet-graph-area {
      .njc-campaign-bullet-graph;
    }
    .kpi-bullet-graph-bottom {
      .njc-text-table-small;
      .njc-flex-direction-row;
      .njc-flex-justify;
    }
  }

  .nc-kpi-rate-arrow-graph {
    width: 100%;

    .date-cycle {
      width: 40%;
      .njc-flex-direction-column;
      .njc-flex-centerstart;
      .njc-text-table-small;

      strong {
        .njc-text-table-standard-bold;
      }
    }
    .kpi-current , .kpi-projected , .kpi-target {
      width: 20%;
      .njc-text-table-standard-bold;
      .njc-flex-direction-column-center;

      span {
        .njc-text-table-small;
      }
    }
  }
  .njc-kpi-gap-text {
    .njc-text-table-standard-bold;
  }
  &.normal {

    .nc-bullet-graph-projected {
      background-color: var(--success-bg-color);
    }
    .nc-bullet-graph-current {
      background-color: var(--success-color);
    }

    &.kpi_never_process {
      .kpi-msg:after {
        content: 'We are currently gathering data. Come back later.';
        display: block;
        margin-bottom: @spacing-2;
      }
      .kpi-bullet-graph-wrap {
        display: none;
      }
    }
    &.kpi_process_waiting_data {
      .kpi-msg:after {
        content: "Still gathering yesterday's data. Come back later.";
        display: block;
        margin-bottom: @spacing-2;
      }

    }
    &.kpi_created {
      .njc-kpi-performance-graph {
        justify-content: center !important;
      }
      .kpi-msg:after {
        content: "Gathering Data. Come back later.";
        display: block;
      }

    }
    &.kpi_error {
      background-color: var(--critical-color-5);

      .kpi-msg:after {
        content: "There was an error receiving one or more data sources.";
        display: block;
        margin-bottom: @spacing-2;
      }

    }

    .njc-kpi-gap-text {
      color: var(--success-color);
    }
    .njc-kpi-tag-normal {
      .njc-text-tag-success;
      display: block !important;
    }
  }
  &.warning {

    .nc-bullet-graph-projected {
      background-color: var(--warning-bg-color);
    }
    .nc-bullet-graph-current {
      background-color: var(--warning-color-dark);
    }
    .njc-kpi-gap-text {
      color: var(--warning-color-dark);
    }
    .njc-kpi-tag-warning {
      .njc-text-tag-warning;
      display: block !important;
    }
  }
  &.critical {

    .nc-bullet-graph-projected {
      background-color: var(--critical-color-50);
    }
    .nc-bullet-graph-current {
      background-color: var(--critical-color);
    }
    .njc-kpi-gap-text {
      color: var(--critical-color);
    }
    .njc-kpi-tag-critical {
      .njc-text-tag-critical;
      display: block !important;
    }
  }
}


.njc-list-table-row-last-overwrite {
  .njc-list-card {
    .njc-list-table {
      .njc-list-table-row {
        &:nth-last-child(2) {
          &:before {
            border-bottom: 0px solid #FFFFFF;
          }
        }
      }
    }
  }
}

.njc-nodata-card {
  border-top: 1px solid var(--grayscale-20);

  .njc-command-center-nodata {
    display: none !important;
  }

  &-content {
    margin: @spacing-large auto;
    padding-right: 425px;
    width: 90%;
    max-width: 800px;
    height: 280px;
    background-image: url("/images/njc/njc-nodata-default.png");
    background-position: 100% 50%;
    background-repeat: no-repeat;
    .njc-flex-direction-column !important;
    align-items: flex-start;
    justify-content: center;

    h2 {
      margin: @spacing-3 @spacing-0 @spacing-0 @spacing-0;
    }

    p {
      .njc-text-paragraph;
    }

    .njc-nodata-button-wrap {
      .njc-flex-direction-row-start;
      a {
        white-space: nowrap;
        margin-right: @spacing-3;
      }
    }

    &.njc-nodata-card-accounts {
      background-image: url("/images/njc/njc-nodata-accounts.png");
    }
    &.njc-nodata-card-account-groups {
      background-image: url("/images/njc/njc-nodata-account-groups.png");
    }
    &.njc-nodata-card-campaign-monitoring {
      background-image: url("/images/njc/njc-nodata-campaign-monitoring.png");
    }
    &.njc-nodata-card-dashboards {
      background-image: url("/images/njc/njc-nodata-dashboard.png");
    }
    &.njc-nodata-card-reports {
      background-image: url("/images/njc/njc-nodata-reports.png");
    }
    &.njc-nodata-card-templates {
      background-image: url("/images/njc/njc-nodata-templates.png");
    }
  }

  .njc-nodata-small-nodata {
    border: 1px dashed var(--grayscale-30);
    border-radius: 5px;
    min-height: 200px;
    padding: 34px 12px 56px;

    .njc-nodata-small-content;
  }
}


.njc-body-pagetitle {
  display: flex !important;
  min-height:130px;
  .njc-flex-startcenter;
  .njc-text-heading-1;

  .njc-beta-tag {
    .njc-text-beta-tag;
  }

  .njc-page-buttons {
    margin-left: auto;
    .njc-flex-direction-row;
    [class^="njc-btn"] {
      margin-left: @spacing-2;
    }
  }
  .njc-pagetitle-title-wrap {
    .njc-flex-direction-column-start;

    .njc-pagetitle-subtext {
      .njc-text-heading-subtext;

      a {
        .njc-flex-direction-row;
        .njc-flex-startcenter;
        svg {
          .njc-sizing-sq(18px);
          transform: rotate(90deg);
        }
      }
    }

    .njc-pagetitle-title {
      margin-top: @spacing-1;
      line-height: normal;
    }

    .njc-pagetitle-posttext {
      font-size: 12px;
      color: var(--grayscale-70);
      font-weight: 400;
      cursor: pointer;
      .njc-flex-direction-row;
      .njc-flex-startcenter;

      svg {
        visibility: hidden;
        .njc-sizing-sq(14px);
        margin-left: 4px;
      }

      &:hover {
        svg {
          visibility: visible;
        }
      }
    }
  }
  &-tertiary {
    height:auto;
    margin: 0px 0px @spacing-3 0px;

    .njc-pagetitle-title-wrap {
      .njc-pagetitle-title {
        margin-top: @spacing-3;
        line-height: 24px;
        .njc-text-heading-4;
      }
    }
  }
}

.njc-pagetitle-title-smaller {
  .njc-text-heading-4;
  line-height:24px;
  margin: -22px 0 10px 0;
}

.njc-pagetitle-3 {
  .njc-text-heading-3;
  min-height: 44px;
  .njc-flex-direction-row;
  .njc-flex-startcenter;
}
.njc-pagetitle-4 {
  .njc-text-heading-4;
  min-height: 44px;
  .njc-flex-direction-row;
  .njc-flex-startcenter;
}


.njc-search-bar-row {
  .njc-search-bar-wrap {
    flex-grow: 1;
    display: flex;
  }
  .njc-flex-direction-row-start;

  .justify {
    margin-left: auto;
  }

  .njc-form-search-bar {
    .njc-text-form;
    .njc-text-form-border;
    background-color: var(--grayscale-0);
    line-height: normal;
    padding: @spacing-3 @spacing-5 @spacing-3 40px;
    margin: @spacing-0 @spacing-0 @spacing-5 @spacing-0;
    width: 100%;
    min-width: 200px;
    background-image: url("/images/njc/njc-icon-search.svg");
    background-repeat: no-repeat;
    background-position: @spacing-4 50%;


    &-less-padding {
      margin-bottom: @spacing-2;
    }
    &-no-padding {
      margin-bottom: @spacing-0;
      border-radius: 5px 5px 0 0;
    }
  }
  .njc-form-sort-bar {
    margin-left: auto;
    white-space: nowrap;
    .njc-flex-direction-row;
    .njc-flex-justify;

    .njc-form-sort {
      background-color: #ffffff;
      border: 1px solid var(--grayscale-30);
      border-radius: 5px;
      height: 44px;

      line-height: normal;
      padding: @spacing-3 @spacing-large @spacing-3 @spacing-4;
      margin: @spacing-0 @spacing-0 @spacing-5 @spacing-2;
      .njc-text-form;
      color: var(--grayscale-100);
      max-width: 220px;

      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      select::-ms-expand {
        display: none;
      }
      background-image: url("/images/njc/njc-icon-dropdown.svg");
      background-repeat: no-repeat;
      background-position: calc(~"100% -" 15px) 50%;
    }
    .njc-form-sort-loading {
      .njc-text-form-border;
      .njc-text-form-select;
      .njc-text-form;
      min-width: 140px;
      position: relative;
      display: inline-flex;
      background-image: none;
      &:before {
        position: absolute;
        content: '';
        top: 13px;
        right: 13px;
        .njc-sizing-sq(14px);
        border-radius: 100%;
        border: 2px solid rgba(0,0,0,.1);
      }
      &:after {
        position: absolute;
        content: '';
        top: 13px;
        right: 13px;
        .njc-sizing-sq(14px);
        animation: njc-spin .6s linear;
        animation-iteration-count: infinite;
        border-radius: 100%;
        border-color: #767676 transparent transparent;
        border-style: solid;
        border-width: 2px;
        box-shadow: 0 0 0 1px transparent;
      }
    }

    .njc-form-date-filter {
      .njc-text-form;
      .njc-text-form-border;
      .njc-text-form-calendar;
      margin: 0 0 @spacing-5 @spacing-2;
    }
  }

  .njc-form-buttons-bar {
    margin-left: auto;
    white-space: nowrap;
    .njc-flex-direction-row;
    .njc-flex-justify;

    .njc-form-toggle-default {
      padding: 0px;
      margin-left: 12px;
      height: 44px;
      .njc-flex-startcenter;

      .nc-toggle-switch , .njc-toggle-switch {
        width: auto;
        padding-left: 58px;
        .njc-flex-startcenter;
      }
    }

    .njc-btn-primary {
      margin:0 0 @spacing-5 @spacing-2;
    }

    .njc-btn-secondary , .njc-dropdown {
      margin-left: @spacing-3;
    }
  }

}

.njc-report-error-top {
  .njc-flex-direction-column-center;

  .njc-report-error-top-data-error , .njc-report-error-top-crit-error {
    .njc-flex-direction-column-center;
    .njc-text-heading-1;

    svg {
      .njc-sizing-sq(44px);
      margin-bottom: 10px;
    }
    span {
      height: 32px;
      display: block;
    }
  }

  .njc-report-error-top-data-error {
    * {
      color: var(--warning-color);
    }
  }
  .njc-report-error-top-crit-error {
    * {
      color: var(--critical-color);
    }
  }

  .njc-report-error-top-label {
    padding: 2px 0;

    span {
      .njc-text-label-bold;
    }
  }
}

.nc-report-list-viewer {
  .njc-search-bar-row {
    .njc-form-search-bar {
      flex: 0 1 35%;
      width: 350px;
    }
    .njc-form-sort-bar {
      flex: 1 1 auto;

      .njc-form-sort {
        width: 33%;
        max-width: initial;
      }
    }
  }
}

.njc-form-button-solo-override {
  margin-top: 20px;

  .njc-btn-primary {
    .njc-btn-secondary;
  }
}

.njc-form-description {
  .njc-flex-direction-column;
  .njc-flex-centerstart;
  flex-basis: 50%;
  max-width: 50%;
  padding-left: @spacing-4;
  margin-left: auto;

  .help-text-wrap {
    width: 100%;
  }

  .nc-help-text , .help-text , .njc-help-text {
    .njc-text-form-description;
    width: 100%;

    a {
      .njc-text-link;
    }
    .njc-help-text-title {
      margin-bottom: @spacing-2;
      .njc-text-paragraph-bold;
      display: block;
    }
  }
  .expand.njc-form-description {
    flex-basis: 100%;
    max-width: 100%;
    margin-top: 0px;
    padding-left: 0px;
  }
}

.njc-form-text-default-email-no-subject {
  .njc-form-text-default-input-container {
    .direction-column {
      .njc-form-label-wrap {
        display: none;
      }
      .njc-form-label-wrap-second {
        margin-top: 0;
      }
    }
  }
}
.njc-email-preview-btn {
  margin: @spacing-3 0 0 auto;
}
.njc-email-template-modal {
  .Fullpage__header_title {
    .text-wrap {
      display: none;
    }
  }
  .njc-email-preview-body {
    margin-top: @spacing-5;
  }
}

.card-header-2-row , .njc-card-header-2-row {
  border-top: 1px solid var(--grayscale-30);
  margin-top: @spacing-large;
  h3 {
    .njc-text-heading-3;
    margin: @spacing-0;
    padding: @spacing-large @spacing-0 @spacing-0 @spacing-0;
    text-transform: capitalize;

  }
  .njc-h2-description {
    margin: @spacing-1 @spacing-0;
    .njc-text-heading-2-description;
  }
}

.njc-form-label-bolder {
  .njc-text-heading-4;
}

.njc-first-container {
  .card-header-2-row , .njc-card-header-2-row {
    margin-top: @spacing-0;
    border-top: 0px solid var(--grayscale-0);
  }

  &.njc-first-container-tertiary {
    .njc-card-header-2-row {
      h3 {
        margin-top: @spacing-5;
      }
    }
  }
}

.njc-forms-full-width {
  .njc-flex-direction-row;

  .njc-form-text-default {
    flex-grow: 1;
    padding-right: @spacing-4;

    .njc-form-text-default-input-container {
      flex-basis: 100%;
    }

    &:last-child {
      padding-right: @spacing-0;
    }
    &.njc-forms-full-width-container-last {
      padding-right: @spacing-0;
    }
  }
}

.njc-custom-metrics-full-width {
  .njc-flex-direction-row;

  .njc-form-text-default-input-container {
    flex-basis: 100%;
  }

  .njc-form-input-wrap {
    .njc-btn-secondary-tiny {
      margin-bottom: @spacing-1;
      span {
        margin-right: 8px;
      }
    }
  }
  .datasources-container {
    padding-right: @spacing-4;
    flex-basis: calc(~"50% +" @spacing-4);

    .njc-form-input {
      border-bottom: 0px;
      border-radius: 5px 5px 0 0;
    }

    .metric-list-item-container {
      display: block;
      overflow-x: hidden;
      overflow-y: scroll;
      border: 1px solid var(--grayscale-30);
      height: 96px;
      width: 100%;
      margin: 0 0 4px 0;
      padding: 4px;

      .metric-list-item {
        cursor: pointer;
        padding: 4px 12px;
        .njc-text-form;

        &.active-metric {
          background-color: var(--primary-color-bg);
          cursor: default;
        }
      }
    }
  }
  .njc-operators-container {
    padding-right: @spacing-4;
    width: 150px;
  }
  .njc-constants-container {
    padding-right: @spacing-4;
    width: 250px;
  }
}

.njc-custom-metrics-formula-valid {
  margin-top: 6px;
  .invalid-formula {
    color: var(--critical-color);
  }
}

.njc-form-image-upload {
  padding: @spacing-5 @spacing-0 @spacing-0 @spacing-0;

  &-container {
    display: flex;
    align-items: center;

    .njc-form-image-upload-pic {

      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: @spacing-5;
      cursor: pointer;

      &-empty {
        .njc-sizing-sq(85px);
        .njc-flex-direction-row-center;
        border: 1px dashed var(--primary-color);
        background-color: var(--primary-color-5);
        border-radius: 5px;
        cursor: pointer;
      }

      img {
        max-width: 85px !important;
        max-height: 85px !important;
        border-radius: 5px;
      }
    }
  }

  &.njc-form-image-upload-pic-small {
    img {
      max-width: 45px !important;
    }
    .njc-form-image-upload-pic-empty {
      .njc-sizing-sq(45px);
      .njc-flex-direction-row-center;
    }
    .njc-empty-placeholder {
      .njc-text-paragraph-bold;
      font-weight: 700;
      color: var(--primary-color);
    }
  }

  .upload-delete-wrap {
    .njc-flex-direction-column;
    .njc-flex-centerstart;
    height: 52px;
    h4 {
      .njc-text-paragraph;
      margin: @spacing-0 @spacing-0 @spacing-2 @spacing-0;
    }
    .upload-delete-buttons {
      .njc-flex-direction-row-start;

      .njc-btn-primary-tiny {
        .njc-text-button-tiny;
        margin-right: @spacing-3;
      }
      .njc-btn-secondary-tiny {
        margin-right: @spacing-3;
      }
    }
  }
}

/* Modal Styling */


.nc-modal-full-page {
  .njc-modal;

  .Fullpage {
    .njc-modal-full;

    .Fullpage__container {
      .njc-modal-full-body;
    }


    .Fullpage__header_title {
      h1 {
        display: none;
      }
    }

    .modal-buttons {
      position: absolute;
      right: -34px;
      top: -40px;
      display: flex;

      .njc-icon-close {
        margin-left: 4px;
      }
    }

    .njc-modal-title , h2.caps {
      line-height: normal;
      margin: @spacing-0;
      .njc-text-heading-1;

      &.njc-modal-title-bottom-space {
        margin-bottom: @spacing-5;
      }
    }

    .njc-list-card {
      border: 0px solid transparent;
      box-shadow: none;
      .njc-list-table {
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        .njc-list-table-header {
          border-bottom: 0px solid transparent;
          border-radius: 5px;
        }
      }
    }

    .njc-custom-mappings {
      .njc-list-card {
        .njc-list-table {
          overflow: visible;
        }
      }
    }

    .Fullpage__content {

      & > .njc-nodata-card {
        border-top: 0px;
        margin-bottom: 50px;
      }

      .njc-body-pagetitle {
        align-items: flex-end;
        height: auto;
        padding-bottom: @spacing-large;
        min-height: auto;

        &-modal-actions {
          .expand {
            align-items: flex-end;
            height: auto;
            width: 100%;
            .njc-flex-direction-row;
            .njc-flex-justify;
          }
          .njc-page-buttons {
            margin-left: auto;
            [class^="njc-btn"] {
              margin-left: @spacing-2;
            }
          }
        }

        .njc-pagetitle-subtext {
          margin-top: -14px;
        }
      }

      .njc-search-bar-row-modal {
        margin-top: @spacing-large;
      }

      .njc-tertiary {
        margin: @spacing-3 @spacing-0;
      }

      .njc-h3-row {
        padding-top: @spacing-0 !important;

        .njc-h3-description {
          margin-top: @spacing-4 !important;
        }
        .njc-form-input-message-link {
          .njc-text-link !important;
          padding: @spacing-1 @spacing-0 @spacing-0 @spacing-0;
          width: 100%;
          display: block;
        }
      }

      h3 {
        .njc-text-heading-3;
        margin: @spacing-0;
        padding: @spacing-large @spacing-0 @spacing-0 @spacing-0;
        text-transform: capitalize;

      }

      .njc-card-header-text {
        p {
          margin: @spacing-5 0 0 0 !important;
          padding-left: @spacing-0 !important;
        }
        &-more-margin {
          margin: @spacing-large @spacing-0 @spacing-3;
          p {
            margin: 0px;
          }
        }
        &-bold {
          .njc-text-paragraph-bold;
        }
      }


      .njc-date-range-container {
        border: 1px solid var(--grayscale-20);
        border-radius: 5px;
        height: 350px;
        .njc-flex-direction-row;
        .njc-flex-startcenter;
        align-content: flex-start;
        flex-wrap: wrap;
        overflow-y: auto;

        div {
          width: 50%;
          min-height: 24px;
          padding: @spacing-0 @spacing-4;
          .njc-flex-direction-row;
          .njc-flex-startcenter;

          &:hover {
            color: var(--primary-color);
            cursor: pointer;
          }
        }

        .nc-date-range-selected {
          color: var(--primary-color);
          background-color: var(--grayscale-10);
        }
      }

      .njc-date-range-relative {
        .form-field-row {
          .njc-date-range-label {
            .njc-text-form-label;
            margin-bottom: @spacing-2;
            display: inline-block;
          }

          .njc-checkbox {
            margin-bottom: @spacing-2;
          }

          .njc-date-range-box-wrap {
            .njc-flex-direction-row;
            .njc-flex-startcenter;
            margin-bottom: @spacing-2;
          }

          .njc-date-range-box1 {
            .njc-text-form;
            .njc-text-form-border;
            margin-right: @spacing-2;
          }

          .njc-date-range-box2 {
            .njc-text-form;
            .njc-text-form-border;
            .njc-text-form-select;
            margin-right: @spacing-2;

            &.njc-date-range-box2-last {
              margin-left: @spacing-2;
              margin-right: @spacing-0;
            }
          }

          .njc-date-range-box3 {
            .njc-text-form;
            .njc-text-form-border;
            .njc-text-form-calendar;
          }

          .njc-date-range-helptext {
            .nc-help-text {
              padding: @spacing-0;
              margin: @spacing-0;
              background-color: transparent;
            }

            .njc-form-description {
              flex-basis: 100%;
              max-width: 100%;
              padding-left: @spacing-0;
              margin-left: @spacing-0;
              .njc-text-form-sublabel;

              .nc-help-text {
                min-height: 30px;
              }
            }
          }
        }

        .njc-form-text-default {
          padding-top: @spacing-0;
        }
      }

      .njc-modal-2column-manage {
        .njc-flex-direction-row;

        h3 {
          .njc-flex-direction-row;
          .njc-flex-startcenter;
          position: relative;

          .njc-btn-secondary-tiny {
            margin-left: auto;
          }
        }



        &-info-panel {
          width: 30%;
          padding-right: @spacing-2;
          padding-bottom: @spacing-large;
          .njc-form-label-wrap {
            margin-top: @spacing-large;
            .njc-form-label {
              .njc-text-form-label;
            }

            p , .njc-img-text-item {
              margin: @spacing-1 @spacing-0 @spacing-0 @spacing-0;
            }

            ul {
              list-style: none;
              margin: @spacing-1 @spacing-0;
              padding-left: @spacing-0;

              li:first-child {
                margin-bottom: @spacing-1;
              }

              .njc-color-warning {
                color: var(--warning-color);
                font-weight: 500;
              }

              .njc-color-critical {
                color: var(--critical-color);
                font-weight: 500;
              }
            }
          }
        }

        .vertical-divide {
          width: 1px;
          min-height: 100%;
          border-left: 1px solid var(--grayscale-30);
          margin: @spacing-large @spacing-0;
        }

        &-input-panel {
          width: 70%;
          padding-left: @spacing-large;
          padding-bottom: @spacing-large;

          .njc-list-card.njc-reorder-table-wrap {
            margin-top: @spacing-4;
          }

          .njc-list-table-header {
            .njc-list-table-cell:nth-child(2) {
              padding-left: 16px;
            }
          }
          .njc-list-table-row {
            .njc-form-text-default {
              width: 100%;
              &-input-container {
                width: 100%;
                .select2-selection {
                  height: 36px;
                }
              }
            }
            &:last-child:before {
              border-bottom: 0px;
            }
          }

          .njc-full-width-table-button {
            width: 100%;
            padding: @spacing-0 @spacing-4;
            margin: @spacing-2 auto;
            background-color: var(--grayscale-0);
            border: 1px dashed var(--grayscale-30);
            height: 44px;
            .njc-flex-direction-row-center;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 400;
            color: var(--grayscale-60);
            cursor: pointer;

            svg {
              margin-right: @spacing-2;
            }
          }

          .njc-table-empty-msg {
            .njc-flex-direction-row-center;
            color: var(--grayscale-60);
            height: 110px;
            border-bottom: 1px solid var(--grayscale-20);
          }
        }
      }

      .njc-add-network-list {
        .njc-flex-direction-row;
        .njc-flex-centerstart;
        flex-wrap: wrap;
        margin: 0 -10px;


        &-card {
          width: 175px;
          height: 180px;
          margin: @spacing-2;
          cursor: pointer;
          border: 1px solid var(--grayscale-30);
          border-radius: 8px;

          &:hover {
            background-color: var(--grayscale-5);
          }

          .cata-card {
            .njc-flex-direction-column-center;
            height: 100%;

            .cata-card-bottom {
              display: none;
            }

            .cata-card-top {
              .njc-sizing-sq(70px);
              margin-bottom: @spacing-5;
              .njc-flex-direction-column-center;

              .cata-logo-wrap {
                width: 100%;
              }

              img {
                max-height: 70px !important;
                max-width: 70px !important;
              }
            }

            .nc-board-text-container {
              h5 {
                .njc-text-table-large-bold;
                margin: 0;
                text-align: center;
                width: 120px;
              }
            }
          }
        }
      }

      .njc-template-options-wrap {
        margin-bottom: 50px;
      }

      .template-options-wrap {
        .njc-flex-direction-row;
        .njc-flex-justify;

        .template-option-card-wrap {
          border-radius: 5px;
          border: 1px solid var(--grayscale-30);
          width: calc(~"50% -" @spacing-1);
          padding: 44px 44px 36px 44px;
          text-decoration: none;
          .njc-transition(0.4s);

          &:hover {
            background-color: var(--grayscale-5);
            .njc-transition(0.2s);
          }

          svg {
            max-width: 234px;
            max-height: 178px;
            margin: auto;
            display: block;
          }

          .template-content-wrap {
            margin-top: 28px;
            text-align: center;

            .template-title-text {
              .njc-text-heading-3;
            }
            .template-body-text {
              .njc-text-paragraph;
              margin: @spacing-3 @spacing-0 @spacing-0 @spacing-0;
            }
          }
        }

        .nc-vue-table-template-picker-container {
          width: 100%;
          .njc-flex-direction-column;

          .template-card-row {
            .njc-flex-direction-row;
            justify-content: space-between;
            width: 100%;

            .template-card-wrap[row-index="0"][column-index="0"] {
              .template-content-wrap {
                .njc-premade-template-image {
                  background-image: url('/images/starter/execSummary16_9.png');
                }
              }
            }
            .template-card-wrap[row-index="0"][column-index="1"] {
              .template-content-wrap {
                .njc-premade-template-image {
                  background-image: url('/images/starter/execSummary4_3.png');
                }
              }
            }
            .template-card-wrap[row-index="0"][column-index="2"] {
              .template-content-wrap {
                .njc-premade-template-image {
                  background-image: url('/images/starter/facebook16_9.png');
                }
              }
            }
            .template-card-wrap[row-index="1"][column-index="0"] {
              .template-content-wrap {
                .njc-premade-template-image {
                  background-image: url('/images/starter/facebook4_3.png');
                }
              }
            }
            .template-card-wrap[row-index="1"][column-index="1"] {
              .template-content-wrap {
                .njc-premade-template-image {
                  background-image: url('/images/starter/googleAds16_9.png');
                }
              }
            }
            .template-card-wrap[row-index="1"][column-index="2"] {
              .template-content-wrap {
                .njc-premade-template-image {
                  background-image: url('/images/starter/googleAds4_3.png');
                }
              }
            }
            .template-card-wrap[row-index="2"][column-index="0"] {
              .template-content-wrap {
                .njc-premade-template-image {
                  background-image: url('/images/starter/googleAnalytics16_9.png');
                }
              }
            }
            .template-card-wrap[row-index="2"][column-index="1"] {
              .template-content-wrap {
                .njc-premade-template-image {
                  background-image: url('/images/starter/googleAnalytics4_3.png');
                }
              }
            }
            .template-card-wrap[row-index="2"][column-index="2"] {
              .template-content-wrap {
                .njc-premade-template-image {
                  background-image: url('/images/starter/programmatic16_9.png');
                }
              }
            }
            .template-card-wrap[row-index="3"][column-index="0"] {
              .template-content-wrap {
                .njc-premade-template-image {
                  background-image: url('/images/starter/programmatic4_3.png');
                }
              }
            }
            .template-card-wrap[row-index="3"][column-index="1"] {
              .template-content-wrap {
                .njc-premade-template-image {
                  background-image: url('/images/starter/SEO16_9.png');
                }
              }
            }
            .template-card-wrap[row-index="3"][column-index="2"] {
              .template-content-wrap {
                .njc-premade-template-image {
                  background-image: url('/images/starter/SEO4_3.png');
                }
              }
            }

            .template-card-wrap {
              flex: 1;
              width: 300px;
              margin: @spacing-0 @spacing-3 24px;
              position: relative;

              .template-card {
                padding: @spacing-large @spacing-5 @spacing-3;
                border-radius: 5px;
                border: 1px solid var(--grayscale-30);
                .njc-transition(0.4s);
                cursor: pointer;
                height: 100%;

                &:hover {
                  background-color: var(--grayscale-5);
                  .njc-transition(0.2s);
                }
              }

              &:first-child {
                margin-left: 0;
              }
              &:last-child {
                margin-right: 0;
              }
              .template-content-wrap {
                text-decoration: none;
                width: 100%;

                .njc-premade-template-image {
                  margin: 0 0 @spacing-5 0;
                  height: 190px;
                  width: 100%;
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center center;
                }

                svg {
                  margin: 0 @spacing-large @spacing-5;
                }

                .template-title-buttons {
                  position: absolute;
                  top: 8px;
                  right: 8px;

                  svg {
                    margin: 0;
                  }
                }

                .template-title-text {
                  .njc-text-paragraph-bold;
                  text-align: center;
                }
                .template-body-text {
                  .njc-text-paragraph;
                  text-align: center;
                  margin: @spacing-1 0;
                }

                .template-networks-wrap {
                  .njc-flex-direction-row-center;
                  width: 100%;

                  .network-icon-wrap {
                    margin: 10px @spacing-1 0;
                    background-color: transparent;
                    border-radius: 5px;
                    .njc-sizing-sq(24px);
                    .njc-flex-direction-row-center;

                    img {
                      .njc-sizing-sq(16px);
                    }
                  }
                }
              }
            }
          }

          .nc-table-page-footer {
            border-top: 1px solid var(--grayscale-30);
            width: calc(~"100% +" 100px);
            padding: 0 50px;
            margin: 0 -50px -20px;

            .pagination-footer {
              height: 70px;
              width: 100%;
              .njc-flex-direction-row;
              .njc-flex-justify;

              .njc-table-footer-page-display {
                .njc-text-table-footer;
                text-transform: lowercase;
              }
            }
          }
        }
      }

      .njc-template-create-margins {
        position: relative;
        padding: 66px 80px;
        width: 390px;
        margin-top: -10px;
        .njc-flex-direction-row-center;

        label {
          margin: 0;
        }
        .njc-form-input-wrap {
          margin-top: 5px;
        }
        .njc-form-input {
          min-height: 36px;
        }

        .njc-template-create-margins-center {
          margin: auto;
          width: 100%;

          img {
            width: 100%;
          }
        }
        .njc-template-create-margins-top {
          top: -4px;
          left: calc(~"50% -" 42px);
        }
        .njc-template-create-margins-left {
          left: 0;
          top: calc(~"50% -" 46px);
        }
        .njc-template-create-margins-right {
          right: 0;
          top: calc(~"50% -" 46px);
        }
        .njc-template-create-margins-bottom {
          bottom: -8px;
          left: calc(~"50% -" 42px);
        }
        .njc-form-text-default {
          width: 70px;
          padding: 0px;
          position: absolute;

          .njc-form-text-default-input-container {
            flex-basis: 100%;
            min-width: 100%;

            .njc-form-label-wrap {
              .njc-flex-center;
            }

            .njc-form-label-wrap {
              .njc-form-input {
                width: 70px !important;
                text-align: center;
              }
            }
          }

          .njc-form-description , .njc-form-sub-label {
            display: none;
          }
        }
      }
      .njc-form-description-new-template {
        margin-top: 0px;
      }
      .njc-modal-footer-full {
        border-top: 1px solid var(--grayscale-30);
        width: calc(~"100% +" 100px);
        padding: 0 50px;
        margin: 20px -50px -20px;

        .align-start {
          .njc-flex-direction-row;
          .njc-flex-flip;
          .njc-flex-startcenter;
          height: 70px;
        }
      }
    }
  }

}

.Modal {
  .njc-modal;

  .Modal__container {
    .njc-modal-small;

    .Modal__content {
      .njc-modal-small-body;

      h3 {
        line-height: normal;
        padding: @spacing-0;
        margin: @spacing-0;
        .njc-text-heading-2;
      }

      .njc-tertiary {
        margin: @spacing-3 @spacing-0 @spacing-5 @spacing-0;
      }

      .accounts-picker-container {
        margin-top: @spacing-5;
        .accounts-list {
          padding: @spacing-0;
          .accounts-list-item {
            margin: @spacing-0;
            label {
              height: 30px;
              .njc-flex-direction-row;
              .njc-flex-startcenter;
              padding: @spacing-0 @spacing-4;

              .njc-checkbox-input {
                margin-right: @spacing-2;
              }
            }
          }
        }
        .sub-text {
          .njc-text-form-sublabel;
          margin: @spacing-2 @spacing-0 @spacing-5 @spacing-0;
        }
        .target-threshold-container {
          .njc-flex-direction-column-start;
          label {
            .njc-text-form-label;
          }
          input {
            .njc-text-form;
            .njc-text-form-border;
            margin-top: @spacing-2;
            width: 100%;
          }
        }
      }
    }
  }
}

.njc-form-toggle-default {
  padding: @spacing-5 @spacing-0 @spacing-0 @spacing-0;
  .njc-flex-direction-row-start;

  .njc-form-input-wrap {
    flex-grow: 1;

    &.has-error {
        .njc-form-input , .njc-form-input-select {
          border: 1px solid var(--critical-color);
          box-shadow: inset 0 0 0 1px var(--critical-color);
        }
        .error-help-block {
          margin-top: @spacing-1;
          .njc-text-warning;
          display: block;
          &::first-letter {
            text-transform: capitalize;
          }
        }
      }
  }

  .njc-form-label-wrap {
    .njc-flex-direction-row;

    &.njc-form-label-wrap-second {
      margin-top: @spacing-5;
    }

    .njc-form-label {
      .njc-text-form-label;
    }

    .njc-form-sub-label {
      .njc-text-form-sublabel;
      width: 6px;
      height: 14px;
      overflow: hidden;
      color: transparent;
      margin-left: 4px;

      &:before {
        content: " *";
        width: 20px;
        height: 10px;
        display: inline;
        .njc-text-form-sublabel;
      }
    }
  }
  .njc-form-input-wrap {
    .njc-text-form-plain-text;
    margin-top: @spacing-2;
  }

  .njc-form-toggle-default-multiple {
    flex-direction: column;
    flex-basis: 50%;
    min-width: 50%;
    .njc-form-text-default-input-container {
      margin-bottom: @spacing-3;
      flex-basis: 100%;
      min-width: 100%;
    }
  }

  .njc-form-text-default-input-container {
    .njc-flex-direction-row;
    .njc-flex-startcenter;

    &-threshold {
      .njc-flex-direction-column-start;

      .njc-threshold-switch {
        .njc-flex-direction-row;
        .njc-flex-startcenter;
        padding: @spacing-2 @spacing-0;
      }

      .threshold-well {
        height: 0px;
        margin-left: 58px;
        overflow: hidden;
        .njc-transition(0.2s);
        &.active {
          height: 112px;
          display: block;
        }

        .flex-container {
          .njc-flex-direction-row;
          .njc-flex-startcenter;
          margin-bottom: @spacing-3;

          .threshold-text {
            .njc-flex-direction-row;
            .njc-flex-startcenter;
            margin-left: @spacing-1;

            .nc-color-warning {
              .njc-text-threshold-tag;
              background-color: var(--warning-color);
            }

            .nc-color-danger {
              .njc-text-threshold-tag;
              background-color: var(--critical-color);
            }
          }

          .njc-form-input {
            .njc-text-form;
            .njc-text-form-border;
            padding: 0 @spacing-5;
          }
        }
      }
    }
  }


  /* The switch - the box around the slider */
  .nc-toggle-switch , .njc-toggle-switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 24px;
  }
  /* Hide default HTML checkbox */
  .nc-toggle-switch input , .njc-toggle-switch input{
    display: none;
  }
  /* The slider */
  .nc-slider , .njc-slider{
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--grayscale-60);
    -webkit-transition: .4s;
    transition: .4s;
    width: 46px;
  }
  .nc-slider:before , .njc-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  input:checked + .nc-slider , input:checked + .njc-slider {
    background-color: var(--primary-color);
  }
  input:focus + .nc-slider , input:focus + .njc-slider {
    box-shadow: 0 0 1px #2196F3;
  }
  input:checked + .nc-slider:before , input:checked + .njc-slider:before {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  input:disabled + .nc-slider , input:disabled + .njc-slider {
    background-image: url("/images/njc/njc-lock-filled-unchecked.svg");
    background-repeat: no-repeat;
    background-position: 24px 50%;
    cursor: not-allowed;
  }
  input:checked:disabled + .nc-slider , input:checked:disabled + .njc-slider{
    background-image: url("/images/njc/njc-lock-filled-checked.svg");
    background-repeat: no-repeat;
    background-position: 5px 50%;
    cursor: not-allowed;
    background-color: var(--primary-color);
  }

  /* Rounded sliders */
  .nc-slider.nc-round , .njc-slider.njc-round {
    border-radius: 34px;
  }
  .nc-slider.nc-round:before , .njc-slider.njc-round:before {
    border-radius: 50%;
  }
  .toggle-text , .njc-toggle-text {
    .njc-text-toggle;
  }
  .njc-toggle-text-large {
    font-weight: 600;
    font-size: 14px;
    color: var(--grayscale-100);
  }
  .njc-sub-toggle-text {
    width: 100%;
    margin: 8px 0 0 60px;
    font-weight: 400;
    font-size: 13px;
    color: var(--grayscale-100);
  }
}

.njc-form-text-default {
  padding: @spacing-5 @spacing-0 @spacing-0 @spacing-0;
  .njc-flex-direction-row-start;

  &.njc-form-text-repeat-override {
    .njc-form-text-default {
      padding: 0px;
      flex-basis: 100%;
      min-width: 100%;

      .njc-form-text-default-input-container {
        flex-basis: 100%;
      }
    }
  }

  &-input-container {
    flex-basis: 50%;
    min-width: 50%;

    [class^="njc-btn"] {
      margin-top: @spacing-2;
    }

    &.njc-full-width {
      .njc-full-width;
    }

    .njc-form-text-default-input-container-multi {
      .njc-flex-direction-row-start;

      .direction-column {
        padding-left: @spacing-5;
        &:first-child {
          padding-left: @spacing-0;
        }
      }
    }

    .direction-column {
      .njc-flex-direction-column;
    }

    .njc-form-label-wrap {
      .njc-flex-direction-row;

      &.njc-form-label-wrap-second {
        margin-top: @spacing-5;
      }

      .njc-form-label {
        .njc-text-form-label;
      }

      .njc-form-sub-label {
        .njc-text-form-sublabel;
        width: 6px;
        height: 14px;
        overflow: hidden;
        color: transparent;
        margin-left: 4px;

        &:before {
          content: " *";
          width: 20px;
          height: 10px;
          display: inline;
          .njc-text-form-sublabel;
        }
      }
    }

    .njc-form-input-wrap {
      .njc-text-form-plain-text;
      margin-top: @spacing-2;

      &.njc-form-input-multiple {
        .njc-flex-direction-row;

        .njc-form-input-select {
          margin: @spacing-1;
          max-width: 80px;

          &:first-child {
            margin-left: @spacing-0;
          }
          &:last-child {
            margin-right: @spacing-0;
          }
        }

        .column {
          margin-right: @spacing-4;
          &:last-child {
            margin-right: @spacing-0;
          }
        }
      }

      &.njc-radio-button-wrap {
        .njc-flex-direction-column;

        .njc-radio-button {
          .njc-text-form-radio;
        }
      }

      &.njc-form-input-pretext {
        position: relative;
        span {
          .njc-flex-direction-column-center;
          .njc-text-form;
          background-color: var(--grayscale-10);
          color: var(--grayscale-60);
          min-height: 44px;
          width: 38px;
          border: 1px solid var(--grayscale-30);
          border-right: 0px;
          border-radius: 5px 0 0 5px;
          position: absolute;
          top: 0;
          left: 0;
        }
        .njc-form-input {
          margin-left: 38px;
          width: calc(~"100% -" 38px) !important;
          border-radius: 0 5px 5px 0;
        }
      }

      &.njc-form-input-posttext {
        position: relative;
        span {
          .njc-flex-direction-column-center;
          .njc-text-form;
          background-color: var(--grayscale-10);
          color: var(--grayscale-60);
          min-height: 44px;
          width: 38px;
          border: 1px solid var(--grayscale-30);
          border-left: 0px;
          border-radius: 0 5px 5px 0;
          position: absolute;
          right: 0;
          top: 0;
        }
        .njc-form-input {
          margin-right: 38px;
          width: calc(~"100% -" 38px) !important;
          border-radius: 5px 0 0 5px;
        }
        &.njc-form-input-180w {
          span {
            left: 180px;
          }
        }
      }

      &.njc-form-input-after-label {
        position: relative;
        span {
          position: absolute;
          left: 100%;
          .njc-text-after-label;
          margin-left: @spacing-2;
        }
        &.njc-form-input-80w {
          span {
            left: 80px;
            top: 12px;
          }
        }
      }

      &.njc-form-input-180w {
        .njc-form-input {
          width: 180px !important;
        }
      }
      &.njc-form-input-80w {
        .njc-form-input {
          width: 80px !important;
        }
      }

      .njc-form-input-date-boxes-wrap {
        .njc-flex-direction-row;
        width: 100% !important;
        flex-wrap: wrap;
      }

      .njc-form-input-post-link {
        .njc-text-form-link !important;
        .njc-flex-endcenter;
        display: flex;
        padding: @spacing-3 @spacing-0;
      }

      .njc-form-input-date-boxes {
        .njc-flex-direction-column-center;
        padding: @spacing-1 @spacing-3 !important;

        input[class^="njc-day"] {
          position: relative;
          .njc-flex-direction-column-center;
          margin: @spacing-4 @spacing-0 @spacing-5 @spacing-0;
          .njc-checkbox-input;

          &:before {
            position: absolute;
            top: -20px;
            .njc-text-form-checkbox-text;
          }
        }
        .njc-day- {
          &Monday:before {
            content: 'Mon';
          }
          &Tuesday:before {
            content: 'Tue';
          }
          &Wednesday:before {
            content: 'Wed';
          }
          &Thursday:before {
            content: 'Thu';
          }
          &Friday:before {
            content: 'Fri';
          }
          &Saturday:before {
            content: 'Sat';
          }
          &Sunday:before {
            content: 'Sun';
          }
          &1:before {
          content: '1';
        }
        &2:before {
          content: '2';
        }
        &3:before {
          content: '3';
        }
        &4:before {
          content: '4';
        }
        &5:before {
          content: '5';
        }
        &6:before {
          content: '6';
        }
        &7:before {
          content: '7';
        }
        &8:before {
          content: '8';
        }
        &9:before {
          content: '9';
        }
        &10:before {
          content: '10';
        }
        &11:before {
          content: '11';
        }
        &12:before {
          content: '12';
        }
        &13:before {
          content: '13';
        }
        &14:before {
          content: '14';
        }
        &15:before {
          content: '15';
        }
        &16:before {
          content: '16';
        }
        &17:before {
          content: '17';
        }
        &18:before {
          content: '18';
        }
        &19:before {
          content: '19';
        }
        &20:before {
          content: '20';
        }
        &21:before {
          content: '21';
        }
        &22:before {
          content: '22';
        }
        &23:before {
          content: '23';
        }
        &24:before {
          content: '24';
        }
        &25:before {
          content: '25';
        }
        &26:before {
          content: '26';
        }
        &27:before {
          content: '27';
        }
        &28:before {
          content: '28';
        }
        &29:before {
          content: '29';
        }
        &30:before {
          content: '30';
        }
        &31:before {
          content: '31';
        }

        }
      }

      .njc-form-input-date-range {
        .njc-text-form-dashed-border;
        text-decoration: none;

        span {
          .njc-text-form-dashed;
        }
        svg {
          .njc-sizing-sq(18px);
          color: var(--grayscale-30);
        }
      }

      &.has-error {
        .njc-form-input , .njc-form-input-select {
          border: 1px solid var(--critical-color);
          box-shadow: inset 0 0 0 1px var(--critical-color);
        }
        .error-help-block {
          margin-top: @spacing-1;
          .njc-text-warning;
          display: block;
          &::first-letter {
            text-transform: capitalize;
          }
        }
      }


      .multiselect {
        .multiselect__tags {
          .njc-text-form-border;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          .multiselect__tags-wrap {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .multiselect__tag {
              .njc-text-form;
              .njc-text-form-select-tag;
              padding-right: 24px;
              align-items: center;
              min-height: 26px;
              span {
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
              }

              &-icon {
                .njc-text-form-select-tag-close;
                margin-right: 8px;

                &:hover {
                  background-color: transparent;
                }

                &:after {
                  display: none;
                }
              }
            }
          }
          .multiselect__placeholder {
            margin: 0;
            padding: 0;
          }
          .multiselect__input {
            font-size: 14px;
            margin: 0px;

            &:focus {
              width: 80% !important;
              padding: 4px 0 !important;

            }
          }
        }
        &.multiselect--above {
          .multiselect__tags {
            flex-direction: column-reverse;
          }
        }
        .multiselect__content-wrapper {
          border: 1px solid var(--grayscale-30);
          .multiselect__content {
            position: relative;
          }
          .multiselect__element {
            &:first-child {
              position: sticky;
              z-index: 10;
              top: 0;
            }
            .multiselect__option {
              padding: 0px;
              min-height: auto;

              > div {
                .njc-text-moreaction-menu-border;
                .njc-text-moreaction-menu;
                border-radius: 0 !important;

                &:after {
                  border-bottom: 1px solid var(--secondary-color-variant) !important;
                }
              }

              &.multiselect__option--highlight , &.multiselect__option--group-selected , &.multiselect__option--selected {
                > div {
                  background-color: var(--primary-color-10);
                }
              }

              input[type="checkbox"] {
                visibility: hidden;
              }

              &:after {
                display: none;
              }
            }

          }

          .njc-app-scrollbars;
        }
      }

      &-address {
        .njc-text-table-standard-bold;
        margin-top: @spacing-2;
      }
    }

    .select-calendar-row {
      .njc-flex-direction-row;
      div {
        button {
          height: 34px;
          width: 40px;
          background-color: var(--grayscale-5);
          border: 0px solid transparent;
          border-radius: 2px;

          &:hover {
            background-color: var(--grayscale-20);
            cursor: pointer;
          }
          &:disabled {
            background-color: var(--grayscale-5);
            color: var(--grayscale-30);
            &:hover {
              background-color: var(--grayscale-5);
              cursor: not-allowed;
            }
          }
        }
        margin: 2px;

        &.active {
          button {
            background-color: var(--primary-color);
            color: var(--grayscale-0);

          }
        }
      }
    }

    .security-row {
      .njc-password-strength;
    }
  }

  .njc-form-input-message-link {
    .njc-text-link !important;
    padding: @spacing-1 @spacing-0 @spacing-0 @spacing-0;
    width: 100%;
    display: block;
  }
  .njc-form-input-message-default {
    .njc-text-default;
    padding: @spacing-1 @spacing-0 @spacing-0 @spacing-0;
    width: 100%;
    display: block;
  }
  .njc-form-input-message-error {
    .njc-text-error;
    padding: @spacing-1 @spacing-0 @spacing-0 @spacing-0;
    width: 100%;
    display: block;
  }

  .njc-form-description {
    margin-top: 26px;
  }
  .njc-form-description-notopmargin {
    margin-top: 0px;
  }

  &.njc-form-text-default-no-label {
    .njc-form-label-wrap {
      display: none;
    }
    .njc-form-input-wrap {
      margin-top: 0px;
    }
    .njc-form-description {
      margin-top: 0px;
    }
  }

}

.njc-form-has-children {
  .njc-form-text-default-input-container {
    flex-wrap: wrap;
    .njc-form-has-children-content {
      flex-basis: 100%;
      padding: @spacing-3 0 0 60px;
      .njc-form-text-default {
        padding: 0px;
      }
      .njc-form-text-default-input-container.njc-full-width > div {
        width: 100%;
      }
    }
  }
}

.njc-form-input , .select2-selection , .njc-form-input-select {
  width: 100% !important;
  .njc-text-form-border;
  .njc-text-form;

  &.njc-50p-width {
    width: 50% !important;
  }

  &.select2-hidden-accessible {
    width: 1px !important;
  }
}
.njc-form-input-select {
  &:required:invalid {
    color: var(--grayscale-70);
  }
}

.njc-form-input-text-tags {
  background-color: transparent;

  .njc-form-text-default .njc-form-input-wrap & {
    max-width: none;
  }

  div.ti-input {
    padding: 0px;
    border: 0px solid transparent;
    width: 100%;

    .ti-tags {
      align-items: center;

      li {
        margin: 0;
        padding: 0;

        &.ti-tag {
          .njc-text-form-text-tag;
          margin: 2px 5px 2px 0;
          word-break: break-all;
        }
      }
      .njc-form-input-text-tags {
        border: 0px solid transparent;
        box-shadow: none;
        min-height: 42px;

        &:focus {
          border: 0px solid transparent;
          box-shadow: none;
        }
        &:hover {
          border: 0px solid transparent;
        }
        &:read-only {
          border: 0px solid transparent !important;
        }
      }
    }
  }

  .ti-autocomplete {
    overflow-y:scroll;
    max-height: 20em;
    left: 0px;
    top: 43px;
    .ti-item {
      word-break: break-all;
    }
  }
}

.njc-body {
  .select2 {
    width: 100% !important;

    &.select2-container--focus {
      .select2-selection {
        .njc-text-form-input-focus;
      }
    }

    .select2-selection__choice, .select12-selection--single .select2-selection__rendered {
      .njc-text-form-select-tag;
      display: flex;
      flex-direction: row-reverse !important;
      flex-wrap: wrap;

      .select2-selection__choice__remove, .select2-selection__clear {
        .njc-text-form-select-tag-close;
      }

    }

    .select2-search--inline {
      margin: 8px 5px 8px 0;
      flex-grow: 1;

      input {
        width: 100%;
        margin-top: 0px;
        height: 100%;
      }
    }

    &.select2-container--disabled {
      .select2-selection--single, .select2-selection--multiple {
        background-image: url("/images/njc/njc-icon-dropdown-disabled.svg");
        .njc-text-form-input-disabled;

        .select2-selection__rendered, .select2-selection__choice {
          color: var(--grayscale-100);

          .select2-selection__choice__remove {
            display: inline-block;
            background-image: url("/images/njc/njc-small-close-grayscale-80.svg");
          }
        }

        .select2-selection__choice {
          background-color: var(--grayscale-20);
        }

        &:focus, &:active {
          box-shadow: none !important;
          .njc-text-form-input-disabled !important;
        }
      }

    }
  }
  .select2-selection , .njc-form-input-select {
    .njc-text-form-select;
    .njc-flex-direction-row;
  }

  .select2-selection__rendered {
    padding: @spacing-0 !important;
    width: 100%;
    .njc-flex-direction-row !important;
    flex-wrap: wrap;

    .select2-selection__clear {
      display: none;
    }

    &[title="Select"] {
      color: var(--grayscale-40);
    }
  }

  .select2-selection__arrow {
    display: none;
  }
}


input.njc-form-input:read-only {
  .njc-text-form-input-disabled;
}
.njc-margin-date-select-column input.njc-form-input:read-only {
  cursor: default;
}

.njc-form-input[type="file"] {
  padding: 9px 20px;
}
.njc-form-input {
  &::-webkit-calendar-picker-indicator {
    background-image: url("/images/njc/njc-calendar.svg");
    background-size: 16px 16px;
    background-repeat: no-repeat;
  }
  &.njc-form-input-date-select {
    background-image: url("/images/njc/njc-calendar.svg");
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: calc(~"100% -" 10px) 50%;
    padding: 0 28px 0 @spacing-1;
    &:read-only {
      border: 1px solid var(--grayscale-30) !important;
      background-color: var(--grayscale-0);
      color: var(--grayscale-100);
    }
  }
  &::-webkit-file-upload-button {
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    cursor: pointer;
    padding: @spacing-0 @spacing-3;
    height: 28px;
    border-radius: 5px;
    text-decoration: none;
    background-color: var(--grayscale-0);
    border: 1px solid var(--secondary-color) !important;
    color: var(--primary-color);
    box-shadow: inset 0 0 0 1px var(--primary-color);
  }
}

textarea.njc-form-input {
  height: 200px;
  padding: 12px 20px;
  .njc-text-form-plain-text;
}


.margins-wrap {

  .njc-margin-date-select-column {
    min-width: 90px;
  }
  .njc-form-input[type="number"] {
    padding: 0 @spacing-1 0 @spacing-2;
  }
  .njc-form-text-default .njc-form-description {
    margin-top: 0px;
  }
  h6.njc-margin-error-text {
    .njc-text-warning !important;
    margin: @spacing-2 0;
  }
}

.njc-form-color-select {
  .njc-flex-direction-row;

  padding: @spacing-5 @spacing-0 @spacing-0 @spacing-0;

  .njc-form-text-default-input-container {

    .direction-column {
      .njc-flex-direction-row;
      .njc-flex-startcenter;
    }

    .njc-form-color-select-pic {
      .nc-sample {
        .njc-sizing-sq(40px);
        border: 1px solid rgba(45, 53, 64, 0.3);
        border-radius: 5px;
        margin-right: @spacing-5;
        cursor: pointer;

        &:hover {
          border: 2px solid rgba(45, 53, 64, 0.3);
        }
      }

      .Modal__container {
        .njc-text-color-modal;
      }
    }
    .njc-form-color-select-buttons {
      .njc-flex-direction-column;
      .njc-flex-justify;

      span {
        .njc-text-paragraph;
      }
    }
  }

  &-small {
    padding: @spacing-5 @spacing-0 @spacing-0 @spacing-0;

    .njc-form-color-select-pic {
      .njc-flex-direction-row;
      .njc-flex-startcenter;
      margin-top: @spacing-3;
      flex-wrap: wrap;

      .nc-sample {
        margin-right: @spacing-2 !important;
        margin-bottom: @spacing-2;
      }

      .Modal__container {
        .njc-text-color-modal;
      }
    }
  }
}

.njc-adding-dropdown-wrap {
  .njc-flex-direction-row;
  align-items: flex-end;

  .njc-adding-dropdown {
    .njc-table-column-grow;
    margin-right: @spacing-2;

    .njc-form-text-default-input-container {
      min-width: 100%;
      flex-basis: 100%;
    }
  }
}

.njc-dashboard-form {
  .Fullpage .Fullpage__content {
    .njc-nodata-card {
      border-top: 0px;
      h3 {
        display: none;
      }
      .njc-adding-dropdown-message {
        .njc-text-dropdown-empty-message;
        .njc-flex-direction-row-center;
        padding: @spacing-3 0;
        background-color: var(--grayscale-5);
        border-radius: 5px;
      }
    }
  }
}

.njc-form-footer-row {
  padding: 40px @spacing-0 @spacing-0 @spacing-0;

  &-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid var(--grayscale-30);
    padding: @spacing-5 50px @spacing-0 50px;
    margin: 0 -50px;

    &-steps {
      .njc-flex-direction-row;
      .njc-flex-justify;
      border-top: 1px solid var(--grayscale-30);
      padding: @spacing-5 50px @spacing-0 50px;
      margin: 0 -50px;

      .forward-actions {
        .njc-flex-direction-row;
        .njc-flex-endcenter;

        button {
          margin-left: @spacing-3;
        }
      }
    }

    .njc-btn-primary , .njc-btn-secondary {
      margin-left: @spacing-3;
    }
  }

  &-small {
    padding: @spacing-0;

    .njc-form-footer-row-container {
      margin: 20px -40px 0;
      padding: 20px 40px 0px;
    }
  }

  .njc-form-footer-row-container-alternative {
    flex-direction: row-reverse;
  }
}
.njc-flex-flip {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
}


.njc-error-wrap {
  .njc-flex-direction-column-center;
  padding: 40px;
  border: 1px dashed var(--grayscale-30);

  .njc-error-img {
    width: 500px;
    height: 176px;
    margin-bottom: 30px;
  }
  .njc-error-heading {
    .njc-text-heading-3;
    text-align: center;
  }
}

.njc-modal-dimension-wrap {
  max-height: none !important;
  overflow-y: visible !important;
  .replacement-overflow {
    overflow-y: auto;
    max-height: 250px;
    .njc-temp-scrollbars;
  }
  .njc-temp-modal-find-replace-entry-wrap {

    .settings-panel-property-content {
      margin: 8px 0 0 0;
      input {
        .njc-text-form;
        .njc-text-form-border;
        background-color: var(--grayscale-0);
        line-height: 16px;
      }
    }

    .njc-temp-modal-find-replace-entry;

  }
  .njc-temp-modal-find-replace-nodata {
    color: var(--grayscale-30);
    text-align: center;
  }
  .njc-temp-modal-find-replace-add {
    border-top: 1px solid var(--grayscale-30);
    padding-top: 10px;
    margin-top: 10px;
    button {
      .njc-btn-dashed-add !important;

    }
  }
  hr {
    display:none;
  }
}

/*Confirmation Modal*/
.swal2-modal {
  .njc-alert;
}

.njc-empty-placeholder {
  .njc-flex-direction-row-center;
  height: 100%;
  .njc-text-heading-2;
  color: var(--primary-color);
  text-transform: uppercase;
}

/*Select 2 Dropdown*/
.select2-container--open {
  .select2-dropdown {
    border-radius: 5px;
    z-index: 1061;

    &--below {
      border-radius: 5px;
      border-top: 1px solid var(--secondary-color-variant);
      margin-top: @spacing-1;
    }
    &--above {
      border-radius: 5px;
      border-bottom: 0px solid transparent;
      position: relative;
      bottom: 4px;
    }

    .select2-search {
      .select2-search__field {
        .njc-text-form-border;
        .njc-text-form;
      }
    }
    .select2-results__options {
      .njc-app-scrollbars;
    }
    .select2-results__option {
      .njc-text-moreaction-menu-border;
      .njc-text-moreaction-menu;
      .njc-flex-direction-column;
      .njc-flex-centerstart;

      &[aria-selected=true] {
        background-color: var(--primary-color-5);
        color: var(--primary-color);
        font-weight: 500;
      }
      &[role=group] {
        .select2-results__options--nested .select2-results__option {
          padding: 0 20px 0 40px;
          border-radius: 0px;
          background-image: url("/images/njc/njc-new-line.svg");
          background-repeat: no-repeat;
          background-position: 20px 50%;
        }

        &:hover {
          background-color: var(--grayscale-0);
          color: var(--grayscale-100);
        }
      }

      &.select2-results__message:hover {
        background-color: transparent;
        color: var(--grayscale-100);
      }

      &--highlighted {
        background-color: transparent;
        color: var(--primary-color);
      }

      .select2-results__group {
        padding: 6px 0;
      }
      .select2-results__options--nested {
        width: calc(~"100% +" 40px);
        margin: 0 -20px;

        .select2-results__option {
          border: 0px;
        }
      }
    }
  }
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-radius: 5px !important;
}


/* Popup calendar */
body {
  .pika-single {
    border-radius: 5px;
    margin-top: @spacing-1;
    border: 1px solid var(--grayscale-30);

    &.is-bound {
      .njc-shadow;
    }

    .pika-lendar {
      margin: 0px;
      width: 275px;

      .pika-title {
        height: 42px;
        padding: @spacing-3 @spacing-2;
        border-bottom: 1px solid var(--grayscale-30);

        .pika-label {
          .njc-text-paragraph-bold;
          line-height: normal;
          padding: @spacing-0 @spacing-1;
        }

        .pika-prev , .pika-next {
          width: 14px;
          height: 14px;
          background-size: 100%;
          opacity: 1;
        }

        .pika-prev {
          background-image: url("/images/njc/njc-calendar-left.svg");
          &:hover {
            background-image: url("/images/njc/njc-calendar-left-hover.svg");
          }
        }
        .pika-next {
          background-image: url("/images/njc/njc-calendar-right.svg");
          &:hover {
            background-image: url("/images/njc/njc-calendar-right-hover.svg");
          }
        }
      }

      .pika-table {
        width: calc(~"100% -" 16px);
        margin: 8px;

        tr {
          height: 24px;

          th {
            line-height: 24px;
            padding-bottom: 8px;
          }

          abbr {
            cursor: default;
            text-decoration: none;
            .njc-text-paragraph-bold;
          }
        }

        td {
          .pika-button {
            height: 28px;
            padding: 0px;
            background-color: transparent;
            color: var(--grayscale-100);
            text-align: center;
            .njc-text-paragraph;

            &:hover {
              background-color: var(--grayscale-10);
            }
          }

          &.is-today {
            .pika-button {
              color: var(--primary-color);
            }
          }

          &[aria-selected="true"] {
            .pika-button {
              .njc-text-paragraph-bold;
              color: var(--grayscale-0);
              background-color: var(--primary-color);
              &:hover {
                background-color: var(--primary-color);
              }
            }
          }
        }
      }
    }
  }
}

/* New Error Styling */
.nc-error-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  &-border {
    border: 1px dashed #BDC2CC;
    border-radius: 5px;
    margin: 10px;
  }
}

.njc-error-img {
  width: 500px;
  height: 176px;
}
.nc-error-heading , .nc-error-message {
  .njc-font-1;
  color: var(--grayscale-100);
}
.nc-error-heading {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 26px;
}
.nc-error-message {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 8px;
}
.nc-error-link {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  cursor: pointer;
  padding: 0 20px;
  height: 30px;
  min-width: 44px;
  border-radius: 5px;
  text-decoration: none;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #545D6B;
}

.dataTables_processing {
  position: relative;
}

.njc-login-wrap {
  .njc-flex-direction-row;
  .njc-font-1;
  height: 100%;

  .njc-login-left {
    .njc-flex-direction-column-center;
    width: 45%;
    padding: 50px 40px;

    .njc-login-logo-row , .njc-login-form-wrap , .njc-login-form-wrap , .njc-login-footer-wrap {
      max-width: 450px;
      width: 100%;
    }
    .njc-login-logo-row {
      margin-bottom: auto;
      .njc-login-logo-wrap {
        .njc-flex-direction-row-start;
      }
    }
    .njc-login-header {
      .njc-text-heading-2;
      margin-bottom: @spacing-large;

      .njc-login-header-desc {
        padding-top: @spacing-3;
        text-transform: initial;
        .njc-text-paragraph;

        .njc-login-mfa-wrap {
          .njc-flex-direction-row-start;

          .njc-login-mfa-instruction {
            padding: 0 @spacing-2 0 0;
            text-align: left;
          }
        }
      }
    }
    .njc-form-text-default-input-container {
      margin-bottom: @spacing-5;
    }

    .njc-login-remember-me {
      .njc-flex-direction-row;
      .njc-flex-justify;
      margin-top: @spacing-3;

      .njc-checkbox {
        .njc-flex-direction-row;
        font-size: 14px;
      }
      .njc-text-link-larger {
        text-align: right;
      }
    }
    .njc-login-button {
      margin-top: @spacing-large;
    }
    .njc-login-footer-wrap {
      margin-top: auto;
      .njc-flex-direction-column-center;

      .njc-login-social-wrap {
        .njc-flex-direction-row-center;
        width: 100%;
        margin-bottom: @spacing-5;

        .njc-login-social-item {
          margin: 0 @spacing-4;
          .njc-sizing-sq(18px);

          svg {
            .njc-sizing-sq(18px);
            color: var(--primary-color);
          }
        }
      }
      .njc-login-powered-by {
        .njc-text-paragraph;
        a {
          color: var(--primary-color);
          text-decoration: none;
        }
      }
    }

    .njc-form-input-password {
      .njc-password-strength;
    }
  }
  .njc-login-right {
    .njc-flex-direction-column-center;
    width: 55%;
    background-size: contain, contain, cover;
    background-repeat: no-repeat;
    background-position: center center, center center, top left;
    background-image: url("/images/njc/login-image-stock.png"), url("/images/njc/login-image-bg.svg"), url("/images/njc/login-bg.png");
  }
}

/*Bulk Management Styling*/
.njc-bulk-pagetitle-subtext {
  .njc-text-paragraph;
  padding-bottom: @spacing-2;
  margin-top: -34px;

  .njc-status-bar {
    margin-top: 12px;
  }
}
.njc-bulk-steps {
  .njc-text-heading-3;
  padding-top: @spacing-2;
  padding-bottom: @spacing-2;
}
.njc-bulk-dl-msg {
  .njc-text-paragraph;
  padding-bottom: @spacing-2;
}
.njc-bulk-dl-link {
  .njc-flex-direction-row;
  .njc-flex-startcenter;
  padding-bottom: @spacing-3;
}
.njc-bulk-downloads {
  padding-bottom: 4px;
}

.njc-status-bar-bulk {
  margin-top: 0;
  svg {
    margin: 0 9px 0 0;
  }
}
.njc-bulk-box {
  width: 100%;
  margin: 0 0 4px 0;
  padding: 12px 20px;
  .njc-flex-direction-column-center;
  border-radius: 5px;
  border: 1px solid var(--grayscale-30);
  background-color: var(--grayscale-0);
  transition: all 0.4s ease-out;
}
.njc-bulk-box-export {
  .njc-flex-direction-row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .njc-text-link-med {
    .njc-flex-direction-row-center;
  }
}
.njc-bulk-box-detail-wrap {
  .njc-flex-direction-column-start;
  margin-right: 10px;
}
.njc-bulk-box-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: var(--grayscale-100);
  .njc-beta-tag {
    .njc-text-beta-tag;
  }
}
.njc-bulk-box-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  strong {
    font-weight: 700;
  }
}
.njc-bulk-upload-box {
  width: 100%;
  margin: 4px 0 28px 0;
  padding: 16px;
  .njc-flex-direction-column-center;
  border-radius: 5px;
  border: 1px dashed var(--grayscale-60);
  background-color: var(--grayscale-0);
}
.njc-bulk-upload-status-icon {
  .njc-sizing-sq(54px);
  margin-bottom: 12px;
}

.njc-bulk-upload-wrap {
  .njc-flex-direction-column-center;
}
.njc-bulk-upload-label {
  margin-bottom: 8px;
  margin-right: auto;
  color: var(--grayscale-80);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}
.njc-bulk-upload-form {
  .njc-flex-direction-column-center;
  select {
    margin: 0 0 8px 0;
    width: auto !important;
  }
  .njc-bulk-upload-text-link {
    margin-bottom: 12px;
    text-decoration: underline;
  }
  .njc-btn-primary {
    min-width: 143px;
  }
}
.njc-bulk-upload-refresh-icon {
   .njc-sizing-sq(54px);
   margin-bottom: 4px;
}
.njc-bulk-upload-title {
  line-height:24px;
  .njc-text-heading-4;
  margin-bottom: 8px;
}
.njc-bulk-upload-filename {
  font-size: 13px;
  color: var(--grayscale-100);
  line-height:18px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
    padding: 3px;
    width: 18px;
    height: 18px;
    color: var(--critical-color);
    cursor: pointer;
  }
}
.njc-bulk-upload-process {
  color: var(--grayscale-70);
  font-size: 12px;
  line-height: 18px;
}
.njc-bulk-import-history-title {
  margin-top: 4px;
}
.njc-pre-table-info {
  .njc-text-paragraph-bold;
  margin: 20px 1px;
}
/* Margin builder styling */
.njc-list-table-margin {
  overflow: visible !important;
}
.njc-month-calendar-wrap {
  position: relative;
}

.datepicker {
  &.dropdown-menu {
    position: fixed;
    z-index: 12;
    float: left;
    min-width: 190px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    background-color: var(--grayscale-0);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
  }

  &:before {
    content: "";
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -7px;
    left: 6px;
  }

  &:after {
    content: "";
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid var(--grayscale-0);
    position: absolute;
    top: -6px;
    left: 7px;
  }
  & table {
    width: 100%;
    margin: 0;
  }
  & thead tr:first-child th {
    cursor: pointer;
  }
  & th.next,
  & th.prev {
    font-size: 21px;
  }
  & td,
  & th {
    text-align: center;
    width: 20px;
    height: 24px;
    border-radius: 4px;
  }
  & td span {
    display: block;
    width: 52px;
    height: 28px;
    line-height: 28px;
    float: left;
    margin: 4px;
    cursor: pointer;
    border-radius: 4px;
    background-color: var(--grayscale-10);

    &.active {
      background-color: var(--primary-color);
      color: var(--grayscale-0);
    }
    &:hover {
      background-color: var(--grayscale-40);
    }
  }
}
.njc-account-group-list-advertisers-loader ,
.njc-account-group-list-advertisers {
  margin-top: 24px;
}

.njc-account-group-list-advertisers {
  .njc-account-group-list-advertisers-title {
    .njc-text-heading-3;
    margin-bottom: 12px;
  }
  .njc-search-bar-row {
    .njc-flex-direction-column;
  }
  .njc-search-bar-wrap , .njc-form-buttons-bar, .njc-adding-dropdown-wrap {
    width: 100%;
    margin-right: 0;
  }
  .njc-form-search-bar {
    margin-bottom: 0px;
  }
  .njc-form-text-default {
    padding: 0px;
  }
  .njc-list-table {
    max-height: 400px;
    .njc-list-table-header {
        .njc-list-table-cell-content {
            cursor: pointer;
        }
    }
    .njc-list-table-cell-content  {
        width: 100%;
        .njc-associated-with {
            .njc-text-table-small;
            white-space: nowrap;
            text-align: right;
            margin-left: auto;
        }
    }
  }
  .njc-adding-dropdown-wrap {
    margin-bottom: 16px;
    .select2-selection {
        min-height: 44px;
    }
    .select2-search__field::-webkit-search-cancel-button {
        -webkit-appearance: none;
        width: auto;
        height: auto;
        background-image: none;
    }
  }
  .select2-container--default .select2-selection--single {
    border: 1px solid var(--grayscale-30);
  }
  .njc-list-card .njc-list-table .njc-list-table-cell {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

// select2 dropdown
.njc-associated-dropdown-row-wrap {
    width: 100%;
    white-space: normal;
    .njc-flex-direction-row;
    .njc-flex-justify;
    .njc-associated-with {
        .njc-text-table-small;
        font-weight: bold;
        white-space: nowrap;
        text-align: right;
        &::before {
            content: "Associated with";
            margin-right: 4px;
            font-weight: normal;
        }
    }
}

.njc-body-pagetitle.njc-within-modal {
  min-height: auto;
  padding: 0px !important;

  .njc-pagetitle-title-wrap {
    width: 100%;
  }
  .njc-pagetitle-title {
    min-height: 44px;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
  .njc-pagetitle-subtext {
    margin-top: 0px !important;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.3px;
    color: var(--grayscale-70);
  }
}

.njc-solo-tag-wrap {
    display: flex;
    flex-wrap: wrap;
    .njc-text-form-border;
    background-color: var(--grayscale-10);

    &.disabled {
        background-color: var(--grayscale-10);
    }
    .njc-solo-tag {
        .njc-text-form;
        .njc-text-form-select-tag;
        align-items: center;
        min-height: 26px;
        background-color: var(--grayscale-20);
        color: var(--grayscale-100);
    }
}

// Data Cloud Demo
.njc-data-cloud-demo-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
}
.njc-data-cloud-bg {
    width: 100%;
    height: auto;
    max-width: 765px;
}
.njc-data-cloud-demo-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 36px;
    row-gap: 20px;

    .njc-data-cloud-demo-title {
        font-size: 34px;
        font-weight: 700;
        line-height: 40px;
        max-width: 380px;
        text-align: center;
    }

    .njc-data-cloud-demo-description {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        max-width: 600px;
        text-align: center;
    }

    .njc-data-cloud-demo-button-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
        .njc-btn-primary , .njc-btn-secondary {
            min-width: 160px;
        }
    }
}

// Shape Login
.njc-extra-login-wrap {
  font-size: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
    gap: 4px;
    a {
        color: var(--primary-color);
        text-decoration: underline;
    }
}

// Error message without has-error wrapper
.njc-form-input-error {
  margin-top: @spacing-1;
  .njc-text-warning;
  display: block;
}
// Toggle Button Styling
.njc-toggle-button-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;
}
.njc-toggle-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18px 24px;
  border: 3px solid var(--grayscale-0);
  border-radius: 8px;
  background-color: var(--grayscale-5);
  cursor: pointer;
  gap: 8px;
  font-weight: 700;
  font-size: 13px;
  color: var(--grayscale-70);
  
  &.selected {
    background-color: var(--grayscale-0);
    border-color: var(--primary-color);
    color: var(--primary-color);
  }
}

// New Modal Title Styling
.njc-full-modal-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .njc-modal-sub-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: var(--grayscale-70);
    text-transform: uppercase;
  }

  .njc-modal-title {
    font-size: 34px;
    font-weight: 700;
    line-height: 40px;
    color: var(--grayscale-100);
  }
}

// Small Modal Title Styling
.njc-small-modal-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .njc-modal-sub-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: var(--grayscale-70);
    text-transform: uppercase;
  }

  .njc-modal-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: var(--grayscale-100);
  }
}

// Dashboard Template Group
.njc-dashboard-template-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 0;
}
.nc-modal-full-page .Fullpage .njc-list-card.njc-dashboard-template-list{
  width: 100%;
  border: 1px solid var(--grayscale-30);
  .njc-list-table-body-empty {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: var(--grayscale-60);
    font-weight: 400;
    line-height: 16px;
    text-align: center;
  }
  .njc-list-table {
    max-height: 600px;
    overflow-y: auto;
    position: relative;
    .njc-list-table-cell {
      span {
        font-weight: 500;
      }
    }
  }
  .njc-list-table-header {
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid var(--grayscale-30);
    position: sticky;
    top: 0;
  }
  .njc-list-table-cell-with-network-icons {
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    align-items: flex-start;
  }
  .njc-network-icon-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;
    .njc-network-icon {
      width: 18px;
      height: 18px;
    }
  }
  .njc-list-table-body-included , .njc-list-table-body-excluded {
    .njc-list-table-row {
      border-bottom: 1px solid var(--grayscale-30);
    }
    .njc-list-table-row:before {
      display: none;
    }
    .njc-list-table-row:last-child {
      border-bottom: none;
    }
  }
  .njc-list-table-body-excluded {
    .njc-list-table-cell {
      opacity: 0.5;
    }
    .njc-table-column-icon {
      opacity: 1;
    }
  }
  .njc-list-header-separator {
    background-color: var(--grayscale-5);
    border-top: 1px solid var(--grayscale-30);
    border-bottom: 1px solid var(--grayscale-30);
    padding: 0 20px;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    span {
      font-weight: 700;
      color: var(--grayscale-60);
      text-transform: uppercase;
      font-size: 12px;
    }
    
  }
  .njc-table-column-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }
  .njc-icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: var(--grayscale-50);
    }
    &.njc-icon-remove-button:hover svg {
      color: var(--critical-color);
    }
    &.njc-icon-add-button:hover svg {
      color: var(--primary-color);
    }
  }
  .njc-icon-left-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    text-indent: 0;
    cursor: pointer;
  }
}

// Report Workflow
#report-workflow-ui {
  margin: 0 auto;
  width: 90%;
  padding-bottom: 8px;
}

.word-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}
