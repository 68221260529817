body {
  #cz_success_center_container {
    z-index: 10 !important;
    .success-center-panel-frame-container.pos-left {
      left: 240px !important;
      transition: left .2s ease-in-out,right .2s ease-in-out !important;
      z-index: 10 !important;
    }
  }
}
@media screen and (max-width: 1140px) {
  body {
    #cz_success_center_container {
      .success-center-panel-frame-container.pos-left {
        left: 65px !important;
      }
    }
    &.njc-body-uncollapse {
      #cz_success_center_container {
        .success-center-panel-frame-container.pos-left {
          left: 240px !important;
        }
      }
    }
  }
}
