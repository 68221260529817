:root {
    --nav-user-display-height: 82px;
}

/*Main Navigation*/

.njc-main-menu {
    width: 240px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--primary-color);
    z-index: 11;
    .njc-transition(0.2s);

    a {
        color: inherit;
    }

    * {
        text-decoration: none;
    }

    .njc-menu-collapsed-button {
        display: none;
    }

    // Body inside njc-main-menu
    .njc-main-menu-body {
        height: 100%;
    }

    // Logo and user display
    .njc-main-menu-account {
        height: var(--nav-user-display-height);
        align-items: flex-start;
        justify-content: flex-end;
        .njc-flex-direction-column;
        position: relative;

        .njc-main-menu-item {
            padding: 0 @spacing-5;
            width: 100%;
        }

        // wrapper for logo and user info
        .njc-main-menu-item-label {
            height: 68px;
        }

        // Logo
        .njc-agency-logo {
            max-height: 44px;
            max-width: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            img,
            svg {
                width: 44px;
                height: auto;
                max-height: 44px !important;
                max-width: 44px !important;
            }
        }

        // User info
        .njc-menu-info {
            height: 36px;
            margin-left: @spacing-2;
            .njc-flex-direction-column;
            align-items: flex-start;
            justify-content: space-between;

            .njc-menu-title {
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                color: var(--grayscale-0);

                .njc-flex-direction-row;
                .njc-flex-startcenter;

                .njc-menu-icon {
                    position: relative;

                    .njc-user-dropdown {
                        display: none;
                        position: absolute;
                        z-index: 100;
                        left: -13px;
                        top: 28px;

                        &-arrow {
                            position: relative;
                            border: var(--grayscale-30);
                            background-color: var(--grayscale-0);
                            min-width: 180px;
                            border: 1px solid var(--grayscale-30);
                            border-radius: 5px;
                            z-index: 99;
                            .njc-flex-direction-column;

                            a {
                                height: 35px;
                                padding: 0 @spacing-3;
                                .njc-text-moreaction-menu;
                                .njc-flex-direction-row;
                                .njc-flex-startcenter;
                                position: relative;

                                &:before {
                                    content: " ";
                                    position: absolute;
                                    left: 0;
                                    bottom: 0;
                                    height: 1px;
                                    width: 100%;
                                    border-bottom: 1px solid var(--grayscale-30);
                                }
                                &:last-child:before {
                                    border-bottom: 0px;
                                }

                                &:hover svg {
                                    color: var(--primary-color);
                                }

                                svg {
                                    margin-right: @spacing-2;
                                }
                            }

                            &:after,
                            &:before {
                                left: 35px;
                                border: solid transparent;
                                content: " ";
                                height: 0;
                                width: 0;
                                position: absolute;
                                pointer-events: none;
                            }
                            &:after {
                                top: -12px;
                                border-bottom-color: var(--grayscale-0);
                                border-width: 6px;
                                margin-left: -20px;
                                z-index: 101;
                            }
                            &:before {
                                top: -14px;
                                border-bottom-color: var(--grayscale-30);
                                border-width: 7px;
                                margin-left: -21px;
                            }
                        }
                    }

                    .show-account-menu {
                        display: block;
                    }
                }
            }
            .njc-menu-subtext {
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                color: var(--primary-color-20);
            }

            .chevron {
                .njc-sizing-sq(18px);

                svg {
                    .njc-sizing-sq(18px);
                    color: var(--primary-color-20);
                }
            }
        }
    }

    // Dropdown body inside njc-main-menu
    .njc-main-menu-dropdowns {
        height: calc(~"100% -" var(--nav-user-display-height));
        .njc-flex-direction-column-start;
        .njc-app-scrollbars;
        overflow-y: overlay;
        overflow-x: hidden;
        z-index: 1;
        position: relative;

        // Navigation section wrapper
        .njc-main-menu-section {
            width: 100%;
            
            &:first-child {
                padding-bottom: 15px;
            }
            
            .njc-main-menu-section-title {
                font-weight: 700;
                font-size: 12px;
                line-height: 18px;
                color: var(--primary-color-10);
                text-transform: uppercase;
                margin: 24px 20px 10px 20px;
            }
        }

        // Parent and dropdown wrapper
        .njc-main-menu-item {
            .njc-flex-direction-column-center;
            .njc-transition(0.2s);
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: var(--primary-color-10);
            cursor: pointer;
            position: relative;
            width: 100%;
            background-color: var(--primary-color);
            margin: 4px 0;
            padding: 0 8px;
            &:hover {
                .njc-main-menu-item-label {
                    color: var(--grayscale-0);
                    background-color: var(--primary-color-hover);
                    width: 100%;
                    border-radius: 5px;
                }
                .njc-menu-icon {
                    color: var(--primary-color-5);
                }
            }

            // Navigation parent wrapper
            .njc-main-menu-item-label {
                display: flex;
                align-items: center;
                width: 100%;
                height: 42px;
                padding: 9px 12px;
                font-size: 14px;
                transition: all 0.2s;
            }

            // Icon for parent links
            .njc-menu-icon {
                .njc-sizing-sq(24px);

                svg {
                    .njc-sizing-sq(24px);
                }

                &.link-icon {
                    margin-left: 10px;
                    color: var(--primary-color-20);
                    .njc-transition(0.2s);
                    .njc-sizing-sq(14px);

                    svg {
                        .njc-sizing-sq(14px);
                    }
                }

                &.ai-icon {
                    margin-left: 10px;
                    .njc-sizing-sq(18px);
                    color: var(--primary-color-10);

                    svg {
                        .njc-sizing-sq(18px);
                    }
                }
            }

            // Text for parent and child links
            .njc-menu-text {
                margin-left: 12px;
                display: flex;
            }

            // .njc-main-menu-dropdowns {
            //   align-items: flex-start;
            //   justify-content: center;
            //   .njc-flex-direction-column;
            // }

            // Navigation child dropdown wrapper
            .njc-main-menu-item-dropwrap {
                .njc-flex-direction-column;
                width: 100%;
                background-color: var(--primary-color);
                max-height: 0;
                .njc-transition(0s);

                .njc-menu-item {
                    font-weight: 500;
                    font-size: 13px;
                    color: var(--primary-color-10);
                    &:hover {
                      color: var(--grayscale-0);
                      transition: color 0.2s;
                    }
                    max-height: 0px;
                    padding: 0 12px 0 48px;
                    .njc-flex-direction-column;
                    align-items: flex-start;
                    justify-content: center;
                    overflow: hidden;
                    .njc-transition(0s);
                    transition: height 1s;
                    .njc-menu-text {
                        font-size: 13px;
                        margin-left: 0;
                        width: 100%;
                        .njc-flex-direction-row;
                        .njc-flex-startcenter;
                        .link-icon {
                            color: var(--primary-color-10);
                            .njc-flex-direction-row;
                            .njc-flex-startcenter;
                        }
                    }

                    &.active , &.w--current{
                        font-weight: 600;
                        color: var(--grayscale-0);
                    }
                }
            }

            // Selected parent link
            &.active {

                .njc-main-menu-item-label {
                    font-weight: 600;
                    color: var(--grayscale-0);
                    background-color: var(--primary-color-dark);
                    width: 100%;
                    border-radius: 5px;
                }

                .njc-main-menu-item-dropwrap {
                    max-height: 400px;
                    overflow: hidden;
                    padding: @spacing-1 @spacing-0;
                    .njc-transition(0.5s);
                    .njc-menu-item {
                        min-height: 33px;
                        max-height: 40px;
                        transition: height 0s;
                        transition-delay: 0s;
                    }
                }
            }
        }

        // Bottom links wrapper
        .njc-main-menu-bottom {
            margin-top: auto;
            padding: 10px 0 16px 0;
        }

        // Separator for sections
        .njc-main-menu-bottom , 
        .njc-main-menu-marketing {
            width: 100%;
            position: relative;

            &:before {
                content: " ";
                position: absolute;
                top: 0;
                left: 20px;
                width: calc(~"100% -" 40px);
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            }
        }
    }

    .njc-main-menu-item-label {
        display: flex;
        align-items: center;
        width: 100%;
    }
}

.njc-menu-full-page-close {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 98;
    left: 0;
    top: 0;
}

.njc-tertiary {
    margin: -25px @spacing-0 @spacing-5 @spacing-0;
    border-bottom: 1px solid var(--grayscale-20);
    width: 100%;
    &-tabs {
        .njc-flex-direction-row;
        align-items: flex-end;
        justify-content: flex-start;

        .njc-command-center-settings-tabs & {
            justify-content: space-between;
        }

        &-link {
            padding: @spacing-2 @spacing-5;
            .njc-text-navigation-tertiary;
            border-bottom: 3px solid transparent;
            text-decoration: none;

            .njc-command-center-settings-tabs & {
                padding: @spacing-2 @spacing-3;
            }

            &-current {
                border-bottom: 4px solid var(--tertiary-color);
            }
        }
    }
}
