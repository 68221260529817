@media screen and (max-width: 1140px) {
    .njc-main-menu {
        width: 64px;
        .njc-transition(0.2s);

        .njc-menu-collapsed-button {
            height: 36px;
            width: 15px;
            border-radius: 0 5px 5px 0;
            background-color: var(--primary-color-dark);
            color: var(--primary-color-5);
            .njc-flex-direction-row-center;
            position: absolute;
            right: -15px;
            top: 30px;
            z-index: 10;
            cursor: pointer;
            svg {
                transform: rotate(270deg);
                .njc-transition(0.2s);
                .njc-sizing-sq(18px);
            }
        }
        // User detail wrapper
        .njc-main-menu-item.account-item {
            position: relative;
            padding: 0px;
            width: 100%;
            transition: padding 0.2s;

            .njc-main-menu-item-label {
                padding: 0 0 0 14px;
                transition: padding 0.2s;
                .njc-agency-logo {
                    img {
                        width: 36px;
                        .njc-transition(0.1s);
                    }
                }
                .njc-menu-info {
                    width: 0%;
                    margin-left: 0px;
                    .njc-transition(0.2s);
                    .njc-menu-title,
                    .njc-menu-subtext {
                        color: transparent;
                        .njc-transition(0.1s);
                        width: 0;
                        white-space: nowrap;
                        overflow: hidden;
                    }

                    .njc-menu-icon {
                        position: static;

                        .njc-user-dropdown {
                            visibility: visible;
                            left: 11px;
                            top: 78px;
                        }
                    }

                }
            }
        }
        // Dropdown body inside njc-main-menu
        .njc-main-menu-dropdowns {
            .njc-main-menu-section {
                // Section title
                .njc-main-menu-section-title {
                    color: transparent;
                    height: 0px;
                    overflow: hidden;
                    white-space: nowrap;
                    margin: 16px 0 0 0;
                    .njc-transition(0.1s);
                }
            }
            .njc-main-menu-item {
                .njc-main-menu-item-label {
                    .njc-menu-text {
                        max-width: 0px;
                        overflow: hidden;
                        margin-left: 0px;
                        color: transparent;
                        white-space: nowrap;
                        .njc-transition(0.1s);
                    }

                    .link-icon {
                        display: none;
                    }
                }

                .njc-main-menu-item-dropwrap {
                    color: transparent;
                    .njc-menu-item {
                        color: transparent;
                        &.active {
                            color: transparent;
                        }
                    }
                }

                &.active {
                    .njc-main-menu-item-dropwrap {
                        max-height: 0px;
                        padding: 0px;
                        white-space: nowrap;
                        .njc-transition(0.1s);
                        color: var(--grayscale-0);
                    }
                }
            }
        }

        /*------------------Opened menu----------------*/
        &.njc-menu-uncollapse {
            width: 240px;

            .njc-menu-collapsed-button {
                svg {
                    transform: rotate(90deg);
                }
            }
            .njc-main-menu-item {

                // User detail wrapper
                &.account-item {
                    padding: 0 20px;
                    .njc-main-menu-item-label {
                        padding: 0;

                        .njc-agency-logo {
                            img {
                                width: 44px;
                                height: auto;
                            }
                        }
                        .njc-menu-info {
                            visibility: visible;
                            height: 36px;
                            margin-left: 8px;
                            position: static;
                            .njc-menu-title,
                            .njc-menu-subtext {
                                width: 100%;
                                white-space: nowrap;
                                overflow: visible;
                            }
                            .njc-menu-title {
                                color: var(--grayscale-0);
                            }
                            .njc-menu-subtext {
                                color: var(--primary-color-20);
                            }
                            .njc-menu-icon {
                                position: relative;

                                .njc-user-dropdown {
                                    visibility: visible;
                                    left: -13px;
                                    top: 28px;
                                }
                            }
                        }
                    }
                }
            }
            // Dropdown body inside njc-main-menu
            .njc-main-menu-dropdowns {
                .njc-main-menu-section {
                    .njc-main-menu-section-title {
                        color: var(--primary-color-10);
                        margin: 24px 20px 10px 20px;
                        height: auto;
                    }
                }
                .njc-main-menu-item {
                    .njc-main-menu-item-label {

                        .njc-menu-text {
                            max-width: 100%;
                            overflow: visible;
                            margin-left: 12px;
                            color: var(--primary-color-10);
                        }

                        .link-icon {
                            display: block;
                        }
                    }
                    // Parent menu item active
                    &.active {
                        .njc-transition(0.2s);
        
                        .njc-menu-text {
                            color: var(--grayscale-0);
                        }

                        .njc-main-menu-item-dropwrap {
                            max-height: 400px;
                            padding: 4px 0px;
                            .njc-menu-item {
                                .njc-menu-text {
                                    color: var(--primary-color-10);
                                }
                                &:hover , &.active {
                                    .njc-menu-text {
                                        color: var(--grayscale-0);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .njc-body {
        margin-left: 64px;
        transition: margin-left 0.2s;

        &.njc-menu-uncollapse {
            margin-left: 240px;
        }
    }
}

@media screen and (max-width: 767px) {
    .njc-login-wrap {
        .njc-flex-direction-row-center;

        .njc-login-left {
            z-index: 1;
            background-color: var(--grayscale-0);
            width: 90%;
            height: 95%;
            border-radius: 5px;
            .njc-shadow;

            .njc-login-header {
                .njc-login-header-desc {
                    .njc-login-mfa-wrap {
                        .njc-flex-direction-column;
                        .njc-flex-centerstart;

                        .njc-login-mfa-instruction {
                            padding: 0 0 @spacing-2 0;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .njc-login-right {
            position: fixed;
            width: 100%;
            height: 100%;
        }
    }
}
