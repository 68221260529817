/* New UI Template Editor Prebuilts*/
.njc-temp-left-panel-header() {
  .njc-font-1;
  font-size: 18px;
  font-weight: 700;
  color: var(--template-main-20);
  text-transform: capitalize;
}

.njc-temp-left-panel-page() {
  .njc-font-1;
  text-align: left;
  color: var(--template-main-40);
  font-weight: 600;
  font-size: 12px;
  &::selection {
    background-color: var(--template-primary-color);
  }
}
.njc-temp-property-wrap-tabs-text() {
  .njc-font-1;
  text-align: left;
  color: var(--template-main-20);
  font-weight: 700;
  font-size: 12px;
}
.njc-temp-right-add-widget-text {
  color: var(--template-main-10);
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  text-transform: capitalize;
  .njc-font-1;
}

.njc-temp-right-panel-header() {
  .njc-font-1;
  text-align: left;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 700;
  color: var(--template-main-5);
}

.njc-temp-right-panel-sub-header() {
  .njc-font-1;
  text-align: left;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  color: var(--template-main-10);
}

.njc-text-widget-status() {
  .njc-font-1;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-transform: capitalize;
}

.njc-temp-right-empty-widget-message() {
  .njc-font-1;
  color: var(--template-main-30);
  margin: 0px 13px;
  padding: 18px;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--template-main-90);
  border-radius: 5px;
  .njc-flex-direction-column-center;
  svg {
    .njc-sizing-sq(24px);
    margin: 0 auto 6px;
  }
}

.njc-temp-right-panel-accord-1() {
  .njc-font-1;
  text-transform: capitalize;
  font-size: 14px;
  color: var(--template-main-10);
  font-weight: 700;
  padding: 0px;
}
.njc-temp-right-panel-accord-2() {
  .njc-font-1;
  text-transform: capitalize;
  font-size: 12px;
  color: var(--template-main-10);
  font-weight: 700;
  padding: 4px 0px;
  text-align: left;
}
.njc-temp-right-panel-accord-border() {
  background-color: var(--template-contour);
  height: 36px;
  padding: 0 13px 1px;
  border: 0px solid #fff;
  border-bottom: 1px solid var(--template-main-100);
  .njc-flex-direction-row;
  .njc-flex-startcenter;

  .njc-temp-accordion-icon {
    .njc-sizing-sq(18px);
    color: var(--template-main-20);
    margin-right: 6px;
  }

  .njc-temp-accordion-arrow {
    .njc-sizing-sq(16px);
    color: var(--grayscale-20);
  }
}
.njc-temp-right-panel-accord-border-2 {
  margin: 0px;
  background-color: var(--template-contour);
  border-radius: 0;
  padding: 0 13px;
  border-bottom: 1px solid var(--template-main-100);
  min-height: 28px;
  height: auto;
}

.njc-temp-more-info-popup() {
  position: absolute;
  left: 50%;
  top: 35px;
  z-index: 2;
  display: block !important;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  transition-delay: 0.5s;

  .njc-temp-more-info-popup-content {
    background-color: var(--template-main-5);
    border: 0px solid var(--template-contour);
    border-radius: 4px;
    .njc-font-1;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    position: relative;
    left: -50%;
    color: var(--template-main-100);
    z-index: 1;
    padding: 6px 8px;
    min-width: 76px;
    max-width: 140px;
    box-shadow: 2px 2px 4px 0 var(--template-border);
    white-space: normal;

    &:after {
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border: 5px solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      border-bottom-color: var(--template-main-5);
      z-index: 2;
    }
    &:before {
      bottom: 100%;
      left: 50%;
      border: 6px solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      border-bottom-color: var(--template-contour);
      z-index: 0;
      margin-left: -6px;
    }
  }
}
.njc-temp-data-source-adding-popup() {
  background-color: var(--template-main-90);
  border: 1px solid var(--template-contour);
  border-radius: 5px;
  .njc-font-1;
  font-size: 12px;
  font-weight: 500;
  .njc-shadow;
  color: var(--template-main-0);
  .njc-flex-direction-column;
  .njc-flex-centerstart;

  &:after {
    top: 13px;
    right: 6px;
    margin-left: -2px;
    border: 5px solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    border-bottom-color: var(--template-main-90);
    z-index: 2;
  }
  &:before {
    top: 11px;
    right: 5px;
    border: 6px solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    border-bottom-color: var(--template-contour);
    z-index: 0;
    margin-left: -3px;
  }
  .njc-temp-right-add-metric-item {
    width: 150px;
    height: 30px;
    cursor: pointer;
    .njc-flex-direction-row;
    .njc-flex-startcenter;
    padding: @spacing-0 @spacing-2;
    border-bottom: 1px solid var(--template-contour);
    white-space: nowrap;

    &:first-child {
      border-radius: 5px 3px 0 0;
    }
    &:last-child {
      border-bottom: 0px;
      border-radius: 0 0 5px 5px;
    }

    svg {
      margin-right: @spacing-1;
    }

    &:hover {
      background-color: var(--template-main-80);
    }
  }
}

.njc-temp-modal-find-replace-entry() {
  .njc-flex-direction-row-start;
  margin-bottom: 15px;

  .settings-panel-property-content {
    width: 50%;
    .njc-flex-direction-column-start;

    &:first-child {
      margin-right: 10px;
    }

    .njc-temp-modal-find-repalce-label {
      .njc-text-form-label;
    }

    .njc-temp-modal-find-repalce-input {
      padding: 0px !important;
      width: 100%;
      position: relative;
      span {
        color: var(--grayscale-60);
        font-size: 14px;
        &:first-child {
          position: absolute;
          left: 8px;
        }
        &:nth-child(2) {
          position: absolute;
          right: 12px;
        }
        &:last-child {
          position: absolute;
          right: 8px;
        }
      }
    }

    .njc-temp-modal-find-repalce-checkbox-wrap {
      .njc-flex-direction-row;
      .njc-flex-startcenter;
      margin-top: 8px;

      b {
        display: none;
      }
      input[type="checkbox"] {
        margin: 0px 6px 0 0;
      }
      .njc-temp-modal-find-repalce-checkbox-text {
        .njc-text-paragraph;
        white-space: nowrap;
        margin-bottom: 0px;
        padding-right: 16px;
        .njc-font-1;
      }
    }


    button {
      .njc-text-link;
      border: 0px;
      background-color: transparent !important;
      height: auto !important;
      margin-top: 8px;
      margin-left: auto;
      padding: 0px;
      cursor: pointer;
    }
  }
}

.njc-temp-right-panel-label-header() {
  .njc-font-1;
  color: var(--template-main-10);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}

.njc-temp-right-panel-label-result() {
  .njc-font-1;
  color: var(--template-main-20);
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.njc-temp-right-panel-property-item() {
  .njc-font-1;
  font-size: 11px;
  color: var(--template-main-5);
  font-weight: 700;
  text-align: left;

  .has-error {
    color: var(--template-main-0);
    font-style: italic;
  }
}
.njc-temp-right-panel-property-item-border() {
  border-radius: 3px;
  padding: @spacing-1 10px;
  margin: @spacing-1 @spacing-0 6px;
  .njc-flex-direction-row;
  .njc-flex-startcenter;
  min-height: 28px;
}
.njc-temp-right-panel-property-item-border-selected() {
  background-color: var(--template-primary-color);
  &:hover {
    background-color: var(--template-primary-color-variant);
  }
}
.njc-temp-right-panel-property-item-border-available() {
  background-color: var(--template-main-90);
  &:hover, &.active {
    background-color: var(--template-hover);
  }
}

.njc-temp-tab-title-text() {
  padding: 12px 13px 4px;
  .njc-font-1;
  font-size: 12px;
  font-weight: 500;
  color: var(--template-main-10);
  text-align: left;
}

.njc-temp-right-input-text() {
  .njc-font-1;
  font-size: 11px;
  color: var(--template-main-20);
  font-weight: 400;
  text-align: left;
  line-height: 12px;
}

.njc-temp-input-form() {
  width: 100%;
  .njc-temp-right-input-text;
  border: 1px solid var(--template-contour);
  border-radius: 5px;
  background-color: var(--template-hover);
  height: 26px;
  background-image: none;
  padding: 0 8px;
  margin-bottom: 0px;
}

.njc-temp-dropdown-form() {
  width: 100%;
  .njc-temp-right-input-text;
  border: 1px solid var(--template-contour);
  border-radius: 5px;
  background-color: var(--template-main-90);
  min-height: 26px;
  background-image: none;
  padding: 4px 8px;
  margin: 0px;
  appearance: none;
  background-image: url("/images/njc/njc-icon-dropdown-medium.svg");
  background-position: calc(~"100% - 6px");
  background-repeat: no-repeat;
  white-space: normal;
}

.njc-temp-toggle-form() {
  padding-top: 5px;
  padding-bottom: 4px;
  label {
    margin-bottom: 0px;
  }
  .toggle-text {
    padding-left: 0px;
    margin-left: 8px;
    .njc-temp-right-panel-label-header;
  }

  .nc-toggle-switch {
    width: 36px;
    height: 20px;
    min-width: 36px;

    .nc-slider {
      width: 36px;
      background-color: var(--template-contour);

      &:before {
        .njc-sizing-sq(16px);
      }
    }

    input:checked + .nc-slider {
      background-color: var(--template-primary-color);
    }
  }

  input:checked + .nc-slider:before {
    transform: translateX(14px);
  }
}

.njc-temp-abbreviation() {
  margin: 0 12px;
  .abbrev-description-title {
    .njc-temp-right-panel-property-item;
    color: var(--template-main-40);
    font-weight: 400;
    margin-left: 0px;
  }
  .abbrev-description-item {
    .njc-flex-direction-row-center;
    .njc-temp-right-panel-property-item;
    color: var(--template-main-20);
  }
}

.njc-temp-color-select() {
  top: 0;
  z-index: 1;
  .sp-preview {
    .njc-sizing-sq(26px);
    border: 1px solid var(--template-contour);
    border-radius: 3px;
    overflow: hidden;
    .sp-preview-inner {
      position: relative;
      .njc-sizing-sq(100%);
      &:after {
        content: " ";
        width: 15px;
        height: 15px;
        background-color: var(--grayscale-0);
        display: block;
        position: absolute;
        bottom: -8px;
        right: -8px;
        transform: rotate(45deg);
      }
    }
  }
}
.njc-temp-color-select-wrap {
  .colorpicker-bit-inner {
    width: 100%;
    opacity: 1;
    .njc-flex-direction-row;
    .njc-flex-startcenter;
    .njc-temp-styling-left-label {
      width: 55px;
      display: flex !important;
      .njc-temp-right-panel-label-header;
    }

    .sp-replacer {
      .njc-temp-color-select;
    }
    img {
      display: none;
    }

    .colorpicker-bit-inner {
      .njc-sizing-sq(26px);
      margin-left: -26px;
      margin-right: 0px;
      border: 1px solid var(--template-contour);
      border-radius: 3px;
      z-index: 2;
      .njc-flex-direction-row-center;
      background-color: var(--template-hover) !important;
      svg {
        .njc-sizing-sq(14px);
        background-color: var(--grayscale-0);
        border-radius: 3px;
      }
    }
  }
}

.njc-temp-upload-image() {
  padding: 0px;
  .settings-panel-property-content {
    margin: 0px;
    min-height: auto;
    justify-content: flex-end;
    .image-preview {
      display: none;
    }

    .file-container {
      margin-bottom: 0px;
      padding-left: 6px;
      .njc-flex-direction-row;
      .njc-temp-right-panel-property-item;
      text-transform: lowercase;

      svg {
        .njc-sizing-sq(18px);
        margin-right: 4px;
      }
      &:first-letter {
        text-transform: capitalize;
      }
      input[type="file"] {
        font-size: 12px;
      }
    }
  }
}

.njc-temp-date-form() {
  margin: 0px;
  border: 1px solid var(--template-contour);
  border-radius: 5px;
  background-color: var(--template-main-90);
  padding: 8px 0;
  min-height: 26px;
  .njc-temp-right-input-text;
  span {
    color: inherit;
    padding: 0px;
    margin-left: 8px;
  }
  img {
    display: none;
  }
  svg {
    .njc-sizing-sq(16px);
    min-width: 16px;
    margin-right: 6px;
  }
}

.njc-temp-search-input() {
  width: 100%;
  height: 34px;
  border: 1px solid var(--template-contour);
  background-color: var(--template-hover);
  padding-left: 35px;
  background-image: url("/images/njc/njc-icon-search.svg");
  background-position: 14px 50%;
  background-size: 14px 14px;
  background-repeat: no-repeat;
  color: var(--template-main-10);
  font-size:12px;

  &::placeholder {
    color: var(--template-main-40);
    font-size:12px;
  }
}

.njc-temp-add-button() {
  .njc-font-1;
  background-color: transparent;
  border-radius: 5px;
  border: 1px dashed var(--template-main-60);
  color: var(--template-main-50);
  height: 34px;
  margin: 10px 0 0 0;
  padding: 0 10px;
  font-weight: 500;
  .njc-flex-direction-row-center;
  text-transform: capitalize;

  svg {
    .njc-sizing-sq(16px);
    margin-right: 6px;
  }

  &:hover {
    border-color: var(--template-main-50);
    color: var(--template-main-20);
    cursor: pointer;
  }
}

.njc-temp-scrollbars() {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;

  }
  &::-webkit-scrollbar-thumb {
    background: var(--template-main-50);
    border: 0px solid transparent;
    border-radius: 4px;
  }
}